import React, { ReactNode } from 'react'
import './HoverText.scss'

import { Popover } from 'antd'

interface HoverTextProps {
  content: string | ReactNode
  text: string | ReactNode
  prefix?: string | ReactNode
  onClick: (item: string) => void
}

const HoverText: React.FC<HoverTextProps> = ({ content, text, prefix, onClick }) => (
  <Popover
    content={
      <div>
        <span className="hover-prefix">{prefix}</span>
        {typeof content == 'string'?<div className="hover-content" dangerouslySetInnerHTML={{ __html: content}}></div>:
        <div className="hover-content" >{content}</div>
        }
      </div>
    }
    title={null}
    placement="right"
  >
    <div className="hover" onClick={onClick}>{text}</div>
  </Popover>
)

export default HoverText
