import React, { CSSProperties } from 'react'
import './EmptyCard.scss'
import { withTranslation, WithTranslation } from 'react-i18next'

import { Icon } from 'antd'

interface EmptyCardProps extends WithTranslation {
  style?: CSSProperties
  theme?: 'dark' | 'light'
}

const EmptyCard: React.FC<EmptyCardProps> = ({ style, theme, t }) => (
  <div className={`empty-card ${theme === 'dark' ? 'dark' : ''}`} style={style}>
    <Icon className="empty-card-icon" type="inbox" />
    <p className="empty-card-content">{t('noData')}</p>
  </div>
)

export default withTranslation('emptyCard')(EmptyCard)
