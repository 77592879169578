import React from 'react'
import { Table} from 'antd'
import { ColumnProps } from 'antd/lib/table'

import { takeover, Pagination} from '../../../_types'
import { scenarioTranslate,secondaryClassification} from '../../../_utils'

interface BagListProps {
  t: any
}

class BagTable extends React.PureComponent<BagListProps> {
  render() {
    const { t,  ...tableProps } = this.props
    const columns: ColumnProps<takeover>[] = [
      {
        title: '二级分类/涉及模块',
        dataIndex: 'name',
        render: (text:any) => scenarioTranslate(text,secondaryClassification),
      },
      {
        title: 'perception',
        dataIndex: 'perception',
      },
      {
        title: 'map',
        dataIndex: 'map',
      },
      {
        title: 'prediction',
        dataIndex: 'prediction',
      },
      {
        title: 'planning',
        dataIndex: 'planning',
      },
      {
        title: 'control',
        dataIndex: 'control',
      },
      {
        title: 'simulation',
        dataIndex: 'simulation',
      },
      {
        title: 'comprehensive',
        dataIndex: 'comprehensive',
      },
      {
        title: 'unknown',
        dataIndex: 'unknown',
      },
      {
        title: t('total'),
        dataIndex: 'total',
      },
    ]
    return (
      <div>
        <Table
          {...tableProps}
          pagination = {false}
          columns={columns}
          bordered
          rowKey='name'
        />
      </div>
    )
  }
}

export default BagTable
