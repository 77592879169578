import React from 'react'
import { Tabs, Icon } from 'antd'
import ListSearch from './ListSearch'
import BarSearch from './BarSearch'

const { TabPane } = Tabs

class Search extends React.PureComponent<any> {
  render() {
    return (
      <Tabs defaultActiveKey="1" className="search-tab">
        <TabPane
          tab={
            <span>
              <Icon type="apple" />
              Bag
            </span>
          }
          key="1"
        >
          <BarSearch />
        </TabPane>
        <TabPane
          tab={
            <span>
              <Icon type="android" />
              Event Bag
            </span>
          }
          key="2"
        >
          <ListSearch />
        </TabPane>
      </Tabs>
    )
  }
}

export default Search
