import modelExtend from 'dva-model-extend'
import lodash from 'lodash'
import { message } from 'antd'
import i18next from 'i18next'
import moment from 'moment'
// @ts-ignore
import * as echarts from 'echarts';
import { pathMatchRegexp } from '../_helpers'
import { VMapePool, VMapSearch, VMapsReducerState, VMapsModel} from '../_types'
import api from '../_dva_services'
import {  modalModel} from '../_utils'

const VMapePools: VMapePool = {
  vehicleAutoComplete: {},
  districts :[]
}
const barChartOption: any = {
  title: {
    show: true,
    text: '车辆',
    left: '42%',
    y: 10,
    textStyle: {
      fontSize: 15
    }
  },
  color: ['rgba(58, 190, 250, 1)', 'rgba(255, 102, 0, 1)', 'rgba(46, 195, 119, 1)'],
  legend: {
    data: ['速度', '加速度'],
    top: '10',
    left: '50%',
    icon: 'circle',
  },
  tooltip: {
    trigger: 'axis',
   // formatter: '时间：{b0}<br/>速度：{c0} m/s<br/>加速度：{c1} m/s²'
  },
  grid: {
    top: 50,
    left: 50,
    right: 50,
    bottom:'22%'
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: []
  },
  yAxis: [{
    name: '速度(m/s)',
    type: 'value',
    nameTextStyle: {
      padding: [0, 0, 0, 15]
    },
    splitLine: {
      show: true
    }
  }, {
    name: '加速度(m/s²)',
    type: 'value',
    nameTextStyle: {
      padding: [0, 30, 0, 0]
    },
    splitLine: {
      show: false
    }
  }],
  dataZoom: [{
    show: true,
    start: 5,
    end: 95
  }]
}
const lineChartOption: any = {
  title: {
    show: true,
    text: '车辆',
    left: '42%',
    y: 10,
    textStyle: {
      fontSize: 15
    }
  },
  color: ['rgba(58, 190, 250, 1)', 'rgba(255, 102, 0, 1)', 'rgba(46, 195, 119, 1)'],
  legend: {
    data: ['加加速度'],
    top: '10',
    left: '50%',
    icon: 'circle',
  },
  tooltip: {
    trigger: 'axis',
  },
  grid: {
    top: 50,
    left: 50,
    right: 50,
    bottom:'22%'
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: []
  },
  yAxis: [{
    name: '加加速度(m/s³)',
    type: 'value',
    nameTextStyle: {
      padding: [0, 0, 0, 15]
    },
    splitLine: {
      show: true
    }
  }],
  dataZoom: [{
    show: true,
    start: 5,
    end: 95
  }]
}
const initSearch: VMapSearch = {
  time: {
    createdAt: ['', (Date.now()).toString()],
    //createdAt: ['1662085525000', '1662085585455'],
    updatedAt: ['', (Date.now() ).toString()],
  },
  vehicleName: '',
}

const initState: VMapsReducerState = {
  pool: VMapePools,
  search: initSearch,
  listingBags: false,
  baseLat:'0',
  baseLon:'0',
  regionChartOption: {
    ...barChartOption,
    series: []
  },
  speedChartOption: {
    ...lineChartOption,
    series: []
  },
  list:[],
  vehicleName:''
  // ...modalState,
}
const vmapsModel: VMapsModel = modelExtend<VMapsModel>(modalModel, {
  namespace: 'vmaps',
  state: initState,
  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(location => {
        if (pathMatchRegexp('/vmaps', location.pathname)) {
          dispatch({ type: 'queryPool' })
          dispatch({ type: 'reset' })
        }
      })
    },
  },
  reducers: {
    reset(state) {
      state.regionChartOption.xAxis.data = []
      state.regionChartOption.series = []
      state.regionChartOption.title.text = `车辆`
      state.speedChartOption.xAxis.data = []
      state.speedChartOption.series = []
      state.speedChartOption.title.text = `车辆`
      return {
        ...state,
        baseLat:'0',
        baseLon:'0',
        search: initSearch,
      }
    },

    listRequest(state, {}) {
      return {
        ...state,
        listingBags: true,
      }
    },

    listSuccess(state: VMapsReducerState) {
      return {
        ...state,
        listingBags: false,
      }
    },

    listFail(state: VMapsReducerState) {
      return {
        ...state,
        listingBags: false,
      }
    },

    listRegionChart(state: VMapsReducerState, { payload }) {
      state.regionChartOption.xAxis.data =  payload.xAxisData.map(function (str:any) {
        return str.replace('.', '\n');
      })
      state.regionChartOption.series = payload.seriesData
      state.regionChartOption.title.text = `车辆${payload.title}`
      payload.regionChart.clear()
      payload.regionChart.setOption(state.regionChartOption)
      // 加加速度
      state.speedChartOption.xAxis.data =  payload.xAxisData.map(function (str:any) {
        return str.replace('.', '\n');
      })
      state.speedChartOption.series = payload.speedData
      state.speedChartOption.title.text = `车辆${payload.title}`
      payload.speedChart.clear()
      console.log(state.speedChartOption)
      payload.speedChart.setOption(state.speedChartOption)
      return {
        ...state,
      }
    },
  },
  effects: {
    *queryPool({ payload = {} }, { call, put }) {
      const data = yield [call(api.listVehicles, payload),call(api.listDistricts, payload)]
      if (data) {
        yield put({
          type: 'updateState',
          payload: {
            pool: {
              vehicleAutoComplete: data[0].success && lodash.keyBy(data[0].vehicles, 'name'),
              districts:data[1].success && data[1].districts
            },
          },
        })
      }
    },


    *setMapChart({payload},{call,put,select}){
      const { search, baseLat,baseLon } = yield select((state: any) => state.vmaps)
      if(search.vehicleName === ''){
        message.warning('请选择车辆')
        return
      }
      if(search.time.createdAt.length === 0||search.time.createdAt[0]===''){
        message.warning('请选择时间')
        return
      }
      if(search.time.createdAt.length>0){
        if(search.time.createdAt[1]-search.time.createdAt[0]>86401000){
          message.warning('数据量大，暂支持查询展示一天数据')
          return
        }
      }
      if(baseLat === '0'||baseLon==='0'){
        message.warning('该车辆没有所属区域')
        return
      }
      let speedObj = []
      let accelerationObj = []
      let timeObj:string[] = []
      let accelerationSpeed = []
      let title = ''
      const para = {
        start_time:search.time.createdAt[0],
        end_time:search.time.createdAt[1],
        vehicle_name:search.vehicleName,
        base_lat :String(baseLat),
        base_lon:String(baseLon),
      }
      yield put({ type: 'listRequest' })
      try {
        const data = yield call(api.vehicleMapList, para)
        console.log(data)
        yield put({ type: 'listSuccess', })
        yield put({
          type: 'updateState',
          payload: {
            list: data.items,
            vehicleName:data.vehicleName
          },
        })
        // 处理速度，加速度，时间字段
        const timeFormate = (time:string)=>{
          const regularTime = moment(time).format('HH:mm:ss')
          // let nanoSecond = '0'
          // if(time.indexOf('.')>0){
          //   nanoSecond = time.split('.')[1].split('+')[0]
          // }
          return `${regularTime}`
        }
        // 如果没数据，给提示
        if(data && data.items && data.items.length === 0){
          message.warn(`${data.vehicleName}车辆暂没有数据`)
        }
        if (data && data.items && data.items.length) {
          // 加加速度
          accelerationSpeed = JSON.parse(JSON.stringify(data.items)).map((item:any)=>{
            return Object.assign(item,{value:0})
          })
          data.items.forEach((item:any)=>{
            item.acceleration = Number(item.acceleration.toFixed(6))
          })
          // 速度
          speedObj = JSON.parse(JSON.stringify(data.items)).map((item:any)=>{
            return Object.assign(item,{value:item.speed})
          })
          //加速度
          accelerationObj = JSON.parse(JSON.stringify(data.items)).map((item:any)=>{
            return Object.assign(item,{value:item.acceleration})
          })
          //时间
          data.items.forEach((item:any) => {
            timeObj.push(timeFormate(item.time))
          });
          // 加加速度
          for(let i=1; i<accelerationSpeed.length; i++){
            let timeDiff = (new Date(accelerationSpeed[i].time).getTime() - new Date(accelerationSpeed[i-1].time).getTime())/1000
            accelerationSpeed[i].value = ((accelerationSpeed[i].acceleration - accelerationSpeed[i-1].acceleration)/timeDiff).toFixed(6)
          }
          console.log(accelerationSpeed)

        }
        if(data.vehicleName){
          title = data.vehicleName
        }
      } catch (e) {
        yield put({ type: 'listFail' })
        message.error('请求数据失败')
      }
      const seriesData = [{
        // data: [{
        //   value:'200',
        //   "longitude": 32.151366944048476,
        //   "latitude":  118.99860130025189,
        //   "acceleration": 0.003927121718127943,
        // }],
        data:speedObj,
        label: {
          show: true,
          position: 'top'
        },
        name: '速度',
        type: 'line',
        smooth: true,
        yAxisIndex: 0
      }, {
        data:accelerationObj,
        label: {
          show: true,
          position: 'top'
        },
        name: '加速度',
        type: 'line',
        smooth: true,
        yAxisIndex: 1
      }]
      const speedData = [{
        data:accelerationSpeed,
        label: {
          show: true,
          position: 'top'
        },
        name: '加加速度',
        type: 'line',
        smooth: true,
        markLine: {
         // symbol: "none", //去掉警戒线最后面的箭头
          data: [
            {
             // silent: false, //鼠标悬停事件  true没有，false有
              lineStyle: {
                //警戒线的样式  ，虚实  颜色
                type: "dashed",
                color: "#FA3934",
              },
              yAxis: "2", 
              label: {
                position: "end",
                formatter:'舒适区'
              },
            },
            {
             // silent: false, //鼠标悬停事件  true没有，false有
              lineStyle: {
                //警戒线的样式  ，虚实  颜色
                type: "dashed",
                color: "#FA3934",
              },
              yAxis: "-2", 
              label: {
                position: "end", 
                formatter:'舒适区'
              },
            },
          ],
        },
      }]
      yield put({
        type: 'listRegionChart',
        payload: {
          seriesData,
          speedData,
          title,
          xAxisData: timeObj,
          regionChart: payload.mapChart,
          speedChart: payload.speedChart
        }
      })
      payload.drawLine
    },
  },
})

export default vmapsModel
