import React from 'react'
import { Link } from 'react-router-dom'
import { Table, Button, Input, Icon, Tooltip } from 'antd'
import { ColumnProps } from 'antd/lib/table'

import { Vehicle, VehicleState, District } from '../../../_types'
import { HoverText, StateTag } from '../../../common'

export interface VehicleListProps {
  districts: District[]
  onCreate: () => void
  onEdit: (item: Vehicle) => void
  loading: boolean
  t: any
}

export class VehicleList extends React.PureComponent<VehicleListProps> {
  getColumnSearchProps = (dataIndex: string): ColumnProps<Vehicle> => {
    const { t } = this.props

    const onInputChange = (e: any, setSelectedKeys: any) => {
      setSelectedKeys && setSelectedKeys(e.target.value ? [e.target.value] : [])
    }
    const onReset = (clearFilters: ((selectedKeys: string[]) => void) | undefined) => {
      clearFilters && clearFilters([])
    }
    const onSearch = (selectedKeys: React.ReactText[] | undefined, confirm: (() => void) | undefined) => {
      confirm && confirm()
    }

    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="trips-filter-dropdown">
          <Input
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys ? selectedKeys[0] : ''}
            onChange={e => onInputChange(e, setSelectedKeys)}
            onPressEnter={() => onSearch(selectedKeys, confirm)}
            style={{ width: 160, marginBottom: '0.5rem', display: 'block' }}
          />
          <Button onClick={() => onReset(clearFilters)} size="small" style={{ width: 75, marginRight: 8 }}>
            {t('btn.reset')}
          </Button>
          <Button type="primary" onClick={() => onSearch(selectedKeys, confirm)} size="small" style={{ width: 75 }}>
            {t('btn.search')}
          </Button>
        </div>
      ),
      filterIcon: filtered => (
        <Icon
          type="search"
          style={{
            width: '2rem',
            fontSize: '0.9rem',
            color: filtered ? '#138D75' : undefined,
          }}
        />
      ),
    }
  }

  render() {
    const { districts, loading, onCreate, onEdit, t, ...tableProps } = this.props

    const columns: ColumnProps<Vehicle>[] = [
      {
        key: 'name',
        dataIndex: 'name',
        title: t('name'),
        render: (text: string, record) => <HoverText text={text} content={record.id} prefix="" />,
        ...this.getColumnSearchProps('name'),
      },
      {
        key: 'districtId',
        dataIndex: 'districtId',
        title: t('district'),
        render: (text: string) => {
          const district = districts.find(district => district.id === text)
          return <HoverText text={district ? district.name : t('tip.unknownDistrict')} content={text} prefix="ID" />
        },
      },
      {
        key: 'type',
        dataIndex: 'type',
        title: t('type'),
      },
      {
        key: 'vin',
        dataIndex: 'vin',
        title: t('vin'),
      },
      {
        key: 'license',
        dataIndex: 'make',
        title: t('make'),
      },
      {
        key: 'model',
        dataIndex: 'model',
        title: t('model'),
      },
      {
        key: 'color',
        dataIndex: 'color',
        title: t('color'),
      },
      {
        key: 'state',
        dataIndex: 'state',
        title: t('state'),
        render: (text: VehicleState) => <StateTag state={text} type="vehicle" />,
      },
      {
        key: 'operationMode',
        dataIndex: 'operationMode',
        title: t('monitor:operationMode'),
        render: (text: string) => text && t(`opMode.${text.toLocaleLowerCase()}`),
      },
      {
        key: 'planningVersion',
        dataIndex: 'planningVersion',
        title: t('planningVersion'),
      },
      {
        key: 'actions',
        fixed: 'right',
        title: (
          <Tooltip title={t('tooltip.addVehicle')} mouseEnterDelay={0.5}>
            <Button type="default" size="small" icon="plus" onClick={onCreate} />
          </Tooltip>
        ),
        render: (text, record) => (
          <div className="vehicles-list-actions">
            <Button type="link" size="small" onClick={() => onEdit(record)}>
              {t('btn.edit')}
            </Button>
            <Link
              to={{
                pathname: '/viz',
                search: '?source=' + record.id,
              }}
              target="_blank"
            >
              <Button type="link" size="small">
                {t('btn.viz')}
              </Button>
            </Link>
          </div>
        ),
      },
    ]

    return <Table {...tableProps} columns={columns} rowKey="id" loading={loading} scroll={{ x: true }} />
  }
}
