import modelExtend from 'dva-model-extend'
import lodash from 'lodash'
import { message } from 'antd'
import i18next from 'i18next'
import moment from 'moment'
import { pathMatchRegexp } from '../_helpers'
import { PerformancesPool, PerformancesSearch, PerformancesReducerState,PerformancesReducerStateOnly, PerformanceModel, ModalState, VEvent } from '../_types'
import api from '../_dva_services'
import { ONE_YEAR } from '../_constants'
import { pageState, pageModel, modalModel, modalState, sortQuery, searchQuery, SearchKeys } from '../_utils'

const PerformancesModelPool: PerformancesPool = {
  vehicleAutoComplete: {},
  taskModels:[],
  modTypes:[],
}

const initSearch: PerformancesSearch = {
  productVersion: '',
  taskId:'',
  taskModel:'',
  modType:'',
  sort: {
    field: 'time.createdAt',
    order: 'descend',
  },
  time: {
    createdAt: ['-1', (Date.now()).toString()],
    updatedAt: ['-1', (Date.now() ).toString()],
  },
  audit: {
    createdBy: '',
    updatedBy: '',
  },
  vehicleName: '',
  startTime: ['-1', (Date.now() + ONE_YEAR).toString()],
  endTime: ['-1', (Date.now() + ONE_YEAR).toString()],
  text: '',
  keys: [],
}

const eventModal: ModalState<VEvent> = {
  currentItem: {} as VEvent,
  modalVisible: false,
  modalType: 'create',
  modifying: false,
}

const initState: PerformancesReducerStateOnly = {
  eventModal: eventModal,
  pool: PerformancesModelPool,
  search: initSearch,
  listingBags: false,
}

const searchKeys: SearchKeys = {
  matchKeys: ['productVersion','eventTime', 'vehicleName', 'audit.createdBy'],
  rangeKeys: ['startTime', 'endTime', 'time.createdAt', 'time.updatedAt'],
  multiMatchKeys: [],
}

const PerformancesModel: PerformanceModel = modelExtend<PerformanceModel>(pageModel, modalModel, {
  namespace: 'performances',
  state: initState,
  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(location => {
        if (pathMatchRegexp('/performances', location.pathname)) {
          dispatch({ type: 'query' })
          dispatch({ type: 'queryPool' })
        }
      })
    },
  },
  reducers: {
    reset(state) {
      return {
        ...state,
        search: initSearch,
      }
    },

    listRequest(state, {}) {
      return {
        ...state,
        listingBags: true,
      }
    },

    listSuccess(state: PerformancesReducerState) {
      return {
        ...state,
        listingBags: false,
      }
    },

    listFail(state: PerformancesReducerState) {
      return {
        ...state,
        listingBags: false,
      }
    },

    // modify = create / update / delete
    modifyRequest(state, {}) {
      return {
        ...state,
        modifying: true,
      }
    },

    modifySuccess(state: PerformancesReducerState) {
      return {
        ...state,
        modifying: false,
      }
    },

    modifyFail(state: PerformancesReducerState) {
      return {
        ...state,
        modifying: false,
      }
    },
    showEventModal(state, { payload }) {
      return {
        ...state,
        eventModal: {
          ...state.eventModal,
          modalVisible: true,
          ...payload,
        },
      }
    },

    hideEventModal(state) {
      return { ...state, eventModal: { ...state.eventModal, modalVisible: false } }
    },

    
  },
  effects: {
    *queryPool({ payload = {} }, { call, put }) {
      const data = yield [call(api.listTaskModel, payload), call(api.listVehicles, payload)
        ,call(api.listModType,payload),
      ]
      if (data) {
        yield put({
          type: 'updateState',
          payload: {
            pool: {
              taskModels: data[0].success && data[0].items.map((t:any)=>{return {value:t.key,text:t.value}}),
              vehicleAutoComplete: data[1].success && lodash.keyBy(data[1].vehicles, 'name'),
              modTypes:data[2].success&&data[2].items.map((t:any)=>{return {value:t.key,text:t.value}}),
            //   taskType:data[3].success&&data[3].items.map((t:any)=>{return {value:t.key,text:t.value}}),
            //   uploadStatus:data[4].success&&data[4].items.map((t:any)=>{return {value:t.key,text:t.value}})
            },
          },
        })
      }
    },



    *query({ payload = {} }, { call, put, select }) {
      const { search, pagination } = yield select((state: any) => state.performances)
      payload = {
        start_date:parseInt(search.time.createdAt[0])==-1?"":moment(parseInt(search.time.createdAt[0])).format('YYYY-MM-DD'),
        end_date :moment(parseInt(search.time.createdAt[1]) ).format('YYYY-MM-DD'),
        vehicle_name:search.vehicleName,
        pagination: pagination,
        task_id:search.taskId,
        product_version:search.productVersion,
        task_model:search.taskModel,
        mod_type:search.modType,
        ...payload,
      }
      payload.pagination = lodash.pick(payload.pagination, ['current', 'total', 'pageSize'])

      try {
        yield put({ type: 'listRequest' })
        // const data = yield call(api.queryBagList, payload)
        const data = yield call(api.queryTaskList, payload)
        yield put({
          type: 'updateState',
          payload: {
            list: data.taskDtls,
            pagination: {
              ...pagination,
              ...data.pagination,
            },
            // search: {
            //   ...search,
            //   sort: cloneSort,
            // },
          },
        })

        yield put({ type: 'listSuccess' })
      } catch (e) {
        yield put({ type: 'listFail' })
      }
    },

    *create({ payload = {} }, { call, put }) {
      yield put({ type: 'modifyRequest' })

      try {
        yield call(api.createBag, { bag: payload })

        yield put({ type: 'modifySuccess' })
        yield put({ type: 'reset' })
        yield put({ type: 'query' })
        yield put({ type: 'hideModal' })

        message.success(i18next.t('msg.createSuccessful'))
      } catch (e) {
        yield put({ type: 'modifyFail' })

        message.error(i18next.t('msg.createFailed'))
      }
    },

    *update({ payload = {} }, { call, put }) {
      yield put({ type: 'modifyRequest' })

      try {
        yield call(api.updateBag, { bag: payload })

        yield put({ type: 'modifySuccess' })
        yield put({ type: 'query' })

        message.success(i18next.t('msg.updateSuccessful'))
      } catch (e) {
        yield put({ type: 'modifyFail' })

        message.error(i18next.t('msg.updateFailed'))
      }
    },

    *delete({ payload = {} }, { call, put }) {
      yield put({ type: 'modifyRequest' })

      try {
        yield call(api.deleteBag, payload)

        yield put({ type: 'modifySuccess' })
        yield put({ type: 'query' })
        yield put({ type: 'hideModal' })

        message.success(i18next.t('msg.deleteSuccessful'))
      } catch (e) {
        yield put({ type: 'modifyFail' })

        message.error(i18next.t('msg.deleteFailed'))
      }
    },

    *uploadLocation({payload = {}},{call,put}){
      console.log(payload)
      try{
        yield call(api.uploadLocation,{carNameAndDate:payload.location})
      }catch(e){
        message.error(i18next.t('msg.updateFailed'))
      }
    }
  },
})

export default PerformancesModel
