import axios, { AxiosResponse } from 'axios'

const axs = axios.create({
  baseURL: '/api/v1/search',
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
    Token: 'this is hack',
  },
})

const get = (params: any, type: string) => {
  return axs.post(`/${type}`, params).then((response: AxiosResponse) => {
    return response.data
  })
}

export const searchServices = {
  get,
}
