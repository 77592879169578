import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'

import { Path, PLocation } from '../../../../_types'
import { EmptyCard } from '../../../../common'
import PointItem from './PointItem'

interface PathListProps extends WithTranslation {
  paths: Path[]
  plocationMap: Map<string, PLocation>
  selectedPathId: string
  onSelectPath: (e: React.MouseEvent, pathId: string) => void
  onSelectPoint: (e: React.MouseEvent, plocationId: string, pathId: string) => void
}

const PathList: React.FC<PathListProps> = ({ paths, plocationMap, selectedPathId, onSelectPath, onSelectPoint }) => {
  const pathList: React.ReactNode[] = paths.map(path => {
    const handleClickPoint = (e: React.MouseEvent, plocationId: string) => {
      onSelectPoint(e, plocationId, path.id)
    }

    const pointList = path.pointIds
      ? path.pointIds.map(pointId => (
          <PointItem key={pointId} point={plocationMap.get(pointId) as PLocation} onClickPoint={handleClickPoint} />
        ))
      : []

    return (
      <li
        key={path.id}
        className={`monitor-list-item ${path.id === selectedPathId ? ' active' : ''} location`}
        onClick={e => onSelectPath(e, path.id)}
      >
        <div className="monitor-list-content">
          <header className="monitor-list-header">
            <h4 className="monitor-list-title">{path.name}</h4>
          </header>
          <ul className="monitor-list-points">
            <PointItem point={plocationMap.get(path.startId) as PLocation} onClickPoint={handleClickPoint} />
            {pointList.length ? pointList : null}
            <PointItem point={plocationMap.get(path.destinationId) as PLocation} onClickPoint={handleClickPoint} />
          </ul>
        </div>
      </li>
    )
  })

  return (
    <ul className="monitor-list">
      {pathList.length ? (
        pathList
      ) : (
        <li className="monitor-list-item">
          <EmptyCard />
        </li>
      )}
    </ul>
  )
}

export default withTranslation('monitor')(PathList)
