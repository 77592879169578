import React from 'react'
import './Scenario.scss'
import { connect } from 'dva'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Dispatch } from 'redux'
import { ScenarioReducerState } from '../../_types/scenariosurvey.types'
import { DvaState } from '../../_types'
import {Spin,Row,Col,Descriptions,Button } from 'antd'
import { scenarioTranslate,gData,secondaryClassification,simulationMainEventType,roadStructureTypes } from '../../_utils'

interface TakeoverProps extends WithTranslation, ScenarioReducerState {
  dispatch: Dispatch,
  match:any,
  scenariorData:any,
  list:any
}

class ScenarioDetail extends React.Component<TakeoverProps> {

  private area: string = ''
  private dataSourceType: string = ''
  private startDate: any = ''
  private endDate: any = ''

  componentDidMount() {
    console.log(this.props)
    const { dispatch } = this.props
    const scenarioId = this.props.match.params.id
    dispatch({
      type: 'scenarioList/queryScenarioDetail',
      payload: {
        id: scenarioId
      },
    })
  }

  render() {
    let {
      listingBags,
      t
    } = this.props
    let {
      id,
      dataSourceType,
      area,
      sourceTable,
      dataSourceName,
      days,
      scenarioPrimaryClassification,
      scenarioSecondaryClassification,
      realScenarioProblemAscription,
      simulationEventTypeJson,
      realCaseType,
      simulationBagPath,
      oriBagPath,
      simulationBagHyperlink,
      scenarioDescriptionActor,
      simulationEgoProblemDescription,
      simulationPerformanceComparisonDescription,
      simulationScenarioId,
      simulationLastJobId,
      simulationLastTaskId,
      simulationJobImageId,
      simulationLastJobCreateTime,
      simulationLastJobImageInfo,
      roadStructureType,
      intersectionName,
      objIdActor,
      objTypeActor,
      drivingDirectionActorCn,
      objIdObstacle,
      objTypeObstacle,
      drivingDirectionObstacleCn
    } = this.props.scenariorData

    const eventType = (str:any) => {
      if(str) {
        let eventObj = JSON.parse(str)
        let eventStr = ''
        eventObj.event_type.forEach((ele:any) => {
          eventStr += (scenarioTranslate(ele,simulationMainEventType)+'，')
        });
        return eventStr
      }
    }
    const replaceALL = (str:any) => {
      if(str) {
        const strAry = str.split('');
        for(let i=0;i<strAry.length;i++){
          if(strAry[i] === '【' && i>1){
            strAry[i] = "<br/>【";
          }
        }
        return strAry.join('');
      }
    }
    return (
      <div className="scenario">
        <section className="scenario-container">
          <div className="monitor-map-loading">
            <Spin spinning={listingBags} />
          </div>
          <div className="scenario-title">场景详情</div>
          <div className="scenario-container-scenario">
            <Row>
              <Col span={24}>
                <div className="info-descript descript-detail">
                  <Descriptions title="基础信息"  bordered column={{ xs: 2, sm: 3, md: 5}}>
                    <Descriptions.Item label="id">{id}</Descriptions.Item>
                    <Descriptions.Item label="数据来源类型">{dataSourceType}</Descriptions.Item>
                    <Descriptions.Item label="地区">{area}</Descriptions.Item>
                    <Descriptions.Item label="来源表">{sourceTable}</Descriptions.Item>
                    <Descriptions.Item label="数据来源名字">{dataSourceName}</Descriptions.Item>
                    <Descriptions.Item label="日期">{days}</Descriptions.Item>
                    <Descriptions.Item label="一级分类">{scenarioTranslate(scenarioPrimaryClassification,gData)}</Descriptions.Item>
                    <Descriptions.Item label="二级分类">{scenarioTranslate(scenarioSecondaryClassification,secondaryClassification)}</Descriptions.Item>
                    <Descriptions.Item label="问题涉及模块">{realScenarioProblemAscription}</Descriptions.Item>
                    <Descriptions.Item label="case类型" span={3}>{realCaseType}</Descriptions.Item>
                    <Descriptions.Item label="事件类型"  span={5}>{eventType(simulationEventTypeJson)}</Descriptions.Item>
                    <Descriptions.Item label="仿真bag地址" span={5}>
                       {simulationBagPath} 
                       <a
                          className="resources-grid-link"
                          href={simulationBagHyperlink}
                          target="_blank"
                          style = {{paddingLeft:'10px'}}
                        >
                          可视化
                        </a>
                    </Descriptions.Item>
                    <Descriptions.Item label="原始bag地址"  span={5}>{oriBagPath}</Descriptions.Item>
                  </Descriptions>
                  <Descriptions title="场景说明"  bordered column={{ xs: 1, sm: 1, md: 1}}>
                    <Descriptions.Item label="场景描述" >
                      <span dangerouslySetInnerHTML={{ __html:replaceALL(scenarioDescriptionActor)}} />
                    </Descriptions.Item>
                    <Descriptions.Item label="自车问题描述">
                     <span dangerouslySetInnerHTML={{ __html:replaceALL(simulationEgoProblemDescription)}} />
                    </Descriptions.Item>
                    <Descriptions.Item label="驾驶性能对比描述">
                      <span dangerouslySetInnerHTML={{ __html:replaceALL(simulationPerformanceComparisonDescription)}} />
                    </Descriptions.Item>
                    <Descriptions.Item><p style={{color:'#ff0000',fontSize:'13px'}}>备注：主车为原始场景中被仿真替换的社会车辆。自车为仿真场景中所模拟的自动驾驶车辆</p></Descriptions.Item>
                  </Descriptions>
                  <Descriptions title="仿真信息"  bordered column={{ xs: 2, sm: 3, md: 6}}>
                    <Descriptions.Item label="仿真平台场景id">{simulationScenarioId}</Descriptions.Item>
                    <Descriptions.Item label="平台job_id">{simulationLastJobId}</Descriptions.Item>
                    <Descriptions.Item label="平台task_id">{simulationLastTaskId}</Descriptions.Item>
                    <Descriptions.Item label="版本id">{simulationJobImageId}</Descriptions.Item>
                    <Descriptions.Item label="job创建时间"  span={3}>{simulationLastJobCreateTime}</Descriptions.Item>
                    <Descriptions.Item label="镜像版本信息" >{simulationLastJobImageInfo}</Descriptions.Item>
                  </Descriptions>
                  <Descriptions title="道路信息"  bordered column={{ xs: 1, sm: 2, md: 6}}>
                    <Descriptions.Item label="道路结构类型">{scenarioTranslate(roadStructureType,roadStructureTypes)}</Descriptions.Item>
                    <Descriptions.Item label="路口名字">{intersectionName}</Descriptions.Item>
                  </Descriptions>
                  <Descriptions title="目标物信息"  bordered column={{ xs: 1, sm: 3, md: 6}}>
                    <Descriptions.Item label="主车id">{objIdActor}</Descriptions.Item>
                    <Descriptions.Item label="主车类型">{objTypeActor}</Descriptions.Item>
                    <Descriptions.Item label="主车行驶方向">{drivingDirectionActorCn}</Descriptions.Item>
                    <Descriptions.Item label="障碍物id">{objIdObstacle}</Descriptions.Item>
                    <Descriptions.Item label="障碍物类型">{objTypeObstacle}</Descriptions.Item>
                    <Descriptions.Item label="障碍物行驶方向">{drivingDirectionObstacleCn}</Descriptions.Item>
                  </Descriptions>
                </div>
              </Col>
            </Row>
          </div>
        </section>
      </div>
    )
  }
}

const mapStateToProps = (state: DvaState) => ({
  ...state.scenarioList,
})

export default connect(mapStateToProps)(withTranslation('scenario')(ScenarioDetail))
