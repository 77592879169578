import React from 'react'
import { Link } from 'react-router-dom'
import { Table, Tooltip, Button } from 'antd'
import { ColumnProps } from 'antd/lib/table'

import { Bag, Pagination, Sort } from '../../../_types'
import { bytesToSize, epochToDate } from '../../../_helpers'
import { HoverText } from '../../../common'

interface BagListProps {
  t: any
  pagination: Pagination
  onChange: any
  sort: Sort
  onCreate: () => void
  onCreateEvent: (item: Bag) => void
  onEdit: (item: Bag) => void
}

class BagTable extends React.PureComponent<BagListProps> {
  render() {
    const { t, onCreate, sort, onEdit, onCreateEvent, pagination, ...tableProps } = this.props

    const columns: ColumnProps<Bag>[] = [
      {
        title: t('name'),
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: t('oldName'),
        dataIndex: 'oldName',
        key: 'oldName',
        render: (text: string) => text && <HoverText content={text} text={text.substr(0, 6) + '...'} />,
      },
      {
        title: t('productVersion'),
        dataIndex: 'productVersion',
        key: 'productVersion',
      },
      {
        title: t('vehicleName'),
        dataIndex: 'vehicleName',
        key: 'vehicleName',
      },
      {
        title: t('description'),
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: t('path'),
        dataIndex: 'path',
        key: 'path',
        render: (text: string) => text && <HoverText content={text} text={text.substr(0, 12) + '...'} />,
      },
      {
        title: t('oldPath'),
        dataIndex: 'oldPath',
        key: 'oldPath',
        render: (text: string) => text && <HoverText content={text} text={text.substr(0, 12) + '...'} />,
      },
      {
        title: t('size'),
        dataIndex: 'size',
        key: 'size',
        render: (bytes: any) => bytesToSize(bytes),
      },
      {
        title: t('startTime'),
        dataIndex: 'startTime',
        key: 'startTime',
        sorter: true,
        sortOrder: sort.field === 'startTime' && sort.order,
        render: (time: any) => epochToDate(time),
      },
      {
        title: t('endTime'),
        dataIndex: 'endTime',
        key: 'endTime',
        sorter: true,
        sortOrder: sort.field === 'endTime' && sort.order,
        render: (time: any) => epochToDate(time),
      },
      {
        title: t('createdAt'),
        dataIndex: 'time.createdAt',
        key: 'time.createdAt',
        sorter: true,
        sortOrder: sort.field === 'time.createdAt' && sort.order,
        render: (time: any) => epochToDate(time),
      },
      {
        title: t('updatedAt'),
        dataIndex: 'time.updatedAt',
        key: 'time.updatedAt',
        sorter: true,
        sortOrder: sort.field === 'time.updatedAt' && sort.order,
        render: (time: any) => epochToDate(time),
      },
      {
        title: t('createdBy'),
        dataIndex: 'audit.createdBy',
        key: 'audit.createdBy',
        sorter: true,
        sortOrder: sort.field === 'audit.createdBy' && sort.order,
      },
      {
        title: t('updatedBy'),
        dataIndex: 'audit.updatedBy',
        key: 'audit.updatedBy',
        sorter: true,
        sortOrder: sort.field === 'audit.updatedBy' && sort.order,
      },
      {
        key: 'action',
        fixed: 'right',
        title: (
          <Tooltip title={t('tooltip.addBag')} mouseEnterDelay={0.5}>
            <Button type="default" size="small" icon="plus" onClick={onCreate} />
          </Tooltip>
        ),
        render: (text: string, bag: Bag) => (
          <div className="bags-list-actions">
            <Button type="link" size="small" onClick={() => onEdit(bag)}>
              {t('btn.edit')}
            </Button>
            <Button type="link" size="small" onClick={() => onCreateEvent(bag)}>
              {t('createVEvent')}
            </Button>
            {bag.path && (
              <Link
                to={{
                  pathname: '/viz',
                  search: '?source=' + bag.path,
                }}
                target="_blank"
              >
                <Button type="link" size="small">
                  {t('btn.viz')}
                </Button>
              </Link>
            )}
          </div>
        ),
      },
    ]

    return (
      <div>
        <Table
          {...tableProps}
          pagination={{
            size: 'small',
            ...pagination,
          }}
          columns={columns}
          rowKey="id"
          scroll={{ x: true }}
        />
      </div>
    )
  }
}

export default BagTable
