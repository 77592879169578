import React from 'react'
import { connect } from 'dva'
import { Dispatch } from 'redux'
import { withTranslation, WithTranslation } from 'react-i18next'

import '../VEvents.scss'
import { DvaState, VEventDetailReducerState } from '../../../_types'
import { epochToDate } from '../../../_helpers'

interface VEventDetailProps extends WithTranslation, VEventDetailReducerState {
  dispatch: Dispatch
}

const fieldRender = (key: any, value: any) => {
  return (
    <div key={key} className="item">
      <div>{key}</div>
      <div>{value}</div>
    </div>
  )
}

class VEventDetail extends React.PureComponent<VEventDetailProps> {
  render() {
    const { event } = this.props

    const items: any[] = []
    ;(Object.keys(event) as Array<keyof typeof event>).forEach(key => {
      let value
      switch (key) {
        case 'gps':
          value = (
            <div>
              <div>{`latitude: ${event.gps.latitude}`}</div>
              <div>{`longitude: ${event.gps.longitude}`}</div>
              <div>{`altitude: ${event.gps.altitude}`}</div>
            </div>
          )
          break
        case 'tags':
          value = (
            <div>
              {event.tags.map(t => (
                <div key={t}>{t}</div>
              ))}
            </div>
          )
          break
        case 'bagNames':
          value = (
            <div>
              {event.bagNames.map(t => (
                <div key={t}>{t}</div>
              ))}
            </div>
          )
          break
        case 'startTime':
          value = epochToDate(event.startTime)
          break
        case 'endTime':
          value = epochToDate(event.endTime)
          break
        case 'time':
          ;(Object.keys(event.time) as Array<keyof typeof event.time>).forEach(k => {
            items.push(fieldRender(k, epochToDate(event.time[k])))
          })
          return
        case 'audit':
          ;(Object.keys(event.audit) as Array<keyof typeof event.audit>).forEach(k => {
            items.push(fieldRender(k, event.audit[k]))
          })
          return
        default:
          value = String(event[key])
      }
      items.push(fieldRender(key, value))
    })

    return (
      <div className="v-events">
        <div className="v-events-inner">
          <div className="v-events-content">{items}</div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: DvaState) => state.veventsDetail

export default connect(mapStateToProps)(withTranslation('veventsDetail')(VEventDetail))
