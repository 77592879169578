import { CSSProperties } from 'react'
import vars from '../../variables.scss'

/** Style Doc:
 *  https://lbs.amap.com/api/javascript-api/reference-amap-ui/mass-data/pathsimplifier#render
 * */

// Fence
export enum FENCE_TYPE {
  RECTANGLE,
}

export interface FenceData {
  type: FENCE_TYPE
  p1: [number, number]
  p2: [number, number]
}

export const FENCE: FenceData[] = [
  {
    type: FENCE_TYPE.RECTANGLE,
    p1: [121.1538, 31.308592],
    p2: [121.161985, 31.315826],
  },

  {
    type: FENCE_TYPE.RECTANGLE,
    p1: [121.157417, 31.272234],
    p2: [121.172836, 31.308592],
  },

  {
    type: FENCE_TYPE.RECTANGLE,
    p1: [121.170361, 31.265001],
    p2: [121.200818, 31.297932],
  },

  {
    type: FENCE_TYPE.RECTANGLE,
    p1: [121.171503, 31.258338],
    p2: [121.190158, 31.265191],
  },

  {
    type: FENCE_TYPE.RECTANGLE,
    p1: [121.198915, 31.262145],
    p2: [121.219664, 31.295648],
  },

  {
    type: FENCE_TYPE.RECTANGLE,
    p1: [121.219283, 31.264743],
    p2: [121.242153, 31.273757],
  },

  {
    type: FENCE_TYPE.RECTANGLE,
    p1: [121.241225, 31.262887],
    p2: [121.277345, 31.266528],
  },

  {
    type: FENCE_TYPE.RECTANGLE,
    p1: [121.276488, 31.252251],
    p2: [121.282056, 31.263458],
  },

  {
    type: FENCE_TYPE.RECTANGLE,
    p1: [121.280058, 31.243542],
    p2: [121.287125, 31.252965],
  },

  {
    type: FENCE_TYPE.RECTANGLE,
    p1: [121.917567, 30.883402],
    p2: [121.955787, 30.91612],
  },

  {
    type: FENCE_TYPE.RECTANGLE,
    p1: [121.909723, 30.874957],
    p2: [121.919538, 30.885962],
  },

  {
    type: FENCE_TYPE.RECTANGLE,
    p1: [121.894813, 30.641013],
    p2: [122.054308, 30.876561],
  },

  {
    type: FENCE_TYPE.RECTANGLE,
    p1: [121.221187, 31.323057],
    p2: [121.233468, 31.332299],
  },

  {
    type: FENCE_TYPE.RECTANGLE,
    p1: [121.15492, 31.275017],
    p2: [121.160678, 31.279086],
  },
]

// Map styles
const LINE_WIDTH = 8
const DISTRICT_OPACITY = 0.2
const PLOCATION_OPACITY = 0.8

export const MAP_STYLES = {
  EMPTY_LINE: {
    strokeStyle: 'rgba(0,0,0,0)',
    lineWidth: 0,
    borderStyle: 'rgba(0,0,0,0)',
    borderWidth: 0,
  },
  TRANS_LINE: {
    strokeStyle: 'rgba(0,0,0,0.2)',
    lineWidth: LINE_WIDTH,
    borderStyle: 'rgba(0,0,0,0)',
    borderWidth: 0,
  },
  TRANS_DARK_LINE: {
    strokeStyle: 'rgba(0,0,0,0.3)',
    lineWidth: LINE_WIDTH,
    borderStyle: 'rgba(0,0,0,0)',
    borderWidth: 0,
  },
  EMPTY_POINT: {
    radius: 15,
    fillStyle: null,
    strokeStyle: null,
    lineWidth: 0,
  },
  HOVER_TITLE: {
    classNames: 'monitor-map-title',
    offset: [10, 0],
  },
  DISTRICT_CIRCLE: {
    radius: 12,
    strokeColor: vars.districtCircle,
    strokeOpacity: DISTRICT_OPACITY,
    strokeWeight: 8,
    fillColor: '#ffffff',
    fillOpacity: DISTRICT_OPACITY,
    zIndex: 0,
  },
  PLOCATION_CIRCLE: {
    radius: 8,
    strokeColor: '#ffffff',
    strokeOpacity: PLOCATION_OPACITY,
    strokeWeight: 3,
    fillColor: '#ffffff',
    fillOpacity: PLOCATION_OPACITY,
    zIndex: 10,
    cursor: 'pointer',
  },
  PATH_LINE: {
    strokeColor: vars.pathLine,
    strokeOpacity: 0.8,
    strokeWeight: 5,
    lineJoin: 'round',
    lineCap: 'round',
  },
}

// Ohter styles
export const SUSPENDED_TAG_STYLE: CSSProperties = {
  backgroundColor: '#f0f0f0',
  color: '#797979',
}

// Presets
export const DEFAULT_VALUE = '----'
export const TOOLTIP_DELAY = 0.5
export const MAP_ZOOM = {
  // INITIAL: 11,
  INITIAL: 14,
}
export const COOL_DOWN = 3 * 60 * 1000 // 3 mins
export const PATH_LEN = 25 // the length of the path must be no less than 2
