import React from 'react'
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom'
import { withCookies, Cookies } from 'react-cookie'

import { WithAuth } from '../WithAuth'

interface AuthRouteProps extends RouteProps {
  cookies: Cookies
  role: string
}

const AuthRoute: React.FC<AuthRouteProps> = ({ component, cookies, role, ...rest }) => {
  const Component = component as React.ComponentType<RouteComponentProps>
  const token = cookies.get('token')
  return (
    <Route
      {...rest}
      render={props =>
        token ? (
          <WithAuth token={token} role={role}>
            <Component {...props} />
          </WithAuth>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

export default withCookies(AuthRoute)
