import React from 'react'
import { Modal, Form, Input, InputNumber, Button } from 'antd'
import { FormComponentProps } from 'antd/lib/form/Form'

import { ModalType, District } from '../../../_types'

const modalBodyStyle = {
  maxHeight: '80vh',
  overflow: 'auto',
  padding: '1rem 1.5rem 0.5rem',
}

const formItemLayout = {
  labelAlign: 'left' as 'left',
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

interface DistrictModalProps extends FormComponentProps {
  item?: District
  loading: boolean
  modalType: ModalType
  onModify: any
  onCancel: () => void
  t: any
}

class DistrictModal extends React.PureComponent<DistrictModalProps> {
  handleUpdate = () => {
    const { item, onModify, modalType, form } = this.props
    const { validateFields, getFieldsValue } = form

    validateFields(errors => {
      if (errors) {
        return
      }

      const data: any = {
        ...getFieldsValue(),
        ...(item && { id: item.id }),
      }

      onModify(data, modalType)
    })
  }

  render() {
    const { item, loading, modalType, onModify, form, t, ...modalProps } = this.props
    const { getFieldDecorator } = form

    return (
      <Modal
        bodyStyle={modalBodyStyle}
        {...modalProps}
        footer={
          <>
            {item ? (
              <Button loading={loading} onClick={() => onModify({ id: item.id }, 'delete')}>
                {t('btn.delete')}
              </Button>
            ) : null}
            <Button type="primary" onClick={this.handleUpdate} loading={loading}>
              {t(`btn.${modalType}`)}
            </Button>
          </>
        }
      >
        <Form {...formItemLayout}>
          <Form.Item label={t('name')}>
            {getFieldDecorator('name', {
              initialValue: item && item.name,
              rules: [{ required: true, message: t('tip.required') }],
            })(<Input />)}
          </Form.Item>
          <Form.Item label={t('longitude')}>
            {getFieldDecorator('longitude', {
              initialValue: item && item.longitude,
              rules: [{ required: true, message: t('tip.required') }],
            })(<InputNumber />)}
          </Form.Item>
          <Form.Item label={t('latitude')}>
            {getFieldDecorator('latitude', {
              initialValue: item && item.latitude,
              rules: [{ required: true, message: t('tip.required') }],
            })(<InputNumber />)}
          </Form.Item>
          <Form.Item label={t('radius')}>
            {getFieldDecorator('radius', {
              initialValue: item && item.radius,
              rules: [{ required: true, message: t('tip.required') }],
            })(<InputNumber />)}
          </Form.Item>
          <Form.Item label={t('baseLon')}>
            {getFieldDecorator('baseLon', {
              initialValue: item && item.baseLon,
              rules: [{ required: true, message: t('tip.required') }],
            })(<InputNumber />)}
          </Form.Item>
          <Form.Item label={t('baseLat')}>
            {getFieldDecorator('baseLat', {
              initialValue: item && item.baseLat,
              rules: [{ required: true, message: t('tip.required') }],
            })(<InputNumber />)}
          </Form.Item>
          <Form.Item label={t('baseX')}>
            {getFieldDecorator('baseX', {
              initialValue: item && item.baseX,
            })(<InputNumber />)}
          </Form.Item>
          <Form.Item label={t('baseY')}>
            {getFieldDecorator('baseY', {
              initialValue: item && item.baseY,
            })(<InputNumber />)}
          </Form.Item>
          <Form.Item label={t('baseZ')}>
            {getFieldDecorator('baseZ', {
              initialValue: item && item.baseZ,
            })(<InputNumber />)}
          </Form.Item>
          <Form.Item label={t('mapRegion')}>
            {getFieldDecorator('mapRegion', {
              initialValue: item && item.mapRegion,
            })(<Input />)}
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default Form.create<DistrictModalProps>()(DistrictModal)
