import React from 'react'
import './Dashboard.scss'
import { connect } from 'dva'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Dispatch } from 'redux'

import { Vehicle, DvaState } from '../../_types'

interface DashboardProps extends WithTranslation {
  vehicles: Vehicle[]
  dispatch: Dispatch
}

class Dashboard extends React.Component<DashboardProps, {}> {
  render() {
    const { t } = this.props

    return (
      <div className="dashboard">
        <div className="dashboard-panel">
          <header className="dashboard-panel-header">
            <h2>{t('statisticsTitle')}</h2>
          </header>
          <div className="dashboard-panel-content">TBD</div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: DvaState) => ({
  vehicles: state.vehicles.vehicles,
})

export default connect(mapStateToProps)(withTranslation('dashboard')(Dashboard))
