import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
// import { createLogger } from 'redux-logger'
import rootReducer from '../_reducers'

// const loggerMiddleware = createLogger()

// export const store = createStore(rootReducer, applyMiddleware(thunkMiddleware, loggerMiddleware))
// redux-thunk 中间件就会被应用到 Redux store 中, 可以更灵活地处理异步操作
export const store = createStore(rootReducer, applyMiddleware(thunkMiddleware))
