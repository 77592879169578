import React from 'react'
import { Modal, Form, Input, Select, Button, Collapse, Icon, Spin } from 'antd'
import { FormComponentProps } from 'antd/lib/form/Form'

import { ModalType, Vehicle, GetResponseData, District } from '../../../_types'

const { Panel } = Collapse
const { Option } = Select

const modalBodyStyle = {
  maxHeight: '80vh',
  overflow: 'auto',
  padding: '1rem 1.5rem 0.5rem',
}

const formItemLayout = {
  labelAlign: 'left' as 'left',
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

// Collapse Panel style
const panelStyle = {
  border: 'none',
}

interface VehicleModalProps extends FormComponentProps {
  item?: Vehicle
  districts?: District[]
  loading: boolean
  gettingVehicle: boolean
  modalType: ModalType
  onModify: any
  onCancel: () => void
  onCollapse: () => any
  t: any
}

interface VehilceModalState {
  activeKey: string
}

class VehicleModal extends React.PureComponent<VehicleModalProps, VehilceModalState> {
  constructor(props: any) {
    super(props)

    this.state = {
      activeKey: '',
    }
  }

  componentDidUpdate(prevProps: VehicleModalProps) {
    const { item } = this.props

    // Reset stops in form
    if (prevProps.item !== item) {
      this.setState({ activeKey: '' })
    }
  }

  handleUpdate = () => {
    const { item, onModify, modalType, form } = this.props
    const { validateFields, getFieldsValue } = form

    validateFields(errors => {
      if (errors) {
        return
      }

      const data: any = {
        ...getFieldsValue(),
        ...(item && { id: item.id }),
      }

      onModify(data, modalType)
    })
  }

  handleChangeCollapse = () => {
    const { activeKey } = this.state
    const { onCollapse, form } = this.props

    if (!activeKey) {
      onCollapse().then((data: GetResponseData) => {
        this.setState({ activeKey: '0' }, () =>
          form.setFieldsValue({
            accessKey: data.accessKey,
            accessSecret: data.accessSecret,
          }),
        )
      })
    } else {
      this.setState({ activeKey: '' })
    }
  }

  render() {
    const { activeKey } = this.state
    const { item, districts, loading, gettingVehicle, modalType, onModify, form, t, ...modalProps } = this.props
    const { getFieldDecorator } = form

    const DistrictsRender = () => {
      return (
        <Form.Item label={t('district')}>
          {getFieldDecorator('districtId', {
            initialValue: item && item.districtId,
            rules: [{ required: true, message: t('tip.required') }],
          })(
            <Select>
              {districts && districts.map(district => <Option key={district.id}>{district.name}</Option>)}
            </Select>,
          )}
        </Form.Item>
      )
    }

    return (
      <Modal
        bodyStyle={modalBodyStyle}
        {...modalProps}
        footer={
          <>
            {item ? (
              <Button loading={loading} onClick={() => onModify({ id: item.id }, 'delete')}>
                {t('btn.delete')}
              </Button>
            ) : null}
            <Button type="primary" onClick={this.handleUpdate} loading={loading}>
              {t(`btn.${modalType}`)}
            </Button>
          </>
        }
      >
        <Form {...formItemLayout}>
          <h4 className="vehicles-form-divider">{t('basic')}</h4>
          <div className="vehicles-form-section">
            <Form.Item label={t('name')}>
              {getFieldDecorator('name', {
                initialValue: item && item.name,
                rules: [{ required: true, message: t('tip.required') }],
              })(<Input />)}
            </Form.Item>
            <DistrictsRender />
            <Form.Item label={t('type')}>
              {getFieldDecorator('type', {
                initialValue: item && item.type,
                rules: [{ required: true, message: t('tip.required') }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label={t('state')}>
              {getFieldDecorator('state', {
                initialValue: item && item.state,
                rules: [{ required: true, message: t('tip.required') }],
              })(
                <Select>
                  <Option value="INACTIVE">{t('stateTag:vehicle.inactive')}</Option>
                  <Option value="AVAILABLE">{t('stateTag:vehicle.available')}</Option>
                  <Option value="INUSE">{t('stateTag:vehicle.inuse')}</Option>
                </Select>,
              )}
            </Form.Item>
            <Form.Item label={t('operationMode')}>
              {getFieldDecorator('operationMode', {
                initialValue: item && item.operationMode,
                rules: [{ required: true, message: t('tip.required') }],
              })(
                <Select>
                  <Option value="NORMAL">{t('opMode.normal')}</Option>
                  <Option value="DEMO">{t('opMode.demo')}</Option>
                </Select>,
              )}
            </Form.Item>
            <Form.Item label={t('planningVersion')}>
              {getFieldDecorator('planningVersion', {
                initialValue: item && item.planningVersion,
                rules: [{ required: true, message: t('tip.required') }],
              })(
                <Select>
                  <Option value="V2">V2</Option>
                  <Option value="V3">V3</Option>
                </Select>,
              )}
            </Form.Item>
          </div>
          <h4 className="vehicles-form-divider">{t('spec')}</h4>
          <div className="vehicles-form-section">
            <Form.Item label={t('vin')}>
              {getFieldDecorator('vin', {
                initialValue: item && item.vin,
              })(<Input />)}
            </Form.Item>
            <Form.Item label={t('license')}>
              {getFieldDecorator('license', {
                initialValue: item && item.license,
              })(<Input />)}
            </Form.Item>
            <Form.Item label={t('make')}>
              {getFieldDecorator('make', {
                initialValue: item && item.make,
              })(<Input />)}
            </Form.Item>
            <Form.Item label={t('model')}>
              {getFieldDecorator('model', {
                initialValue: item && item.model,
              })(<Input />)}
            </Form.Item>
            <Form.Item label={t('color')}>
              {getFieldDecorator('color', {
                initialValue: item && item.color,
              })(<Input />)}
            </Form.Item>
          </div>
          {modalType === 'create' ? (
            <>
              <h4 className="vehicles-form-divider">{t('credential')}</h4>
              <div className="vehicles-form-section">
                <Form.Item label={t('accessSecret')}>{getFieldDecorator('accessSecret')(<Input />)}</Form.Item>
              </div>
            </>
          ) : (
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => <Icon type="caret-left" rotate={isActive ? -90 : 0} />}
              expandIconPosition="right"
              activeKey={activeKey}
              onChange={this.handleChangeCollapse}
            >
              <Panel
                header={
                  <h4 className="vehicles-form-divider">
                    {t('credential')}
                    <span className="vehicles-form-divider-spin">
                      <Spin size="small" spinning={gettingVehicle} />
                    </span>
                  </h4>
                }
                key="0"
                style={panelStyle}
              >
                <div className="vehicles-form-section">
                  <Form.Item label={t('accessKey')}>{getFieldDecorator('accessKey')(<Input disabled />)}</Form.Item>
                  <Form.Item label={t('accessSecret')}>{getFieldDecorator('accessSecret')(<Input />)}</Form.Item>
                </div>
              </Panel>
            </Collapse>
          )}
        </Form>
      </Modal>
    )
  }
}

export default Form.create<VehicleModalProps>()(VehicleModal)
