import React from 'react'
import { Table, Button, Input, Icon } from 'antd'
import { ColumnProps } from 'antd/lib/table'

import { Trip, Pagination, Vehicle } from '../../../_types'
import { epochToDate } from '../../../_helpers'
import { HoverText } from '../../../common'
import QRCode from 'qrcode.react'
import ReactJson from 'react-json-view'

interface ListProps {
  vehicles: Vehicle[]
  onEditItem: (item: Trip) => void
  onChange: any
  pagination: Pagination
  loading: boolean
  t: any
}

class List extends React.PureComponent<ListProps> {
  getColumnSearchProps = (dataIndex: string): ColumnProps<Trip> => {
    const { t } = this.props

    const onInputChange = (e: any, setSelectedKeys: any) => {
      setSelectedKeys && setSelectedKeys(e.target.value ? [e.target.value] : [])
    }
    const onReset = (clearFilters: ((selectedKeys: string[]) => void) | undefined) => {
      clearFilters && clearFilters([])
    }
    const onSearch = (selectedKeys: React.ReactText[] | undefined, confirm: (() => void) | undefined) => {
      confirm && confirm()
    }
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="trips-filter-dropdown">
          <Input
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys ? selectedKeys[0] : ''}
            onChange={e => onInputChange(e, setSelectedKeys)}
            onPressEnter={() => onSearch(selectedKeys, confirm)}
            style={{ width: 160, marginBottom: '0.5rem', display: 'block' }}
          />
          <Button onClick={() => onReset(clearFilters)} size="small" style={{ width: 75, marginRight: 8 }}>
            {t('btn.reset')}
          </Button>
          <Button type="primary" onClick={() => onSearch(selectedKeys, confirm)} size="small" style={{ width: 75 }}>
            {t('btn.search')}
          </Button>
        </div>
      ),
      filterIcon: filtered => (
        <Icon
          type="search"
          style={{
            width: '2rem',
            fontSize: '0.9rem',
            color: filtered ? '#138D75' : undefined,
          }}
        />
      ),
    }
  }

  render() {
    const { t, loading, onEditItem, pagination, vehicles, ...tableProps } = this.props

    const columns: ColumnProps<Trip>[] = [
      {
        key: 'serialNum',
        dataIndex: 'serialNum',
        title: t('serialNum'),
        ...this.getColumnSearchProps('serialNum'),
      },
      {
        key: 'id',
        dataIndex: 'id',
        title: 'ID',
        render: (text: string) => <HoverText text={text} content={<QRCode value={text} />} prefix="" />,
      },
      {
        key: 'pathindex',
        dataIndex: 'pathIndex',
        title: t('pathIndex'),
        render: (obj: {} | undefined) => {
          return obj ? <HoverText text="show" content={<ReactJson src={obj} />} /> : <div />
        },
      },
      {
        key: 'start',
        dataIndex: 'start.name',
        title: t('start'),
      },
      {
        key: 'destination',
        dataIndex: 'desPickupLocs',
        title: t('destination'),
        render: (record: any) => {
          return (
            record &&
            record.length && (
              <HoverText
                text={record[0].name}
                content={record.map((r: any) => (
                  <div key={r.id}>{r.name}</div>
                ))}
              />
            )
          )
        },
      },
      {
        key: 'userId',
        dataIndex: 'userId',
        title: t('user'),
        render: (text: string, record) => <HoverText text={record.userPhone} content={text} prefix="ID" />,
      },
      {
        key: 'vehicleId',
        dataIndex: 'vehicleId',
        title: t('vehicle'),
        render: (text: string, record) => (
          <HoverText text={record.vehicleName || t('tip.unknownVehicle')} content={text} prefix="ID" />
        ),
      },
      {
        key: 'count',
        dataIndex: 'count',
        title: t('count'),
      },
      {
        key: 'feedback',
        dataIndex: 'feedback',
        title: t('feedback'),
      },
      {
        key: 'certUrl',
        dataIndex: 'certUrl',
        title: t('cert'),
        render: (text: string) =>
          text ? (
            <a className="trips-list-download" href={text}>
              <Icon type="download" />
            </a>
          ) : null,
      },
      {
        key: 'status',
        dataIndex: 'status',
        title: t('status'),
      },
      {
        key: 'createdAt',
        dataIndex: 'createdAt',
        title: t('createdAt'),
        render: (text: string) => (text ? <span>{epochToDate(text)}</span> : null),
      },
      {
        key: 'startedAt',
        dataIndex: 'startedAt',
        title: t('startedAt'),
        render: (text: string) => (text ? <span>{epochToDate(text)}</span> : null),
      },
      {
        key: 'completedAt',
        dataIndex: 'completedAt',
        title: t('completedAt'),
        render: (text: string) => (text ? <span>{epochToDate(text)}</span> : null),
      },
      {
        key: 'actions',
        render: (text, record) => (
          <Button type="link" size="small" onClick={() => onEditItem(record)}>
            {t('btn.edit')}
          </Button>
        ),
      },
    ]

    return (
      <Table
        {...tableProps}
        columns={columns}
        rowKey="id"
        pagination={{
          size: 'small',
          ...pagination,
        }}
        loading={loading}
        scroll={{ x: true }}
      />
    )
  }
}

export default List
