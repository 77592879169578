import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import moment from 'moment'
import { Tooltip, Icon } from 'antd'

import { Vehicle, VehicleStatus } from '../../../../_types'
import { EmptyCard } from '../../../../common'
import { SUSPENDED_TAG_STYLE, DEFAULT_VALUE, TOOLTIP_DELAY } from '../../constants'
import { toPrecision, msToKmh } from '../../../../_utils'
import { getRunningState } from '../../util'
import { Tag } from '../'

interface VehicleListProps extends WithTranslation {
  vehicles: Vehicle[]
  selectedVehicleId: string
  trackingVehicleId: string
  onSelectVehicle: (e: React.MouseEvent, vehicleId: string) => void
  onTrackVehicle: (vehicleId: string) => void
  onClearSelectedVehicle: () => void
}

const VehicleList: React.FC<VehicleListProps> = ({
  vehicles,
  selectedVehicleId,
  trackingVehicleId,
  onSelectVehicle,
  onTrackVehicle,
  onClearSelectedVehicle,
  t,
}) => {
  const vehicleList: React.ReactNode[] = vehicles
    .sort((vehicleA: Vehicle, vehicleB: Vehicle) => vehicleA.name.localeCompare(vehicleB.name))
    .sort((vehicleA: Vehicle, vehicleB: Vehicle) => {
      const statusA = vehicleA.status as VehicleStatus
      const isRunningA = getRunningState(statusA.timestamp)
      const statusB = vehicleB.status as VehicleStatus
      const isRunningB = getRunningState(statusB.timestamp)

      return isRunningA && !isRunningB ? -1 : 0
    })
    .map((vehicle: Vehicle) => {
      const status = vehicle.status as VehicleStatus
      const isRunning = getRunningState(status.timestamp)

      return (
        <li
          key={vehicle.id}
          className={`monitor-list-item${vehicle.id === selectedVehicleId ? ' active' : ''}${
            !trackingVehicleId || vehicle.id === trackingVehicleId ? '' : ' masked'
          }`}
          onClick={e => onSelectVehicle(e, vehicle.id)}
        >
          <div className="monitor-list-content">
            <header className="monitor-list-header">
              <h4 className="monitor-list-title">
                {vehicle.name}
                <span className="monitor-list-subtitle">{vehicle.model || DEFAULT_VALUE}</span>
              </h4>
              <div className="monitor-list-liveness">
                {vehicle.operationMode === 'DEMO' ? (
                  <Tag style={{ backgroundColor: '#AF7AC5' }}>{t(`opMode.demo`)}</Tag>
                ) : null}
                <Tag
                  style={
                    isRunning
                      ? { backgroundColor: status.drivingMode === 'MODE_AUTO' ? '#101010' : '#5499C7' }
                      : SUSPENDED_TAG_STYLE
                  }
                >
                  {status.drivingMode ? t(`drivingMode.${status.drivingMode.toLowerCase()}`) : DEFAULT_VALUE}
                </Tag>
              </div>
            </header>
            <section className="monitor-list-block">
              <div className="monitor-list-stats">
                <h6 className="monitor-list-stats-title">{t('speed')}</h6>
                <div className="monitor-list-stats-figure">{toPrecision(msToKmh(status.speed || 0), 2)}</div>
              </div>
              <div className="monitor-list-stats">
                <h6 className="monitor-list-stats-title">{t('accel')}</h6>
                <div className="monitor-list-stats-figure">{toPrecision(status.accel.linear.X || 0, 2)}</div>
              </div>
              <div className="monitor-list-stats">
                <h6 className="monitor-list-stats-title">{t('planningState')}</h6>
                <div className="monitor-list-stats-figure">
                  {status.planningState ? status.planningState : DEFAULT_VALUE}
                </div>
              </div>
            </section>
            <footer className="monitor-list-footer">
              <span className="monitor-list-footer-version">{status.productVersion || t('unkownVersion')}</span>
              <span className="monitor-list-footer-time">
                {moment(parseInt(status.timestamp)).format('YYYY-MM-DD  HH:mm:ss')}
              </span>
            </footer>
          </div>
          <div className="monitor-list-actions">
            <Tooltip title={t('tip.track')} mouseEnterDelay={TOOLTIP_DELAY}>
              <button
                className={`monitor-list-action${vehicle.id === trackingVehicleId ? ' active' : ''}`}
                onClick={() => onTrackVehicle(vehicle.id)}
              >
                <Icon type="thunderbolt" theme={vehicle.id === trackingVehicleId ? 'filled' : 'outlined'} />
              </button>
            </Tooltip>
          </div>
        </li>
      )
    })

  return (
    <ul className="monitor-list" onClick={onClearSelectedVehicle}>
      {vehicleList.length ? (
        vehicleList
      ) : (
        <li className="monitor-list-item">
          <EmptyCard />
        </li>
      )}
    </ul>
  )
}

export default withTranslation('monitor')(VehicleList)
