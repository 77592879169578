import { PLocation, Path, VehicleStatus, District,VmapsPosition } from '../_types'
import { transformPosition } from './'

export const filterPathsByPLocations = (paths: Path[], plocationMap: Map<string, PLocation>) => {
  return paths.filter(path => plocationMap.get(path.startId) && plocationMap.get(path.destinationId))
}

export const getPosition = (lngLatItem: PLocation | VehicleStatus | District | VmapsPosition) => {
  return transformPosition([lngLatItem.longitude as number, lngLatItem.latitude as number])
}
// 计算两个经纬度点距离
export const getMapDistanceApi  = (paramObj:any) => {
    var lng1 = paramObj.lng1
    var lat1 = paramObj.lat1

    var lng2 = paramObj.lng2
    var lat2 = paramObj.lat2
    
    var radLat1 = lat1*Math.PI / 180.0;
    var radLat2 = lat2*Math.PI / 180.0;
    var a = radLat1 - radLat2;
    var  b = lng1*Math.PI / 180.0 - lng2*Math.PI / 180.0;
    var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a/2),2) +
    Math.cos(radLat1)*Math.cos(radLat2)*Math.pow(Math.sin(b/2),2)));
    s = s *6378.137 ;// EARTH_RADIUS;
    s = Math.round(s * 10000) / 10000;
    
    s = s * 1000
    return s
}
