import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Tag } from 'antd'

import { VehicleState, PointState, PathState, UserStatus } from '../../_types'
import vars from '../../variables.scss'

const vehicleStateColor: { [key in VehicleState]: string } = {
  INACTIVE: vars.vehicleINACTIVE,
  AVAILABLE: vars.vehicleAVAILABLE,
  INUSE: vars.vehicleINUSE,
  NIL: '',
}

const plocationStateColor: { [key in PointState]: string } = {
  DEPRECATED: vars.plocationDEPRECATED,
  ACTIVE: vars.plocationACTIVE,
  INACTIVE: vars.plocationINACTIVE,
  NONSTOP: vars.plocationNONSTOP,
  NILSTATE: '',
}

const pathStateColor: { [key in PathState]: string } = {
  ACTIVE: vars.pathACTIVE,
  INACTIVE: vars.pathINACTIVE,
  NIL_STATE: '',
}

const userStatusColor: { [key in UserStatus]: string } = {
  ACTIVE: vars.userACTIVE,
  INACTIVE: vars.userINACTIVE,
  NILSTATUS: '',
}

interface StateTagProps extends WithTranslation {
  state?: VehicleState | PointState | PathState | UserStatus
  type: 'vehicle' | 'plocation' | 'path' | 'userStatus'
}

const StateTag: React.FC<StateTagProps> = ({ state, type, t }) => {
  if (state) {
    switch (type) {
      case 'vehicle':
        return <Tag color={vehicleStateColor[state as VehicleState]}>{t(`vehicle.${state.toLowerCase()}`)}</Tag>
      case 'plocation':
        return <Tag color={plocationStateColor[state as PointState]}>{t(`plocation.${state.toLowerCase()}`)}</Tag>
      case 'path':
        return <Tag color={pathStateColor[state as PathState]}>{t(`path.${state.toLowerCase()}`)}</Tag>
      case 'userStatus':
        return <Tag color={userStatusColor[state as UserStatus]}>{t(`userStatus.${state.toLowerCase()}`)}</Tag>
      default:
        return <Tag>{state}</Tag>
    }
  } else {
    return <Tag>{t('tip.noState')}</Tag>
  }
}

export default withTranslation('stateTag')(StateTag)
