import React, { CSSProperties } from 'react'
import { AutoComplete, Modal, Form, Input, DatePicker, Select, Button } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { VEvent, ModalType, VEventPool } from '../../../_types'
import { epochToMoment, momentToEpoch } from '../../../_helpers'

const { Option } = Select

interface VEventModalProps extends FormComponentProps {
  item?: VEvent
  modalType: ModalType
  loading: boolean
  pool: VEventPool
  fromBag?: boolean
  onModify: any
  onCancel: () => void
  t: any
}

const modalBodyStyle: CSSProperties = {
  maxHeight: '80vh',
  overflow: 'auto',
  padding: '1rem 1.5rem 0.5rem',
}

const formItemLayout = {
  labelAlign: 'left' as 'left',
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

class VEventModal extends React.PureComponent<VEventModalProps> {
  handleUpdate = () => {
    const { item, onModify, modalType, form, pool } = this.props
    const { validateFields, getFieldsValue } = form
    const { vehicleAutoComplete } = pool
    validateFields(errors => {
      if (errors) {
        return
      }
      const { bagNames, startTime, endTime, ...fieldsValue } = getFieldsValue()
      let vId = ''
      if (fieldsValue.vehicleName) {
        vId = vehicleAutoComplete[fieldsValue.vehicleName] && vehicleAutoComplete[fieldsValue.vehicleName].id
      }
      if (!vId) {
        return
      }
      const data: any = {
        ...fieldsValue,
        startTime: momentToEpoch(startTime),
        endTime: momentToEpoch(endTime),
        vehicleId: vId,
        ...(item && { uuid: item.uuid }),
      }

      onModify(data, modalType)
    })
  }

  render() {
    const { item, pool, fromBag, loading, modalType, onModify, form, t, ...modalProps } = this.props
    const { getFieldDecorator } = form

    return (
      <div>
        <Modal
          {...modalProps}
          bodyStyle={modalBodyStyle}
          footer={
            <>
              {item ? (
                <Button loading={loading} onClick={() => onModify(item, 'delete')}>
                  {t('btn.delete')}
                </Button>
              ) : null}
              <Button loading={loading} type="primary" onClick={this.handleUpdate}>
                {t(`btn.${modalType}`)}
              </Button>
            </>
          }
        >
          <Form {...formItemLayout}>
            <div className="v-events-form-section">
              <Form.Item label={t('productVersion')}>
                {getFieldDecorator('productVersion', {
                  initialValue: item && item.productVersion,
                })(<Input disabled={fromBag} />)}
              </Form.Item>
              <Form.Item label={t('vehicleName')}>
                {getFieldDecorator('vehicleName', {
                  initialValue: item && item.vehicleName,
                })(<AutoComplete dataSource={Object.keys(pool.vehicleAutoComplete)} disabled={fromBag} />)}
              </Form.Item>
              <Form.Item label={t('description')}>
                {getFieldDecorator('description', {
                  initialValue: item && item.description,
                })(<Input />)}
              </Form.Item>
              <Form.Item label={t('tags')}>
                {getFieldDecorator('tags', {
                  initialValue: item && item.tags,
                })(<Select mode="multiple">{pool.tags && pool.tags.map(t => <Option key={t}>{t}</Option>)}</Select>)}
              </Form.Item>
              <Form.Item label={t('startTime')}>
                {getFieldDecorator('startTime', {
                  initialValue: item && epochToMoment(item.startTime),
                })(<DatePicker showTime disabled={fromBag} />)}
              </Form.Item>
              <Form.Item label={t('endTime')}>
                {getFieldDecorator('endTime', {
                  initialValue: item && epochToMoment(item.endTime),
                })(<DatePicker showTime disabled={fromBag} />)}
              </Form.Item>
            </div>
          </Form>
        </Modal>
      </div>
    )
  }
}

export default Form.create<VEventModalProps>()(VEventModal)
