import React from 'react'
import { Modal, Form, Input, InputNumber, Button, Select } from 'antd'
import { FormComponentProps } from 'antd/lib/form/Form'

import { ModalType, RoadTest } from '../../../_types'

const modalBodyStyle = {
  maxHeight: '80vh',
  overflow: 'auto',
  padding: '1rem 1.5rem 0.5rem',
}

const formItemLayout = {
  labelAlign: 'left' as 'left',
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

interface RoadTestModalProps extends FormComponentProps {
  item?: RoadTest
  loading: boolean
  modalType: ModalType
  onModify: any
  onCancel: () => void
  t: any
}

class RoadTestModal extends React.PureComponent<RoadTestModalProps> {
  handleUpdate = () => {
    const { item, onModify, modalType, form } = this.props
    const { validateFields, getFieldsValue } = form

    validateFields(errors => {
      if (errors) {
        return
      }

      const data: any = {
        ...getFieldsValue(),
        ...(item && { id: item.id }),
      }

      onModify(data, modalType)
    })
  }

  render() {
    const { item, loading, modalType, onModify, form, t, ...modalProps } = this.props
    const { getFieldDecorator } = form

    return (
      <Modal
        bodyStyle={modalBodyStyle}
        {...modalProps}
        footer={
          <>
            {item ? (
              <Button loading={loading} onClick={() => onModify({ id: item.id }, 'delete')}>
                {t('btn.delete')}
              </Button>
            ) : null}
            <Button type="primary" onClick={this.handleUpdate} loading={loading}>
              {t(`btn.${modalType}`)}
            </Button>
          </>
        }
      >
        <Form {...formItemLayout}>
          <Form.Item label={t('vehicleId')}>
            {getFieldDecorator('vehicleId', {
              initialValue: item && item.vehicleId,
              rules: [{ required: true, message: t('tip.required') }],
            })(<Input />)}
          </Form.Item>
          <Form.Item label={t('driverId')}>
            {getFieldDecorator('driverId', {
              initialValue: item && item.driverId,
              rules: [{ required: true, message: t('tip.required') }],
            })(<InputNumber />)}
          </Form.Item>
          <Form.Item label={t('passengers')}>
            {getFieldDecorator('passengers', {
              initialValue: item && item.passengers,
            })(<Select mode="tags" />)}
          </Form.Item>
          <Form.Item label={t('status')}>
            {getFieldDecorator('status', {
              initialValue: item && item.status,
            })(<Input />)}
          </Form.Item>
          <Form.Item label={t('description')}>
            {getFieldDecorator('description', {
              initialValue: item && item.description,
            })(<Input />)}
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default Form.create<RoadTestModalProps>()(RoadTestModal)
