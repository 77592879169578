import React from 'react'
import { Modal, Form, Input, Select, InputNumber, Button } from 'antd'
import { FormComponentProps } from 'antd/lib/form/Form'

import { ModalType, PLocation, Pose, Orientation, PointState, District } from '../../../_types'

const { Option } = Select

const modalBodyStyle = {
  maxHeight: '80vh',
  overflow: 'auto',
  padding: '1rem 1.5rem 0.5rem',
}

const formItemLayout = {
  labelAlign: 'left' as 'left',
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

const plocationStates: PointState[] = ['ACTIVE', 'NONSTOP', 'INACTIVE', 'DEPRECATED']

interface PLocModalProps extends FormComponentProps {
  item?: PLocation
  districts?: District[]
  loading: boolean
  modalType: ModalType
  onModify: any
  onCancel: () => void
  t: any
}

class PLocModal extends React.PureComponent<PLocModalProps> {
  handleUpdate = () => {
    const { item, onModify, modalType, form } = this.props
    const { validateFields, getFieldsValue } = form

    validateFields(errors => {
      if (errors) {
        return
      }

      const data: any = {
        ...getFieldsValue(),
        ...(item && { id: item.id }),
      }
      this.postProcessData(data)

      onModify(data, modalType)
    })
  }

  postProcessData = (data: any) => {
    let pose = data.pose

    Object.entries(data).forEach(obj => {
      const [key, subKey] = obj[0].split('_')
      if (subKey) {
        // If no pose
        !pose && (pose = {} as Pose)

        // If pose no key
        !pose[key] && (pose[key] = {} as Position | Orientation)

        pose[key][subKey] = obj[1]

        delete data[obj[0]]
      }
    })

    data.pose = pose
  }

  // processData = (data: Location) => {
  //   delete data.createdAt
  //   delete data.updatedAt

  //   if (data.pose) {
  //     Object.entries(data.pose).forEach(obj => {
  //       Object.entries(obj[1]).forEach(subObj => {
  //         data[`${obj[0]}_${subObj[0]}`] = subObj[1] as number
  //       })
  //     })
  //   }
  // }

  render() {
    const { item, districts, loading, modalType, onModify, form, t, ...modalProps } = this.props
    const { getFieldDecorator } = form

    const DistrictsRender = () => {
      return (
        <Form.Item label={t('district')}>
          {getFieldDecorator('districtId', {
            initialValue: item && item.districtId,
            rules: [{ required: true, message: t('tip.required') }],
          })(
            <Select>
              {districts && districts.map(district => <Option key={district.id}>{district.name}</Option>)}
            </Select>,
          )}
        </Form.Item>
      )
    }

    return (
      <Modal
        bodyStyle={modalBodyStyle}
        {...modalProps}
        footer={
          <>
            {item ? (
              <Button loading={loading} onClick={() => onModify({ id: item.id }, 'delete')}>
                {t('btn.delete')}
              </Button>
            ) : null}
            <Button type="primary" onClick={this.handleUpdate} loading={loading}>
              {t(`btn.${modalType}`)}
            </Button>
          </>
        }
      >
        <Form {...formItemLayout}>
          <h4 className="plocations-form-divider">{t('basic')}</h4>
          <div className="plocations-form-section">
            <Form.Item label={t('name')}>
              {getFieldDecorator('name', {
                initialValue: item && item.name,
                rules: [{ required: true, message: t('tip.required') }],
              })(<Input />)}
            </Form.Item>
            <DistrictsRender />
            <Form.Item label={t('state')}>
              {getFieldDecorator('state', {
                initialValue: item && item.state,
                rules: [{ required: true, message: t('tip.required') }],
              })(
                <Select>
                  {plocationStates.map(state => (
                    <Option key={state} value={state}>
                      {t(`stateTag:plocation.${state.toLowerCase()}`)}
                    </Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
            <Form.Item label={t('description')}>
              {getFieldDecorator('description', {
                initialValue: item && item.description,
              })(<Input />)}
            </Form.Item>
          </div>
          <h4 className="plocations-form-divider">{t('parameters')}</h4>
          <div className="plocations-form-section">
            <Form.Item label={t('position') + ' X'}>
              {getFieldDecorator('position_X', {
                initialValue: item && item.pose && item.pose.position.X,
                rules: [{ required: true, message: t('tip.required') }],
              })(<InputNumber />)}
            </Form.Item>
            <Form.Item label={t('position') + ' Y'}>
              {getFieldDecorator('position_Y', {
                initialValue: item && item.pose && item.pose.position.Y,
                rules: [{ required: true, message: t('tip.required') }],
              })(<InputNumber />)}
            </Form.Item>
            <Form.Item label={t('position') + ' Z'}>
              {getFieldDecorator('position_Z', {
                initialValue: item && item.pose && item.pose.position.Z,
                rules: [{ required: true, message: t('tip.required') }],
              })(<InputNumber />)}
            </Form.Item>
            <Form.Item label={t('orientation') + ' X'}>
              {getFieldDecorator('orientation_X', {
                initialValue: item && item.pose && item.pose.orientation.X,
              })(<InputNumber />)}
            </Form.Item>
            <Form.Item label={t('orientation') + ' Y'}>
              {getFieldDecorator('orientation_Y', {
                initialValue: item && item.pose && item.pose.orientation.Y,
              })(<InputNumber />)}
            </Form.Item>
            <Form.Item label={t('orientation') + ' Z'}>
              {getFieldDecorator('orientation_Z', {
                initialValue: item && item.pose && item.pose.orientation.Z,
              })(<InputNumber />)}
            </Form.Item>
            <Form.Item label={t('orientation') + ' W'}>
              {getFieldDecorator('orientation_W', {
                initialValue: item && item.pose && item.pose.orientation.W,
              })(<InputNumber />)}
            </Form.Item>
            <Form.Item label={t('longitude')}>
              {getFieldDecorator('longitude', {
                initialValue: item && item.longitude,
              })(<InputNumber />)}
            </Form.Item>
            <Form.Item label={t('latitude')}>
              {getFieldDecorator('latitude', {
                initialValue: item && item.latitude,
              })(<InputNumber />)}
            </Form.Item>
            <Form.Item label={t('altitude')}>
              {getFieldDecorator('altitude', {
                initialValue: item && item.altitude,
              })(<InputNumber />)}
            </Form.Item>
          </div>
        </Form>
      </Modal>
    )
  }
}

export default Form.create<PLocModalProps>()(PLocModal)
