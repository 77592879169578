import React from 'react'
import { Table, Button, Input, Icon, Tooltip } from 'antd'
import { ColumnProps } from 'antd/lib/table'

import { User, UserStatus, UserType } from '../../../_types'
import { HoverText, StateTag } from '../../../common'

export interface UserListProps {
  onCreate: () => void
  onEdit: (item: User) => void
  loading: boolean
  t: any
}

export class UserList extends React.PureComponent<UserListProps> {
  getColumnSearchProps = (dataIndex: string): ColumnProps<User> => {
    const { t } = this.props

    const onInputChange = (e: any, setSelectedKeys: any) => {
      setSelectedKeys && setSelectedKeys(e.target.value ? [e.target.value] : [])
    }
    const onReset = (clearFilters: ((selectedKeys: string[]) => void) | undefined) => {
      clearFilters && clearFilters([])
    }
    const onSearch = (selectedKeys: React.ReactText[] | undefined, confirm: (() => void) | undefined) => {
      confirm && confirm()
    }

    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="trips-filter-dropdown">
          <Input
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys ? selectedKeys[0] : ''}
            onChange={e => onInputChange(e, setSelectedKeys)}
            onPressEnter={() => onSearch(selectedKeys, confirm)}
            style={{ width: 160, marginBottom: '0.5rem', display: 'block' }}
          />
          <Button onClick={() => onReset(clearFilters)} size="small" style={{ width: 75, marginRight: 8 }}>
            {t('btn.reset')}
          </Button>
          <Button type="primary" onClick={() => onSearch(selectedKeys, confirm)} size="small" style={{ width: 75 }}>
            {t('btn.search')}
          </Button>
        </div>
      ),
      filterIcon: filtered => (
        <Icon
          type="search"
          style={{
            width: '2rem',
            fontSize: '0.9rem',
            color: filtered ? '#138D75' : undefined,
          }}
        />
      ),
    }
  }

  render() {
    const { t, loading, onCreate, onEdit, ...tableProps } = this.props

    const columns: ColumnProps<User>[] = [
      {
        key: 'name',
        dataIndex: 'name',
        title: t('name'),
        render: (text: string, record) => <HoverText text={text} content={record.id} prefix="ID" />,
        ...this.getColumnSearchProps('name'),
      },
      {
        key: 'phone',
        dataIndex: 'phone',
        title: t('phone'),
      },
      {
        key: 'email',
        dataIndex: 'email',
        title: t('email'),
      },
      {
        key: 'address',
        dataIndex: 'address',
        title: t('address'),
      },
      {
        key: 'type',
        dataIndex: 'type',
        title: t('type'),
        render: (text: UserType) => {
          const type = text.toLowerCase()

          switch (type) {
            case 'normal':
            case 'invited':
            case 'guest':
              return t(`userType.${type}`)
            default:
              return type
          }
        },
      },
      {
        key: 'status',
        dataIndex: 'status',
        title: t('status'),
        render: (text: UserStatus) => <StateTag state={text} type="userStatus" />,
      },
      {
        key: 'actions',
        title: (
          <Tooltip title={t('tooltip.createUser')} mouseEnterDelay={0.5}>
            <Button type="default" size="small" icon="plus" onClick={onCreate} />
          </Tooltip>
        ),
        render: (text, record) => (
          <div className="users-actions">
            <div className="users-actions-btn">
              <Button type="link" size="small" onClick={() => onEdit(record)}>
                {t('btn.edit')}
              </Button>
            </div>
          </div>
        ),
      },
    ]

    return <Table {...tableProps} columns={columns} rowKey="id" loading={loading} scroll={{ x: true }} />
  }
}
