import React from 'react'
import './Scenario.scss'
import { connect } from 'dva'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Dispatch } from 'redux'
import { ScenarioReducerState } from '../../_types/scenariosurvey.types'
import { DvaState } from '../../_types'
import { List,ListSecond, DescriptUi } from './components'
import { Select, DatePicker, Tabs , Empty,Spin,Row,Col } from 'antd'
import { getScenarioTitle } from '../../_utils'
import moment from 'moment'
const { TabPane } = Tabs;

interface TakeoverProps extends WithTranslation, ScenarioReducerState {
  dispatch: Dispatch,
  list:any
}

class Scenario extends React.Component<TakeoverProps> {
  private caseTotalChart: any
  private interactiveChart: any
  private perceptionChart: any
  private caseDateChart: any
  private area: string = ''
  private dataSourceType: string = ''
  private startDate: any = ''
  private endDate: any = ''

  componentDidMount() {
    const { dispatch } = this.props
    dispatch({
      type: 'scenario/setScenarioChart',
      payload: {
        area: this.area,
        dataSourceType: this.dataSourceType,
        startDate: this.startDate,
        endDate: this.endDate
      },
    })
  }

  onRegionChangeHandler(value: any) {
    this.area = value
  }

  onDataChangeHandler(value: any) {
    this.dataSourceType = value
  }

  onDateChangeHandler(value: any) {
    if (value instanceof Array && value.length > 1) {
      this.startDate = moment(value[0]).format('YYYY-MM-DD')
      this.endDate = moment(value[1]).format('YYYY-MM-DD')
    }
    else {
      this.startDate = undefined
      this.endDate = undefined
    }
  }

  onSearchHandler(event: any) {
    const { dispatch } = this.props
    dispatch({
      type: 'scenario/setScenarioChart',
      payload: {
        caseTotalChart: this.caseTotalChart.getEchartsInstance(),
        interactiveChart: this.interactiveChart.getEchartsInstance(),
        perceptionChart: this.perceptionChart.getEchartsInstance(),
        caseDateChart: this.caseDateChart.getEchartsInstance(),
        area: this.area,
        dataSourceType: this.dataSourceType,
        startDate: this.startDate,
        endDate: this.endDate
      },
    })
  }
  disabledDate = (current:any) => {
      return current && current > moment().endOf('day');
    };
  render() {
    let {
      listingBags,
      list,
      t
    } = this.props
    return (
      <div className="scenario">
        <section className="scenario-container">
          <div className="monitor-map-loading">
            <Spin spinning={listingBags} />
          </div>
          <Tabs defaultActiveKey="1" style={{display:list.length>0?'block':'none'}}>
          {
            list.map((item:any,index:any)=>{
              return  <TabPane tab={getScenarioTitle(item.title)} key={index}>
                 <div className="scenario-container-scenario"  key={index}>
                  {/* <div className="scenario-title">{getScenarioTitle(item.title)}</div> */}
                  <Row>
                    <Col span={15}>
                        <DescriptUi {...{dataSource:item.verifyT,t: t}}/>
                    </Col>
                    <Col span={9}>
                      <div className="info-list-scenario">
                        <List {...{dataSource: item.items,loading: listingBags,t: t}}/>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <div className="info-list-scenario">
                        <ListSecond {...{dataSource:item.secondItems,loading: listingBags,t: t,}} />
                      </div>
                    </Col>
                  </Row>
                 </div>
                </TabPane>  
            })
          }
          </Tabs>
          <Empty description="暂无数据" style={{display:list.length>0?'none':'block'}}/>
        </section>
      </div>
    )
  }
}

const mapStateToProps = (state: DvaState) => ({
  ...state.scenario,
})

export default connect(mapStateToProps)(withTranslation('scenario')(Scenario))
