import React from 'react'
import { Modal, Form, Input, Select, Button } from 'antd'
import { FormComponentProps } from 'antd/lib/form/Form'

import { ModalType, Admin, AdminRole } from '../../../_types'
import { reg } from '../../../_utils'

const { Option } = Select

const modalBodyStyle = {
  maxHeight: '80vh',
  overflow: 'auto',
  padding: '1rem 1.5rem 0.5rem',
}

const formItemLayout = {
  labelAlign: 'left' as 'left',
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

const adminRoles: AdminRole[] = ['Admin', 'Operator', 'Dev']

interface AdminModalProps extends FormComponentProps {
  item?: Admin
  loading: boolean
  modalType: ModalType
  onModify: any
  onCancel: () => void
  t: any
}

class AdminModal extends React.PureComponent<AdminModalProps> {
  handleUpdate = () => {
    const { item, onModify, modalType, form } = this.props
    const { validateFields, getFieldsValue } = form

    validateFields(errors => {
      if (errors) {
        return
      }

      const fieldsData = getFieldsValue()
      const data: any = {
        ...fieldsData,
        roles: fieldsData.roles.join(', '),
        ...(item && { id: item.id }),
      }

      onModify(data, modalType)
    })
  }

  render() {
    const { item, loading, modalType, onModify, form, t, ...modalProps } = this.props
    const { getFieldDecorator } = form

    return (
      <Modal
        bodyStyle={modalBodyStyle}
        {...modalProps}
        footer={
          <>
            {item ? (
              <Button loading={loading} onClick={() => onModify({ id: item.id }, 'delete')}>
                {t('btn.delete')}
              </Button>
            ) : null}
            <Button type="primary" onClick={this.handleUpdate} loading={loading}>
              {t(`btn.${modalType}`)}
            </Button>
          </>
        }
      >
        <Form {...formItemLayout}>
          <h4 className="admins-form-divider">{t('basic')}</h4>
          <div className="admins-form-section">
            <Form.Item label={t('name')}>
              {getFieldDecorator('name', {
                initialValue: item && item.name,
                rules: [{ required: true, message: t('tip.required') }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label={t('email')}>
              {getFieldDecorator('email', {
                initialValue: item && item.email,
                rules: [
                  { required: true, message: t('tip.required') },
                  {
                    pattern: reg.adminEmail,
                    message: t('tip.invalidEmail'),
                  },
                ],
              })(<Input />)}
            </Form.Item>
          </div>
          {item ? null : (
            <>
              <h4 className="admins-form-divider">{t('security')}</h4>
              <div className="admins-form-section">
                <Form.Item label={t('password')}>
                  {getFieldDecorator('password', {
                    rules: [
                      { required: true, message: t('tip.required') },
                      {
                        pattern: reg.pwdAdmin,
                        message: t('tip.invalidPwd'),
                      },
                    ],
                  })(<Input.Password allowClear />)}
                </Form.Item>
              </div>
            </>
          )}

          <h4 className="admins-form-divider">{t('account')}</h4>
          <div className="admins-form-section">
            <Form.Item label={t('roles')}>
              {getFieldDecorator('roles', {
                initialValue: item && item.roles && item.roles.split(', '),
                rules: [{ required: true, message: t('tip.required') }],
              })(
                <Select mode="multiple">
                  {adminRoles.map(role => (
                    <Option key={role} value={role}>
                      {role}
                    </Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          </div>
        </Form>
      </Modal>
    )
  }
}

export default Form.create<AdminModalProps>()(AdminModal)
