import React from 'react'
import './Takeover.scss'
import { connect } from 'dva'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Dispatch } from 'redux'
import { TakeoverReducerState } from '../../_types/takeover.types'
import { DvaState } from '../../_types'
import { List } from './components'
import ReactEcharts from 'echarts-for-react'
import { Select, DatePicker, Button, Icon } from 'antd'
import { objectToParams } from '../../_utils'
import moment from 'moment'
import { T } from 'antd/lib/upload/utils'
const { Option } = Select
const { RangePicker } = DatePicker

interface TakeoverProps extends WithTranslation, TakeoverReducerState {
  dispatch: Dispatch,
  totalMileage: number,
  totalTime: number,
  aveTakeoverMileage: number,
  aveWorkTime: number
  takeOverChartOption: any,
  workChartOption: any,
  regionChartOption: any,
  selectOption: Array<any>,
  vehicleOption: Array<any>,
  taskTypeOption:Array<any>,
  // takeOverInfoProps: TakeoverInfoProps
}

class Takeover extends React.Component<TakeoverProps> {
  private takeoverChart: any
  private workChart: any
  private regionChart: any
  private vehicleChart: any
  private searchRegion: any
  private vehicleId: any
  private taskType:any = 'routine_dt' 
  private searchStartTime: any = Date.now() - 6 * 24 * 3600000
  private searchEndTime: any = Date.now()
  private globalMap: any

  componentDidMount() {
    const { dispatch } = this.props
    dispatch({
      type: 'takeover/setTotalInfo',
      payload: {}
    })
    dispatch({
      type: 'takeover/setRegionTable',
      payload: {
        regionId: this.searchRegion,
        vehicleId: this.vehicleId,
        taskType:this.taskType,
        startTime: this.searchStartTime,
        endTime: this.searchEndTime
      }
    })
    dispatch({
      type: 'takeover/setRegionList',
      payload: {}
    })
    dispatch({
      type: 'takeover/setVehicleList',
      payload: {}
    })
    dispatch({
      type: 'takeover/setTaskTypeList',
      payload: {}
    })
  }

  onRegionChangeHandler(value: any) {
    this.searchRegion = value
  }

  onVehicleChangeHandler(value: any) {
    this.vehicleId = value
  }

  onTasktTypeChangeHandler(value:any){
    this.taskType = value
  }

  onDateChangeHandler(value: any) {
    if (value instanceof Array && value.length > 1) {

      this.searchStartTime = value[0].toDate().getTime()
      this.searchEndTime = value[1].toDate().getTime()
    }
    else {
      this.searchStartTime = undefined
      this.searchEndTime = undefined
    }
  }
  onDownloadList() {
    const urlPara = {
      region: this.searchRegion,
      vehicle_id:this.vehicleId,
      task_type:this.taskType,
      start_time: this.searchStartTime,
      end_time: this.searchEndTime
    }
    window.location.href = `/api/mpi/file/export?${objectToParams(urlPara)}`
  }

  onSearchHandler(event: any) {
    const { dispatch } = this.props
    dispatch({
      type: 'takeover/setRegionTable',
      payload: {
        regionId: this.searchRegion,
        vehicleId: this.vehicleId,
        taskType:this.taskType,
        startTime: this.searchStartTime,
        endTime: this.searchEndTime
      }
    })
  }
  get listProps() {
    const { list, listingBags, pagination, t } = this.props
    return {
      dataSource: list,
      pagination: pagination,
      loading: listingBags,
      t: t,
    }
  }

  render() {
    let { totalMileage,
      totalTime,
      takeOverChartOption,
      workChartOption,
      aveTakeoverMileage,
      aveWorkTime,
      regionChartOption,
      vehicleChartOption,
      selectOption,
      vehicleOption,
      taskTypeOption,
      t
    } = this.props
    return (
      <div className="takeover">
        <section className="takeover-container">
          {/* <div className="takeover-container-info info-first">
            <div className="takeover-container-info-mileage">无人驾驶总里程<span className="strong-line">{totalMileage}</span>公里</div>
            <div className="takeover-container-info-time">无人驾驶总时长<span className="strong-line">{totalTime}</span>小时</div>
          </div>
          <div className="takeover-container-info info-second">
            <div className="takeover-container-info-mileage">无人驾驶平均<span className="strong-line font-color-orange">{aveTakeoverMileage}</span>公里接管一次</div>
            <div className="takeover-container-info-time">路边单元平均无故障工作时间<span className="strong-line font-color-orange">{aveWorkTime}</span>小时</div>
          </div> */}
          <div className="takeover-container-region">
            {/* <span></span>
            <Select className="takeover-container-region-select tool-item" showSearch placeholder="请选择区域" optionFilterProp="children" onChange={this.onRegionChangeHandler.bind(this)} allowClear>
              {
                selectOption.map(item => {
                  return <Option value={item.value} key={item.id}>{item.label}</Option>
                })
              }
            </Select> */}
            <span>{t('productVersion')}</span>
            <Select className="takeover-container-region-select tool-item" showSearch placeholder="请选择车辆" optionFilterProp="children" onChange={this.onVehicleChangeHandler.bind(this)} allowClear>
              {
                vehicleOption.map(item => {
                  return <Option value={item.id} key={item.id}>{item.name}</Option>
                })
              }
            </Select>
            <span>{t('vehicleName')}</span>
            <Select className="takeover-container-region-select tool-item" showSearch placeholder="请选择车辆" optionFilterProp="children" onChange={this.onVehicleChangeHandler.bind(this)} allowClear>
              {
                vehicleOption.map(item => {
                  return <Option value={item.id} key={item.id}>{item.name}</Option>
                })
              }
            </Select>
            <span>{t('taskType')}</span>
            <Select className="takeover-container-region-select tool-item" showSearch placeholder="请选择任务类型" optionFilterProp="children" onChange={this.onTasktTypeChangeHandler.bind(this)} allowClear defaultValue={'routine_dt'}>
              {
                taskTypeOption.map(item => {
                  return <Option value={item.key} key={item.key}>{item.value}</Option>
                })
              }
            </Select>
            <span>{t('date')}</span>
            <RangePicker
              className="tool-item"
              onChange={this.onDateChangeHandler.bind(this)}
              defaultValue={[moment().startOf('day').subtract(6, 'days'), moment().endOf('day')]}
              placeholder={['开始时间', '结束时间']}
            />
            <Button className="tool-item" type="primary" onClick={this.onSearchHandler.bind(this)}>
            {t('btn.search')}
            </Button>
            <div className="info-list">
              <List {...this.listProps} />
            </div>
            <div className="list-download">
              <Button type="primary" icon="download" size={'small'} onClick={this.onDownloadList.bind(this)}>
                下载
              </Button>
            </div>
            {/* <Map {...this.listProps}/> */}
          </div>
        </section>
      </div>
    )
  }
}

const mapStateToProps = (state: DvaState) => ({
  ...state.takeover,
})

export default connect(mapStateToProps)(withTranslation('tokeover')(Takeover))
