import React from 'react'
import { Link } from 'react-router-dom'
import { Table,Button} from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { takeover, Pagination} from '../../../_types'
import { scenarioTranslate,secondaryClassification,simulationMainEventType,roadStructureTypes } from '../../../_utils'

interface BagListProps {
  t: any
  pagination: Pagination
}

class BagTable extends React.PureComponent<BagListProps> {
  render() {
    const { t,pagination, ...tableProps } = this.props
    const columns: ColumnProps<takeover>[] = [
      {
        title: 'id',
        dataIndex: 'id',
      },
      {
        title: '地区',
        dataIndex: 'area',
      },
      {
        title: '日期',
        dataIndex: 'days',
      },
      {
        title: '数据来源名字',
        dataIndex: 'dataSourceName',
      },
      {
        title: '二级分类',
        dataIndex: 'scenarioSecondaryClassification',
        key: 'scenarioSecondaryClassification',
        render: (text:any) => scenarioTranslate(text,secondaryClassification),
      },
      {
        title: '场景问题模块',
        dataIndex: 'realScenarioProblemAscription',
      },
      {
        title: '主要事件类型',
        dataIndex: 'simulationMainEventType',
        render: (text:any) => scenarioTranslate(text,simulationMainEventType),
      },
      {
        title: '道路结构类型',
        dataIndex: 'roadStructureType',
        render: (text:any) => scenarioTranslate(text,roadStructureTypes),
      },
      {
        key: 'action',
        title: '操作',
        render: (text: string,item:any) => (
          <div className="bags-list-actions">
            <Link
                to={{
                  pathname: `/scenarioDetail/${item.id}`,
                }}
              >
              <Button type="link" size="small">
                 详情
              </Button>
            </Link>
            <a
              className="resources-grid-link"
              href={item.simulationBagHyperlink}
              target="_blank"
            >
              可视化
            </a>  
          </div>
        ),
      },
    ]
    return (
      <div>
        <Table
          {...tableProps}
          pagination={{
              size: 'small',
              ...pagination,
          }}
          columns={columns}
          bordered
          rowKey='id'
         // scroll={{ x: true }}
        />
      </div>
    )
  }
}

export default BagTable
