import { PageState, ModalState } from '../_types'
import modelExtend from 'dva-model-extend'
import { Model } from 'dva'

export const model: Model = {
  namespace: '',
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
}

export const pageState: PageState<any> = {
  list: [],
  pagination: {
    showSizeChanger: true,
    showQuickJumper: true,
    current: 1,
    total: 1,
    pageSize: 10,
  },
}

export const pageModel = modelExtend(model, {
  namespace: '',
  state: pageState,
  reducers: {
    querySuccess(state, { payload }) {
      const { list, pagination } = payload
      return {
        ...state,
        list,
        pagination: {
          ...state.pagination,
          ...pagination,
        },
      }
    },
  },
})

export const modalState: ModalState<any> = {
  currentItem: {},
  modifying: false,
  modalVisible: false,
  modalType: 'create',
}

export const modalModel = modelExtend(model, {
  namespace: '',
  state: modalState,
  reducers: {
    showModal(state, { payload }) {
      return { ...state, ...payload, modalVisible: true }
    },

    hideModal(state) {
      return { ...state, modalVisible: false }
    },
  },
})
