import { ThunkDispatch } from 'redux-thunk'
import { AxiosError } from 'axios'
import { AnyAction } from 'redux'

import { searchConstants } from '../_constants'
import { searchServices } from '../_services'

const get = (params: any, global: any, type: string) => {
  const request = () => {
    return { type: searchConstants.GET_REQUEST }
  }

  const success = (docs: any) => {
    return { type: searchConstants.GET_SUCCESS, docs, idxType: type }
  }

  const failure = () => {
    return { type: searchConstants.GET_FAILURE }
  }

  const genRangeQuery = (data: any) => {
    if (!data.timerange || data.timerange.length < 2) {
      return
    }
    const from = data.timerange[0].format()
    const to = data.timerange[1].format()
    if (from && to) {
      return [
        {
          from: from,
          to: to,
          key: '@timestamp',
        },
      ]
    }
  }

  const genMatchQuery = (arr: any[], obj: any) => {
    let matchQuery = []
    for (let key in obj) {
      if (key !== 'timerange' && obj[key]) {
        if (type === 'incarevent' && key === 'fields.car_id') {
          matchQuery.push({ key: 'vehicle_name', value: obj[key] })
        } else {
          matchQuery.push({ key: key, value: obj[key] })
        }
      }
    }

    arr &&
      arr.forEach(item => {
        if (item.key && item.value) {
          matchQuery.push(item)
        }
      })
    return matchQuery.length === 0 ? null : matchQuery
  }

  const genMultiMatchQuery = (arr: any[]) => (arr ? [arr] : null)

  const match_query = genMatchQuery(params.matchSearch, global)
  const range_query = genRangeQuery(global)
  const multi_match_query = genMultiMatchQuery(params.multiMatch)
  const payload: any = {}
  if (match_query) {
    payload.match_query = match_query
  }
  if (range_query) {
    payload.range_query = range_query
  }
  if (multi_match_query) {
    payload.multi_match_query = multi_match_query
  }

  return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(request())

    return searchServices.get(payload, type).then(
      (data: any) => {
        dispatch(success(data.docs))
      },
      (error: AxiosError) => {
        dispatch(failure())
        return Promise.reject(error.response)
      },
    )
  }
}

export const searchActions = {
  get,
}
