import React from 'react'
import './Invitations.scss'
import { connect } from 'dva'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Dispatch } from 'redux'
import moment from 'moment'
import { List, Button, Skeleton } from 'antd'

import { InvitationsReducerState, DvaState, Invitation } from '../../_types'

interface InvitationsProps extends WithTranslation, InvitationsReducerState {
  dispatch: Dispatch
}

class Invitations extends React.PureComponent<InvitationsProps> {
  handleLoadMore = () => {
    console.log('loadmore')
  }

  handleCreateInvitation = () => {
    const { dispatch } = this.props

    dispatch({
      type: 'invitations/create',
    })
  }

  handleDeleteInvitation = (invitation: Invitation) => {
    const { dispatch } = this.props

    dispatch({
      type: 'invitations/delete',
      payload: {
        code: invitation.code,
      },
    })
  }

  render() {
    const { t, list, listingInvitations, initLoading } = this.props

    // const loadMore =
    //   !initLoading && !listingInvitations ? (
    //     <div
    //       style={{
    //         textAlign: 'center',
    //         marginTop: 12,
    //         height: 32,
    //         lineHeight: '32px',
    //       }}
    //     >
    //       <Button onClick={this.handleLoadMore}>{t('btn.loadMore')}</Button>
    //     </div>
    //   ) : null

    return (
      <div className="invitations">
        <div className="invitations-inner">
          <header className="invitations-header">
            <h2>{t('invitationsListTitle')}</h2>
            <div className="invitations-header-actions">
              <Button type="primary" onClick={this.handleCreateInvitation}>
                {t('btn.create')}
              </Button>
            </div>
          </header>
          <div className="invitations-list">
            <List
              className="invitations-list-panel"
              loading={initLoading}
              itemLayout="horizontal"
              // loadMore={loadMore}
              dataSource={list}
              renderItem={item => (
                <List.Item
                  actions={[
                    <Button key={item.createdAt} type="link" onClick={() => this.handleDeleteInvitation(item)}>
                      {t('btn.delete')}
                    </Button>,
                  ]}
                >
                  <Skeleton title={false} loading={listingInvitations}>
                    <List.Item.Meta
                      title={<span className={`invitations-code${item.valid ? ' valid' : ''}`}>{item.code}</span>}
                      description={`${moment(parseInt(item.createdAt)).format()}`}
                    />
                  </Skeleton>
                </List.Item>
              )}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: DvaState) => state.invitations

export default connect(mapStateToProps)(withTranslation('invitations')(Invitations))
