import React from 'react'
import './VMaps.scss'
import { message,Spin,Select,Button,Tabs  } from 'antd'
import { Link } from 'react-router-dom'
import { connect } from 'dva'
import { DvaState,Pagination, VMapsReducerState, Mileage, ModalState,Vehicle } from '../../_types'
import { Dispatch } from 'redux'
import ReactEcharts from 'echarts-for-react'
import { withTranslation, WithTranslation } from 'react-i18next'
import lodash from 'lodash'
import vars from '../../variables.scss'
import { MAP_STYLES,MAP_ZOOM } from './constants'
import {
  getPosition,
  getMapDistanceApi
} from '../../_utils'
import { Header, List, } from './components'
import carAuto from '../../_assets/car_auto.png'
import moment from 'moment'
const { Option } = Select
const { TabPane } = Tabs;
// Extract AMap from window
const AMap = (window as any).AMap
interface VMapsProps extends WithTranslation, VMapsReducerState, ModalState<Mileage> {
  dispatch: Dispatch,
  regionChartOption: any,
  speedChartOption: any
}
interface MonitorState {
  loadingMap: boolean,
  trackingVehicleId: string,
  baseLat: string,
  baseLon: string,
  flag:boolean,
  timeScale:string,
  timeRange:any,
  defaultTime:string
}

class VMaps extends React.PureComponent<VMapsProps> {
  // Refs
  private map: any
  // Constructors
  private mapRef = React.createRef<HTMLDivElement>()
  private regionChart:any
  private speedChart:any
  state: MonitorState = {
    loadingMap: true,
    trackingVehicleId: '',
    baseLat:'0',
    baseLon:'0',
    flag:true,
    timeRange:[],
    timeScale:'',
    defaultTime:'60'
  }
  get searchProps() {
    const { dispatch, pool, search, t } = this.props
    return {
      item: search,
      pool: pool,
      t: t,
      onSearch: () => {
        this.setState({flag:true})
        this.map.clearMap()
        dispatch({
          type: 'vmaps/setMapChart',
          payload: {
            mapChart: this.regionChart.getEchartsInstance(),
            speedChart: this.speedChart.getEchartsInstance(),
          }
        })
      },
      onChange: (fields: any) => {
        const {baseLat,baseLon} = this.state
       
        if(fields.vehicleName && fields.vehicleName.value){
          const key = fields.vehicleName.value
          if(pool.vehicleAutoComplete[key]){
            const districtId = pool.vehicleAutoComplete[key].districtId
            if(districtId){
              pool.districts.forEach((item:any)=>{
                if(item.id === districtId){
                  this.setState({ baseLat: item.baseLat, baseLon: item.baseLon,},()=>{
                    dispatch({
                      type: 'vmaps/updateState',
                      payload: {
                        baseLat:this.state.baseLat,
                        baseLon:this.state.baseLon,
                      },
                    })
                  })
                }
              })
            }else{
              this.setState({ baseLat: '0', baseLon: '0',},()=>{
                dispatch({
                  type: 'vmaps/updateState',
                  payload: {
                    baseLat:this.state.baseLat,
                    baseLon:this.state.baseLon,
                  },
                })
              })
            }
          }
        }
        dispatch({
          type: 'vmaps/updateState',
          payload: {
            search: {
              ...search,
              ...lodash.mapValues(fields, o => ('value' in o ? o.value : o)),
            },
            baseLat:baseLat,
            baseLon:baseLon,
          },
        })
      },
      onClear: () => {
        dispatch({
          type: 'vmaps/reset',
        })
      }
    }
  }
  
  componentDidMount(){
    const { dispatch } = this.props
    // Init AMap instantly
    this.initAMap()
   // this.drawLine()
  }
  componentDidUpdate(prevProps:any){
    const { list } = this.props
    if(prevProps.list !== list) {
      this.drawFilterData(list)
    }
  }
  componentWillUnmount() {
    this.map && this.map.destroy()
    this.regionChart.getEchartsInstance().dispose()
    this.speedChart.getEchartsInstance().dispose()
  }
  //绘制路劲方法
  drawFilterData = (list:any) => {
    const lineArr:any[] = []
    if(list&&list.length>0){
      console.log(list)
      list.forEach((ele:any) => {
        if(ele.latitude!==0&&ele.longitude!==0){
          lineArr.push(ele)
          // if(lineArr.length === 0){
          //   lineArr.push(ele)
          //  }else{
          //     //时间每秒
          //     if(ele.time.indexOf('.')>0) {
          //       let eleTime = ele.time.split('+')[0]
          //       let lineTime = lineArr[lineArr.length-1].time.split('+')[0]
          //       if(eleTime !== lineTime){
          //         lineArr.push(ele)
          //       }
          //     }
          //  }
        }
      });
      // 筛选数据，数据时间上不连贯，大于某个阈值，重新绘制
      const timeSlot = 10  //分钟
      const linesPath:any = []
      let index = 0
      linesPath[index] = []
      linesPath[index].push(getPosition({longitude:lineArr[0].longitude,latitude:lineArr[0].latitude}))
      for(let i=1; i<lineArr.length; i++){
        let obj = {
          lng1: lineArr[i].longitude,
          lat1: lineArr[i].latitude,
          lng2: lineArr[i-1].longitude,
          lat2: lineArr[i-1].latitude,
        }

        if(new Date(lineArr[i].time).getTime()-new Date(lineArr[i-1].time).getTime()>timeSlot*60*1000 || getMapDistanceApi(obj) > 20){
          index++
          linesPath[index] = []
          linesPath[index].push(getPosition({longitude:lineArr[i].longitude,latitude:lineArr[i].latitude}))
        }else{
          linesPath[index].push(getPosition({longitude:lineArr[i].longitude,latitude:lineArr[i].latitude}))
        }
      }
      if(linesPath.length>0){
        linesPath.forEach((item:any) => {
          this.drawLine(item)
        });
      }
      //this.drawLine(linePath)
    }
  }
  drawLine = (path:any) => {
    const {flag} = this.state
    // var path1 = [
    //   [75.757904, 38.118117],
    //   [97.375719, 24.598057],
    //   [117.375719, 38.118117]
    // ];
    var polyline1 = new AMap.Polyline({
      path: path,            // 设置线覆盖物路径
      showDir:true,
      //dirColor:'pink',
      strokeColor: '#3366bb',   // 线颜色
      strokeWeight: 10           // 线宽
    });
    this.map.add([polyline1])
    if(flag){
      this.setState({flag:false})
      this.map.setZoomAndCenter(MAP_ZOOM.INITIAL,path[0])
    }
  }
  initAMap = () => {
    // Incase AMap loading fails
    if (!AMap) return
    // New AMap instance
    this.map = new AMap.Map(this.mapRef.current, {
      mapStyle: 'amap://styles/whitesmoke',
    })
    this.map.on('complete', () => {
      this.setState({ loadingMap: false })
    })
    // Add tool bar
    AMap.plugin(['AMap.ToolBar'], () => {
      this.map.addControl(
        new AMap.ToolBar({
          liteStyle: true,
        }),
      )
    })
  }

  /** Vehicles */
  loadVehicles = (param:any) => {
    const { dispatch } = this.props
    const {flag,defaultTime} = this.state
    //移除上次几点数据
    const CircleMarker = this.map.getAllOverlays('CircleMarker')
    if(CircleMarker.length>0){
      this.map.remove(CircleMarker)
    }
   // this.map.clearMap()
    var pos = {
      longitude:param.data.longitude,
      latitude:param.data.latitude
    }
    const marker = new AMap.CircleMarker({
      map: this.map,
      center: getPosition(pos),
      ...MAP_STYLES.PLOCATION_CIRCLE,
      fillColor: vars[`plocationACTIVE`],
      zIndex:60
    })
    marker.on('click', () => this.handleInfo(param))

    // 查询之后第一次点折线图去设置地图视角
    if(flag){
      this.setState({flag:false})
      this.map.setZoomAndCenter(MAP_ZOOM.INITIAL,getPosition(pos))
    }
    // 点击折线图设置点击点前后时间范围
    this.setState({
      timeScale:param.data.time
    },()=>{
      this.handleFormat(defaultTime)
    })
  }
  // 格式化时间方法
  handleFormat = (rangeNum:any) => {
    const {timeScale} = this.state
    let timeRange = []
    let timeStamp = new Date(timeScale).getTime()
    timeRange[0] = moment(timeStamp-rangeNum/2*1000).format('YYYY-MM-DD HH:mm:ss')
    timeRange[1] = moment(timeStamp+rangeNum/2*1000).format('YYYY-MM-DD HH:mm:ss')
    this.setState({ 
      timeRange
    })
  }
  // 点击地图marker弹框
  handleInfo = (param:any)=>{
    const { vehicleName } = this.props
    var info = [];
    var infoWindow
    info.push(`<div class='input-card content-window-card'>车辆名：${vehicleName}<br/>速度：${param.data.speed}m/s<br/>加速度：${param.data.acceleration}(m/s)/s</div> `);
    infoWindow = new AMap.InfoWindow({
        content: info.join(""),  //使用默认信息窗体框样式，显示信息内容
        offset: new AMap.Pixel(0, -12),
    });
    infoWindow.open(this.map, getPosition({longitude:param.data.longitude,latitude:param.data.latitude}));
  }
  onclick = {
    'click': this.onChartClick.bind(this)
  }
  onChartClick(param:any) {
    this.loadVehicles(param)
  }
  handleChange = (value: string) => {
   this.setState({
     defaultTime: value
   })
   this.handleFormat(value)
  };
  render() {
    const {
      regionChartOption,
      speedChartOption,
      listingBags,
      vehicleName,
      t
    } = this.props
    const {
     loadingMap,
     timeRange,
     defaultTime,
     baseLat,
     baseLon
    } = this.state
    
    const timesOption = [
      {
        value: '60',
        label: '1分钟',
      },
      {
        value: '300',
        label: '5分钟',
      },
      {
        value: '900',
        label: '15分钟',
      },
      {
        value: '1800',
        label: '30分钟',
      },
    ]
    return (
      <div className="vmaps">
        <div className="vmaps-inner">
          <Header {...this.searchProps} />
        </div>
        <div className="vmaps-line">
          <div className="vmaps-detail" style={{display:timeRange.length>0 ? "block" : "none"}}>
            <span>原始颗粒度数据：</span>
            <Select defaultValue={defaultTime} style={{ width: 120 }} onChange={this.handleChange}>
              {timesOption.map(time=> (
                <Option key={time.label} value={time.value}>
                  {time.label}
                </Option>
              ))}
            </Select> 
            <span style={{paddingLeft: "10px",paddingRight: "10px" }}>{timeRange[0]} ~ {timeRange[1]}</span>
            <Link
                to={{
                  pathname: `/vmapsdetail`,
                 // search:{timeRange:timeRange,vehicleName:vehicleName}
                  search:`?timeBegin=${timeRange[0]}&timeEnd=${timeRange[1]}&vehicleName=${vehicleName}&baseLat=${baseLat}&baseLon=${baseLon}`
                }}
                target = "_blank"
              >
              <Button type="link" size="small">
                 详情
              </Button>
            </Link>
          </div>
          <Tabs defaultActiveKey="1">
            <TabPane tab="速度和加速度" key="1">
              <ReactEcharts style={{height:360}} ref={e => { this.regionChart = e }} option={regionChartOption} onEvents={this.onclick} />
            </TabPane>
            <TabPane tab="加加速度" key="2" forceRender={true}>
            <ReactEcharts style={{height:360}} ref={e => { this.speedChart = e }} option={speedChartOption} onEvents={this.onclick} />
            </TabPane>
          </Tabs>
        </div>
        <div className="vmaps-map-container" ref={this.mapRef}/>
        <div className="monitor-map-loading">
          <Spin spinning={loadingMap||listingBags} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: DvaState) => {
  return {
    ...state.vmaps,
  }
}

export default connect(mapStateToProps)(withTranslation('bags')(VMaps))
