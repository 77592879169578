import React from 'react'
import { Table} from 'antd'
import { ColumnProps } from 'antd/lib/table'

import { takeover, Pagination} from '../../../_types'

interface BagListProps {
  t: any
}

class BagTable extends React.PureComponent<BagListProps> {
  render() {
    const { t, ...tableProps } = this.props
    const columns: ColumnProps<takeover>[] = [
      {
        title: t('caseType'),
        dataIndex: 'name',
      },
      {
        title: t('interactive'),
        dataIndex: 'interactive',
      },
      {
        title: t('perception'),
        dataIndex: 'perception',
      },
      {
        title: t('total'),
        dataIndex: 'total',
      },
    ]
    return (
      <div>
        <Table
          {...tableProps}
          pagination = {false}
          columns={columns}
          bordered
          rowKey='name'
        />
      </div>
    )
  }
}

export default BagTable
