import React from 'react'
import './Bags.scss'
import { message } from 'antd'
import { connect } from 'dva'
import { DvaState, BagsReducerState, Bag, ModalState } from '../../_types'
import { Dispatch } from 'redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import lodash from 'lodash'

import { Pagination, VEvent } from '../../_types'
import { Header, List, Modal } from './components'
import { Modal as EventModal } from '../VEvents/components'

interface BagsProps extends WithTranslation, BagsReducerState, ModalState<Bag> {
  dispatch: Dispatch
}

const decorateEvent = (bag: Bag) => {
  const { vehicleName, productVersion, startTime } = bag
  if (!vehicleName || !startTime) {
    return undefined
  }
  const fiveMin = 5 * 60 * 1000
  let bagStartTime = parseInt(startTime)
  let eventStartTime = bagStartTime - fiveMin
  let eventEndTime = bagStartTime + fiveMin
  eventStartTime = eventStartTime > 0 ? eventStartTime : 0
  return {
    vehicleName: vehicleName,
    productVersion: productVersion,
    startTime: eventStartTime.toString(),
    endTime: eventEndTime.toString(),
  }
}

class Bags extends React.PureComponent<BagsProps> {
  get searchProps() {
    const { dispatch, pool, search, t } = this.props

    return {
      item: search,
      pool: pool,
      t: t,
      onSearch: () => {
        dispatch({ type: 'bags/query' })
      },
      onChange: (fields: any) => {
        dispatch({
          type: 'bags/updateState',
          payload: {
            search: {
              ...search,
              ...lodash.mapValues(fields, o => ('value' in o ? o.value : o)),
            },
          },
        })
      },
      onClear: () => {
        dispatch({
          type: 'bags/reset',
        })
      },
    }
  }

  get listProps() {
    const { list, listingBags, dispatch, search, pagination, t } = this.props
    return {
      dataSource: list,
      pagination: pagination,
      loading: listingBags,
      sort: search.sort,
      t: t,
      sortDirections: ['descend', 'ascend'],
      onChange: (pagination: Pagination, filters: any, sorter: any) => {
        if (!sorter.order) {
          sorter.order = 'descend'
        }
        dispatch({
          type: 'bags/query',
          payload: {
            search: search,
            pagination: pagination,
            sort: sorter,
          },
        })
      },
      onCreate: () => {
        dispatch({
          type: 'bags/showModal',
          payload: {
            modalType: 'create',
            currentItem: {},
          },
        })
      },
      onCreateEvent: (item: Bag) => {
        const event = decorateEvent(item)
        if (!event) {
          message.error('当前bag不能创建event, 请使用正确的bag车辆名, 创建时间')
          return
        }
        dispatch({ type: 'bags/showEventModal', payload: { currentItem: event } })
      },
      onEdit: (item: Bag) => {
        dispatch({
          type: 'bags/showModal',
          payload: {
            modalType: 'update',
            currentItem: item,
          },
        })
      },
    }
  }

  get modalProps() {
    const { admin, dispatch, t, pool, modifying, currentItem, modalVisible, modalType } = this.props

    return {
      item: modalType === 'create' ? undefined : currentItem,
      pool: pool,
      visible: modalVisible,
      destroyOnClose: true,
      maskClosable: true,
      loading: modifying,
      modalType: modalType,
      title: t(`${modalType}Bag`),
      centered: true,
      t: t,
      onModify: (data: Bag, action: string) => {
        let payload = {} as any
        switch (action) {
          case 'create':
            data.audit = { ...currentItem.audit, createdBy: admin ? admin.name : '' }
            payload = data
            break
          case 'update':
            data.audit = { ...currentItem.audit, updatedBy: admin ? admin.name : '' }
            payload = data
            break
        }
        dispatch({
          type: `bags/${action}`,
          payload,
        })
      },
      onCancel: () => {
        dispatch({
          type: 'bags/hideModal',
        })
      },
    }
  }

  get eventModalProps() {
    const { admin, dispatch, pool, t, eventModal } = this.props
    const { modifying, modalVisible, modalType, currentItem } = eventModal
    return {
      item: currentItem,
      pool: pool,
      fromBag: true,
      visible: modalVisible,
      loading: modifying,
      destroyOnClose: true,
      maskClosable: true,
      modalType: modalType,
      title: t(`${modalType}VEvent`),
      centered: true,
      t,
      onModify: (data: VEvent, action: string) => {
        const payload = {} as any
        data.audit = { ...currentItem.audit, createdBy: admin ? admin.name : '' }
        payload.vehicleEvents = [data]
        dispatch({
          type: `vevents/${action}`,
          payload,
        })
      },
      onCancel: () => {
        dispatch({ type: 'bags/hideEventModal' })
      },
    }
  }

  render() {
    return (
      <div className="bags">
        <div className="bags-inner">
          <Header {...this.searchProps} />
        </div>
        <div className="bags-inner">
          <List {...this.listProps} />
          <Modal {...this.modalProps} />
          <EventModal {...this.eventModalProps} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: DvaState) => {
  return {
    ...state.bags,
    admin: state.app.admin,
  }
}
// connect 方法传入的第一个参数是 mapStateToProps 函数，mapStateToProps 函数会返回一个对象，用于建立 State 到 Props 的映射关系
export default connect(mapStateToProps)(withTranslation('bags')(Bags))
