import moment from 'moment'
import { COOL_DOWN } from './constants'

// AMap instance status
export const getMapStatus = (status: boolean) => ({
  dragEnable: status,
  keyboardEnable: status,
  doubleClickZoom: status,
  rotateEnable: status,
})

export const getRunningState = (timestamp: string) => {
  return moment().valueOf() - +timestamp < COOL_DOWN
}
