import React from 'react'
import { RouteProps } from 'react-router-dom'
import { Dispatch } from 'redux'
import { connect } from 'dva'
import { Redirect } from 'react-router-dom'
import lodash from 'lodash'

import { AppReducerState, DvaState } from '../../_types'
import { roleCheck } from '../../_helpers'
import { Loading } from '../../pages'

interface WithAuthProps extends AppReducerState, RouteProps {
  token: string
  role: string
  dispatch: Dispatch
}

class WithAuth extends React.PureComponent<WithAuthProps, {}> {
  render() {
    const { admin, role, children, location } = this.props
    if (lodash.isEmpty(admin)) {
      return <Loading />
    }
    return roleCheck(admin, role) ? (
      children
    ) : (
      <Redirect
        to={{
          pathname: '/',
          state: { from: location },
        }}
      />
    )
  }
}

const mapStateToProps = (state: DvaState) => state.app

export default connect(mapStateToProps)(WithAuth)
