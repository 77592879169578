import React from 'react'
import './progress.scss'
import { Progress,Row, Col,Icon } from 'antd'

interface BagListProps {
  statue: boolean
  result: boolean
  file:any
  percent:number
  onReUpload: (item:any) => void
}

class Progressd extends React.PureComponent<BagListProps> {
  render() {
    const {  statue,result, file,percent,onReUpload } = this.props
    return (
      <div className="progress-box">
        <Row>
          <Col span={2}><Icon type={statue?'loading':'paper-clip'} style={{color:result?'#ccc':'#ff0000'}}/></Col>
          <Col span={20}>
            <span  style={{color:result?'#16A085':'#ff0000'}}>{file.name}</span>
            <Progress percent={percent} strokeWidth={3} style={{display:statue?'block':'none'}}/>
          </Col>
          <Col span={1}>
            <Icon type="cloud-upload" title="续传"  className="progress-upload" style={{display:result?'none':'inline-block'}} onClick={() => onReUpload(file)}/>
          </Col>
        </Row>
      </div>
    )
  }
}

export default Progressd
