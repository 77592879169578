import React from 'react'
import { Table, Tooltip, Button } from 'antd'
import { ColumnProps } from 'antd/lib/table'

import { Target, Pagination, Sort } from '../../../_types'

interface TargetListProps {
  t: any
  pagination: Pagination
  onChange: any
  sort: Sort
  onCreate: () => void
  onEdit: (item: Target) => void
}

class TargetTable extends React.PureComponent<TargetListProps> {
  render() {
    const { t, onCreate, sort, onEdit, pagination, ...tableProps } = this.props
    const columns: ColumnProps<Target>[] = [
      {
        title: t('vehicleName'),
        dataIndex: 'vehicleName',
        key: 'vehicleName',
      },
      {
        title: t('vehicleState'),
        dataIndex: 'vehicleState',
        key: 'vehicleState',
        // render: (text: string) => text && <HoverText content={text} text={text.substr(0, 6) + '...'} />,
      },
      {
        title: t('faultTotalDuration'),
        dataIndex: 'faultTotalDuration',
        key: 'faultTotalDuration',
      },
      {
        title: t('faultConditions'),
        dataIndex: 'faultConditions',
        key: 'faultConditions'
      },
      {
        title: t('faultTime'),
        dataIndex: 'faultTime',
        key: 'faultTime',
      },
      {
        title: t('issues'),
        dataIndex: 'fixIssues',
        key: 'fixIssues',
        // render: (text: string) => text && <HoverText content={text} text={text.substr(0, 12) + '...'} />,
      },
      {
        title: t('fixTime'),
        dataIndex: 'fixTime',
        key: 'fixTime',
        // render: (text: string) => text && <HoverText content={text} text={text.substr(0, 12) + '...'} />,
      },
      {
        title: t('faultStates'),
        dataIndex: 'faultStates',
        key: 'faultStates',
        
        // sortOrder: sort.field === 'endTime' && sort.order,
        // render: (time: any) => epochToDate(time),
      },
      {
        title: t('faultDuration'),
        dataIndex: 'faultDuration',
        key: 'faultDuration',
        // render: (bytes: any) => bytesToSize(bytes),
      },
      {
        title: t('modeType'),
        dataIndex: 'modeType',
        key: 'modeType',
        // sortOrder: sort.field === 'startTime' && sort.order,
        // render: (time: any) => epochToDate(time),
      },
      {
        title: t('priority'),
        dataIndex: 'priority',
        key: 'priority',
        // sortOrder: sort.field === 'time.createdAt' && sort.order,
        // render: (time: any) => epochToDate(time),
      },
      {
        title: t('followUpPerson'),
        dataIndex: 'followUpPerson',
        key: 'followUpPerson',
        
        // sortOrder: sort.field === 'time.updatedAt' && sort.order,
        // render: (time: any) => epochToDate(time),
      },
      {
        title: t('createdBy'),
        dataIndex: 'createdBy',
        key: 'createdBy',
        
        // sortOrder: sort.field === 'audit.createdBy' && sort.order,
      },
      {
        key: 'action',
        fixed: 'right',
        title: (
          <Tooltip title={t('btn.create')} mouseEnterDelay={0.5}>
            <Button type="default" size="small" icon="plus" onClick={onCreate} />
          </Tooltip>
        ),
        render: (text: string, fault: Target) => (
          <div className="bags-list-actions">
            <Button type="link" size="small" onClick={() => onEdit(fault)}>
              {t('btn.edit')}
            </Button>
          </div>
        ),
      },
    ]

    return (
      <div>
        <Table
          {...tableProps}
          pagination={{
            size: 'small',
            ...pagination,
          }}
          columns={columns}
          rowKey="id"
          scroll={{ x: true }}
        />
      </div>
    )
  }
}

export default TargetTable
