// import { grpc } from "@improbable-eng/grpc-web";
// import { StatusRequest, StatusResponse } from '../_proto/proto/api/ctrlstatus/ctrlstatus_pb'
// import { Apictrlstatus } from '../_proto/proto/api/ctrlstatus/ctrlstatus_pb_service'
import { Func } from 'echarts-for-react'
import { string } from 'prop-types'

const host = 'http://127.0.0.1:62726/stream'

// function getData(vehicle_id: any, onMessage: Func, onEnd: Func) {
//   debugger
//   const dataRequest = new StatusRequest()

//   dataRequest.setVehicleid(vehicle_id)

//   // For inspect request data
//   // console.log(dataRequest.toObject())

//   grpc.invoke(Apictrlstatus.Status, {
//     host,
//     request: dataRequest,
//     onHeaders: headers => {
//       // console.log('Fetch starts ...')
//     },
//     onMessage: message => {
//       // console.log(message)
//       onMessage && onMessage(message.toObject())
//     },
//     onEnd: (...end) => {
//       // console.log('Fetch ends')
//       onEnd && onEnd(end)
//     },
//   })
// }

function getWxDumyData(vehicle_id: string, callback: Func) {
  let data = JSON.parse(
    '{"time_meas":"1000","position":{"X":1,"Y":2,"Z":3},"roll_pitch_yaw":{"X":4,"Y":5,"Z":6},"speed":7,"acceleration":8,"engine_rpm":9,"actual_steering_angle":10,"actual_throttle_pedal":11,"actual_brake_pedal":12,"turn_signal":"LEFT","warning_blinker_on":true,"gear":"REVERSE","driving_mode":"AUTO"}',
  )

  let i = 0
  setInterval(function() {
    i = (i + 1) % 30
    data.speed = i
    callback(JSON.stringify(data))
  }, 2000)
}

let wait: any

function getWsData(vehicle_id: string, onMessage: Func) {
  let url = ''

  if (location.protocol == 'http:') {
    url = 'ws://47.101.75.34:8080/stream'
  } else {
    url = 'wss://47.101.75.34:8080/stream'
  }
  let ws = new WebSocket(url)
  ws.onopen = function(evt) {
    // Bind VehicleId
    ws.send(JSON.stringify({ vehicleId: vehicle_id }))
  }

  ws.onmessage = function(res) {
    if (!wait) {
      wait = true
      setTimeout(function() {
        onMessage(res.data)
        wait = false
      }, 100)
    }
  }
}

export const driveServices = {
  getWsData,
  getWxDumyData,
}
