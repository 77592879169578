export default {
  common: {
    // 故障统计
    modeType: '模块',
    fault:'故障统计',
    vehicleState:'车辆状态',
    faultTime:'故障时间',
    faultConditions: '故障情况',
    faultTotalDuration: '故障总时长',
    issues: '解决情况',
    fixTime:'解决时间',
    faultDuration: '故障时长',
    faultStates:'故障状态',
    priority: '优先级',
    followUpPerson: '跟进人',

    normal: '正常',
    faults: '故障',
    processing:'处理中',
    toBeVerified:'待验证',
    resolved: '已解决',
    high: '高',
    middle: '中',
    low: '低',
// 指标
    target: '指标',
    eventsType: '事件类型',

    routeName: '路线信息',
// 事件
    extraRouteName: '路线信息(附加)',
    eventsBag: '事件bag',
    dealState: '处理状态',
    issueNumber: 'issue单号',
    issueLink: 'issue链接',
    problemDescription: '问题描述',
    qa_result:'QA判定结果',
    simulation_info:'仿真信息',
    bag_oss_path:'bag地址',
    log_oss_path:'log地址',
    event_time: '事件时间',
    eventTimeRange: '时间范围',
    vehicle_id: '车辆Id',
    hmi_username: 'hmi用户名',
    event_category: '事件种类',

mileage_type: '里程类型',
event_tag: '事件标签',
bag_mhd_path: 'bag的U盘地址',
version_config: '版本配置',
peak_type: '峰值类型',
log_mhd_path: '日志U盘地址',
jira_belonging_module: 'jira所属模块',
latitude_longitude: '经纬度',
event_id: '事件Id',
img_oss_path: '图片地址',
event_second: '事件时间点',
simulation_result: '仿真结果',
simulation_scenario_id: '仿真场景Id',
simulation_job_id: '仿真Job Id',
simulation_task_id: '仿真Task Id',
curr_week:'所属周数',
curr_month:'所属月份',
curr_quarter: '所属季度',
product_dir: 'product目录路径',
simulation_bag_path: '仿真bag地址',

    bagLogData:'bag和log文件',
    sumDuration:'总时长(h)',
    sumMileage:'总里程(km)',
    sumTakeover:'接管(次)',
    mileagePerTakeover:'里程/接管(km/次)',
    region:'区域',
    ts:'时间戳',
    date:'日期',
    eventBag:'bag 路径',
    eventLog:'log 路径',
    eventTag:'标签',
    eventType:'类型',
    uploadStatus:'状态',
    taskType:'任务类型',
    taskId:'任务ID',
    modType:'模块名',    
    taskModel:'模式',
    vehicleId:'车辆ID',
    duration:'持续时长(h)',
    Avg:'平均耗时',
    max:'Max耗时',
    avgTop5:'top5耗时均值',
    monitorUrl:'监控链接',
    category:'类目',
    index: '索引',
    name: '名称',
    invitations: '邀请码',
    plocations: '接驳点',
    plocation: '接驳点',
    districtId: '区域ID',
    district: '区域',
    longitude: '经度',
    latitude: '纬度',
    altitude: '高度',
    position: '位置',
    orientation: '方向',
    state: '状态',
    status: '状态',
    startId: '起点ID',
    start: '起点',
    destinationId: '终点ID',
    destination: '终点',
    paths: '路径',
    path: '路径',
    points: '途经点',
    point: '途经点',
    createdAt: '创建于',
    updatedAt: '更新于',
    createdBy: '创建者',
    updatedBy: '更新者',
    accel: '加速度',
    speed: '速度',
    mode: '模式',
    radius: '半径',
    baseLon: '基站经度',
    baseLat: '基站纬度',
    baseX: '基站 X',
    baseY: '基站 Y',
    baseZ: '基站 Z',
    mapRegion: '地图区域',
    type: '类型',
    vin: '车架号',
    license: '牌照',
    make: '厂商',
    model: '型号',
    color: '颜色',
    timestamp: '时间戳',
    time: '时间',
    pose: '姿态',
    twist: 'Twist',
    linear: 'Linear',
    angular: 'Angular',
    operationMode: '运营模式',
    planningVersion: '规划版本',
    planningState: '规划状态',
    user: '用户',
    admin: '管理员',
    vehicle: '车辆',
    vehicles: '车辆',
    phone: '电话',
    email: '电子邮箱',
    address: '地址',
    password: '密码',
    vehicleName: '车辆名',
    productVersion: '产品版本',
    startTimeRange: '开始时间范围',
    endTimeRange: '结束时间范围',
    keywords: '关键字',
    description: '描述',
    size: '大小',
    startTime: '开始时间',
    endTime: '结束时间',
    tags: '标签',
    noStatus:'无状态',
    using:'使用中',
    opMode: {
      normal: '正常',
      demo: 'Demo',
    },
    userType: {
      normal: '常规',
      invited: '邀请',
      guest: '游客',
    },
    basic: '基本信息',
    spec: '规格',
    credential: '安全凭证',
    security: '安全',
    account: '账户',
    accessKey: 'Access Key',
    accessSecret: 'Access Secret',
    btn: {
      loadMore: '加载更多',
      delete: '删除',
      create: '创建',
      edit: '编辑',
      search: '搜索',
      reset: '重置',
      update: '更新',
      viz: 'Viz',
      download: '下载',
      advance: '高级',
      upload:'上传',
      confirm:'确认',

    },
    msg: {
      createSuccessful: '创建成功',
      createFailed: '创建失败',
      deleteSuccessful: '删除成功',
      deleteFailed: '删除失败',
      updateSuccessful: '更新成功',
      updateFailed: '更新失败',
      startSuccessful: '开始成功',
      startFailed: '开始失败',
      submitSuccessful: '提交成功',
      submitFailed: '提交失败',
      resetSuccessful: '重置成功',
      resetFailed: '重置失败',
      getSuccessful: '获取成功',
      getFailed: '获取失败',
    },
    tip: {
      noEmpty: '{{field}}不能为空',
      noData: '无数据',
      required: '必填项',
      unknownDistrict: '未知区域',
      unknownPLocation: '未知接驳点',
      unknownVehicle: '未知车辆',
      noState: '无状态',
    },
  },
  login: {
    usernameMsg: '用户名不能为空',
    passwordMsg: '密码不能为空',
    message: {
      unkown: '未知错误',
      noUser: '用户不存在. 请检查用户名或邮箱是否正确.',
      wrongPwd: '密码不正确.',
    },
    btn: {
      login: '登陆',
      logout: '退出登陆',
    },
    plhd: {
      username: '用户名 / 邮箱',
      password: '密码',
    },
    loginFailed: '登陆失败',
    logo: '中智行',
  },
  scenario: {
    dataSourceType: '数据来源',
    area: '区域',
    days:'日期',
    scenarioPrimaryClassification: '一级分类',
    scenarioSecondary: '二级分类',
    scenarioTrtiary: '三级分类',
    caseType: 'case类型/一级分类',
    interactive:'交互类',
    perception:'感知类',
    total:'总计',
    count: 'case数量',
  },
  layout: {
    mileTake: '里程 / 接管',
    dashboard: '控制面板',
    monitor: '车辆监控',
    locations: '地点管理',
    plocations: '接驳点',
    paths: '路径',
    accounts: '账号管理',
    admins: '管理员',
    users: '用户',
    vehicles: '车辆管理',
    trips: '订单管理',
    districts: '区域管理',
    roadTests: '路测管理',
    data: '数据管理',
    bags: 'Bags',
    events: '事件',
    resources: '资源链接',
    mileage: '里程统计',
    errorinfo: '车/路异常信息',
    datav: '数据监控',
    takeEvent:'事件',
    performances:'性能统计',
    vmaps:'实车状态信息',
    scenario:'场景管理',
    scenarioSurvey:'场景统计',
    scenarioList:'场景列表',
    btn: {
      logout: '退出登陆',
    },
    tooltip: {
      logout: '退出登陆',
      expand: '展开',
      collapse: '折叠',
    },
  },
  dashboard: {
    statisticsTitle: '数据统计',
  },
  resources: {
    resourcesTitle: '资源链接',
    tools: '工具',
    internalPlatforms: '内部平台',
    externalPlatforms: '外部平台',
    vehicleMonitoring:'车辆状态监控'
  },
  monitor: {
    tip: {
      track: '追踪',
      initView: '初始视野',
      fitPLocations: '适应$t(plocations)',
      fitVehicles: '适应$t(vehicles)',
    },
    moveDisabled: '移动锁定',
    drivingMode: {
      mode_auto: '自动',
      mode_manual: '手动',
    },
    unkownVersion: '未知版本',
    trafficLayer: '实时路况图层',
    fenceLayer: '路测累积围栏',
  },
  admins: {
    adminList: '管理员列表',
    createAdmin: '添加管理员',
    updateAdmin: '编辑管理员',
    name: '用户名',
    roles: '角色',
    tooltip: {
      addAdmin: '添加管理员',
    },
    tip: {
      invalidEmail: '邮箱必须以"allride.ai"或"kaizhe.ai"结尾',
      invalidPwd: '最少8位, 至少包含一个字母一个数字',
    },
  },
  users: {
    userList: '$t(user)列表',
    createUser: '添加$t(user)',
    updateUser: '编辑$t(user)',
    name: '用户名',
    tip: {
      invalidEmail: '邮箱地址无效',
      invalidPwd: ' 最少6位字符，最多20位',
    },
    trips: '订单',
    tripStatus: {
      SUBMITTED: '已提交',
      PICKING_UP: '前往出发点',
      WAITING: '等待',
      ON_ROUTE: '前往目的地',
      COMPLETED: '已完成',
      CANCELED: '已取消',
    },
    tooltip: {
      createUser: '创建$t(user)',
    },
  },
  vehicles: {
    vehicleList: '车辆列表',
    createVehicle: '添加车辆',
    updateVehicle: '更新车辆',
    tooltip: {
      addVehicle: '添加车辆',
    },
    vehicleState: {
      inactive: '未激活',
      available: '可使用',
      inuse: '使用中',
    },
  },
  drive: {
    speed: '速度',
    twist: '转速',
  },
  plocations: {
    plocationList: '接驳点列表',
    createPLocation: '创建接驳点',
    updatePLocation: '更新接驳点',
    basic: '基本信息',
    parameters: '其他参数',
    tooltip: {
      createPLocation: '创建接驳点',
    },
  },
  paths: {
    pathList: '$t(path)列表',
    createPath: '创建$t(path)',
    updatePath: '更新$t(path)',
    tooltip: {
      createPath: '创建$t(path)',
    },
    btn: {
      addStop: '添加$t(stop)',
    },
  },
  stateTag: {
    vehicle: {
      inactive: '未激活',
      available: '可使用',
      inuse: '使用中',
    },
    plocation: {
      deprecated: '已废弃',
      active: '已激活',
      inactive: '未激活',
      nonstop: '不经停',
    },
    path: {
      active: '已激活',
      inactive: '未激活',
    },
    userStatus: {
      active: '已激活',
      inactive: '未激活',
    },
  },
  trips: {
    tripList: '订单列表',
    updateTrip: '更新订单',
    userId: '用户ID',
    vehicleId: '车辆ID',
    count: '次序',
    feedback: '反馈',
    cert: '证书',
    createdAt: '创建时间',
    startedAt: '结束时间',
    completedAt: '完成时间',
    serialNum: '序列号',
    pathIndex: '路径索引',
  },
  roadTests: {
    roadTestList: '路测列表',
    updateRoadTest: '更新路测信息',
    vehicleId: '车辆ID',
    driverId: '驾驶员ID',
    passengers: '乘客',
  },
  districts: {
    districtsList: '$t(district)列表',
    createDistrict: '添加$t(district)',
    updateDistrict: '更新$t(district)',
    tooltip: {
      createDistrict: '创建$t(district)',
    },
  },
  emptyCard: {
    noData: '无数据',
  },
  invitations: {
    invitationsListTitle: '$t(invitations)列表',
  },
  bags: {
    timeRange: '创建时间范围',
    oldName: '$t(name)(旧)',
    oldPath: '$t(path)(旧)',
    createBag: '添加Bag',
    updateBag: '更新Bag',
    createVEvent: '添加事件',
    tooltip: {
      addBag: '添加Bag',
    },
  },
  vevents: {
    timeRange: '创建时间范围',
    createVEvent: '添加事件',
    updateVEvent: '更新事件',
    bagNames: 'Bag名(多)',
    tooltip: {
      addVEvent: '添加事件',
    },
  },
}
