import React from 'react'
import { Table, Tooltip, Button } from 'antd'
import { ColumnProps } from 'antd/lib/table'

import { Bag, Pagination, Sort } from '../../../_types'
import { bytesToSize, epochToDate } from '../../../_helpers'
import { HoverText } from '../../../common'
import { Resizable } from 'react-resizable';

interface BagListProps {
  t: any
  pagination: Pagination
  sort: Sort
}

const ResizeableTitle = (props:any) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};
class BagTable extends React.PureComponent<BagListProps> {
  constructor(props: any) {
    super(props)
    const { t } = this.props
    this.state = {
      columns:  [
        {
          title: t('vehicleName'),
          dataIndex: 'vehicleName',
          width: 130,
        },
        {
          title: t('productVersion'),
          dataIndex: 'productVersion',
          width: 150,
        },
        {
          title: t('time'),
          dataIndex: 'time',
          width: 150,
        },
        {
          title: t('eventBag'),
          dataIndex: 'eventBag',
          width: 150,
          render: (text: string) => text && <HoverText content={text} text={text.substr(0, 16) + '...'} />,
        },
        {
          title: t('eventLog'),
          dataIndex: 'eventLog',
          width: 150,
          render: (text: string) => text && <HoverText content={text} text={text.substr(0, 16) + '...'} />,
        },
        {
          title: t('eventTag'),
          dataIndex: 'eventTag',
          width: 200,
        },
        {
          title: t('eventType'),
          dataIndex: 'eventType',
          width: 200,
        },
        {
          title:t('uploadStatus'),
          dataIndex:'uploadStatus',
          width:100,
          
        },
        {
          title: t('description'),
          dataIndex: 'description',
          width: 200,
        },
        {
          title: t('region'),
          dataIndex: 'region',
          width: 100,
        },
        {
          title: t('ts'),
          dataIndex: 'ts',
          width: 150,
        },
      ],
    };
  }
  components =  {
    header: {
      cell: ResizeableTitle,
    },
  }
  handleResize = (index:number) => (e:any, { size }:any) => {
    this.setState(({ columns }:any) => {
      const nextColumns = [...columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };
      return { columns: nextColumns };
    });
  };

  render() {
    const { t, sort,pagination, ...tableProps } = this.props
    const  {columns}:any = this.state
    const columnscResiz = columns.map((col:any, index:number) => ({
      ...col,
      onHeaderCell: (column:any) => ({
        width: column.width,
        onResize: this.handleResize(index),
      }),
    }));
    return (
      <div>
        <Table
          {...tableProps}
          pagination={{
            size: 'small',
            ...pagination,
          }}
          size="small"
          bordered
          components={this.components}
          columns={columnscResiz}
          rowKey='ts'
          className ="columnscResiz"
          scroll={{ x: true }}
        />
      </div>
    )
  }
}

export default BagTable
