import modelExtend from 'dva-model-extend'
import lodash from 'lodash'
import { message } from 'antd'
import i18next from 'i18next'

import { pathMatchRegexp } from '../_helpers'
import { BagPool, BagSearch, BagsReducerState, BagModel, ModalState, VEvent } from '../_types'
import api from '../_dva_services'
import { ONE_YEAR } from '../_constants'
import { pageState, pageModel, modalModel, modalState, sortQuery, searchQuery, SearchKeys } from '../_utils'

const bagPool: BagPool = {
  vehicleAutoComplete: {},
  tags: [],
}

const initSearch: BagSearch = {
  productVersion: '',
  name: '',
  sort: {
    field: 'time.createdAt',
    order: 'descend',
  },
  time: {
    createdAt: ['-1', (Date.now() + ONE_YEAR).toString()],
    updatedAt: ['-1', (Date.now() + ONE_YEAR).toString()],
  },
  audit: {
    createdBy: '',
    updatedBy: '',
  },
  vehicleName: '',
  startTime: ['-1', (Date.now() + ONE_YEAR).toString()],
  endTime: ['-1', (Date.now() + ONE_YEAR).toString()],
  text: '',
  keys: ['Id', 'ProductVersion', 'VehicleName', 'Description', 'Name', 'OldName', 'Path', 'OldPath'],
}

const eventModal: ModalState<VEvent> = {
  currentItem: {} as VEvent,
  modalVisible: false,
  modalType: 'create',
  modifying: false,
}

const initState: BagsReducerState = {
  list: [],
  eventModal: eventModal,
  pool: bagPool,
  search: initSearch,
  listingBags: false,
  ...pageState,
  ...modalState,
}

const searchKeys: SearchKeys = {
  matchKeys: ['productVersion', 'vehicleName', 'audit.createdBy'],
  rangeKeys: ['startTime', 'endTime', 'time.createdAt', 'time.updatedAt'],
  multiMatchKeys: [],
}

const bagsModel: BagModel = modelExtend<BagModel>(pageModel, modalModel, {
  namespace: 'bags', // 表示在全局 state 上的 key
  state: initState,
  // 订阅数据源的 subscriptions 相当于vue中的watch函数
  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(location => {
        if (pathMatchRegexp('/bags', location.pathname)) {
          dispatch({ type: 'query' })
          dispatch({ type: 'queryPool' })
        }
      })
    },
  },
  // 处理同步动作，用来算出最新的 State 等同于 redux 里的 reducer，接收 action，同步更新 state
  // dispatching function 是一个用于触发 action 的函数，action 是改变 State 的唯一途径，但是它只描述了一个行为，而 dipatch 可以看作是触发这个行为的方式，而 Reducer 则是描述如何改变数据的。
  reducers: {
    reset(state) {
      return {
        ...state,
        search: initSearch,
      }
    },

    listRequest(state, {}) {
      return {
        ...state,
        listingBags: true,
      }
    },

    listSuccess(state: BagsReducerState) {
      return {
        ...state,
        listingBags: false,
      }
    },

    listFail(state: BagsReducerState) {
      return {
        ...state,
        listingBags: false,
      }
    },

    // modify = create / update / delete
    modifyRequest(state, {}) {
      return {
        ...state,
        modifying: true,
      }
    },

    modifySuccess(state: BagsReducerState) {
      return {
        ...state,
        modifying: false,
      }
    },

    modifyFail(state: BagsReducerState) {
      return {
        ...state,
        modifying: false,
      }
    },
    showEventModal(state, { payload }) {
      return {
        ...state,
        eventModal: {
          ...state.eventModal,
          modalVisible: true,
          ...payload,
        },
      }
    },

    hideEventModal(state) {
      return { ...state, eventModal: { ...state.eventModal, modalVisible: false } }
    },
  },
  // 处理异步逻辑动作的 effects，
  effects: {
    *queryPool({ payload = {} }, { call, put }) {
      const data = yield [call(api.listTag, payload), call(api.listVehicles, payload)]
      if (data) {
        yield put({
          type: 'updateState',
          payload: {
            pool: {
              tags: data[0].success && data[0].tags.map((t: any) => t.name).sort(),
              vehicleAutoComplete: data[1].success && lodash.keyBy(data[1].vehicles, 'name'),
            },
          },
        })
      }
    },
    // call：执行异步函数
    // put：发出一个 Action，类似于 dispatch
    *query({ payload = {} }, { call, put, select }) {
      const { search, pagination } = yield select((state: any) => state.bags)
      const { sort } = search

      payload = {
        search: search,
        pagination: pagination,
        sort: sort,
        ...payload,
      }

      const cloneSort = lodash.pick(payload.sort, ['field', 'order'])
      payload.sort = sortQuery(payload.sort)
      searchKeys.multiMatchKeys = search.keys
      payload.search = searchQuery(payload.search, searchKeys)
      payload.pagination = lodash.pick(payload.pagination, ['current', 'total', 'pageSize'])

      try {
        yield put({ type: 'listRequest' })

        const data = yield call(api.queryBagList, payload)

        yield put({
          type: 'updateState',
          payload: {
            list: data.bags,
            pagination: {
              ...pagination,
              ...data.pagination,
            },
            search: {
              ...search,
              sort: cloneSort,
            },
          },
        })

        yield put({ type: 'listSuccess' })
      } catch (e) {
        yield put({ type: 'listFail' })
      }
    },

    *create({ payload = {} }, { call, put }) {
      yield put({ type: 'modifyRequest' })

      try {
        yield call(api.createBag, { bag: payload })

        yield put({ type: 'modifySuccess' })
        yield put({ type: 'reset' })
        yield put({ type: 'query' })
        yield put({ type: 'hideModal' })

        message.success(i18next.t('msg.createSuccessful'))
      } catch (e) {
        yield put({ type: 'modifyFail' })

        message.error(i18next.t('msg.createFailed'))
      }
    },

    *update({ payload = {} }, { call, put }) {
      yield put({ type: 'modifyRequest' })

      try {
        yield call(api.updateBag, { bag: payload })

        yield put({ type: 'modifySuccess' })
        yield put({ type: 'query' })

        message.success(i18next.t('msg.updateSuccessful'))
      } catch (e) {
        yield put({ type: 'modifyFail' })

        message.error(i18next.t('msg.updateFailed'))
      }
    },

    *delete({ payload = {} }, { call, put }) {
      yield put({ type: 'modifyRequest' })

      try {
        yield call(api.deleteBag, payload)

        yield put({ type: 'modifySuccess' })
        yield put({ type: 'query' })
        yield put({ type: 'hideModal' })

        message.success(i18next.t('msg.deleteSuccessful'))
      } catch (e) {
        yield put({ type: 'modifyFail' })

        message.error(i18next.t('msg.deleteFailed'))
      }
    },
  },
})

export default bagsModel
