import modelExtend from 'dva-model-extend'
import lodash from 'lodash'
import { message } from 'antd'
import i18next from 'i18next'

import { pathMatchRegexp } from '../_helpers'
import { TargetPool, TargetSearch, TargetsReducerState, TargetModel, ModalState, VEvent } from '../_types'
import api from '../_dva_services'
import { ONE_YEAR } from '../_constants'
import { pageState, pageModel, modalModel, modalState, sortQuery, searchQuery, SearchKeys } from '../_utils'

const targetPool: TargetPool = {
  vehicleArray: [],
  event_type: []
}

const initSearch: TargetSearch = {
  audit: {
    createdBy: ''
  },
  vehicle_name: '',
      product_version: '',
      ts: '',
      event_type: '',
      route_name: '',
      belonging_module: '',
      start_ts: '',
      end_ts: '',
  // createdBy: '',
  event_time: ['-1', (Date.now() + ONE_YEAR).toString()],
  sort: {
    field: 'time.createdAt',
    order: 'descend',
  },
  text: '',
  // time: {
  //   createdAt: ['-1', (Date.now() + ONE_YEAR).toString()],
  //   updatedAt: ['-1', (Date.now() + ONE_YEAR).toString()],
  // },
  keys: ['vehicleName', 'vehicleState', 'modeType', 'createdBy', 'faultsTime'],
}

const eventModal: ModalState<VEvent> = {
  currentItem: {} as VEvent,
  modalVisible: false,
  modalType: 'create',
  modifying: false,
}

const initState: TargetsReducerState = {
  list: [],
  eventModal: eventModal,
  pool: targetPool,
  search: initSearch,
  listingBags: false,
  ...pageState,
  ...modalState,
}

const searchKeys: SearchKeys = {
  matchKeys: [],
  rangeKeys: [],
  multiMatchKeys: [],
}

// "models" 目录通常用于存放应用程序的数据模型或业务逻辑模型
const faultsModel: TargetModel = modelExtend<TargetModel>(pageModel, modalModel, {
  namespace: 'targets', // 表示在全局 state 上的 key
  state: initState,
  // 订阅数据源的 subscriptions 相当于vue中的watch函数,进入页面就调用
  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(location => {
        if (pathMatchRegexp('/target', location.pathname)) {
          dispatch({ type: 'query' })
          dispatch({ type: 'modeTypeList' })
        }
      })
    },
  },
  // 处理同步动作，用来算出最新的 State 等同于 redux 里的 reducer，接收 action，同步更新 state
  // dispatching function 是一个用于触发 action 的函数，action 是改变 State 的唯一途径，但是它只描述了一个行为，而 dipatch 可以看作是触发这个行为的方式，而 Reducer 则是描述如何改变数据的。
  reducers: {
    reset(state) {
      return {
        ...state,
        search: initSearch,
      }
    },

    listRequest(state, {}) {
      return {
        ...state,
        listingBags: true,
      }
    },

    listSuccess(state: TargetsReducerState) {
      return {
        ...state,
        listingBags: false,
      }
    },

    listFail(state: TargetsReducerState) {
      return {
        ...state,
        listingBags: false,
      }
    },

    // modify = create / update / delete
    modifyRequest(state, {}) {
      return {
        ...state,
        modifying: true,
      }
    },

    modifySuccess(state: TargetsReducerState) {
      return {
        ...state,
        modifying: false,
      }
    },

    modifyFail(state: TargetsReducerState) {
      return {
        ...state,
        modifying: false,
      }
    },
    showEventModal(state, { payload }) {
      return {
        ...state,
        eventModal: {
          ...state.eventModal,
          modalVisible: true,
          ...payload,
        },
      }
    },

    hideEventModal(state) {
      return { ...state, eventModal: { ...state.eventModal, modalVisible: false } }
    },


  },
  // 处理异步逻辑动作的 effects，
  effects: {
    *modeTypeList({}, { call, put }) {
      const data = yield [call(api.listTaskType), call(api.listTaskType)]
        if (data) {
            yield put({
              type: 'updateState',
              payload: {
                pool:{
                  vehicleArray:data[0].items,
                }
               }
            })
        }
    },
  
    // call：执行异步函数
    // put：发出一个 Action，类似于 dispatch
    *query({ payload = {} }, { call, put, select }) {
      // yield select 是 Redux-Saga 库中的一个特殊语法，用于从 Redux store 中选择数据。
      // 它允许 Saga 从 store 中获取特定的数据并在需要时使用。
      const { search, pagination } = yield select((state: any) => state.targets)
      const { sort } = search
      console.log('-----------1111---------', search, sort)
      payload = {
        search: search,
        pagination: pagination,
        sort: sort,
      }
      console.log('-----------2222---------', payload)
      // 用于从对象中选择指定的属性。它接受两个参数：第一个参数是要选择属性的对象，第二个参数是要选择的属性名。它返回一个新的对象，包含只包含指定属性的副本
      // const cloneSort = lodash.pick(payload.sort, ['field', 'order'])
      // payload.sort = sortQuery(payload.sort)
      // searchKeys.multiMatchKeys = search.keys
      // payload.search = searchQuery(payload.search, searchKeys)
      payload.pagination = lodash.pick(payload.pagination, ['current', 'total', 'pageSize'])
      try {
        yield put({ type: 'listRequest' })

        // const datas = yield call(api.queryBagList, payload)
        const data = {
          "code": 200,
          "message": "success",
              "items": [
                {
                  "id": 1,
                  "vehicleName": "id001",
                  "vehicleState": "Normal",
                  'faultTotalDuration': 0,
                  'firstRowIndex': 0,
                  'rowSpan': 0,
                  "faultConditions": "车辆停了",
                  "faultTime": "2023-7-15 12:00",
                  "fixIssues": "情况",
                  "fixTime": "2023-7-15 12:00",
                  "faultDuration": 6,
                  "modeType": "planning",
                  "faultStates": "processing",
                  "priority": "high",
                  "followUpPerson": "wangjie",
                  "createdBy": "shuzhen",
                  "createTime": "2023-1-20 12:00"
               },
                {
                  "id": 2,
                  "vehicleName": "id001",
                  "vehicleState": "Fault",
                  'faultTotalDuration': 0,
                  'firstRowIndex': 0,
                  'rowSpan': 0,
                  "faultConditions": "车辆停了",
                  "faultTime": "2023-7-15 12:00",
                  "fixIssues": "情况",
                  "fixTime": "2023-7-15 12:00",
                  "faultDuration": 1,
                  "modeType": "planning",
                  "faultStates": "toBeVerified",
                  "priority": "middle",
                  "followUpPerson": "wangjie",
                  "createdBy": "shuzhen",
                  "createTime": "2023-1-20 12:00"
               },
               {
                 "id": 3,
                 "vehicleName": "id001",
                 "vehicleState": "正常",
                 'faultTotalDuration': 0,
                 'firstRowIndex': 0,
                 'rowSpan': 0,
                 "faultConditions": "车辆停了",
                 "faultTime": "2023-7-15 12:00",
                 "fixIssues": "情况",
                 "fixTime": "2023-7-15 12:00",
                 "faultDuration": 1,
                 "modeType": "planning",
                 "faultStates": "resolved",
                 "priority": "low",
                 "followUpPerson": "wangjie",
                 "createdBy": "shuzhen",
                 "createTime": "2023-1-20 12:00"
              },
              {
                "id": 4,
                "vehicleName": "id002",
                "vehicleState": "正常",
                'faultTotalDuration': 0,
                'firstRowIndex': 0,
                'rowSpan': 0,
                "faultConditions": "车辆停了",
                "faultTime": "2023-7-15 12:00",
                "fixIssues": "情况",
                "fixTime": "2023-7-15 12:00",
                "faultDuration": 5,
                "modeType": "planning",
                "faultStates": "已解决",
                "priority": "高",
                "followUpPerson": "wangjie",
                "createdBy": "shuzhen",
                "createTime": "2023-1-20 12:00"
             },
             {
               "id": 5,
               "vehicleName": "id002",
               "vehicleState": "正常",
               'faultTotalDuration': 0,
               'firstRowIndex': 0,
               'rowSpan': 0,
               "faultConditions": "车辆停了",
               "faultTime": "2023-7-15 12:00",
               "fixIssues": "情况",
               "fixTime": "2023-7-15 12:00",
               "faultDuration": 5,
               "modeType": "planning",
               "faultStates": "已解决",
               "priority": "高",
               "followUpPerson": "wangjie",
               "createdBy": "shuzhen",
               "createTime": "2023-1-20 12:00"
            },
            {
              "id": 6,
              "vehicleName": "id003",
              "vehicleState": "正常",
              'faultTotalDuration': 0,
              'firstRowIndex': 0,
              'rowSpan': 0,
              "faultConditions": "车辆停了",
              "faultTime": "2023-7-15 12:00",
              "fixIssues": "情况",
              "fixTime": "2023-7-15 12:00",
              "faultDuration": 3,
              "modeType": "planning",
              "faultStates": "已解决",
              "priority": "高",
              "followUpPerson": "wangjie",
              "createdBy": "shuzhen",
              "createTime": "2023-1-20 12:00"
           },
           {
             "id": 7,
             "vehicleName": "id001",
             "vehicleState": "正常",
             'faultTotalDuration': 0,
             'firstRowIndex': 0,
             'rowSpan': 0,
             "faultConditions": "车辆停了",
             "faultTime": "2023-7-15 12:00",
             "fixIssues": "情况",
             "fixTime": "2023-7-15 12:00",
             "faultDuration": 1,
             "modeType": "planning",
             "faultStates": "已解决",
             "priority": "高",
             "followUpPerson": "wangjie",
             "createdBy": "shuzhen",
             "createTime": "2023-1-20 12:00"
          },
          {
            "id": 8,
            "vehicleName": "id008",
            "vehicleState": "正常",
            'faultTotalDuration': 0,
            'firstRowIndex': 0,
            'rowSpan': 0,
            "faultConditions": "车辆停了",
            "faultTime": "2023-7-15 12:00",
            "fixIssues": "情况",
            "fixTime": "2023-7-15 12:00",
            "faultDuration": 1,
            "modeType": "planning",
            "faultStates": "已解决",
            "priority": "高",
            "followUpPerson": "wangjie",
            "createdBy": "shuzhen",
            "createTime": "2023-1-20 12:00"
         },
         {
           "id": 9,
           "vehicleName": "id008",
           "vehicleState": "正常",
           'faultTotalDuration': 0,
           'firstRowIndex': 0,
           'rowSpan': 0,
           "faultConditions": "车辆停了",
           "faultTime": "2023-7-15 12:00",
           "fixIssues": "情况",
           "fixTime": "2023-7-15 12:00",
           "faultDuration": 1,
           "modeType": "planning",
           "faultStates": "已解决",
           "priority": "高",
           "followUpPerson": "wangjie",
           "createdBy": "shuzhen",
           "createTime": "2023-1-20 12:00"
        }
              ],
              "pagination": {
                "current": 1,
                "total": 790,
                "pageSize": 10
              }
      }
      let lists = lodash.sortBy(data.items, ['vehicleName'])
      
      //如果vehicleName值相等，累加faultDuration，然后添加新的totalFaultDuration
      lists.forEach((item,itemIndex) => {
        lists.forEach((data,dataIndex) => {
          if(item.vehicleName === data.vehicleName) {
            if(item.rowSpan === 0) {
              item.firstRowIndex = dataIndex
            }
            item.rowSpan = item.rowSpan + 1
            
            item.faultTotalDuration = item.faultTotalDuration + data.faultDuration
          }
        })
        // console.log(item.firstRowIndex,item.rowSpan)
      })
        yield put({
          type: 'updateState',
          payload: {
            list: lists,
            pagination: {
              ...pagination,
              ...data.pagination,
            },
            search: {
              ...search,
              // sort: cloneSort,
            },
          },
        })

        yield put({ type: 'listSuccess' })
      } catch (e) {
        yield put({ type: 'listFail' })
      }
    },
     // 创建方法
    *create({ payload = {} }, { call, put }) {
      // 这里是做什么？
      yield put({ type: 'modifyRequest' }) 

      try {
        // 修改创建事件接口和参数的地方
        yield call(api.createBag, { bag: payload })

        yield put({ type: 'modifySuccess' })
        yield put({ type: 'reset' })
        yield put({ type: 'query' })
        yield put({ type: 'hideModal' })

        message.success(i18next.t('msg.createSuccessful'))
      } catch (e) {
        yield put({ type: 'modifyFail' })

        message.error(i18next.t('msg.createFailed'))
      }
    },
    // 更新一条数据
    *update({ payload = {} }, { call, put }) {
      yield put({ type: 'modifyRequest' })

      try {
        yield call(api.updateBag, { bag: payload })

        yield put({ type: 'modifySuccess' })
        yield put({ type: 'query' })

        message.success(i18next.t('msg.updateSuccessful'))
      } catch (e) {
        yield put({ type: 'modifyFail' })

        message.error(i18next.t('msg.updateFailed'))
      }
    },
    // 删除一条数据 不需要
    *delete({ payload = {} }, { call, put }) {
      yield put({ type: 'modifyRequest' })

      try {
        yield call(api.deleteBag, payload)

        yield put({ type: 'modifySuccess' })
        yield put({ type: 'query' })
        yield put({ type: 'hideModal' })

        message.success(i18next.t('msg.deleteSuccessful'))
      } catch (e) {
        yield put({ type: 'modifyFail' })

        message.error(i18next.t('msg.deleteFailed'))
      }
    },
  },
})

export default faultsModel
