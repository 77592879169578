import { Admin } from '../_types'
import { Path, pathToRegexp } from 'path-to-regexp'
import { curry } from 'lodash'
import i18n from './i18n'
import moment from 'moment'
import lodash from 'lodash'

export const languages = i18n.languages

export const deLangPrefix = curry((languages: string[], pathname: string) => {
  if (!pathname) {
    return ''
  }
  for (const item of languages) {
    if (pathname.startsWith(`/${item}/`)) {
      return pathname.replace(`/${item}/`, '/')
    }
  }

  return pathname
})(languages)

export const pathMatchRegexp = (regexp: Path, pathname: string) => {
  return pathToRegexp(regexp).exec(deLangPrefix(pathname))
}

export const roleCheck = (admin: Admin | undefined, role: string): boolean => {
  if (!admin || admin.roles == undefined) {
    return false
  }
  if (role === '') {
    return true
  }
  return admin.roles.split(',').some(v => {
    return v.trim() === role
  })
}

interface AccObject {
  [key: string]: any
}

const drill = (acc: AccObject, cur: string) => {
  if (typeof acc === 'undefined' || acc === null) {
    return undefined
  }
  return acc[cur]
}

export const drillDown = (parent: AccObject, descendents: string[]) => descendents.reduce(drill, parent)
/**
 * 
 * @param e 时间
 * @returns 转化为时间
 */
export const epochToMoment = (e: string | undefined) => {
  if (!e) {
    return null
  }
  if(String(e).length < 13){
    e = e + '000'
  }
  const num = parseInt(e)
  return num > 0 ? moment(num) : null
}
/**
 * 
 * @param e 时间
 * @returns 时间戳转年月日
 */
export const epochToDate = (e: string | undefined) => {
  if(String(e).length < 13){
    e = e + '000'
  }
  const m = epochToMoment(e)
  if (!m) {
    return ''
  }
  return m.local().format('YYYY-MM-DD HH:mm:ss')
}
/**
 * 
 * @param e 时间
 * @returns 时间戳留时分秒
 */
export const epochToTime = (e: string | undefined) => {
  if(String(e).length < 13){
    e = e + '000'
  }
  const m = epochToMoment(e)
  if (!m) {
    return ''
  }
  return m.local().format('HH:mm:ss')
}
/**
 * 
 * @param m 时间
 * @returns 时间转时间戳
 */
export const momentToEpoch = (m: string) => {
  if (!m) {
    return '0'
  }
  return moment(m)
    .valueOf()
    .toString()
}

export const momentToEpoch2 = (m: string) => {
  if (!m) {
    return '0'
  }
  return moment(m).startOf('day')
    .valueOf()
    .toString()
}

export const bytesToSize = (bytes: string) => {
  const b = parseInt(bytes)
  if (!b || b == 0) {
    return '0 Byte'
  }
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  let i = Math.floor(Math.log(b) / Math.log(1024))
  return Math.round(b / Math.pow(1024, i)) + ' ' + sizes[i]
}

export const stringHashCode = (str: string, size: number) => {
  let hash = 0,
    chr
  if (str.length === 0) {
    return hash
  }
  for (let i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
  return Math.abs(hash % size)
}

// flatten obj deeply
export const flattenObj = (obj: any, prefix: string = '', acc = {} as any) => {
  for (let k in obj) {
    let propName = prefix ? prefix + '.' + k : k
    if (lodash.isPlainObject(obj[k])) {
      flattenObj(obj[k], propName, acc)
    } else {
      acc[propName] = obj[k]
    }
  }
  return acc
}

// base64方法
export const Base64 = {

  // private property
  _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
  
  // public method for encoding
  encode: function(input:any) {
  var output = "";
  var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
  var i = 0;
  
  input = Base64._utf8_encode(input);
  
  while (i < input.length) {
  
  chr1 = input.charCodeAt(i++);
  chr2 = input.charCodeAt(i++);
  chr3 = input.charCodeAt(i++);
  
  enc1 = chr1 >> 2;
  enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
  enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
  enc4 = chr3 & 63;
  
  if (isNaN(chr2)) {
  enc3 = enc4 = 64;
  } else if (isNaN(chr3)) {
  enc4 = 64;
  }
  
  output = output + this._keyStr.charAt(enc1) + this._keyStr.charAt(enc2) + this._keyStr.charAt(enc3) + this._keyStr.charAt(enc4);
  
  }
  
  return output;
  },
  
  // public method for decoding
  decode: function(input:any) {
    var output = "";
    var chr1, chr2, chr3;
    var enc1, enc2, enc3, enc4;
    var i = 0;
    
    input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");
    
    while (i < input.length) {
    
    enc1 = this._keyStr.indexOf(input.charAt(i++));
    enc2 = this._keyStr.indexOf(input.charAt(i++));
    enc3 = this._keyStr.indexOf(input.charAt(i++));
    enc4 = this._keyStr.indexOf(input.charAt(i++));
    
    chr1 = (enc1 << 2) | (enc2 >> 4);
    chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
    chr3 = ((enc3 & 3) << 6) | enc4;
    
    output = output + String.fromCharCode(chr1);
    
    if (enc3 != 64) {
    output = output + String.fromCharCode(chr2);
    }
    if (enc4 != 64) {
    output = output + String.fromCharCode(chr3);
    }
    
    }
    
    output = Base64._utf8_decode(output);
    
    return output;
    
  },
  
  // private method for UTF-8 encoding
  _utf8_encode: function(string:any) {
  string = string.replace(/\r\n/g, "\n");
  var utftext = "";
  
  for (var n = 0; n < string.length; n++) {
  
  var c = string.charCodeAt(n);
  
  if (c < 128) {
  utftext += String.fromCharCode(c);
  } else if ((c > 127) && (c < 2048)) {
  utftext += String.fromCharCode((c >> 6) | 192);
  utftext += String.fromCharCode((c & 63) | 128);
  } else {
  utftext += String.fromCharCode((c >> 12) | 224);
  utftext += String.fromCharCode(((c >> 6) & 63) | 128);
  utftext += String.fromCharCode((c & 63) | 128);
  }
  
  }
  
  return utftext;
  },
  
  // private method for UTF-8 decoding
  _utf8_decode: function(utftext:any) {
  var string = "";
  var i = 0;
  var c = 0,c1 =0, c2 = 0,c3 = 0;
  
  while (i < utftext.length) {
  
  c = utftext.charCodeAt(i);
  
  if (c < 128) {
  string += String.fromCharCode(c);
  i++;
  } else if ((c > 191) && (c < 224)) {
  c2 = utftext.charCodeAt(i + 1);
  string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
  i += 2;
  } else {
  c2 = utftext.charCodeAt(i + 1);
  c3 = utftext.charCodeAt(i + 2);
  string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
  i += 3;
  }
  
  }
  
  return string;
  }
  
}
