import React from 'react'
import { Tree, Input} from 'antd'
import { gData } from '../../../_utils'

interface BagListProps {
  t: any
  onClickTree: (item:any,e:any) => void
}
const { TreeNode } = Tree;
const { Search } = Input;

const dataList:any = [];
const generateList = (data:any) => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    const { key,title } = node;
    dataList.push({ key, title });
    if (node.children) {
      generateList(node.children);
    }
  }
};
generateList(gData);

const getParentKey:any = (key:any, tree:any) => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some((item:any) => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey;
};
class BagTable extends React.PureComponent<BagListProps> {
  state = {
    expandedKeys: [],
    searchValue: '',
    autoExpandParent: true,
  };

  onExpand = (expandedKeys:any) => {
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
  };

  onChange = (e:any) => {
    const { value } = e.target;
    const expandedKeys = dataList
      .map((item:any) => {
        if (item.title.indexOf(value) > -1) {
          return getParentKey(item.key, gData);
        }
        return null;
      })
      .filter((item:any, i:any, self:any) => item && self.indexOf(item) === i);
    this.setState({
      expandedKeys,
      searchValue: value,
      autoExpandParent: true,
    });
  };
  // onSelect = (selectedKeys:any,e:any)=>{
  //   console.log(selectedKeys)
  //   console.log(e)
  // }
  render() {
  const { searchValue, expandedKeys, autoExpandParent } = this.state;
  const {t, onClickTree} = this.props
  const loop = (data:any) =>
    data.map((item:any) => {
      const index = item.title.indexOf(searchValue);
      const beforeStr = item.title.substr(0, index);
      const afterStr = item.title.substr(index + searchValue.length);
      const title =
        index > -1 ? (
          <span>
            {beforeStr}
            <span style={{ color: '#f50' }}>{searchValue}</span>
            {afterStr}
          </span>
        ) : (
          <span>{item.title}</span>
        );
      if (item.children) {
        return (
          <TreeNode key={item.key} title={title}>
            {loop(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.key} title={title} />;
    });
    return (
      <div className="scenario-list-tree">
        <Search style={{ marginBottom: 8 }} placeholder="Search" onChange={this.onChange} />
        <Tree
          onExpand={this.onExpand}
          expandedKeys={expandedKeys}
          autoExpandParent={autoExpandParent}
          onSelect = {onClickTree}
        >
          {loop(gData)}
        </Tree>
      </div>
    )
  }
}

export default BagTable
