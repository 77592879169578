import modelExtend from 'dva-model-extend'
import jwtDecode from 'jwt-decode'
import Cookies from 'universal-cookie'
import { routerRedux } from 'dva/router'

import { DecodedToken } from '../_types'
import { pathMatchRegexp } from '../_helpers'
import api from '../_dva_services'
import { model } from '../_utils'

const cookies = new Cookies()

const appModel = modelExtend(model, {
  namespace: 'app',
  state: {
    admin: {},
    locationPathname: '',
  },
  subscriptions: {
    setup({ dispatch }) {
      dispatch({ type: 'query' })
    },
    setupHistory({ dispatch, history }) {
      history.listen(location => {
        dispatch({
          type: 'updateState',
          payload: {
            locationPathname: location.pathname,
          },
        })
      })
    },
  },
  effects: {
    *query({ payload = {} as any }, { call, put }) {
      const token = cookies.get('token')
      let success, admin
      try {
        const decoded = jwtDecode<DecodedToken>(token)
        ;({ success, admin } = yield call(api.queryUserInfo, { id: decoded.id }))
      } catch (e) {
        success = false
      }
      if (success && admin) {
        yield put({ type: 'updateState', payload: { admin } })
        if (pathMatchRegexp(['/', '/login'], window.location.pathname)) {
          routerRedux.push('/dashboard')
        }
      } else {
        yield put({ type: 'logout' })
      }
    },
    *logout({ payload = {} as any }, { put }) {
      cookies.remove('token')
      yield put(routerRedux.push('/login', { from: window.location.pathname }))
    },
  },
})

export default appModel
