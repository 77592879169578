import modelExtend from 'dva-model-extend'
import jwtDecode from 'jwt-decode'
import Cookies from 'universal-cookie'

import api, { reloadApiHeader } from '../_dva_services'
import { model } from '../_utils'
import { DecodedToken } from '../_types'

const cookies = new Cookies()

const authModel = modelExtend(model, {
  namespace: 'auth',
  state: {
    loggingIn: false,
  },
  effects: {
    *login({ payload }, { put, call, select }) {
      try {
        const data = yield call(api.loginUser, payload)
        if (data.success) {
          const token = data.token
          const decoded = jwtDecode<DecodedToken>(token)
          cookies.set('token', token, { expires: new Date(decoded.exp) })
          reloadApiHeader()
          yield put({ type: 'app/updateState', payload: { admin: data.admin } })
        }
      } catch (error) {
        yield put({ type: 'updateState', payload: { message: { type: 'error', detail: error.detail } } })
      }
    },
    *clear({ payload }, { put }) {
      yield put({ type: 'updateState', payload: { message: undefined } })
    },
  },
})

export default authModel
