import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import { CookiesProvider } from 'react-cookie'
import { History } from 'history'

import { routes, store } from './_helpers'
import { AuthRoute, Layout } from './common'

function RouterConfig({ history }: { history: History }) {
  return (
    <Provider store={store}>
      <CookiesProvider>
        <div className="App">
          <Router history={history}>
            <Switch>
              {routes
                .filter(route => !route.auth)
                .map(({ path, auth, exact, component }) => (
                  <Route key={path} path={path} component={component} auth={auth} exact={exact} />
                ))}
              <Layout>
                {routes
                  .filter(route => route.auth)
                  .map(({ path, role, exact, component }) => (
                    <AuthRoute key={path} role={role} path={path} component={component} exact={exact} />
                  ))}
              </Layout>
            </Switch>
          </Router>
        </div>
      </CookiesProvider>
    </Provider>
  )
}

export default RouterConfig
