import request from '../_utils/request'
// import { apiPrefix } from '../_utils/config'
import Cookies from 'universal-cookie'
import { axs } from '../_helpers'
import { api, APIKeys } from './api'

const cookies = new Cookies()

const gen = (params: any) => {
  const paramsArray = params.split(' ')
  let url = params
  let method = 'GET'
  const headers = {
    Token: cookies.get('token'),
    'Content-Type': 'application/json;charset=utf-8',
  }
  if (paramsArray.length === 2) {
    method = paramsArray[0]
    url = paramsArray[1]
  }
  if (paramsArray.length === 2 && /^((http||https):\/\/)/.test(paramsArray[1])) {
    url = paramsArray[1]
  }
  return function(data: any) {
    return request({
      url,
      headers,
      data,
      method,
    })
  }
}

const APIFunction = {} as { [key in APIKeys]: ReturnType<typeof gen> }
;(Object.keys(api) as APIKeys[]).map(key => (APIFunction[key] = gen(api[key])))

// reload api header since created instance will cache header
export const reloadApiHeader = () => {
  Object.assign(axs.defaults, {
    headers: {
      Token: cookies.get('token'),
      'Content-Type': 'application/json;charset=utf-8',
    },
  })
  ;(Object.keys(api) as APIKeys[]).map(key => (APIFunction[key] = gen(api[key])))
}

export default APIFunction
