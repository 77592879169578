import modelExtend from 'dva-model-extend'
import lodash from 'lodash'
import { message } from 'antd'
import i18next from 'i18next'

import { pathMatchRegexp, epochToDate } from '../_helpers'
import { FaultPool, FaultSearch, FaultsReducerState, FaultModel, ModalState, VEvent } from '../_types'
import api from '../_dva_services'
import { pageState, pageModel, modalModel, modalState } from '../_utils'

const faultPool: FaultPool = {
  tags: [],
  vehicleArray: [],
  userArray: []
}

const initSearch: FaultSearch = {
  vehicleName: '',
  vehicleState: '',
  modType: '',
  createdBy: '',
  faultsTime: ['-1', (Date.now()).toString()],
  faultTime: ['-1', (Date.now()).toString()],
  sort: {
    field: 'time.createdAt',
    order: 'descend',
  },
  text: '',
  keys: ['vehicleName', 'vehicleState', 'modType', 'createdBy', 'faultTime']
}

const eventModal: ModalState<VEvent> = {
  currentItem: {} as VEvent,
  modalVisible: false,
  modalType: 'create',
  modifying: false,
}

const initState: FaultsReducerState = {
  list: [],
  eventModal: eventModal,
  pool: faultPool,
  search: initSearch,
  listingBags: false,
  ...pageState,
  ...modalState,
}

// "models" 目录通常用于存放应用程序的数据模型或业务逻辑模型
const faultsModel: FaultModel = modelExtend<FaultModel>(pageModel, modalModel, {
  namespace: 'faults', // 表示在全局 state 上的 key
  state: initState,
  // 订阅数据源的 subscriptions 相当于vue中的watch函数,进入页面就调用
  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(location => {
        if (pathMatchRegexp('/fault', location.pathname)) {
          dispatch({ type: 'query' })
          dispatch({ type: 'queryPool' })
        }
      })
    },
  },
  // 处理同步动作，用来算出最新的 State 等同于 redux 里的 reducer，接收 action，同步更新 state
  // dispatching function 是一个用于触发 action 的函数，action 是改变 State 的唯一途径，但是它只描述了一个行为，而 dipatch 可以看作是触发这个行为的方式，而 Reducer 则是描述如何改变数据的。
  reducers: {
    reset(state) {
      return {
        ...state,
        search: initSearch,
      }
    },

    listRequest(state, {}) {
      return {
        ...state,
        listingBags: true,
      }
    },

    listSuccess(state: FaultsReducerState) {
      return {
        ...state,
        listingBags: false,
      }
    },

    listFail(state: FaultsReducerState) {
      return {
        ...state,
        listingBags: false,
      }
    },

    // modify = create / update / delete
    modifyRequest(state, {}) {
      return {
        ...state,
        modifying: true,
      }
    },

    modifySuccess(state: FaultsReducerState) {
      return {
        ...state,
        modifying: false,
      }
    },

    modifyFail(state: FaultsReducerState) {
      return {
        ...state,
        modifying: false,
      }
    },
    showEventModal(state, { payload }) {
      return {
        ...state,
        eventModal: {
          ...state.eventModal,
          modalVisible: true,
          ...payload,
        },
      }
    },

    hideEventModal(state) {
      return { ...state, eventModal: { ...state.eventModal, modalVisible: false } }
    },


  },
  // 处理异步逻辑动作的 effects，
  effects: {
    *queryPool({}, { call, put }) {
      const data = yield [call(api.userListUrl), call(api.modulesListUrl), call(api.vehiclesListUrl)]
        if (data) {
            yield put({
              type: 'updateState',
              payload: {
                pool:{
                  userArray:data[0].data.items,
                  tags:data[1].data.items,
                  vehicleArray:data[2].data.items,
                }
               }
            })
        }
    },
  
    // call：执行异步函数
    // put：发出一个 Action，类似于 dispatch
    *query({ payload = {} }, { call, put, select }) {
      // yield select 是 Redux-Saga 库中的一个特殊语法，用于从 Redux store 中选择数据。
      // 它允许 Saga 从 store 中获取特定的数据并在需要时使用。
      const { search, pagination } = yield select((state: any) => state.faults)
      if(JSON.stringify(payload) !== "{}" ) {
        payload = {  ...payload.search, ...payload.pagination }
        payload['faultTime'] = payload.faultsTime
      } else {
        payload = {  ...search, ...pagination }
        payload['faultTime'] = search.faultsTime
      }
      try {
        yield put({ type: 'listRequest' })
        const data = yield call(api.queryEventListUrl, payload)
        const dealData = data.data.items.map(items => {
          items['faultTotalDuration'] = 0
          items['rowSpan'] = 0
          items['firstRowIndex'] = 0
          return items
        })
        let lists = lodash.sortBy(dealData, ['vehicleName'])
      //如果vehicleName值相等，累加faultDuration，然后添加新的totalFaultDuration
      lists.forEach((item,itemIndex) => {
        lists.forEach((datas,dataIndex) => {
          if(item.vehicleName === datas.vehicleName) {
            if(item.rowSpan === 0) {
              item.firstRowIndex = dataIndex
            }
            item.rowSpan = item.rowSpan + 1
            
            item.faultTotalDuration = item.faultTotalDuration + datas.faultDuration
          }
        })
        // console.info(item.firstRowIndex,item.rowSpan)
      })
      // console.info('list========', lists)
        yield put({
          type: 'updateState',
          payload: {
            list: lists,
            pagination: {
              ...pagination,
              ...data.pagination,
            },
            search: {
              ...search,
              // sort: cloneSort,
            },
          },
        })

        yield put({ type: 'listSuccess' })
      } catch (e) {
        yield put({ type: 'listFail' })
      }
    },
     // 创建方法
    *create({ payload = {} }, { call, put }) {
      // 这里是做什么？
      yield put({ type: 'modifyRequest' }) 
      // console.info('创建方法', payload)
      payload =  { ...payload, ...{ operator: payload.audit.createdBy} } 
      try {
        // 修改创建事件接口和参数的地方
        const data = yield call(api.updateEventUrl, payload)

        yield put({ type: 'modifySuccess' })
        yield put({ type: 'reset' })
        yield put({ type: 'query' })
        yield put({ type: 'hideModal' })

          message.success(data.message)
      } catch (e) {
        yield put({ type: 'modifyFail' })

        message.error(i18next.t('msg.createFailed'))
      }
    },
    // 更新一条数据
    *update({ payload = {} }, { call, put }) {
      yield put({ type: 'modifyRequest' })
      payload =  { ...payload, ...{ operator: payload.audit.updatedBy} } 
      try {
        const data = yield call(api.updateEventUrl, payload)

        yield put({ type: 'modifySuccess' })
        yield put({ type: 'query' })
        yield put({ type: 'hideModal' })

        message.success(data.message)
      } catch (e) {
        yield put({ type: 'modifyFail' })

        message.error(i18next.t('msg.updateFailed'))
      }
    },
    // 删除一条数据 不需要
    *delete({ payload = {} }, { call, put }) {
      yield put({ type: 'modifyRequest' })

      try {
        yield call(api.deleteBag, payload)

        yield put({ type: 'modifySuccess' })
        yield put({ type: 'query' })
        yield put({ type: 'hideModal' })

        message.success(i18next.t('msg.deleteSuccessful'))
      } catch (e) {
        yield put({ type: 'modifyFail' })

        message.error(i18next.t('msg.deleteFailed'))
      }
    },
  },
})

export default faultsModel
