import React from 'react'
import { Input } from 'antd'
import { connect } from 'react-redux'
import { searchActions } from '../../_actions'
import BarSearchResult from './BarSearchResult'
const { Search } = Input

class BarSearch extends React.PureComponent<any> {
  handleSearch = (value: any) => {
    const { dispatch } = this.props
    const data = {
      multiMatch: {
        value: value,
        keys: ['Name', 'Description'],
      },
    }
    dispatch(searchActions.get(data, {}, 'incarbag'))
  }

  render() {
    const { bag } = this.props
    return (
      <div>
        <Search
          className="search-bar"
          placeholder="input search text"
          enterButton="Search"
          size="large"
          onSearch={this.handleSearch}
        />
        <BarSearchResult bag={bag} />
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  bag: state.search.bag,
  gettingData: state.search.gettingData,
})

export default connect(mapStateToProps)(BarSearch)
