import React from 'react'
import { Modal, Form, Input, Select, Button, Icon } from 'antd'
import { FormComponentProps } from 'antd/lib/form/Form'

import { ModalType, PLocation, Path } from '../../../_types'

const { Option } = Select

const modalBodyStyle = {
  maxHeight: '80vh',
  overflow: 'auto',
  padding: '1rem 1.5rem 0.5rem',
}

const formItemLayout = {
  labelAlign: 'left' as 'left',
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

interface PathModalProps extends FormComponentProps {
  item?: Path
  plocations: PLocation[]
  loading: boolean
  modalType: ModalType
  onModify: any
  onCancel: () => void
  t: any
}

class PathModal extends React.PureComponent<PathModalProps> {
  private id = 0

  componentDidUpdate(prevProps: PathModalProps) {
    const { item, form } = this.props

    // Reset stops in form
    if (prevProps.item !== item) {
      const keys = item && item.pointIds ? Object.keys(item.pointIds) : []

      form.setFieldsValue({
        keys,
      })

      this.id = keys.length
    }
  }

  handleUpdate = () => {
    const { item, onModify, modalType, form } = this.props
    const { validateFields, getFieldsValue } = form

    validateFields(errors => {
      if (errors) {
        return
      }

      const data: any = {
        ...getFieldsValue(),
        ...(item && { id: item.id }),
      }

      if (item) {
        delete data.startId
        delete data.destinationId
      }

      if (data.pointIds) {
        data.pointIds = data.keys.map((key: number) => data.pointIds[key])
      }
      delete data.keys

      onModify(data, modalType)
    })
  }

  remove = (k: number) => {
    const { form } = this.props

    const keys = form.getFieldValue('keys')

    form.setFieldsValue({
      keys: keys.filter((key: number) => key !== k),
    })
  }

  add = () => {
    const { form } = this.props

    const keys = form.getFieldValue('keys')
    const nextKeys = keys.concat(this.id++)

    form.setFieldsValue({
      keys: nextKeys,
    })
  }

  render() {
    const { item, plocations, loading, modalType, onModify, form, t, ...modalProps } = this.props
    const { getFieldDecorator, getFieldValue } = form

    const PLocationsRender = ({ field }: { field: 'startId' | 'destinationId' }) => (
      <Form.Item label={t(field.split('Id')[0])}>
        {getFieldDecorator(field, {
          initialValue: item && item[field],
          rules: [{ required: true, message: t('tip.required') }],
        })(
          <Select disabled={!!item}>
            {plocations && plocations.map(plocation => <Option key={plocation.id}>{plocation.name}</Option>)}
          </Select>,
        )}
      </Form.Item>
    )

    getFieldDecorator('keys', { initialValue: item && item.pointIds ? Object.keys(item.pointIds) : [] })
    const keys = getFieldValue('keys')

    const formItems = keys.map((k: number, index: number) => (
      <div key={k} className="paths-form-stops">
        <div className="paths-form-stops-header">
          <span>{`${t('point')} ${index + 1}`}</span>
          <Icon type="minus-circle-o" className="paths-form-stops-delete" onClick={() => this.remove(k)} />
        </div>
        <Form.Item label={t('point')} {...formItemLayout}>
          {getFieldDecorator(`pointIds[${k}]`, {
            initialValue: item && item.pointIds && item.pointIds[k],
            rules: [{ required: true, message: t('tip.required') }],
          })(
            <Select>
              {plocations && plocations.map(plocation => <Option key={plocation.id}>{plocation.name}</Option>)}
            </Select>,
          )}
        </Form.Item>
        {/* <Form.Item label={t('point')} {...formItemLayout}>
          {getFieldDecorator(`pointIds[${k}]`, {
            initialValue: item && item.pointIds && item.pointIds[k],
            rules: [{ required: true, message: t('tip.required') }],
          })(<Input />)}
        </Form.Item> */}
      </div>
    ))

    return (
      <Modal
        bodyStyle={modalBodyStyle}
        {...modalProps}
        footer={
          <>
            {item ? (
              <Button loading={loading} onClick={() => onModify({ id: item.id }, 'delete')}>
                {t('btn.delete')}
              </Button>
            ) : null}
            <Button type="primary" onClick={this.handleUpdate} loading={loading}>
              {t(`btn.${modalType}`)}
            </Button>
          </>
        }
      >
        <Form {...formItemLayout}>
          <Form.Item label={t('name')}>
            {getFieldDecorator('name', {
              initialValue: item && item.name,
              rules: [{ required: true, message: t('tip.required') }],
            })(<Input />)}
          </Form.Item>
          {item ? (
            <Form.Item label={t('state')}>
              {getFieldDecorator('state', {
                initialValue: item.state,
                rules: [{ required: true, message: t('tip.required') }],
              })(
                <Select>
                  <Option value="ACTIVE">{t('stateTag:path.active')}</Option>
                  <Option value="INACTIVE">{t('stateTag:path.inactive')}</Option>
                </Select>,
              )}
            </Form.Item>
          ) : null}
          <PLocationsRender field="startId" />
          <PLocationsRender field="destinationId" />
          <Form.Item label={t('points')}>
            {formItems}
            <Form.Item>
              <Button type="dashed" onClick={this.add} style={{ width: '100%' }}>
                <Icon type="plus" /> {t('btn.addStop')}
              </Button>
            </Form.Item>
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default Form.create<PathModalProps>()(PathModal)
