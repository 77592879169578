import React from 'react'
import './RoadTests.scss'
import { connect } from 'dva'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Dispatch } from 'redux'

import { DvaState, RoadTestsReducerState, RoadTest } from '../../_types'
import { RoadTestList, RoadTestModal } from './components'

interface RoadTestsProps extends WithTranslation, RoadTestsReducerState {
  dispatch: Dispatch
}

class RoadTests extends React.PureComponent<RoadTestsProps> {
  get roadTestListProps() {
    const { dispatch, roadTests, listingRoadTests, t } = this.props

    return {
      dataSource: roadTests,
      roadTests,
      loading: listingRoadTests,
      t,
      onCreate: () => {
        dispatch({
          type: 'roadTests/showModal',
          payload: {
            modalType: 'create',
          },
        })
      },
      onEdit: (item: RoadTest) => {
        dispatch({
          type: 'roadTests/showModal',
          payload: {
            modalType: 'update',
            currentItem: item,
          },
        })
      },
    }
  }

  get RoadTestModalProps() {
    const { roadTests, dispatch, currentItem, modalVisible, modalType, modifyingRoadTest, t } = this.props

    return {
      item: modalType === 'create' ? undefined : currentItem,
      roadTests,
      visible: modalVisible,
      modalType,
      title: t(`${modalType}RoadTest`),
      loading: modifyingRoadTest,
      destroyOnClose: true,
      maskClosable: true,
      centered: true,
      t,
      onModify: (data: any, action: string) => {
        dispatch({
          type: `roadTests/${action}`,
          payload: data,
        })
      },
      onCancel: () => {
        dispatch({
          type: 'roadTests/hideModal',
        })
      },
    }
  }

  render() {
    const { t } = this.props

    return (
      <div className="roadTests">
        <div className="roadTests-inner">
          <header className="roadTests-header">
            <h2>{t('roadTestList')}</h2>
          </header>
          <RoadTestList {...this.roadTestListProps} />
          <RoadTestModal {...this.RoadTestModalProps} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: DvaState) => ({
  ...state.roadTests,
})

export default connect(mapStateToProps)(withTranslation('roadTests')(RoadTests))
