import React from 'react'
import { Table, Tooltip, Button } from 'antd'
import { ColumnProps } from 'antd/lib/table'

import { Bag, Pagination, Sort } from '../../../_types'
import { bytesToSize, epochToDate } from '../../../_helpers'
import { HoverText } from '../../../common'
import { Resizable } from 'react-resizable';

interface BagListProps {
    t: any
    pagination: Pagination
    onChange: any
    sort: Sort
    onCreate: () => void
    onCreateEvent: (item: Bag) => void
    onEdit: (item: Bag) => void
}

const ResizeableTitle = (props: any) => {
    const { onResize, width, ...restProps } = props;

    if (!width) {
        return <th {...restProps} />;
    }

    return (
        <Resizable
            width={width}
            height={0}
            onResize={onResize}
            draggableOpts={{ enableUserSelectHack: false }}
        >
            <th {...restProps} />
        </Resizable>
    );
};
class BagTable extends React.PureComponent<BagListProps> {
    constructor(props: any) {
        super(props)
        const { t } = this.props
        this.state = {
            columns: [
                // {
                //   title: t('vehicleId'),
                //   dataIndex: 'vehicleId',
                //   width: 300,
                //  // render: (text: string) => text && <HoverText content={text} text={text.substr(0, 13) + '...'} />,
                // },
                {
                    title: t('vehicleName'),
                    dataIndex: 'VehicleName',
                    width: 130,
                },
                {
                    title: t('taskId'),
                    dataIndex: 'TaskId',
                    width: 150,
                    render: (text: string) => text && <HoverText content={text} text={text.substr(0, 16) + '...'} />,
                },
                // {
                //   title: t('date'),
                //   dataIndex: 'date',
                //   width: 100,
                // },
                {
                    title: t('date'),
                    dataIndex: 'Date',
                    width: 150,
                },
                {
                    title: t('startTime'),
                    dataIndex: 'startTime',
                    width: 100,
                    // render: (text: string) => text && <HoverText content={text} text={text.substr(0, 16) + '...'} />,
                },
                {
                    title: t('endTime'),
                    dataIndex: 'endTime',
                    width: 100,
                    // render: (text: string) => text && <HoverText content={text} text={text.substr(0, 16) + '...'} />,
                },
                {
                    title: t('duration'),
                    dataIndex: 'Duration',
                    width: 100,
                },
                {
                    title: t('taskModel'),
                    dataIndex: 'TaskModel',
                    width: 150,
                },
                {
                    title: t('productVersion'),
                    dataIndex: 'ProductVersion',
                    width: 150,

                },
                {
                    title: t('modType'),
                    dataIndex: 'ModType',
                    width: 100,
                },
                {
                    title: t('Avg'),
                    dataIndex: 'Avg',
                    width: 100,
                },
                {
                    title: t('P50'),
                    dataIndex: 'P50',
                    width: 100,
                },
                {
                    title: t('P80'),
                    dataIndex: 'P80',
                    width: 100,
                },
                {
                    title: t('P90'),
                    dataIndex: 'P90',
                    width: 100,
                },
                {
                    title: t('P95'),
                    dataIndex: 'P95',
                    width: 100,
                },
                {
                    title: t('P99'),
                    dataIndex: 'P99',
                    width: 100,
                },
                {
                    title: t('P999'),
                    dataIndex: 'P999',
                    width: 100,
                },
                {
                    title: t('P9999'),
                    dataIndex: 'P9999',
                    width: 100,
                },
                {
                    title: t('P99999'),
                    dataIndex: 'P99999',
                    width: 100,
                },
                {
                    title: t('max'),
                    dataIndex: 'Max',
                    width: 100,
                },
                {
                    title: t('avgTop5'),
                    dataIndex: 'AvgTop5',
                    width: 150,
                },
                {
                    title: t('monitorUrl'),
                    dataIndex: 'monitorUrl',
                    width: 200,
                    render: (text: string) => text &&<a href={text} target="_blank"><HoverText content={text} text={text.substr(0, 25) + '...'} /></a> ,
                },
            ],
        };
    }
    components = {
        header: {
            cell: ResizeableTitle,
        },
    }
    handleResize = (index: number) => (e: any, { size }: any) => {
        this.setState(({ columns }: any) => {
            const nextColumns = [...columns];
            nextColumns[index] = {
                ...nextColumns[index],
                width: size.width,
            };
            return { columns: nextColumns };
        });
    };

    render() {
        const { t, onCreate, sort, onEdit, onCreateEvent, pagination, ...tableProps } = this.props
        const { columns }: any = this.state
        const columnscResiz = columns.map((col: any, index: number) => ({
            ...col,
            onHeaderCell: (column: any) => ({
                width: column.width,
                onResize: this.handleResize(index),
            }),
        }));
        return (
            <div>
                <Table
                    {...tableProps}
                    pagination={{
                        size: 'small',
                        ...pagination,
                    }}
                    size="small"
                    bordered
                    components={this.components}
                    columns={columnscResiz}
                    rowKey='Id'
                    className="columnscResiz"
                    scroll={{ x: true }}
                />
            </div>
        )
    }
}

export default BagTable
