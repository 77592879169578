import modelExtend from 'dva-model-extend'
import i18next from 'i18next'
import { Model } from 'dva'
import { message } from 'antd'
// @ts-ignore
import * as echarts from 'echarts';

import { pathMatchRegexp } from '../_helpers'
import { TakeoverReducerState } from '../_types/takeover.types'
import api from '../_dva_services'
import { pageModel, modalModel } from '../_utils'
import { pad } from 'lodash';

const barChartOption: any = {
  title: {
    show: false,
    text: '按地区/时段统计',
    x: 15,
    y: 20,
    textStyle: {
      fontSize: 15
    }
  },
  color: ['rgba(58, 190, 250, 1)', 'rgba(255, 102, 0, 1)', 'rgba(46, 195, 119, 1)'],
  // ['rgba(58, 190, 250, 1)','rgba(58, 190, 250, 0.4)'],
  //    ['rgba(46, 195, 119, 1)','rgba(46, 195, 119, 0.4)'],
  //    ['rgba(255, 102, 0, 1)','rgba(255, 102, 0, 0.4)']
  legend: {
    data: ['总里程', '总时长', '总接管次数'],
    bottom: '0',
    left: 'center',
    icon: 'circle',
  },
  tooltip: {
    trigger: 'axis',
    formatter: '{b0}<br/>总里程：{c0}公里<br/>总时长：{c1}小时<br/>总接管次数：{c2}次'
  },
  grid: {
    top: 50,
    left: 50,
    right: 100
  },
  xAxis: {
    type: 'category',
    data: []
  },
  yAxis: [{
    name: '总里程(km)',
    type: 'value',
    nameTextStyle: {
      padding: [0, 0, 0, 15]
    },
    splitLine: {
      show: true
    }
  }, {
    name: '总时长(h)',
    type: 'value',
    splitLine: {
      show: false
    }
  }, {
    name: '总接管次数',
    type: 'value',
    splitLine: {
      show: false
    },
    offset: 60
  }],
}
const initState: any = {
  totalMileage: 0,
  aveTakeoverMileage: 0,
  aveWorkTime: 0,
  totalTime: 0,
  selectOption: [],
  vehicleOption: [],
  taskTypeOption:[],
  takeOverChartOption: {
    title: {
      show: true,
      text: '接管原因统计',
      left: 'center',
      top: 'center',
      textStyle: {
        fontSize: 15
      }
    },
    tooltip: {
      trigger: 'item',
      formatter: '原因：{b}<br/>数量：{c}'
    },
    legend: {
      bottom: '0',
      icon: 'circle',
      left: 'center'
    },
    series: [{
      radius: ['35%', '60%'],
      avoidLabelOverlap: true,
      name: '接管原因统计',
      //nameS
      itemStyle: {
        borderColor: '#f4f4f4',
        borderWidth: 2,
        color(params: any) {
          let colorList = [
            ['#108D89', '#83FFE9'],
            ['#8D3510', '#FFA783'],
            ['#651180', '#D099FF'],
            ['#4B8D10', '#83FFC0'],
            ['#8D104B', '#FF83D8'],
            ['#EF33B1', '#F6E6BC'],
            ['#7F610F', '#FFE899'],
            ['#F9957F', '#F2F5D0'],
            ['#6CC6CB', '#EAE5C9'],
            ['#4B8D10', '#83FFC0'],
            ['#C973FF', '#AEBAF8'],
            ['#0F5C7F', '#99DFFF']
          ]
          return new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: colorList[params.dataIndex][0]
          }, {
            offset: 1,
            color: colorList[params.dataIndex][1]
          }])
        }
      },
      label: {
        show: true,
        formatter(params: any) {
          let percentNum = params.data.total ? ((params.value / params.data.total * 100).toFixed(2)) : 0
          return params.name + ' :' + params.value + ' (' + percentNum + '%)'
        },
        position: 'outside'
      },
      labelLine: {
        show: true
      },
      type: 'pie',
      minAngle: 3,
      data: []
    }]
  },
  workChartOption: {
    title: {
      show: true,
      text: '故障原因统计',
      left: 'center',
      top: 'center',
      textStyle: {
        fontSize: 15
      }
    },
    tooltip: {
      trigger: 'item',
      formatter: '原因：{b}<br/>数量：{c}'
    },
    legend: {
      bottom: '5%',
      left: 'center',
      icon: 'circle'
    },
    series: [{
      radius: ['35%', '60%'],
      avoidLabelOverlap: false,
      name: '故障原因统计',
      //nameS
      itemStyle: {
        borderColor: '#fff',
        borderWidth: 2,
        color(params: any) {
          let colorList = [
            ['#108D89', '#83FFE9'],
            ['#8D3510', '#FFA783'],
            ['#651180', '#D099FF'],
            ['#4B8D10', '#83FFC0'],
            ['#8D104B', '#FF83D8'],
            ['#EF33B1', '#F6E6BC'],
            ['#7F610F', '#FFE899'],
            ['#F9957F', '#F2F5D0'],
            ['#6CC6CB', '#EAE5C9'],
            ['#4B8D10', '#83FFC0'],
            ['#C973FF', '#AEBAF8'],
            ['#0F5C7F', '#99DFFF']
          ]

          return new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: colorList[params.dataIndex][0]
          }, {
            offset: 1,
            color: colorList[params.dataIndex][1]
          }])
        }
      },
      label: {
        show: true,
        formatter(params: any) {
          return params.name + ' :' + params.value + ' (' + (Math.round(params.value / params.data.total * 100)) + '%)'
        },
        position: 'outside'
      },
      labelLine: {
        show: true
      },
      type: 'pie',
      data: []
    }]
  },
  regionChartOption: {
    ...barChartOption,
    series: []
  },
  vehicleChartOption: {
    ...barChartOption,
    series: [],
    // dataZoom: [
    //   {
    //     show: true,
    //     start: 94,
    //     end: 100
    //   }
    // ]
  },
  listingBags: false,
}

const takeoverModel = modelExtend<Model>(pageModel, modalModel, {
  namespace: 'takeover',
  state: initState,
  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(location => {
        // if (pathMatchRegexp('/Takeover', location.pathname)) {
        //   dispatch({ type: 'initPageData' })
        // }
      })
    },
  },
  reducers: {
    initPageData() {
      return initState
    },
    listTotalData(state: TakeoverReducerState, { payload }) {
      return {
        ...state,
        ...payload
      }
    },
    listRegion(state: TakeoverReducerState, { payload }) {
      return {
        ...state,
        ...payload
      }
    },
    listVehicle(state: TakeoverReducerState, { payload }) {
      return {
        ...state,
        ...payload
      }
    },
    listTaskType(state: TakeoverReducerState, { payload }) {
      return {
        ...state,
        ...payload
      }
    },
    listTakeOverChart(state: TakeoverReducerState, { payload }) {
      let series = state.takeOverChartOption.series
      series[0].data = payload.seriesData
      payload.takeOverChart.setOption(state.takeOverChartOption)
      return {
        ...state
      }
    },
    listWorkChart(state: TakeoverReducerState, { payload }) {
      let series = state.workChartOption.series
      series[0].data = payload.seriesData
      payload.workChart.setOption(state.workChartOption)
      return {
        ...state
      }
    },
    listRegionChart(state: TakeoverReducerState, { payload }) {
      state.regionChartOption.xAxis.data = payload.xAxisData.length == 2 ? [payload.xAxisData[0] + payload.xAxisData[1]] : payload.xAxisData
      state.regionChartOption.series = payload.seriesData
      console.log(payload.region)
      state.regionChartOption.region = payload.region
      payload.regionChart.setOption(state.regionChartOption)
      return {
        ...state,
      }
    },
    vehicleRegionChart(state: TakeoverReducerState, { payload }) {
      state.vehicleChartOption.xAxis.data = payload.xAxisData
      state.vehicleChartOption.series = payload.seriesData
      payload.vehicleChart.setOption(state.vehicleChartOption)
      return {
        ...state,
      }
    },
    listRequest(state, { }) {
      return {
        ...state,
        listingBags: true
      }
    },
    listSuccess(state, { }) {
      return {
        ...state,
        listingBags: false
      }
    },
    listFail(state, { }) {
      return {
        ...state,
        listingBags: false
      }
    }
  },
  effects: {
    *setTotalInfo({ }, { call, put }) {
      const totalData = yield call(api.mpiGetsum, {})
      let totalMileage = (totalData.sumMileage / 1000.0).toFixed(2)
      let totalTime = (totalData.sumDuration / (1000 * 3600.0)).toFixed(2)
      let aveTakeoverMileage = ((totalData.sumMileage / 1000.0) / totalData.sumTakeover).toFixed(2)
      const data = {
        totalMileage,
        totalTime,
        aveTakeoverMileage,
        aveWorkTime: 0
      }
      yield put({
        type: 'listTotalData',
        payload: {
          ...data
        }
      })
    },
    *setRegionList({ }, { call, put }) {
      let selectOption = [ {
        id: 0,
        label: '北京',
        value: 'BEIJING'
      }, {
        id: 1,
        label: '苏州',
        value: 'SUZHOU'
      }]
      yield put({
        type: 'listRegion',
        payload: {
          selectOption
        }
      })
    },
    *setVehicleList({ }, { call, put }) {
      const data = yield call(api.listVehicles, {})
      if (data) {
        yield put({
          type: 'listVehicle',
          payload: {
            vehicleOption: data.vehicles
          }
        })
      }
    },
    *setTaskTypeList({ }, { call, put }) {
      const data = yield call(api.listTaskType, {})
      if (data) {
        yield put({
          type: 'listTaskType',
          payload: {
            taskTypeOption: data.items
          }
        })
      }
    },
    *setTakeOverChart({ payload }, { call, put }) {
      const res: any = {
        xAxisData: [],
        seriesData: []
      }
      let total = 0
      try {
        const data = yield call(api.reasonList, {
          region: payload.regionId,
          vehicleId: payload.vehicleId,
          taskType:payload.taskType,
          start_time: payload.startTime,
          end_time: payload.endTime
        })
        if (data && data.items && data.items.length) {
          data.items.forEach((item: any) => {
            res.xAxisData.push(item.takeoverReason)
            res.seriesData.push(Number(item.count))
            total += Number(item.count)
          });
        }
      } catch (e) {
        console.log(e)
      }
      const xAxisData = res.xAxisData
      const seriesData = res.seriesData.map((item: any, idx: number) => {
        return {
          value: item,
          name: xAxisData[idx],
          total
        }
      })
      yield put({
        type: 'listTakeOverChart',
        payload: {
          seriesData,
          takeOverChart: payload.takeOverChart
        }
      })
    },
    *setWorkChart({ payload }, { call, put }) {
      const res = {
        xAxisData: ['原因1', '原因2', '原因3', '原因4', '原因5', '原因6'],
        seriesData: [10, 20, 30, 40, 30, 10]
      }
      const xAxisData = res.xAxisData
      let total = 140
      const seriesData = res.seriesData.map((item, idx) => {
        return {
          value: item,
          name: xAxisData[idx],
          total
        }
      })
      yield put({
        type: 'listWorkChart',
        payload: {
          seriesData,
          workChart: payload.workChart
        }
      })
    },
    *setRegionTable({ payload }, { call, put }) {
      try {
        yield put({ type: 'listRequest' })
        const data = yield call(api.regionVehicleList, {
          region: payload.regionId,
          vehicleId: payload.vehicleId,
          taskType:payload.taskType,
          start_time: payload.startTime,
          end_time: payload.endTime
        })
        data.items.forEach((item: any, index: number) => {
          item.sumMileage = (item.sumMileage / 1000.0).toFixed(2)
          item.sumDuration = (Number(item.sumDuration) / (3600.0 * 1000)).toFixed(2)
          item.key = index,
          item.mileagePerTakeover = item.sumTakeover !== '0'?(item.sumMileage/item.sumTakeover).toFixed(2):item.sumMileage
        });
        yield put({
          type: 'updateState',
          payload: {
            list: data.items
          }
        })
        yield put({ type: 'listSuccess' })
      } catch (e) {
        yield put({ type: 'listFail' })
      }
    },
    *setRegionChart({ payload }, { call, put }) {
      let apiList = payload.regionChart ? api.regionStatList : api.regionVehicleList
      const data = yield call(apiList, {
        region: payload.regionId,
        vehicleId: payload.vehicleId,
        taskType:payload.taskType,
        start_time: payload.startTime,
        end_time: payload.endTime
      })
      let res: any = {
        xAxisData: [],
        seriesData1: [],
        seriesData2: [],
        seriesData3: [],
        region: [],
      }
      if (data && data.items && data.items.length) {
        data.items.forEach((item: any) => {
          if (payload.regionChart) {
            res.xAxisData.push(item.region)
            res.xAxisData.push(item.vehicleName)
          } else {
            res.xAxisData.push(item.vehicleName)
          }
          res.seriesData1.push((item.sumMileage / 1000.0).toFixed(2))
          res.seriesData2.push((Number(item.sumDuration) / (3600.0 * 1000)).toFixed(2))
          res.seriesData3.push(item.sumTakeover)
          res.region.push(item.region)
        })
      }
      const colorList = [
        ['rgba(58, 190, 250, 1)', 'rgba(58, 190, 250, 0.4)'],
        ['rgba(255, 102, 0, 1)', 'rgba(255, 102, 0, 0.4)'],
        ['rgba(46, 195, 119, 1)', 'rgba(46, 195, 119, 0.4)'],
      ]
      const xAxisData = res.xAxisData
      const region = res.region
      const seriesData = [{
        data: res.seriesData1.map((item: any) => {
          return {
            value: item,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(
                1, 0, 0, 1,
                [
                  { offset: 0, color: colorList[0][0] },
                  { offset: 1, color: colorList[0][1] }
                ]
              )
            }
          }
        }),
        label: {
          show: true,
          position: 'top'
        },
        name: '总里程',
        type: 'bar',
        barWidth: 30,
        yAxisIndex: 0
      }, {
        data: res.seriesData2.map((item: any) => {
          return {
            value: item,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(
                1, 0, 0, 1,
                [
                  { offset: 0, color: colorList[1][0] },
                  { offset: 1, color: colorList[1][1] }
                ]
              )
            }
          }
        }),
        label: {
          show: true,
          position: 'top'
        },
        name: '总时长',
        type: 'bar',
        barWidth: 30,
        yAxisIndex: 1
      }, {
        data: res.seriesData3.map((item: any) => {
          return {
            value: item,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(
                1, 0, 0, 1,
                [
                  { offset: 0, color: colorList[2][0] },
                  { offset: 1, color: colorList[2][1] }
                ]
              )
            }
          }
        }),
        label: {
          show: true,
          position: 'top'
        },
        name: '总接管次数',
        type: 'bar',
        barWidth: 30,
        yAxisIndex: 2
      }]
      yield put({
        type: 'listRegionChart',
        payload: {
          seriesData,
          xAxisData,
          regionChart: payload.regionChart ? payload.regionChart : payload.vehicleChart,
          region,
        }
      })
    }
  },
})

export default takeoverModel
