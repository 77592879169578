import React, { CSSProperties } from 'react'
import './Tag.scss'

interface TagProps {
  style?: CSSProperties
}

const Tag: React.FC<TagProps> = ({ children, style }) => {
  return (
    <span className="tag" style={style}>
      {children}
    </span>
  )
}

export default Tag
