import modelExtend from 'dva-model-extend'

import { pathMatchRegexp } from '../_helpers'
import { TagsReducerState, TagModel } from '../_types'
import api from '../_dva_services'
import { modalModel, modalState } from '../_utils'

const initState: TagsReducerState = {
  list: [],
  loading: false,
  ...modalState,
}

const tagsModel: TagModel = modelExtend<TagModel>(modalModel, {
  namespace: 'tags',
  state: initState,
  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(location => {
        if (pathMatchRegexp('/tags', location.pathname)) {
          dispatch({
            type: 'query',
          })
        }
      })
    },
  },
  effects: {
    *query({ payload = {} }, { call, put }) {
      const data = yield call(api.listTag, payload)
      if (data) {
        yield put({
          type: 'updateState',
          payload: {
            list: data.tags,
          },
        })
      }
    },
    *create({ payload = {} }, { call, put }) {
      const data = yield call(api.createTag, payload)
      if (data) {
        yield put({ type: 'query' })
        yield put({ type: 'hideModal' })
      }
    },
    *update({ payload = {} }, { call, put }) {
      const data = yield call(api.updateTag, payload)
      if (data) {
        yield put({ type: 'query' })
        yield put({ type: 'hideModal' })
      }
    },
    *delete({ payload = {} }, { call, put }) {
      const data = yield call(api.deleteTag, payload)
      if (data) {
        yield put({ type: 'query' })
      }
    },
  },
})

export default tagsModel
