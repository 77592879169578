import React from 'react'
import { Card, Row, Col, Icon, Modal } from 'antd'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'

class BarSearchResult extends React.PureComponent<any> {
  state = {
    name: '',
    visible: false,
  }
  showModal = (name: any) => {
    this.setState({
      name: name,
      visible: true,
    })
  }
  handleCancel = () => {
    this.setState({
      visible: false,
    })
  }
  codeString = () => {
    const prefix = 'oss://allride-sharing/important/bags/'
    return `ossutil64 cp ${prefix}${this.state.name} .`
  }
  render() {
    const { bag } = this.props
    return (
      <div style={{ background: '#ECECEC', padding: '30px' }}>
        <Row gutter={16}>
          {bag.map((item: any) => (
            <Col span={8} key={item.name} className="search-result-card">
              <Card
                title={item.name}
                bordered={false}
                style={{ width: 300 }}
                actions={[<Icon type="download" key="download" onClick={() => this.showModal(item.name)} />]}
              >
                <p>{item.description}</p>
                <p>{item.timestamp}</p>
              </Card>
            </Col>
          ))}
        </Row>
        <Modal title="script" visible={this.state.visible} footer={null} onCancel={this.handleCancel}>
          <SyntaxHighlighter language="bash">{this.codeString()}</SyntaxHighlighter>
        </Modal>
      </div>
    )
  }
}

export default BarSearchResult
