import React from 'react'
import { Button, Input, Form, DatePicker, Select } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import moment from 'moment'

import { FaultSearch, FaultPool } from '../../../_types'
import { epochToMoment, momentToEpoch } from '../../../_helpers'

const { RangePicker } = DatePicker
const { Option } = Select

interface HeaderProps {
  item: FaultSearch
  pool: FaultPool
  t: any
  onSearch: () => void
  onChange: (field: any) => void
  onClear: () => void
}

interface HeaderState {
  activeKey: string
}

interface SearchFromProps extends HeaderProps, FormComponentProps {
  activeKey: string
  onCollapse: () => void
}

const SearchForm = Form.create<SearchFromProps>({
  mapPropsToFields(props) {
    return {
      vehicleName: Form.createFormField({ value: props.item.vehicleName }),
      vehicleState: Form.createFormField({ value: props.item.vehicleState }),
      modType: Form.createFormField({ value: props.item.modType }),
      createdBy: Form.createFormField({ value: props.item.createdBy }),
      faultsTime: Form.createFormField({
        value: props.item.faultsTime.map(i => epochToMoment(i)),
      }),
    }
  },
  onFieldsChange(props, fields) {
    if (fields.faultsTime) {
      fields.faultsTime = fields.faultsTime.value.map((i: any) => momentToEpoch(i))
    }
    props.onChange(fields)
  },
})(({ form, t, pool, onSearch, onClear }: SearchFromProps) => {
  const { getFieldDecorator } = form

  const onPressEnter = (e: React.KeyboardEvent) => {
    e.preventDefault()
    onSearch()
  }

  return (
    <Form layout="inline" onSubmit={onSearch}>
          <Form.Item label={t('vehicleName')}>
            {getFieldDecorator('vehicleName')(
              <Select style={{ width: "13rem" }} showSearch allowClear>
              {
                pool.vehicleArray.map(item => {
                  return <Option value={item.vehicleName} key={item.vehicleName}>{item.vehicleName}</Option>
                })
              }
            </Select>
            )}
          </Form.Item>
          <Form.Item label={t('vehicleState')}>
            {getFieldDecorator('vehicleState')(
              <Select style={{ width: "13rem" }} allowClear>
                  <Option value="正常">{t('normal')}</Option>
                  <Option value="故障">{t('faults')}</Option>
                </Select>
            )}
          </Form.Item>
          <Form.Item label={t('modeType')}>
            {getFieldDecorator('modType')(
              <Select style={{ width: "13rem" }} showSearch allowClear>
              {
                pool.tags.map(item => {
                  return <Option value={item.moduleName} key={item.moduleName}>{item.moduleName}</Option>
                })
              }
            </Select>
            )}
          </Form.Item>
          <Form.Item label={t('createdBy')}>
            {getFieldDecorator('createdBy')(
              <Select style={{ width: "13rem" }} showSearch allowClear>
              {
                pool.userArray.map(item => {
                  return <Option value={item.userName} key={item.userName}>{item.userName}</Option>
                })
              }
            </Select>
            )}
          </Form.Item>
          <Form.Item label={t('faultTime')}>
            {getFieldDecorator('faultsTime')(
              <RangePicker showTime={{ defaultValue: [moment('00:00:00', 'HH:mm:ss')], }} format="YYYY-MM-DD HH:mm:ss" />,
            )}
          </Form.Item>

          <Form.Item>
          <Button type="primary" onClick={onSearch}>
            {t('btn.search')}
          </Button>
          <Button style={{ marginLeft: '0.5rem' }} onClick={onClear}>
            {t('btn.reset')}
          </Button>
        </Form.Item>
    </Form>
  )
})

class Header extends React.PureComponent<HeaderProps, HeaderState> {
  constructor(props: any) {
    super(props)

    this.state = {
      activeKey: '',
    }
  }

  get collapseProps() {
    const { activeKey } = this.state

    return {
      activeKey,
      onCollapse: () => {
        this.setState({ activeKey: activeKey ? '' : '0' })
      },
    }
  }

  render() {
    return <SearchForm {...this.props} {...this.collapseProps} />
  }
}

export default Header
