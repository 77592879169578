import { Euler, Quaternion } from 'three'
import { transform, WGS84, GCJ02 } from 'gcoord'

import { Vehicle, Orientation } from '../_types'

export function filterActiveVehicles(vehicles: Vehicle[], districtId?: string) {
 // debugger
  return vehicles.filter(
    (vehicle: Vehicle) =>
      vehicle.status &&
      vehicle.status.longitude &&
      vehicle.status.latitude &&
      (!districtId || vehicle.status.districtId === districtId),
  )
}

export function orientationToAngle(orientation: Orientation): number {
  return (new Euler().setFromQuaternion(new Quaternion(...Object.values(orientation))).z * 180) / Math.PI
}

export function transformPosition(position: [number, number]) {
  return transform(position, WGS84, GCJ02) as [number, number]
}

export function toPrecision(value: number, precision: number) {
  return precision ? Math.floor(value * 10 ** precision) / 10 ** precision : Math.round(value)
}

export function msToKmh(speed: number) {
  return speed * 3.6
}
