import React from 'react'
import './Search.scss'
import { Form, Input, Button, DatePicker } from 'antd'

const frontFeildStyle = {
  style: {
    width: '85%',
    marginRight: '15%',
  },
}

const lastFeildStyle = {
  style: {
    width: '85%',
    marginLeft: '15%',
  },
}

class SearchFeild extends React.PureComponent<any> {
  uuid: number
  constructor(props: any) {
    super(props)
    this.uuid = 0
  }

  render() {
    const { name } = this.props
    const { getFieldDecorator, setFieldsValue, getFieldValue } = this.props.form
    getFieldDecorator(`${name}-keys`, {
      initialValue: [0],
    })
    const keys = getFieldValue(`${name}-keys`)
    const add = () => {
      const keys = getFieldValue(`${name}-keys`)
      this.uuid = this.uuid + 1
      const nextKeys = keys.concat([this.uuid])
      const obj: any = {}
      obj[`${name}-keys`] = nextKeys
      setFieldsValue(obj)
    }
    const remove = (index: any) => {
      const keys = getFieldValue(`${name}-keys`)
      if (keys.length === 0) {
        return
      }
      keys.splice(index, 1)
      const obj: any = {}
      obj[`${name}-keys`] = keys
      setFieldsValue(obj)
    }

    return (
      <div>
        <div className="match-search">
          {keys &&
            keys.map((k: any, index: any) => (
              <div key={index}>
                <Form.Item>
                  {getFieldDecorator(`${name}.matchSearch[${k}].key`)(<Input placeholder="key" {...frontFeildStyle} />)}
                </Form.Item>
                <div className="match-colon">:</div>
                <Form.Item>
                  {getFieldDecorator(`${name}.matchSearch[${k}].value`)(
                    <Input placeholder="value" {...lastFeildStyle} />,
                  )}
                </Form.Item>
                <div className="match-colon" style={{ paddingLeft: 20 }}>
                  {index <= 0 && <Button shape="circle" size="small" icon="plus" type="primary" onClick={add} />}
                  {index > 0 && (
                    <Button shape="circle" size="small" icon="minus" type="default" onClick={() => remove(index)} />
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    )
  }
}

export default SearchFeild
