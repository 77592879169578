import React, { MouseEvent } from 'react'
import './Monitor.scss'
import { connect } from 'dva'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Dispatch } from 'redux'
import { Spin, Icon, Select, Tabs, Popover, Switch } from 'antd'

import { Vehicle, VehicleStatus, DrivingMode, District, PLocation, Path, DvaState } from '../../_types'
import {
  filterActiveVehicles,
  orientationToAngle,
  filterPathsByPLocations,
  getPosition,
  transformPosition,
} from '../../_utils'
import { MAP_STYLES, MAP_ZOOM, PATH_LEN, FENCE } from './constants'
import { getMapStatus, getRunningState } from './util'
import { MapButton, VehicleList, PLocationList, PathList } from './components'
import vars from '../../variables.scss'

import carAuto from '../../_assets/car_auto.png'
import carManul from '../../_assets/car_manul.png'
import carSuspended from '../../_assets/car_suspended.png'

const { Option } = Select
const { TabPane } = Tabs

// Extract AMap from window
const AMap = (window as any).AMap
const AMapUI = (window as any).AMapUI

interface MonitorProps extends WithTranslation {
  vehicles: Vehicle[]
  districts: District[]
  plocations: PLocation[]
  paths: Path[]
  dispatch: Dispatch
}

interface MonitorState {
  currentDistrictId: string
  selectedVehicleId: string
  trackingVehicleId: string
  selectedPLocationId: string
  selectedPathId: string
  loadingMap: boolean
  isTrafficLayerVisible: boolean
  isFenceLayerVisible: boolean
}

interface VehiclePathData {
  id: string
  name: string
  path: [number, number][]
  initRotateDegree: number
  isRunning: boolean
  mode: DrivingMode
  content: any
  cursor: {
    idx: number
    tail: number
  }
}

class Monitor extends React.Component<MonitorProps, MonitorState> {
  // Instances
  private map: any
  private trafficLayer: any
  private pathSimplifier: any
  private fenceLayer: any
  // Constructors
  private PathSimplifier: any
  // No-render data
  private plocationMarkerMap = new Map<string, any>()
  private plocationMap = new Map<string, PLocation>()
  private pathLineMap = new Map<string, any>()
  private vehiclePathMap: VehiclePathData[] = []
  private navigators: any[] = []
  private centerMarker: any
  // Refs
  private mapRef = React.createRef<HTMLDivElement>()
  private districtsSelectRef = React.createRef<Select<string>>()
  // Others
  private expandInterval: number = -1

  state: MonitorState = {
    currentDistrictId: '',
    selectedVehicleId: '',
    trackingVehicleId: '',
    selectedPLocationId: '',
    selectedPathId: '',
    loadingMap: true,
    isTrafficLayerVisible: false,
    isFenceLayerVisible: false,
  }

  componentDidMount() {
    const { dispatch } = this.props

    // Init AMap instantly
    this.initAMap()
    this.initAMapUI(() => {
      ;(dispatch({ type: 'districts/list' }) as any).then((districts: District[]) => {
        // If no district in localStorage, set the first in districts
        this.setState({ currentDistrictId: localStorage.getItem('district') || (districts[0] && districts[0].id) })
      })

      dispatch({ type: 'paths/list' })
    })
  }

  componentDidUpdate(prevProps: MonitorProps, prevState: MonitorState) {
    const { vehicles, plocations, paths } = this.props
    const { currentDistrictId } = this.state

    if (prevProps.vehicles !== vehicles) {
      this.expandPath()
    }

    if (prevProps.plocations !== plocations) {
      this.addPLocationsMarkers(plocations)
      this.addPathLines(paths)
    }

    if (prevState.currentDistrictId !== currentDistrictId) {
      this.handleLocateDistrict(currentDistrictId)
      this.loadPLocations(currentDistrictId)
      this.loadVehicles(currentDistrictId)
    }
  }

  componentWillUnmount() {
    this.map && this.map.destroy()
    this.expandInterval && window.clearInterval(this.expandInterval)
  }

  initAMap = () => {
    // Incase AMap loading fails
    if (!AMap) return

    // New AMap instance
    this.map = new AMap.Map(this.mapRef.current, {
      mapStyle: 'amap://styles/whitesmoke',
    })

    this.map.on('complete', () => {
      this.setState({ loadingMap: false })
    })

    this.trafficLayer = new AMap.TileLayer.Traffic({
      zIndex: 1,
      opacity: 0.3,
      autoRefresh: true,
    })
    this.trafficLayer.setMap(this.map)
    this.trafficLayer.hide()

    // fence
    this.fenceLayer = {
      fences: FENCE.map(item => {
        let sw = new AMap.LngLat(...transformPosition(item.p1))
        let ne = new AMap.LngLat(...transformPosition(item.p2))

        let bounds = new AMap.Bounds(sw, ne)
        let rect = new AMap.Rectangle({
          bounds: bounds,
          strokeColor: 'black',
          strokeWeight: 0,
          strokeOpacity: 0,
          strokeDasharray: [30, 10],
          // strokeStyle还支持 solid
          strokeStyle: 'dashed',
          fillColor: 'blue',
          fillOpacity: 0.08,
          cursor: 'pointer',
          zIndex: 50,
        })

        rect.setMap(this.map)
        this.setState
        return rect
      }),
    }

    this.setState({ isFenceLayerVisible: true })

    // Add tool bar
    AMap.plugin(['AMap.ToolBar'], () => {
      this.map.addControl(
        new AMap.ToolBar({
          liteStyle: true,
        }),
      )
    })
  }

  initAMapUI = (callback: () => void) => {
    // Incase AMapUI loading fails
    if (!AMapUI) return

    AMapUI.loadUI(['overlay/SimpleMarker', 'misc/PathSimplifier'], (SimpleMarker: any, PathSimplifier: any) => {
      this.PathSimplifier = PathSimplifier

      // Init pathSimplifier instance
      this.pathSimplifier = new PathSimplifier({
        zIndex: 110,
        map: this.map,
        autoSetFitView: false,
        getPath: (pathData: VehiclePathData) => {
          return pathData.path
        },
        getHoverTitle: (pathData: VehiclePathData) => {
          return pathData.name
        },
        renderOptions: {
          eventSupportInvisible: false,
          pathLineStyle: MAP_STYLES.TRANS_LINE,
          pathLineHoverStyle: MAP_STYLES.TRANS_DARK_LINE,
          pathLineSelectedStyle: MAP_STYLES.TRANS_DARK_LINE,
          keyPointStyle: MAP_STYLES.EMPTY_POINT,
          keyPointHoverStyle: MAP_STYLES.EMPTY_POINT,
          startPointStyle: MAP_STYLES.EMPTY_POINT,
          endPointStyle: MAP_STYLES.EMPTY_POINT,
          keyPointOnSelectedPathLineStyle: MAP_STYLES.EMPTY_POINT,
          hoverTitleStyle: MAP_STYLES.HOVER_TITLE,
        },
      })

      // Add path listeners
      this.pathSimplifier.on('pointClick', (e: any, info: any) => {
        // When in tracking mode, disable click vehicle event
        this.state.trackingVehicleId || this.map.panTo(info.pathData.path[info.pointIndex])

        this.setState({
          selectedVehicleId: info.pathData.id,
        })
      })

      callback()
    })
  }

  handleToggleTrafficLayer = () => {
    if (!this.trafficLayer) return

    const { isTrafficLayerVisible } = this.state

    this.setState({ isTrafficLayerVisible: !isTrafficLayerVisible }, () => {
      isTrafficLayerVisible ? this.trafficLayer.hide() : this.trafficLayer.show()
    })
  }

  handleToggleFenceLayer = () => {
    if (!this.fenceLayer) return
    const { isFenceLayerVisible } = this.state

    this.setState({ isFenceLayerVisible: !isFenceLayerVisible }, () => {
      if (isFenceLayerVisible) {
        this.fenceLayer.fences.forEach((item: any) => item.hide())
      } else {
        this.fenceLayer.fences.forEach((item: any) => item.show())
      }
    })
  }

  /** Vehicles */
  loadVehicles = (districtId: string) => {
    const { dispatch } = this.props

    // Clear expand interval and vehicle path data
    this.expandInterval > 0 && window.clearInterval(this.expandInterval)
    this.pathSimplifier.setData()
    this.vehiclePathMap = []

    this.expandInterval = window.setInterval(
      () =>
        dispatch({
          // type: 'vehicles/query',
          type: 'vehicles/list',
          payload: { districtId },
        }),
      1000,
    )
  }

  // Init path data from vehicle data
  createVehiclePathData = (vehicle: Vehicle): VehiclePathData => {
    const status = vehicle.status as VehicleStatus

    return {
      id: vehicle.id,
      name: vehicle.name,
      path: [getPosition(status)],
      initRotateDegree: status.pose ? 90 - orientationToAngle(status.pose.orientation) : 0,
      isRunning: getRunningState(status.timestamp),
      mode: status.drivingMode,
      content: this.getCarImgContent(status),
      cursor: { idx: 0, tail: 0 },
    }
  }

  createPathNavigator = (index: number, content: any, initRotateDegree = 0, speed = 50) => {
    return this.pathSimplifier.createPathNavigator(index, {
      speed,
      pathNavigatorStyle: {
        width: 24,
        height: 48,
        content,
        initRotateDegree,
        strokeStyle: null,
        fillStyle: null,
        pathLinePassedStyle: MAP_STYLES.EMPTY_LINE,
      },
    })
  }

  expandPath = () => {
    const { currentDistrictId } = this.state
    const { vehicles } = this.props

    // Update vehiclePathMap
    filterActiveVehicles(vehicles, currentDistrictId).forEach((vehicle: Vehicle) => {
      // Check if vehicles have already been on the map
      const dataIndex = this.vehiclePathMap.findIndex(pathData => pathData.id === vehicle.id)

      if (dataIndex > -1) {
        // Extract data to be updated
        const nextStatus = vehicle.status as VehicleStatus
        const nextMode = nextStatus.drivingMode
        const nextIsRunning = getRunningState(nextStatus.timestamp)
        const nextPosition = getPosition(nextStatus)

        // CarImg need to be updated in these two circumstances
        if (
          nextMode !== this.vehiclePathMap[dataIndex].mode ||
          nextIsRunning !== this.vehiclePathMap[dataIndex].isRunning
        ) {
          this.vehiclePathMap[dataIndex].mode = nextMode
          this.vehiclePathMap[dataIndex].isRunning = nextIsRunning
          this.vehiclePathMap[dataIndex].content = this.getCarImgContent(nextStatus)
        }

        const pathData = this.vehiclePathMap[dataIndex]
        const currentPosition = pathData.path[pathData.path.length - 1]

        // If different position, update path
        if (nextPosition[0] !== currentPosition[0] || nextPosition[1] !== currentPosition[1]) {
          pathData.path.push(nextPosition)

          if (pathData.path.length > PATH_LEN) {
            pathData.path.shift()
          }
        }

        // Clone prev cursor into new path data
        const cursor = this.navigators[dataIndex].getCursor().clone()
        pathData.cursor = { idx: cursor.idx, tail: cursor.tail }

        // Relocate if in tracking mode
        this.state.trackingVehicleId === vehicle.id && this.map.panTo(nextPosition)
      } else {
        this.vehiclePathMap.push(this.createVehiclePathData(vehicle))
      }
    })

    // To be improved
    this.pathSimplifier.setData(this.vehiclePathMap)
    this.navigators = this.vehiclePathMap.map((pathData: VehiclePathData, index: number) => {
      const nav =
        pathData.path.length > 1
          ? this.createPathNavigator(index, pathData.content)
          : this.createPathNavigator(index, pathData.content, pathData.initRotateDegree)
      nav.start()
      nav.moveToPoint(pathData.path.length === PATH_LEN ? PATH_LEN - 2 : pathData.cursor.idx, pathData.cursor.tail)

      return nav
    })
  }

  /** Pickup Loactions */
  loadPLocations = (districtId: string) => {
    const { dispatch } = this.props

    dispatch({
      type: 'plocations/listByDistrictId',
      payload: { districtId },
    })
  }

  addPLocationsMarkers = (plocations: PLocation[]) => {
    // Clear previous plocations
    this.map.remove(Array.from(this.plocationMarkerMap.values()))
    this.plocationMarkerMap.clear()
    this.plocationMap.clear()

    plocations.forEach(plocation => {
      // Marker part
      const marker = new AMap.CircleMarker({
        map: this.map,
        center: getPosition(plocation),
        ...MAP_STYLES.PLOCATION_CIRCLE,
        fillColor: vars[`plocation${plocation.state}`],
      })

      marker.on('click', () => this.handleClickMarker(plocation.id))

      // Infor window part
      const template = `
            <div class="monitor-map-info">${plocation.name}</div>`

      const infoWindow = new AMap.InfoWindow({
        content: template,
        offset: new AMap.Pixel(0, -12),
      })
      infoWindow.on('close', () => this.setState({ selectedPLocationId: '' }))

      marker.setExtData({
        infoWindow,
      })

      this.plocationMarkerMap.set(plocation.id, marker)
      this.plocationMap.set(plocation.id, plocation)
    })

    // this.map.setFitView() // Cancel fit view
  }

  handleClickMarker = (id: string) => {
    if (this.state.trackingVehicleId || !this.map) return

    const marker = this.plocationMarkerMap.get(id)

    if (marker) {
      this.setState({ selectedPLocationId: id }, () => {
        const position = marker.getCenter()

        // Open info window & map pan to marker center
        marker.getExtData().infoWindow.open(this.map, position)
        this.map.panTo(position)
      })
    }
  }

  handleCloseInfoWindow = (id?: string) => {
    if (!this.map) return

    if (id) {
      // Close one
      const marker = this.plocationMarkerMap.get(id)
      marker && marker.infoWindow.close()
    } else {
      // Close all
      this.map.clearInfoWindow()
    }
  }

  handleChangeDistrict = (value: string) => {
    // Lose focus when change happens
    this.districtsSelectRef.current && this.districtsSelectRef.current.blur()

    this.setState(
      {
        currentDistrictId: value,
        selectedVehicleId: '',
        selectedPLocationId: '',
        trackingVehicleId: '',
      },
      () => {
        this.map.setStatus(getMapStatus(true))

        localStorage.setItem('district', value)
      },
    )
  }

  handleSelectVehicle = (e: MouseEvent, id: string) => {
    e.stopPropagation()

    this.setState({ selectedVehicleId: id }, () => {
      this.handleLocateVehicle(id)
    })
  }

  clearSelectedVehicle = () => {
    this.setState({ selectedVehicleId: '' })
  }

  handleSelectPLocation = (e: MouseEvent, id: string) => {
    e.stopPropagation()

    this.handleClickMarker(id)
  }

  handleSelectPoint = (e: MouseEvent, plocationId: string, pathId: string) => {
    e.stopPropagation()

    const marker = this.plocationMarkerMap.get(plocationId)
    const position = marker.getCenter()
    marker.getExtData().infoWindow.open(this.map, position)

    this.handleSelectPath(e, pathId, true)
  }

  clearSelectedPLocation = () => {
    const { selectedPLocationId } = this.state

    if (selectedPLocationId) {
      this.handleCloseInfoWindow()

      this.setState({ selectedPLocationId: '' })
    }
  }

  handleLocateVehicle = (id: string) => {
    const { vehicles } = this.props
    const index = vehicles.findIndex(vehicle => vehicle.id === id)

    if (this.map && index > -1) {
      const status = vehicles[index].status as VehicleStatus
      this.map.panTo(getPosition(status))
    }
  }

  handleTrackVehicle = (id: string) => {
    const { trackingVehicleId } = this.state

    if (trackingVehicleId) {
      this.setState({ trackingVehicleId: '' })
      this.map.setStatus(getMapStatus(true))
    } else {
      this.setState({
        trackingVehicleId: id,
        selectedVehicleId: '',
        selectedPLocationId: '',
      })
      this.map.setStatus(getMapStatus(false))

      // Relocate vehicle immediately
      this.handleLocateVehicle(id)
    }

    // Close all info window
    this.handleCloseInfoWindow()
  }

  // District
  handleLocateDistrict = (districtId: string) => {
    const { districts } = this.props
    const district = districts.find(district => district.id === districtId)

    if (!district) return

    const position = getPosition(district)

    // Reuse or add centerMarker
    if (this.centerMarker) {
      this.centerMarker.setCenter(position)
    } else {
      this.centerMarker = new AMap.CircleMarker({
        map: this.map,
        center: position,
        ...MAP_STYLES.DISTRICT_CIRCLE,
      })
    }

    this.map.setZoomAndCenter(MAP_ZOOM.INITIAL, position)
  }

  // Paths
  addPathLines = (paths: Path[]) => {
    this.map.remove(this.pathLineMap)

    filterPathsByPLocations(paths, this.plocationMap).forEach(path => {
      const { pathLine, pathMarkers } = this.createPathData(path)

      const line = new AMap.Polyline({
        map: this.map,
        path: pathLine,
        ...MAP_STYLES.PATH_LINE,
        zIndex: 0,
        extData: { markers: pathMarkers },
      })
      line.hide()

      this.pathLineMap.set(path.id, line)
    })
  }

  createPathData = (path: Path) => {
    const pathLine = [] as [number, number][]
    const pathMarkers = [] as any[]

    this.addPointToPathLine(pathLine, pathMarkers, path.startId)
    path.pointIds && path.pointIds.forEach(pointId => this.addPointToPathLine(pathLine, pathMarkers, pointId))
    this.addPointToPathLine(pathLine, pathMarkers, path.destinationId)

    return { pathLine, pathMarkers }
  }

  addPointToPathLine = (pathLine: [number, number][], pathMarkers: PLocation[], plocationId: string) => {
    const marker = this.plocationMarkerMap.get(plocationId)

    if (marker) {
      pathMarkers.push(marker)
      pathLine.push(marker.getCenter())
    }
  }

  handleSelectPath = (e: MouseEvent, pathId: string, fromPoint?: boolean) => {
    e.stopPropagation()

    if (!this.map) return

    const { selectedPathId } = this.state
    selectedPathId && this.pathLineMap.get(selectedPathId).hide()

    if (!fromPoint && selectedPathId === pathId) {
      this.setState({ selectedPathId: '' }, () => {
        this.handleCloseInfoWindow()
      })
    } else {
      this.setState({ selectedPathId: pathId }, () => {
        const pathLine = this.pathLineMap.get(pathId)
        pathLine.show()

        this.map.setFitView(pathLine.getExtData().markers)
      })
    }
  }

  getCarImgContent = (status: VehicleStatus) => {
    // Icon loading callbacks
    const onload = () => {
      this.pathSimplifier.renderLater()
    }

    const onerror = () => {
      console.log('Vehicle icon load error')
    }

    const carImg = getRunningState(status.timestamp)
      ? status.drivingMode === 'MODE_AUTO'
        ? carAuto
        : carManul
      : carSuspended

    return this.PathSimplifier.Render.Canvas.getImageContent(carImg, onload, onerror)
  }

  render() {
    const {
      currentDistrictId,
      selectedVehicleId,
      trackingVehicleId,
      selectedPLocationId,
      selectedPathId,
      loadingMap,
      isTrafficLayerVisible,
      isFenceLayerVisible,
    } = this.state
    const { t, vehicles, plocations, districts, paths } = this.props

    const settings = (
      <div className="monitor-map-settings">
        <label>{t('trafficLayer')}</label>
        <div>
          <Switch size="small" checked={isTrafficLayerVisible} onChange={this.handleToggleTrafficLayer} />
        </div>
        <label>{t('fenceLayer')}</label>
        <div>
          <Switch size="small" checked={isFenceLayerVisible} onChange={this.handleToggleFenceLayer} />
        </div>
      </div>
    )

    return (
      <div className="monitor">
        <div className="monitor-display">
          <div className="monitor-map">
            <div className="monitor-map-container" ref={this.mapRef} />
            <div className="monitor-map-loading">
              <Spin spinning={loadingMap} />
            </div>
            <div className={`monitor-map-status${trackingVehicleId ? ' visible' : ''}`}>
              <Icon type="lock" />
              <span className="monitor-map-status-content">{t('moveDisabled')}</span>
            </div>
            <div className="monitor-map-districts">
              <Select
                value={currentDistrictId}
                style={{ width: '100%' }}
                onChange={this.handleChangeDistrict}
                ref={this.districtsSelectRef}
              >
                {districts && districts.map(district => <Option key={district.id}>{district.name}</Option>)}
              </Select>
            </div>
            <div className="monitor-map-locating">
              <MapButton title={t('tip.initView')} onClick={() => this.handleLocateDistrict(currentDistrictId)}>
                <i className="icon-circle"></i>
              </MapButton>
              <MapButton title={t('tip.fitPLocations')} onClick={() => this.map && this.map.setFitView()}>
                <Icon type="flag" />
              </MapButton>
              <MapButton
                title={t('tip.fitVehicles')}
                onClick={() => this.pathSimplifier && this.pathSimplifier.setFitView(-1)}
              >
                <Icon type="car" />
              </MapButton>
              <Popover content={settings} trigger="click" placement="left">
                <MapButton>
                  <Icon type="setting" />
                </MapButton>
              </Popover>
            </div>
          </div>
          <aside className="monitor-control">
            <Tabs size="small">
              <TabPane tab={t('vehicles')} key="0" forceRender>
                <div className="ant-tabpane-wrap">
                  <VehicleList
                    vehicles={filterActiveVehicles(vehicles, currentDistrictId)}
                    selectedVehicleId={selectedVehicleId}
                    trackingVehicleId={trackingVehicleId}
                    onSelectVehicle={this.handleSelectVehicle}
                    onTrackVehicle={this.handleTrackVehicle}
                    onClearSelectedVehicle={this.clearSelectedVehicle}
                  />
                </div>
              </TabPane>
              <TabPane tab={t('plocations')} key="1" forceRender>
                <div className="ant-tabpane-wrap">
                  <PLocationList
                    plocations={plocations}
                    selectedPLocationId={selectedPLocationId}
                    onSelectPLocation={this.handleSelectPLocation}
                    onClearSelectedPLocation={this.clearSelectedPLocation}
                  />
                </div>
              </TabPane>
              <TabPane tab={t('paths')} key="2" forceRender>
                <div className="ant-tabpane-wrap">
                  <PathList
                    paths={filterPathsByPLocations(paths, this.plocationMap)}
                    plocationMap={this.plocationMap}
                    selectedPathId={selectedPathId}
                    onSelectPath={this.handleSelectPath}
                    onSelectPoint={this.handleSelectPoint}
                  />
                </div>
              </TabPane>
            </Tabs>
          </aside>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: DvaState) => ({
  vehicles: state.vehicles.vehicles,
  plocations: state.plocations.plocations,
  districts: state.districts.districts,
  paths: state.paths.paths,
})

export default connect(mapStateToProps)(withTranslation('monitor')(Monitor))
