import React from 'react'
import './PLocations.scss'
import { connect } from 'dva'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Dispatch } from 'redux'

import { PLocationsReducerState, DvaState, PLocation, District } from '../../_types'
import { List, PLocModal } from './components'

interface PLocationsProps extends WithTranslation, PLocationsReducerState {
  districts: District[]
  dispatch: Dispatch
}

class PLocations extends React.PureComponent<PLocationsProps> {
  get listProps() {
    const { dispatch, plocations, districts, listingPLocations, t } = this.props

    return {
      dataSource: plocations,
      districts,
      loading: listingPLocations,
      t,
      onCreate: () => {
        dispatch({
          type: 'plocations/showModal',
          payload: {
            modalType: 'create',
          },
        })
      },
      onEdit: (item: PLocation) => {
        dispatch({
          type: 'plocations/showModal',
          payload: {
            modalType: 'update',
            currentItem: item,
          },
        })
      },
    }
  }

  get PLocModalProps() {
    const { districts, dispatch, currentItem, modalVisible, modalType, modifyingPLocation, t } = this.props

    return {
      item: modalType === 'create' ? undefined : currentItem,
      districts,
      visible: modalVisible,
      modalType,
      title: t(`${modalType}PLocation`),
      loading: modifyingPLocation,
      destroyOnClose: true,
      maskClosable: true,
      centered: true,
      t,
      onModify: (data: any, action: string) => {
        dispatch({
          type: `plocations/${action}`,
          payload: data,
        })
      },
      onCancel: () => {
        dispatch({
          type: 'plocations/hideModal',
        })
      },
    }
  }

  render() {
    const { t } = this.props

    return (
      <div className="plocations">
        <div className="plocations-locations">
          <div className="plocations-inner">
            <div className="plocations-header">
              <h2>{t('plocationList')}</h2>
            </div>
            <List {...this.listProps} />
            <PLocModal {...this.PLocModalProps} />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: DvaState) => ({
  ...state.plocations,
  districts: state.districts.districts,
})

export default connect(mapStateToProps)(withTranslation('plocations')(PLocations))
