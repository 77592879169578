import React from 'react'
import { Table, Tooltip, Button, Tag } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { Link } from 'react-router-dom'

import { VEvent, Pagination, Sort } from '../../../_types'
import { stringHashCode, epochToDate } from '../../../_helpers'
import { tagColorPool } from '../../../_utils'

interface VEventListProps {
  t: any
  pagination: Pagination
  onChange: any
  sort: Sort
  onCreate: () => void
  onEdit: (item: VEvent) => void
}

class VEventTable extends React.PureComponent<VEventListProps> {
  render() {
    const { t, onCreate, sort, onEdit, pagination, ...tableProps } = this.props

    const columns: ColumnProps<VEvent>[] = [
      {
        title: 'ID',
        dataIndex: 'uuid',
        key: 'id',
        render: (id: string) => <Link to={`/vevents/${id}`}>{id}</Link>,
      },
      {
        title: t('productVersion'),
        dataIndex: 'productVersion',
        key: 'productVersion',
      },
      {
        title: t('vehicleName'),
        dataIndex: 'vehicleName',
        key: 'vehicleName',
      },
      {
        title: t('description'),
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: t('tags'),
        key: 'tags',
        dataIndex: 'tags',
        render: (tags: string[]) => (
          <span>
            {tags &&
              tags.map(tag => {
                const color = tagColorPool[stringHashCode(tag, tagColorPool.length)]
                return (
                  <Tag color={color} key={tag}>
                    {tag}
                  </Tag>
                )
              })}
          </span>
        ),
      },
      {
        title: t('startTime'),
        dataIndex: 'startTime',
        key: 'startTime',
        sorter: true,
        sortOrder: sort.field === 'startTime' && sort.order,
        render: (time: any) => epochToDate(time),
      },
      {
        title: t('endTime'),
        dataIndex: 'endTime',
        key: 'endTime',
        sorter: true,
        sortOrder: sort.field === 'endTime' && sort.order,
        render: (time: any) => epochToDate(time),
      },
      {
        title: t('createdAt'),
        dataIndex: 'time.createdAt',
        key: 'time.createdAt',
        sorter: true,
        sortOrder: sort.field === 'time.createdAt' && sort.order,
        render: (time: any) => epochToDate(time),
      },
      {
        title: t('updatedAt'),
        dataIndex: 'time.updatedAt',
        key: 'time.updatedAt',
        sorter: true,
        sortOrder: sort.field === 'time.updatedAt' && sort.order,
        render: (time: any) => epochToDate(time),
      },
      {
        title: t('createdBy'),
        dataIndex: 'audit.createdBy',
        key: 'audit.createdBy',
        sorter: true,
        sortOrder: sort.field === 'audit.createdBy' && sort.order,
      },
      {
        title: t('updatedBy'),
        dataIndex: 'audit.updatedBy',
        key: 'audit.updatedBy',
        sorter: true,
        sortOrder: sort.field === 'audit.updatedBy' && sort.order,
      },
      {
        key: 'action',
        title: (
          <Tooltip title={t('tooltip.addVEvent')} mouseEnterDelay={0.5}>
            <Button type="default" size="small" icon="plus" onClick={onCreate} />
          </Tooltip>
        ),
        render: (text: string, ve: VEvent) => (
          <div className="bags-list-actions">
            <Button type="link" size="small" onClick={() => onEdit(ve)}>
              {t('btn.edit')}
            </Button>
          </div>
        ),
      },
    ]

    return (
      <div>
        <Table
          {...tableProps}
          pagination={{
            size: 'small',
            ...pagination,
          }}
          columns={columns}
          expandedRowRender={item => (
            <div>
              <b>Bags: </b>
              {item.bagNames && item.bagNames.join(', ')}
            </div>
          )}
          rowKey="uuid"
          scroll={{ x: true }}
        />
      </div>
    )
  }
}

export default VEventTable
