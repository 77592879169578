import React from 'react'
import { Button, Input, Form, DatePicker, Select } from 'antd'
import { FormComponentProps } from 'antd/lib/form'

import { TargetSearch, TargetPool } from '../../../_types'
import { epochToMoment, momentToEpoch } from '../../../_helpers'

const { RangePicker } = DatePicker
const { Option } = Select

interface HeaderProps {
  item: TargetSearch
  pool: TargetPool
  t: any
  onSearch: () => void
  onChange: (field: any) => void
  onClear: () => void
}

interface HeaderState {
  activeKey: string
}

interface SearchFromProps extends HeaderProps, FormComponentProps {
  activeKey: string
  onCollapse: () => void
}

const SearchForm = Form.create<SearchFromProps>({
  mapPropsToFields(props) {
    return {
      vehicle_name: Form.createFormField({ value: props.item.vehicle_name }),
      product_version: Form.createFormField({ value: props.item.product_version }),
      ts: Form.createFormField({ value: props.item.ts }),
      // createdBy: Form.createFormField({ value: props.item.createdBy }),
      event_time: Form.createFormField({
        value: props.item.event_time.map(i => epochToMoment(i)),
      }),
      event_type: Form.createFormField({ value: props.item.event_type }),
      route_name: Form.createFormField({ value: props.item.route_name }),
      belonging_module: Form.createFormField({ value: props.item.belonging_module }),
      start_ts: Form.createFormField({ value: props.item.start_ts }),
      end_ts: Form.createFormField({ value: props.item.end_ts })
    }
  },
  onFieldsChange(props, fields) {
    if (fields.event_time) {
      fields.event_time = fields.event_time.value.map((i: any) => momentToEpoch(i))
    }
    props.onChange(fields)
  },
})(({ form, t, pool, onSearch, onClear }: SearchFromProps) => {
  const { getFieldDecorator } = form

  const onPressEnter = (e: React.KeyboardEvent) => {
    e.preventDefault()
    onSearch()
  }

  return (
    <Form layout="inline" onSubmit={onSearch}>
          <Form.Item label={t('vehicleName')}>
            {getFieldDecorator('vehicle_name')(
              <Select style={{ width: "13rem" }}>
              {
                pool.vehicleArray.map(item => {
                  return <Option value={item.key} key={item.key}>{item.value}</Option>
                })
              }
            </Select>
            )}
          </Form.Item>
          <Form.Item label={t('productVersion')}>
            {getFieldDecorator('product_version')(
              <Select style={{ width: "13rem" }}>
                  <Option value="Normal">{t('normal')}</Option>
                  <Option value="Fault">{t('faults')}</Option>
                </Select>
            )}
          </Form.Item>
          <Form.Item label={t('modeType')}>
            {getFieldDecorator('belonging_module')(
              <Select style={{ width: "13rem" }} showSearch>
              {
                pool.vehicleArray.map(item => {
                  return <Option value={item.key} key={item.key}>{item.value}</Option>
                })
              }
            </Select>
            )}
          </Form.Item>
          <Form.Item label={t('eventsType')}>
            {getFieldDecorator('event_type')(
              <Select style={{ width: "13rem" }}>
              {
                pool.vehicleArray.map(item => {
                  return <Option value={item.key} key={item.key}>{item.value}</Option>
                })
              }
            </Select>
            )}
          </Form.Item>
          <Form.Item label={t('date')}>
            {getFieldDecorator('event_time')(
              <RangePicker showTime={{ format: 'HH:mm:ss' }} format="YYYY-MM-DD HH:mm:ss" />,
            )}
          </Form.Item>
          <Form.Item label={t('startTime')}>
            {getFieldDecorator('start_ts')(<DatePicker showTime />)}
          </Form.Item>
          <Form.Item label={t('endTime')}>
            {getFieldDecorator('end_ts')(<DatePicker showTime />)}
          </Form.Item>
          <Form.Item label={t('routeName')}>
            {getFieldDecorator('route_name')(
              <Input />
            )}
          </Form.Item>
          <Form.Item label={t('timestamp')}>
            {getFieldDecorator('ts')(
              <Input />
            )}
          </Form.Item>
          <Form.Item>
          <Button type="primary" onClick={onSearch}>
            {t('btn.search')}
          </Button>
          <Button style={{ marginLeft: '0.5rem' }} onClick={onClear}>
            {t('btn.reset')}
          </Button>
        </Form.Item>
    </Form>
  )
})

class Header extends React.PureComponent<HeaderProps, HeaderState> {
  constructor(props: any) {
    super(props)

    this.state = {
      activeKey: '',
    }
  }

  get collapseProps() {
    const { activeKey } = this.state

    return {
      activeKey,
      onCollapse: () => {
        this.setState({ activeKey: activeKey ? '' : '0' })
      },
    }
  }

  render() {
    return <SearchForm {...this.props} {...this.collapseProps} />
  }
}

export default Header
