import React from 'react'
import { Table, Button } from 'antd'
import { ColumnProps } from 'antd/lib/table'

import { RoadTest } from '../../../_types'

export interface RoadTestListProps {
  onCreate: () => void
  onEdit: (item: RoadTest) => void
  loading: boolean
  t: any
}

export class RoadTestList extends React.PureComponent<RoadTestListProps> {
  render() {
    const { t, loading, onEdit, ...tableProps } = this.props

    const columns: ColumnProps<RoadTest>[] = [
      {
        key: 'id',
        dataIndex: 'id',
        title: 'ID',
      },
      {
        key: 'vehicleId',
        dataIndex: 'vehicleId',
        title: t('vehicleId'),
      },
      {
        key: 'driverId',
        dataIndex: 'driverId',
        title: t('driverId'),
      },
      {
        key: 'passengers',
        dataIndex: 'passengers',
        title: t('passengers'),
      },
      {
        key: 'status',
        dataIndex: 'status',
        title: t('status'),
      },
      {
        key: 'startTime',
        dataIndex: 'startTime',
        title: t('startTime'),
      },
      {
        key: 'endTime',
        dataIndex: 'endTime',
        title: t('endTime'),
      },
      {
        key: 'description',
        dataIndex: 'description',
        title: t('description'),
      },
      {
        key: 'actions',
        render: (text, record) => (
          <div className="roadTests-actions">
            <div className="roadTests-actions-btn">
              <Button type="link" size="small" onClick={() => onEdit(record)}>
                {t('btn.edit')}
              </Button>
            </div>
          </div>
        ),
      },
    ]

    return <Table {...tableProps} columns={columns} rowKey="id" loading={loading} scroll={{ x: true }} />
  }
}
