import React, { CSSProperties } from 'react'
import './MapButton.scss'
import { Tooltip } from 'antd'

import { TOOLTIP_DELAY } from '../../constants'

interface MapButtonProps {
  style?: CSSProperties
  title?: string
  onClick?: React.MouseEventHandler<HTMLElement>
}

const MapButton: React.FC<MapButtonProps> = ({ children, style, title, onClick }) => {
  return (
    <Tooltip title={title} placement="right" mouseEnterDelay={TOOLTIP_DELAY}>
      <button className="map-button" style={style} onClick={onClick}>
        {children}
      </button>
    </Tooltip>
  )
}

export default MapButton
