import React from 'react'
import { Modal, Form, Input, Select, Switch, Button } from 'antd'
import { FormComponentProps } from 'antd/lib/form/Form'

import { ModalType, User } from '../../../_types'
import { reg } from '../../../_utils'

const { Option } = Select

const modalBodyStyle = {
  maxHeight: '80vh',
  overflow: 'auto',
  padding: '1rem 1.5rem 0.5rem',
}

const formItemLayout = {
  labelAlign: 'left' as 'left',
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

interface UserModalProps extends FormComponentProps {
  item?: User
  loading: boolean
  modalType: ModalType
  onModify: any
  onCancel: () => void
  t: any
}

class UserModal extends React.PureComponent<UserModalProps> {
  handleUpdate = () => {
    const { item, onModify, modalType, form } = this.props
    const { validateFields, getFieldsValue } = form

    validateFields(errors => {
      if (errors) {
        return
      }

      const fieldsData = getFieldsValue()
      const data: any = {
        ...fieldsData,
        ...(item && { id: item.id }),
        ...(item && { status: fieldsData.status ? 'ACTIVE' : 'INACTIVE' }),
      }

      onModify(data, modalType)
    })
  }

  render() {
    const { item, loading, modalType, onModify, form, t, ...modalProps } = this.props
    const { getFieldDecorator } = form

    return (
      <Modal
        bodyStyle={modalBodyStyle}
        {...modalProps}
        footer={
          <>
            {item ? (
              <Button loading={loading} onClick={() => onModify({ id: item.id }, 'delete')}>
                {t('btn.delete')}
              </Button>
            ) : null}
            <Button type="primary" onClick={this.handleUpdate} loading={loading}>
              {t(`btn.${modalType}`)}
            </Button>
          </>
        }
      >
        <Form {...formItemLayout}>
          <h4 className="users-form-divider">{t('basic')}</h4>
          <div className="users-form-section">
            <Form.Item label={t('name')}>
              {getFieldDecorator('name', {
                initialValue: item && item.name,
                rules: [{ required: true, message: t('tip.required') }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label={t('phone')}>
              {getFieldDecorator('phone', {
                initialValue: item && item.phone,
              })(<Input />)}
            </Form.Item>
            <Form.Item label={t('email')}>
              {getFieldDecorator('email', {
                initialValue: item && item.email,
              })(<Input />)}
            </Form.Item>
            <Form.Item label={t('address')}>
              {getFieldDecorator('address', {
                initialValue: item && item.address,
              })(<Input />)}
            </Form.Item>
          </div>
          {item ? null : (
            <>
              <h4 className="users-form-divider">{t('security')}</h4>
              <div className="users-form-section">
                <Form.Item label={t('password')}>
                  {getFieldDecorator('password', {
                    rules: [
                      {
                        pattern: reg.pwdUser,
                        message: t('tip.invalidPwd'),
                      },
                      { required: true, message: t('tip.required') },
                    ],
                  })(<Input.Password allowClear />)}
                </Form.Item>
              </div>
            </>
          )}

          <h4 className="users-form-divider">{t('account')}</h4>
          <div className="users-form-section">
            <Form.Item label={t('type')}>
              {getFieldDecorator('type', {
                initialValue: item && item.type,
                rules: [{ required: true, message: t('tip.required') }],
              })(
                <Select>
                  <Option value="NORMAL">{t('userType.normal')}</Option>
                  <Option value="INVITED">{t('userType.invited')}</Option>
                  <Option value="GUEST">{t('userType.guest')}</Option>
                </Select>,
              )}
            </Form.Item>
            {item ? (
              <Form.Item label={t('status')}>
                {getFieldDecorator('status', {
                  valuePropName: 'checked',
                  initialValue: item && item.status === 'ACTIVE',
                  rules: [{ required: true, message: t('tip.required') }],
                })(<Switch />)}
              </Form.Item>
            ) : null}
          </div>
        </Form>
      </Modal>
    )
  }
}

export default Form.create<UserModalProps>()(UserModal)
