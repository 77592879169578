import React, { CSSProperties } from 'react'
import { AutoComplete, Modal, Select, Form, Input, DatePicker, Button, InputNumber } from 'antd'
import { FormComponentProps } from 'antd/lib/form'

import { Fault, FaultPool, ModalType } from '../../../_types'
import { epochToMoment, momentToEpoch, epochToDate } from '../../../_helpers'
const { Option } = Select

interface FaultModalProps extends FormComponentProps {
  item?: Fault
  pool: FaultPool
  loading: boolean
  modalType: ModalType
  onModify: any
  onCancel: () => void
  t: any
}

const modalBodyStyle: CSSProperties = {
  maxHeight: '80vh',
  overflow: 'auto',
  padding: '1rem 1.5rem 0.5rem',
}

const formItemLayout = {
  labelAlign: 'left' as 'left',
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

class FaultModal extends React.PureComponent<FaultModalProps> {
  handleUpdate = () => {
    const { item, onModify, modalType, form } = this.props
    const { validateFields, getFieldsValue } = form
    validateFields(errors => {
      if (errors) {
        return
      }      
      const { faultTime, fixTime, ...fieldsValue } = getFieldsValue()
      // const newFaultTime = parseInt(parseInt(momentToEpoch(faultTime)) / 1000)
      // const newFixTime = parseInt(parseInt(momentToEpoch(fixTime)) / 1000)
      const faultTimeDate = new Date(epochToDate(momentToEpoch(faultTime)))
      const newFaultTime = Date.parse(faultTimeDate)
      const fixTimeDate = new Date(epochToDate(momentToEpoch(fixTime)))
      const newFixTime = Date.parse(fixTimeDate)
      const data: any = {
        ...fieldsValue,
        faultTime: newFaultTime,
        fixTime: newFixTime,
        ...(item && { id: item.id }),
      }
      onModify(data, modalType)
    })
  }

  render() {
    const { item, form, pool, loading, modalType, onModify, t, ...modalProps } = this.props
    const { getFieldDecorator } = form

    return (
      <div>
        <Modal
          {...modalProps}
          bodyStyle={modalBodyStyle}
          footer={
            <>
              {/* {item ? (
                <Button loading={loading} onClick={() => onModify({ id: item.id }, 'delete')}>
                  {t('btn.delete')}
                </Button>
              ) : null} */}
              <Button loading={loading} type="primary" onClick={this.handleUpdate}>
                {t(`btn.${modalType}`)}
              </Button>
            </>
          }
        >
          <Form {...formItemLayout}>
            <Form.Item label={t('vehicleName')}>
              {getFieldDecorator('vehicleName', {
                initialValue: item && item.vehicleName,
                rules: [{ required: true, message: t('tip.required') }],
              })(
                <Select showSearch allowClear>
                  {
                    pool.vehicleArray.map(item => {
                      return <Option value={item.vehicleName} key={item.vehicleName}>{item.vehicleName}</Option>
                    })
                  }
                </Select>
              )}
            </Form.Item>
            <Form.Item label={t('vehicleState')}>
              {getFieldDecorator('vehicleState', {
                initialValue: item && item.vehicleState,
                rules: [{ required: true, message: t('tip.required') }],
              })(
                <Select allowClear>
                  <Option value="正常">{t('normal')}</Option>
                  <Option value="故障">{t('faults')}</Option>
                </Select>
              )}
            </Form.Item>
            <Form.Item label={t('faultStates')}>
              {getFieldDecorator('faultState', {
                initialValue: item && item.faultState,
                rules: [{ required: true, message: t('tip.required') }],
              })(
                <Select allowClear>
                  <Option value="处理中">{t('processing')}</Option>
                  <Option value="待验证">{t('toBeVerified')}</Option>
                  <Option value="已解决">{t('resolved')}</Option>
                </Select>
              )}
            </Form.Item>
            <Form.Item label={t('faultConditions')}>
              {getFieldDecorator('faultConditions', {
                initialValue: item && item.faultConditions,
              })(<Input allowClear  />)}
            </Form.Item>
            
            <Form.Item label={t('faultTime')}>
            {getFieldDecorator('faultTime', {
                initialValue: item && epochToMoment(item.faultTime),
                rules: [{ required: true, message: t('tip.required') }],
              })(<DatePicker showTime />)}
            </Form.Item>
            <Form.Item label={t('issues')}>
              {getFieldDecorator('fixIssue', {
                initialValue: item && item.fixIssue,
              })(<Input allowClear />)}
            </Form.Item>
            <Form.Item label={t('fixTime')}>
            {getFieldDecorator('fixTime', {
                initialValue: item && epochToMoment(item.fixTime),
              })(<DatePicker showTime />)}
            </Form.Item>
            {/* <Form.Item label={t('faultDuration')}>
            {getFieldDecorator('faultDuration', {
                initialValue: item && item.faultDuration,
              })(<InputNumber />)}
            </Form.Item> */}
            <Form.Item label={t('modeType')}>
            {getFieldDecorator('modType', {
                initialValue: item && item.modType,
              })(
                <Select showSearch allowClear>
              {
                pool.tags.map(item => {
                  return <Option value={item.moduleName} key={item.moduleName}>{item.moduleName}</Option>
                })
              }
            </Select>
              )}
            </Form.Item>
            <Form.Item label={t('priority')}>
              {getFieldDecorator('priority', {
                initialValue: item && item.priority,
              })(
                <Select allowClear>
                  <Option value="high">{t('high')}</Option>
                  <Option value="middle">{t('middle')}</Option>
                  <Option value="low">{t('low')}</Option>
                </Select>
              )}
            </Form.Item>
            <Form.Item label={t('followUpPerson')}>
              {getFieldDecorator('followUpPerson', {
                initialValue: item && item.followUpPerson,
              })(
                <Select showSearch allowClear>
              {
                pool.userArray.map(item => {
                  return <Option value={item.userName} key={item.userName}>{item.userName}</Option>
                })
              }
            </Select>
              )}
            </Form.Item>
          </Form>
        </Modal>
      </div>
    )
  }
}

export default Form.create<FaultModalProps>()(FaultModal)
