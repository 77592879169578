import { searchConstants } from '../_constants'

const initialState: any = {
  event: [],
  log: [],
  monitor: [],
  bag: [],
  gettingData: false,
}

export function search(state = initialState, action: any) {
  switch (action.type) {
    case searchConstants.GET_REQUEST:
      return {
        ...state,
        event: [],
        log: [],
        monitor: [],
        bag: [],
        gettingData: true,
      }
    case searchConstants.GET_SUCCESS:
      switch (action.idxType) {
        case 'incarlog':
          return {
            ...state,
            log: action.docs || [],
            gettingData: false,
          }
        case 'incarevent':
          return {
            ...state,
            event: action.docs || [],
            gettingData: false,
          }
        case 'incarmonitor':
          return {
            ...state,
            monitor: action.docs || [],
            gettingData: false,
          }
        case 'incarbag':
          return {
            ...state,
            bag: action.docs || [],
            gettingData: false,
          }
        default:
          return state
      }
    case searchConstants.GET_FAILURE:
      return {
        ...state,
        gettingData: false,
      }
    default:
      return state
  }
}
