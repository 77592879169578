export interface DataFields {
  [key: string]: any
}

export function dataToFields(data: Record<string, any>, fields: DataFields = {}) {
  // Notice: Set password to ''
  Object.keys(data).forEach(key => {
    fields[key] = { value: key === 'password' ? '' : data[key] }
  })

  return fields
}

export function fieldsToData(fields: DataFields, data: Record<string, any>) {
  // Notice: If password is '', it shouldn't be in data
  Object.keys(fields).forEach(key => {
    if (key === 'password' && !fields.password.value) return

    data[key] = fields[key].value
  })

  return data
}
export function objectToParams(fields:DataFields){
  let _result:any = []
  for (let key in fields) {
    let value = fields[key]
    // 去掉为空的参数
    if (['', undefined, null].includes(value)) {
      continue
    }
    _result.push(encodeURIComponent(key) + '=' + encodeURIComponent(value))
  }

  return _result.length ? _result.join('&') : ''
}
