import { Target } from './../_types/target.types';
export { Login } from './Login'
export { Dashboard } from './Dashboard'
export { Monitor } from './Monitor'
export { Loading } from './Loading'
export { Users } from './Users'
export { Vehicles } from './Vehicles'
export { Admins } from './Admins'
export { Trips } from './Trips'
export { RoadTests } from './RoadTests'
export { Districts } from './Districts'
// export { Drive } from './Drive'
export { Search } from './Search'
export { Recents } from './Recents'
export { Tags } from './Tags'
export { Bags } from './Bags'
export { Invitations } from './Invitations'
export { PLocations } from './PLocations'
export { Paths } from './Paths'
export { VEvents, VEventDetail } from './VEvents'
export { Resources } from './Resources'
export { Mileage } from './Mileage'
export { Takeover } from './Takeover'
export { Performances } from './Performances'
export { Fault } from './Fault'
export { Target } from './Target'

export {VMap} from './VMap'
export {VMapDetail} from './VMap'
export { ScenarioSurvey } from './ScenarioSurvey'
export { ScenarioList } from './ScenarioSurvey'
export { ScenarioDetail } from './ScenarioSurvey'