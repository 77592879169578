import React from 'react'
import { AutoComplete, Button, Input, Form, DatePicker,Select, TimePicker } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import moment from 'moment'
import { MileageSearch, MileagePool } from '../../../_types'
import { epochToMoment, momentToEpoch } from '../../../_helpers'

const { RangePicker } = DatePicker

const { Option } = Select

interface HeaderProps {
  item: MileageSearch
  pool: MileagePool
  t: any
  onSearch: () => void
  onChange: (field: any) => void
  onClear: () => void
  onUploadFun: () => void
}

interface HeaderState {
  activeKey: string
}

interface SearchFromProps extends HeaderProps, FormComponentProps {
  activeKey: string
  onCollapse: () => void
}

const SearchForm = Form.create<SearchFromProps>({
  mapPropsToFields(props) {
    return {
      vehicle_name: Form.createFormField({ value: props.item.vehicle_name }),
      product_version: Form.createFormField({ value: props.item.product_version }),
      route_name: Form.createFormField({ value: props.item.route_name }),
      extra_route_name: Form.createFormField({ value: props.item.extra_route_name }),
      internal_event_type: Form.createFormField({ value: props.item.internal_event_type }),
      belonging_module: Form.createFormField({ value: props.item.belonging_module }),
      ts: Form.createFormField({
        value: props.item.ts.map(i => epochToMoment(i)),
      }),
      event_time: Form.createFormField({ value: props.item.event_time.map(i => epochToMoment(i)) })
    }
  },
  onFieldsChange(props, fields) {
    if (fields.ts) {
      fields.ts = fields.ts.value.map((i: any) => momentToEpoch(i))
    }
    if (fields.event_time) {
      fields.event_time = fields.event_time.value.map((i: any) => momentToEpoch(i))
    }
console.log('fields',fields)
    props.onChange(fields)
  },
})(({ form, t, pool, onSearch, onClear, onUploadFun, item, activeKey, onCollapse }: SearchFromProps) => {
  const { getFieldDecorator } = form
  const onPressEnter = (e: React.KeyboardEvent) => {
    e.preventDefault()
    onSearch()
  }
  return (
    <Form layout="inline" onSubmit={onSearch}>
      <Form.Item label={t('vehicleName')}>
        {getFieldDecorator('vehicle_name')(
        <Select style={{ width: "13rem" }} showSearch allowClear mode="multiple">
        {
          pool.vehicleName.map(item => {
            return <Option value={item.cn_name} key={item.cn_name}>{item.cn_name}</Option>
          })
        }
      </Select>
      )}
      </Form.Item>
      <Form.Item label={t('modeType')}>
        {getFieldDecorator('belonging_module')(
          <Select style={{ width: "13rem" }} showSearch allowClear mode="multiple">
          {
            pool.belonging_module.map(item => {
              return <Option value={item.cn_name} key={item.cn_name}>{item.cn_name}</Option>
            })
          }
        </Select>
        )}
      </Form.Item>
      <Form.Item label={t('productVersion')}>
            {getFieldDecorator('product_version')(
              <Input placeholder='如:master-1.0,master-2.0'></Input>
            )}
      </Form.Item>
      <Form.Item label={t('eventsType')}>
        {getFieldDecorator('internal_event_type')(
          <Select style={{ width: "13rem" }} showSearch allowClear mode="multiple">
          {
            pool.internal_event_type.map(item => {
              return <Option value={item.cn_name} key={item.cn_name}>{item.cn_name}</Option>
            })
          }
        </Select>
        )}
      </Form.Item>
      <Form.Item label={t('routeName')}>
        {getFieldDecorator('route_name')(
          <Select style={{ width: "13rem" }} showSearch allowClear mode="multiple">
          {
            pool.route_name.map(item => {
              return <Option value={item.cn_name} key={item.cn_name}>{item.cn_name}</Option>
            })
          }
        </Select>
        ) }
      </Form.Item>
      <Form.Item label={t('extraRouteName')}>
            {getFieldDecorator('extra_route_name')(
              <Input placeholder='如:八字,新四跨'></Input>
            )}
      </Form.Item>
      
      <Form.Item label={t('event_time')}>
        {getFieldDecorator('event_time')(
        <RangePicker showTime={{ format: 'HH:mm:ss' }} format = 'HH:mm:ss' /> )}
      </Form.Item>
      <Form.Item label={t('eventTimeRange')}>
        {getFieldDecorator('ts')(
          <RangePicker showTime={{ defaultValue: [moment('00:00:00', 'HH:mm:ss')], }} format="YYYY-MM-DD HH:mm:ss"
          />
        )}
      </Form.Item>
      <Form.Item>
        <Button type="primary" onClick={onSearch}>
          {t('btn.search')}
        </Button>
        <Button style={{ marginLeft: '0.5rem' }} onClick={onClear}>
          {t('btn.reset')}
        </Button>
      </Form.Item>
    </Form>
  )
})

class Header extends React.PureComponent<HeaderProps, HeaderState> {
  constructor(props: any) {
    super(props)

    this.state = {
      activeKey: '',
    }
  }

  get collapseProps() {
    const { activeKey } = this.state

    return {
      activeKey,
      onCollapse: () => {
        this.setState({ activeKey: activeKey ? '' : '0' })
      },
    }
  }

  render() {
    return <SearchForm {...this.props} {...this.collapseProps} />
  }
}

export default Header
