import React, { CSSProperties } from 'react'
import { Modal, Form, Input, DatePicker } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { Tag, ModalType } from '../../../_types'
import { epochToMoment, momentToEpoch } from '../../../_helpers'

interface TagModalProps extends FormComponentProps {
  onOk: (data: Tag) => void
  onCancel: () => void
  modalType: ModalType
  item: Tag
  t: any
}

const modalBodyStyle: CSSProperties = {
  maxHeight: '70vh',
  overflow: 'auto',
  padding: '1rem 1.5rem 0.5rem',
}

const formItemLayout = {
  labelAlign: 'left' as 'left',
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

class TagModal extends React.PureComponent<TagModalProps> {
  handleOk = () => {
    const { item = {} as Tag, onOk, form } = this.props
    const { validateFields, getFieldsValue } = form

    validateFields(errors => {
      if (errors) {
        return
      }
      const data: any = {
        ...getFieldsValue(),
        id: item.id,
      }
      onOk(data)
    })
  }

  render() {
    const { item = {} as Tag, form, t, ...modalProps } = this.props
    const { getFieldDecorator } = form

    const isUpdate = modalProps.modalType == 'update'

    return (
      <div>
        <Modal {...modalProps} onOk={this.handleOk} bodyStyle={modalBodyStyle}>
          <Form {...formItemLayout}>
            <div className="tags-form-section">
              <Form.Item label={t('name')}>
                {getFieldDecorator('name', {
                  initialValue: item.name,
                })(<Input disabled={isUpdate} />)}
              </Form.Item>
              <Form.Item label={t('description')}>
                {getFieldDecorator('description', {
                  initialValue: item.description,
                })(<Input />)}
              </Form.Item>
              <Form.Item label={t('type')}>
                {getFieldDecorator('type', {
                  initialValue: item.type,
                })(<Input />)}
              </Form.Item>
            </div>
          </Form>
        </Modal>
      </div>
    )
  }
}

export default Form.create<TagModalProps>()(TagModal)
