import React from 'react'
import { Table} from 'antd'
import { ColumnProps } from 'antd/lib/table'

import { takeover, Pagination} from '../../../_types'

interface BagListProps {
  t: any
  pagination: Pagination
}

class BagTable extends React.PureComponent<BagListProps> {
  render() {
    const { t, pagination, ...tableProps } = this.props
    const columns: ColumnProps<takeover>[] = [
      {
        title: t('vehicleId'),
        dataIndex: 'vehicleId',
      },
      {
        title: t('vehicleName'),
        dataIndex: 'vehicleName',
      },
      {
        title: t('region'),
        dataIndex: 'region',
      },
      {
        title: t('sumMileage'),
        dataIndex: 'sumMileage',
      },
      {
        title: t('sumDuration'),
        dataIndex: 'sumDuration',
      },
      {
        title: t('sumTakeover'),
        dataIndex: 'sumTakeover',
      },
      {
        title:t('mileagePerTakeover'),
        dataIndex:'mileagePerTakeover'
      }
    ]
    return (
      <div>
        <Table
          {...tableProps}
          pagination = {false}
          columns={columns}
          rowKey='key'
          //scroll={{ x: true }}
        />
      </div>
    )
  }
}

export default BagTable
