import React, { CSSProperties } from 'react'
import { AutoComplete, Modal, Select, Form, Input, DatePicker, Button, InputNumber } from 'antd'
import { FormComponentProps } from 'antd/lib/form'

import { Target, TargetPool, ModalType } from '../../../_types'
import { epochToMoment, momentToEpoch } from '../../../_helpers'
const { Option } = Select

interface FaultModalProps extends FormComponentProps {
  item?: Target
  pool: TargetPool
  loading: boolean
  modalType: ModalType
  onModify: any
  onCancel: () => void
  t: any
}

const modalBodyStyle: CSSProperties = {
  maxHeight: '80vh',
  overflow: 'auto',
  padding: '1rem 1.5rem 0.5rem',
}

const formItemLayout = {
  labelAlign: 'left' as 'left',
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

class FaultModal extends React.PureComponent<FaultModalProps> {
  handleUpdate = () => {
    const { item, onModify, modalType, form } = this.props
    const { validateFields, getFieldsValue } = form
    validateFields(errors => {
      if (errors) {
        return
      }
      const { event_time, ...fieldsValue } = getFieldsValue()
      const data: any = {
        ...fieldsValue,
        event_time: momentToEpoch(event_time),
        // fixTime: momentToEpoch(fixTime),
        ...(item && { id: item.id }),
      }
      onModify(data, modalType)
    })
  }

  render() {
    const { item, form, pool, loading, modalType, onModify, t, ...modalProps } = this.props
    const { getFieldDecorator } = form

    return (
      <div>
        <Modal
          {...modalProps}
          bodyStyle={modalBodyStyle}
          footer={
            <>
              {/* {item ? (
                <Button loading={loading} onClick={() => onModify({ id: item.id }, 'delete')}>
                  {t('btn.delete')}
                </Button>
              ) : null} */}
              <Button loading={loading} type="primary" onClick={this.handleUpdate}>
                {t(`btn.${modalType}`)}
              </Button>
            </>
          }
        >
          <Form {...formItemLayout}>
            <Form.Item label={t('vehicleName')}>
              {getFieldDecorator('vehicle_name', {
                initialValue: item && item.vehicle_name,
                rules: [{ required: true, message: t('tip.required') }],
              })(
                <Select>
                  {
                    pool.vehicleArray.map(item => {
                      return <Option value={item.key} key={item.key}>{item.value}</Option>
                    })
                  }
                </Select>
              )}
            </Form.Item>
            <Form.Item label={t('productVersion')}>
              {getFieldDecorator('product_version', {
                initialValue: item && item.product_version,
                rules: [{ required: true, message: t('tip.required') }],
              })(
                <Select>
                  <Option value="Normal">{t('normal')}</Option>
                  <Option value="Fault">{t('faults')}</Option>
                </Select>
              )}
            </Form.Item>
            <Form.Item label={t('faultStates')}>
              {getFieldDecorator('ts', {
                initialValue: item && item.ts,
                rules: [{ required: true, message: t('tip.required') }],
              })(
                <Select>
                  <Option value="processing">{t('processing')}</Option>
                  <Option value="toBeVerified">{t('toBeVerified')}</Option>
                  <Option value="resolved">{t('resolved')}</Option>
                </Select>
              )}
            </Form.Item>
            <Form.Item label={t('faultConditions')}>
              {getFieldDecorator('event_type', {
                initialValue: item && item.event_type,
              })(<Input />)}
            </Form.Item>
            
            <Form.Item label={t('faultTime')}>
            {getFieldDecorator('event_time', {
                initialValue: item && epochToMoment(item.event_time),
              })(<DatePicker showTime />)}
            </Form.Item>
            <Form.Item label={t('issues')}>
              {getFieldDecorator('belonging_module', {
                initialValue: item && item.belonging_module,
              })(<Input />)}
            </Form.Item>
          </Form>
        </Modal>
      </div>
    )
  }
}

export default Form.create<FaultModalProps>()(FaultModal)
