import React from 'react'

import { PLocation } from '../../../../_types'
import vars from '../../../../variables.scss'

interface PointItemProps {
  point: PLocation
  onClickPoint: (e: React.MouseEvent, plocationId: string) => void
}

const PointItem: React.FC<PointItemProps> = ({ point, onClickPoint }) => {
  return (
    <li
      className="monitor-list-point"
      style={{
        color: vars[`plocation${point?.state}`],
      }}
    >
      <span className="monitor-list-point-name" onClick={e => onClickPoint(e, point.id)}>
        {point?.name}
      </span>
    </li>
  )
}

export default PointItem
