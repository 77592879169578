import React from 'react'
import './Vehicles.scss'
import { connect } from 'dva'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Dispatch } from 'redux'

import { VehiclesReducerState, Vehicle, District, DvaState } from '../../_types'
import { VehicleList, VehicleModal } from './components'

interface VehiclesProps extends WithTranslation, VehiclesReducerState {
  vehicles: Vehicle[]
  districts: District[]
  dispatch: Dispatch
}

class Vehicles extends React.PureComponent<VehiclesProps> {
  get vehiclelistProps() {
    const { dispatch, vehicles, districts, listingVehicles, t } = this.props

    return {
      dataSource: vehicles,
      districts,
      loading: listingVehicles,
      t,
      onCreate: () => {
        dispatch({
          type: 'vehicles/showModal',
          payload: {
            modalType: 'create',
          },
        })
      },
      onEdit: (item: Vehicle) => {
        dispatch({
          type: 'vehicles/showModal',
          payload: {
            modalType: 'update',
            currentItem: item,
          },
        })
      },
    }
  }

  get VehicleModalProps() {
    const {
      districts,
      dispatch,
      currentItem,
      modalVisible,
      modalType,
      modifyingVehicle,
      gettingVehicle,
      t,
    } = this.props

    return {
      item: modalType === 'create' ? undefined : currentItem,
      districts,
      visible: modalVisible,
      modalType,
      title: t(`${modalType}Vehicle`),
      loading: modifyingVehicle,
      gettingVehicle,
      destroyOnClose: true,
      maskClosable: true,
      centered: true,
      t,
      onModify: (data: any, action: string) => {
        dispatch({
          type: `vehicles/${action}`,
          payload: data,
        })
      },
      onCancel: () => {
        dispatch({
          type: 'vehicles/hideModal',
        })
      },
      onCollapse: () => {
        // Async dispatch
        return dispatch({
          type: 'vehicles/get',
          payload: { id: currentItem.id },
        }) as any // Dva does not provide correct dispatch type
      },
    }
  }

  render() {
    const { t } = this.props

    return (
      <div className="vehicles">
        <div className="vehicles-inner">
          <header className="vehicles-header">
            <h2>{t('vehicleList')}</h2>
          </header>
          <VehicleList {...this.vehiclelistProps} />
          <VehicleModal {...this.VehicleModalProps} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: DvaState) => ({
  ...state.vehicles,
  districts: state.districts.districts,
})

export default connect(mapStateToProps)(withTranslation('vehicles')(Vehicles))
