import axois from 'axios'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

export const axs = axois.create({
  baseURL: '/api/v1/',
  headers: {
    Token: cookies.get('token'),
    'Content-Type': 'application/json;charset=utf-8',
  },
})
