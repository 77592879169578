import moment from 'moment'
import './_helpers/i18n'
import './index.scss'
import dva from 'dva'

import { history } from './_helpers'

// global Inject
// @ts-ignore
// Object.prototype.deepClone = function () {
//   let copy = this.constructor === Array ? [] : {};
//   for (let key in this) {
//     // @ts-ignore
//     if (typeof this[key] === 'object') {
//       // @ts-ignore
//       copy[key] = this[key].deepClone()
//       // @ts-ignore
//     } else if (typeof this[key] === 'function') {
//       // @ts-ignore
//       copy[key] = this[key].bind(copy)
//     } else {
//       // @ts-ignore
//       copy[key] = this[key]
//     }
//   }
//   return copy
// }


// 1. Initialize
const app = dva({
  history: history,
  onError(e) {
    console.log('e', e)
  },
})

// 2. Pluginsd
app.use({})

// 3. Model
require('./_models').default.forEach((key: any) => app.model(key.default))

// 4. Router
app.router(require('./router').default)

// 5. Start
app.start('#root')

moment.locale('en')
