import React from 'react'
import './Search.scss'
import { connect } from 'react-redux'
import { Collapse, Form, Row, Col, Input, List, Button, DatePicker } from 'antd'
import { searchConstants } from '../../_constants'
import { searchActions } from '../../_actions'
import SearchField from './SearchField'
import SearchResult from './SearchResult'

const { Panel } = Collapse
const { RangePicker } = DatePicker
const backField = {
  style: {
    marginLeft: '20px',
  },
}
class ListSearch extends React.PureComponent<any> {
  handleReset = () => {
    const { dispatch } = this.props
    dispatch({ type: searchConstants.GET_REQUEST })
    this.props.form.resetFields()
  }
  handleSearch = (e: any) => {
    e.preventDefault()
    const { getFieldsValue } = this.props.form
    const { dispatch } = this.props
    const search = (data: any) => {
      const global = {
        'fields.car_id': data.carName,
        timerange: data.timerange,
      }
      if (data.event) {
        dispatch(searchActions.get(data.event, global, 'incarevent'))
      }
      if (data.log) {
        dispatch(searchActions.get(data.log, global, 'incarlog'))
      }
      if (data.monitor) {
        dispatch(searchActions.get(data.monitor, global, 'incarmonitor'))
      }
    }
    const data = getFieldsValue()
    search(data)
  }

  render() {
    const { event, log, monitor, gettingData, form } = this.props
    const { getFieldDecorator } = form
    return (
      <div>
        <Form className="search-advanced-form" onSubmit={this.handleSearch}>
          <Form.Item label={`car name`}> {getFieldDecorator(`carName`)(<Input />)} </Form.Item>
          <Form.Item label={`time range`} {...backField}>
            {getFieldDecorator(`timerange`)(
              <RangePicker showTime={{ format: 'HH:mm:ss' }} format="YYYY-MM-DD HH:mm:ss" />,
            )}
          </Form.Item>
          <Collapse className="search-collapse" bordered={false} defaultActiveKey={['1']}>
            <Panel header="incar-event" key="1">
              <SearchField form={this.props.form} name="event" />
            </Panel>
            <Panel header="incar-log" key="2">
              <SearchField form={this.props.form} name="log" />
            </Panel>
            <Panel header="incar-monitoring" key="3">
              <SearchField form={this.props.form} name="monitor" />
            </Panel>
          </Collapse>
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Search
                </Button>
                <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>
                  Clear
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div className="search-result-list">
          <h3>Search Result list</h3>
          <SearchResult data={event} name="event" button={true} />
          <SearchResult data={log} name="log" />
          <SearchResult data={monitor} name="monitor" />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  event: state.search.event,
  log: state.search.log,
  monitor: state.search.monitor,
  gettingData: state.search.gettingData,
})

export default Form.create({ name: 'search_form' })(connect(mapStateToProps)(ListSearch))
