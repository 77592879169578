import React from 'react'
import { Table, Button, Input, Icon, Tooltip } from 'antd'
import { ColumnProps } from 'antd/lib/table'

import { Path, PLocation, PathState } from '../../../_types'
import { HoverText, StateTag } from '../../../common'

export interface PathListProps {
  plocations: PLocation[]
  onCreate: () => void
  onEdit: (item: Path) => void
  loading: boolean
  t: any
}

export class PathList extends React.PureComponent<PathListProps> {
  getColumnSearchProps = (dataIndex: string): ColumnProps<Path> => {
    const { t } = this.props

    const onInputChange = (e: any, setSelectedKeys: any) => {
      setSelectedKeys && setSelectedKeys(e.target.value ? [e.target.value] : [])
    }
    const onReset = (clearFilters: ((selectedKeys: string[]) => void) | undefined) => {
      clearFilters && clearFilters([])
    }
    const onSearch = (selectedKeys: React.ReactText[] | undefined, confirm: (() => void) | undefined) => {
      confirm && confirm()
    }

    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="trips-filter-dropdown">
          <Input
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys ? selectedKeys[0] : ''}
            onChange={e => onInputChange(e, setSelectedKeys)}
            onPressEnter={() => onSearch(selectedKeys, confirm)}
            style={{ width: 160, marginBottom: '0.5rem', display: 'block' }}
          />
          <Button onClick={() => onReset(clearFilters)} size="small" style={{ width: 75, marginRight: 8 }}>
            {t('btn.reset')}
          </Button>
          <Button type="primary" onClick={() => onSearch(selectedKeys, confirm)} size="small" style={{ width: 75 }}>
            {t('btn.search')}
          </Button>
        </div>
      ),
      filterIcon: filtered => (
        <Icon
          type="search"
          style={{
            width: '2rem',
            fontSize: '0.9rem',
            color: filtered ? '#138D75' : undefined,
          }}
        />
      ),
    }
  }

  getPLocation = (plocationId: string) => {
    const { plocations, t } = this.props

    const plocation = plocations.find(plocation => plocation.id === plocationId)

    return <HoverText text={plocation ? plocation.name : t('tip.unknownPLocation')} content={plocationId} prefix="ID" />
  }

  render() {
    const { loading, onCreate, onEdit, t, ...tableProps } = this.props

    const columns: ColumnProps<Path>[] = [
      {
        key: 'name',
        dataIndex: 'name',
        title: t('name'),
        render: (text: string, record) => <HoverText content={record.id} text={text} prefix="ID" />,
        ...this.getColumnSearchProps('name'),
      },
      {
        key: 'startId',
        dataIndex: 'startId',
        title: t('startId'),
        render: this.getPLocation,
      },
      {
        key: 'destinationId',
        dataIndex: 'destinationId',
        title: t('destinationId'),
        render: this.getPLocation,
      },
      {
        key: 'pointIds',
        dataIndex: 'pointIds',
        title: t('points'),
        render: (text: string[]) =>
          text ? (
            <ol className="paths-list-stops">
              {text.map((pointId, index) => (
                <li key={index} className="paths-list-stop">
                  {this.getPLocation(pointId)}
                </li>
              ))}
            </ol>
          ) : null,
      },
      {
        key: 'state',
        dataIndex: 'state',
        title: t('state'),
        render: (text: PathState) => <StateTag state={text} type="path" />,
      },
      {
        key: 'actions',
        title: (
          <Tooltip title={t('tooltip.createPath')} mouseEnterDelay={0.5}>
            <Button type="default" size="small" icon="plus" onClick={onCreate} />
          </Tooltip>
        ),
        render: (text, record) => (
          <Button type="link" size="small" onClick={() => onEdit(record)}>
            {t('btn.edit')}
          </Button>
        ),
      },
    ]

    return <Table {...tableProps} columns={columns} rowKey="id" loading={loading} scroll={{ x: true }} />
  }
}
