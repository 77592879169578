import React from 'react'
import './Resources.scss'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

type ResourcesProps = WithTranslation

class Resources extends React.Component<ResourcesProps, {}> {
  render() {
    const { t } = this.props

    return (
      <div className="resources">
        <div className="resources-panel">
          <header className="resources-panel-header">
            <h2>{t('resourcesTitle')}</h2>
          </header>
          <div className="resources-panel-content resources-grid">
            <h3 className="resources-grid-section">{t`tools`}</h3>
            <h4 className="resources-grid-name">Allride-Viz</h4>
            <Link
              to={{
                pathname: '/viz',
              }}
              target="_blank"
              className="resources-grid-link"
            >
              https://command.allride-ai.cn/viz/
            </Link>
            <h4 className="resources-grid-name">Allride-DataV</h4>
            <Link
              to={{
                pathname: '/datav',
              }}
              target="_blank"
              className="resources-grid-link"
            >
              https://command.allride-ai.cn/datav/
            </Link>
            <h4 className="resources-grid-name">All-Label</h4>
            <a
              className="resources-grid-link"
              href="https://label.allride-ai.cn/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://label.allride-ai.cn/
            </a>
            <h3 className="resources-grid-section">{t`internalPlatforms`}</h3>
            <h4 className="resources-grid-name">GitLab</h4>
            <a
              className="resources-grid-link"
              href="https://gitlab.allride-ai.cn/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://gitlab.allride-ai.cn/
            </a>
            <h4 className="resources-grid-name">Confluence</h4>
            <a
              className="resources-grid-link"
              href="http://confluence.allride-ai.cn:8090/"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://confluence.allride-ai.cn:8090/
            </a>
            <h4 className="resources-grid-name">Jenkins</h4>
            <a
              className="resources-grid-link"
              href="https://jenkins.allride-ai.cn/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://jenkins.allride-ai.cn/
            </a>
            <h4 className="resources-grid-name">Jira</h4>
            <a
              className="resources-grid-link"
              href="http://jira.allride-ai.cn:8080/"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://jira.allride-ai.cn:8080/
            </a>
            <h4 className="resources-grid-name">Artifactory</h4>
            <a
              className="resources-grid-link"
              href="https://artifactory.allride-ai.cn/artifactory/webapp/#/home"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://artifactory.allride-ai.cn/
            </a>
            <h4 className="resources-grid-name">{t`vehicleMonitoring`}</h4>
            <a
              className="resources-grid-link"
              href="http://monitor.allride-ai.cn:30361"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://monitor.allride-ai.cn:30361/
            </a>
            <h3 className="resources-grid-section">{t`externalPlatforms`}</h3>
            <h4 className="resources-grid-name">Aliyun</h4>
            <a
              className="resources-grid-link"
              href="https://homenew.console.aliyun.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://homenew.console.aliyun.com/
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation('resources')(Resources)
