import React from 'react'
import './Users.scss'
import { connect } from 'dva'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Dispatch } from 'redux'

import { UsersReducerState, DvaState, User } from '../../_types'
import { UserList, UserModal } from './components'

interface UsersProps extends WithTranslation, UsersReducerState {
  dispatch: Dispatch
}

class Users extends React.Component<UsersProps> {
  get userlistProps() {
    const { dispatch, users, listingUsers, t } = this.props

    return {
      dataSource: users,
      loading: listingUsers,
      t,
      onCreate: () => {
        dispatch({
          type: 'users/showModal',
          payload: {
            modalType: 'create',
          },
        })
      },
      onEdit: (item: User) => {
        dispatch({
          type: 'users/showModal',
          payload: {
            modalType: 'update',
            currentItem: item,
          },
        })
      },
    }
  }

  get userModalProps() {
    const { dispatch, currentItem, modalVisible, modalType, modifyingUser, t } = this.props

    return {
      item: modalType === 'create' ? undefined : currentItem,
      visible: modalVisible,
      modalType,
      title: t(`${modalType}User`),
      loading: modifyingUser,
      destroyOnClose: true,
      maskClosable: true,
      centered: true,
      t,
      onModify: (data: any, action: string) => {
        dispatch({
          type: `users/${action}`,
          payload: data,
        })
      },
      onCancel: () => {
        dispatch({
          type: 'users/hideModal',
        })
      },
    }
  }

  render() {
    const { t } = this.props

    return (
      <div className="users">
        <div className="users-inner">
          <header className="users-header">
            <h2>{t('userList')}</h2>
          </header>
          <UserList {...this.userlistProps} />
          <UserModal {...this.userModalProps} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: DvaState) => ({
  ...state.users,
})

export default connect(mapStateToProps)(withTranslation('users')(Users))
