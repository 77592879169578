export const apiPrefix = '/api/v1'

export const tagColorPool = [
  'magenta',
  'red',
  'volcano',
  'orange',
  'gold',
  'lime',
  'green',
  'cyan',
  'blue',
  'geekblue',
  'purple',
]

const keyNameMap: any = {
  descend: -1,
  ascend: 1,
  'time.createdAt': 'CreatedAt',
  'time.updatedAt': 'UpdatedAt',
  'audit.createdBy': 'CreatedBy',
  'audit.updatedBy': 'UpdatedBy',
}

export const getConstMapValue = (key: string) => {
  if (!key || typeof key !== 'string') {
    return undefined
  }
  if (key in keyNameMap) {
    return keyNameMap[key]
  }
  return key.charAt(0).toUpperCase() + key.slice(1)
}

const dataSourceType:any = {
  rsu:'路端',
  car:'车端'
}
const area:any = {
  suzhoubei:'苏州北',
  jiangning:'江宁',
  nanjing:'南京'
}
const sourceTable:any = {
  scenario_cross_road:'行人横穿',
  scenario_conflict_interaction:'综合交互'
}

export const getScenarioTitle = (title: string) => {
  let titleArr = title.split('-')
  return `${dataSourceType[titleArr[0]]}-${area[titleArr[1]]}-${sourceTable[titleArr[2]]}场景库`
}
export const gData:any = [
  {
    "title": "交互",
    "key": "interactive_class",
    "children": [
      {
        "title": "nudge",
        "key": "nudge"
      },
      {
        "title": "通过障碍物",
        "key": "通过障碍物"
      },
      {
        "title": "会车",
        "key": "会车"
      },
      {
        "title": "cut-out",
        "key": "cut-out"
      },
      {
        "title": "cut-in",
        "key": "cut-in"
      },
      {
        "title": "变道跟车",
        "key": "变道跟车"
      },
      {
        "title": "交叉穿行",
        "key": "交叉穿行"
      },
      {
        "title": "前方跟车",
        "key": "前方跟车"
      },
      {
        "title": "后方跟车",
        "key": "后方跟车"
      },
      {
        "title": "逆行",
        "key": "逆行"
      }
    ]
  },
  {
      "title": "感知",
      "key": "perception_class",
      "children": [
        {
          "title": "轮廓跳变",
          "key": "shape_unsteady"
        },
        {
          "title": "运动朝向跳变",
          "key": "heading_unsteady"
        },
        {
          "title": "速度跳变",
          "key": "velocity_unsteady"
        },
        {
          "title": "物体闪现",
          "key": "sudden_appear"
        }
      ]
  }
];
export const secondaryClassification = [
  {
    "title": "nudge",
    "key": "nudge"
  },
  {
    "title": "通过障碍物",
    "key": "通过障碍物"
  },
  {
    "title": "会车",
    "key": "会车"
  },
  {
    "title": "cut-out",
    "key": "cut-out"
  },
  {
    "title": "cut-in",
    "key": "cut-in"
  },
  {
    "title": "变道跟车",
    "key": "变道跟车"
  },
  {
    "title": "交叉穿行",
    "key": "交叉穿行"
  },
  {
    "title": "前方跟车",
    "key": "前方跟车"
  },
  {
    "title": "后方跟车",
    "key": "后方跟车"
  },
  {
    "title": "逆行",
    "key": "逆行"
  },
  {
    "title": "轮廓跳变",
    "key": "shape_unsteady"
  },
  {
    "title": "运动朝向跳变",
    "key": "heading_unsteady"
  },
  {
    "title": "速度跳变",
    "key": "velocity_unsteady"
  },
  {
    "title": "物体闪现",
    "key": "sudden_appear"
  }
]
export const simulationMainEventType = [
  {
    "title": "碰撞",
    "key": "collision"
  },
  {
    "title": "卡住",
    "key": "standstill"
  },
  {
    "title": "急刹",
    "key": "emergency_brake"
  },
  {
    "title": "planning曲率异常",
    "key": "curvature_abnormal"
  },
  {
    "title": "急加速",
    "key": "rapid_acc"
  },
  {
    "title": "距离过近",
    "key": "too_close"
  },
  {
    "title": "飘移",
    "key": "not_under_control"
  },
  {
    "title": "轻微卡住",
    "key": "little_standstill"
  },
  {
    "title": "轻微急刹",
    "key": "little_emergency_brake"
  },
  {
    "title": "未能达到终点",
    "key": "not_completed"
  }
]
export const roadStructureTypes = [
  {
    "title": "丁字路口",
    "key": "T-intersection"
  },  
  {
    "title": "十字路口",
    "key": "crossroads"
  }
]
export const scenarioTranslate = (key:any,arr:any) => {
  let title = key
  if(key){
    arr.forEach((item:any) => {
      if(item.key === key) {
        title =  item.title
      }
    });
  }
  return title
}
