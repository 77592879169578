import React from 'react'
import './Tags.scss'
import { connect } from 'dva'
import { DvaState, TagsReducerState, Tag, ModalState } from '../../_types'
import { Dispatch } from 'redux'
import { withTranslation, WithTranslation } from 'react-i18next'

import TagList from './components/List'
import Modal from './components/Modal'

interface TagsProps extends WithTranslation, TagsReducerState, ModalState<Tag> {
  dispatch: Dispatch
}

class Tags extends React.PureComponent<TagsProps> {
  get listProps() {
    const { list, dispatch, t } = this.props
    return {
      dataSource: list,
      t: t,
      onCreate: () => {
        dispatch({
          type: 'tags/showModal',
          payload: {
            modalType: 'create',
            currentItem: {},
          },
        })
      },
      onEdit: (item: Tag) => {
        dispatch({
          type: 'tags/showModal',
          payload: {
            modalType: 'update',
            currentItem: item,
          },
        })
      },
      onDelete: (tag: Tag) => {
        dispatch({
          type: 'tags/delete',
          payload: {
            id: tag.id,
          },
        })
      },
    }
  }

  get modalProps() {
    const { dispatch, loading, t, currentItem, modalVisible, modalType } = this.props

    return {
      item: modalType === 'create' ? ({} as Tag) : currentItem,
      visible: modalVisible,
      destroyOnClose: true,
      maskClosable: false,
      modalType: modalType,
      title: `${modalType === 'create' ? t`Create Tag` : t`Update Tag`}`,
      centered: true,
      t: t,
      onOk: (data: Tag) => {
        dispatch({
          type: `tags/${modalType}`,
          payload: {
            tag: data,
          },
        })
      },
      onCancel: () => {
        dispatch({
          type: 'tags/hideModal',
        })
      },
    }
  }

  render() {
    return (
      <div className="tags">
        <div className="tags-panel">
          <TagList {...this.listProps} />
          <Modal {...this.modalProps} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: DvaState) => state.tags

export default connect(mapStateToProps)(withTranslation('tags')(Tags))
