import modelExtend from 'dva-model-extend'
import lodash from 'lodash'
import { message } from 'antd'
import i18next from 'i18next'

import { pathMatchRegexp } from '../_helpers'
import { VEventSearch, VEventsReducerState, VEventModel, VEventPool } from '../_types'
import api from '../_dva_services'
import { pageState, pageModel, modalModel, modalState, sortQuery, searchQuery, SearchKeys } from '../_utils'
import { ONE_YEAR } from '../_constants'

const initSearch: VEventSearch = {
  productVersion: '',
  vehicleName: '',
  tags: '',
  sort: {
    field: 'time.createdAt',
    order: 'descend',
  },
  time: {
    createdAt: ['-1', (Date.now() + ONE_YEAR).toString()],
    updatedAt: ['-1', (Date.now() + ONE_YEAR).toString()],
  },
  audit: {
    createdBy: '',
    updatedBy: '',
  },
  startTime: ['-1', (Date.now() + ONE_YEAR).toString()],
  endTime: ['-1', (Date.now() + ONE_YEAR).toString()],
  text: '',
  keys: ['Id', 'ProductVersion', 'VehicleName', 'Description', 'Tags'],
  ifBag: true,
}

const genSearchKeys = (multiMatchKeys: string[] | null, existKeys: string[] | null): SearchKeys => {
  const keys: SearchKeys = {
    matchKeys: ['productVersion', 'vehicleName', 'tags', 'audit.createdBy'],
    rangeKeys: ['startTime', 'endTime', 'time.createdAt', 'time.updatedAt'],
  }

  if (multiMatchKeys && multiMatchKeys.length) {
    keys.multiMatchKeys = multiMatchKeys
  }

  if (existKeys && existKeys.length) {
    keys.existKeys = existKeys
  }

  return keys
}

const vEventPool: VEventPool = {
  vehicleAutoComplete: {},
  tags: [],
}

const initState: VEventsReducerState = {
  list: [],
  search: initSearch,
  listingVEvents: false,
  pool: vEventPool,
  ...pageState,
  ...modalState,
}

const vEventsModel: VEventModel = modelExtend<VEventModel>(pageModel, modalModel, {
  namespace: 'vevents',
  state: initState,
  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(location => {
        if (pathMatchRegexp('/vevents', location.pathname)) {
          dispatch({ type: 'query' })
          dispatch({ type: 'queryPool' })
        }
      })
    },
  },
  reducers: {
    reset(state) {
      return {
        ...state,
        search: initSearch,
      }
    },

    listRequest(state, {}) {
      return {
        ...state,
        listingVEvents: true,
      }
    },

    listSuccess(state: VEventsReducerState) {
      return {
        ...state,
        listingVEvents: false,
      }
    },

    listFail(state: VEventsReducerState) {
      return {
        ...state,
        listingVEvents: false,
      }
    },

    // modify = create / update / delete
    modifyRequest(state, {}) {
      return {
        ...state,
        modifying: true,
      }
    },

    modifySuccess(state: VEventsReducerState) {
      return {
        ...state,
        modifying: false,
      }
    },

    modifyFail(state: VEventsReducerState) {
      return {
        ...state,
        modifying: false,
      }
    },
  },
  effects: {
    *queryPool({ payload = {} }, { call, put }) {
      const data = yield [call(api.listTag, payload), call(api.listVehicles, payload)]
      if (data) {
        yield put({
          type: 'updateState',
          payload: {
            pool: {
              tags: data[0].success && data[0].tags.map((t: any) => t.name).sort(),
              vehicleAutoComplete: data[1].success && lodash.keyBy(data[1].vehicles, 'name'),
            },
          },
        })
      }
    },

    *query({ payload = {} as any }, { call, put, select }) {
      const { search, pagination } = yield select((state: any) => state.vevents)
      const { sort } = search

      payload = {
        search: search,
        pagination: pagination,
        sort: sort,
        ...payload,
      }
      const cloneSort = lodash.pick(payload.sort, ['field', 'order'])
      const existKeys = search.ifBag ? ['BagNames'] : null
      const searchKeys = genSearchKeys(search.keys, existKeys)
      payload.sort = sortQuery(payload.sort)
      searchKeys.multiMatchKeys = search.keys
      payload.search = searchQuery(payload.search, searchKeys)
      payload.pagination = lodash.pick(payload.pagination, ['current', 'total', 'pageSize'])

      try {
        yield put({ type: 'listRequest' })

        const data = yield call(api.listVEvent, payload)

        yield put({
          type: 'updateState',
          payload: {
            list: data.events,
            pagination: {
              ...pagination,
              ...data.pagination,
            },
            search: {
              ...search,
              sort: cloneSort,
            },
          },
        })

        yield put({ type: 'listSuccess' })
      } catch (e) {
        yield put({ type: 'listFail' })
      }
    },

    *create({ payload = {} }, { call, put }) {
      yield put({ type: 'modifyRequest' })

      try {
        yield call(api.createVEvent, payload)

        yield put({ type: 'modifySuccess' })
        yield put({ type: 'reset' })
        yield put({ type: 'query' })
        yield put({ type: 'hideModal' })

        message.success(i18next.t('msg.createSuccessful'))
      } catch (e) {
        yield put({ type: 'modifyFail' })

        message.error(i18next.t('msg.createFailed'))
      }
    },

    *update({ payload = {} }, { call, put }) {
      yield put({ type: 'modifyRequest' })

      try {
        yield call(api.updateVEvent, payload)

        yield put({ type: 'modifySuccess' })
        yield put({ type: 'query' })

        message.success(i18next.t('msg.updateSuccessful'))
      } catch (e) {
        yield put({ type: 'modifyFail' })

        message.error(i18next.t('msg.updateFailed'))
      }
    },

    *delete({ payload = {} }, { call, put }) {
      yield put({ type: 'modifyRequest' })

      try {
        yield call(api.deleteVEvent, payload)

        yield put({ type: 'modifySuccess' })
        yield put({ type: 'query' })
        yield put({ type: 'hideModal' })

        message.success(i18next.t('msg.deleteSuccessful'))
      } catch (e) {
        yield put({ type: 'modifyFail' })

        message.error(i18next.t('msg.deleteFailed'))
      }
    },
  },
})

export default vEventsModel
