import modelExtend from 'dva-model-extend'
import { routerRedux } from 'dva/router'

import { pathMatchRegexp } from '../_helpers'
import { VEvent, VEventDetailReducerState, VEventDetailModel, GPS } from '../_types'
import { model } from '../_utils'

import api from '../_dva_services'

const gps: GPS = {
  latitude: 0,
  longitude: 0,
  altitude: 0,
}

const initvevent: VEvent = {
  tags: [],
  bagNames: [],
  productVersion: '',
  vehicleName: '',
  description: '',
  time: {
    createdAt: '',
    updatedAt: '',
  },
  audit: {
    createdBy: '',
    updatedBy: '',
  },
  startTime: '',
  endTime: '',
  uuid: '',
  gps: gps,
}

const initState: VEventDetailReducerState = {
  event: initvevent,
}

const vEventDetailModel: VEventDetailModel = modelExtend<VEventDetailModel>(model, {
  namespace: 'veventsDetail',
  state: initState,
  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(location => {
        const match = pathMatchRegexp('/vevents/:id', location.pathname)
        if (match) {
          dispatch({ type: 'query', payload: { id: match[1] } })
        }
      })
    },
  },
  effects: {
    *query({ payload = {} as any }, { call, put }) {
      try {
        const data = yield call(api.getVEvent, payload)
        if (data) {
          yield put({ type: 'updateState', payload: { event: data.vehicleEvent } })
        }
      } catch (e) {
        yield put(routerRedux.push('/vevents'))
      }
    },
  },
})

export default vEventDetailModel
