import React from 'react'
import { AutoComplete, Button, Input, Form, DatePicker, Collapse, Icon, Divider } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import moment from 'moment'
import { VMapSearch, VMapePool } from '../../../_types'
import { epochToMoment, momentToEpoch} from '../../../_helpers'
import { size } from 'lodash'

const { RangePicker } = DatePicker

interface HeaderProps {
  item: VMapSearch
  pool: VMapePool
  t: any
  onSearch: () => void
  onChange: (field: any) => void
  onClear: () => void// 
}

interface SearchFromProps extends HeaderProps, FormComponentProps {
 
}

const SearchForm = Form.create<SearchFromProps>({
  mapPropsToFields(props) {
    return {
      vehicleName: Form.createFormField({ value: props.item.vehicleName }),
      'time.createdAt': Form.createFormField({
        value:
          [epochToMoment(props.item.time.createdAt[0]), epochToMoment(props.item.time.createdAt[1])],
      }),
    }
  },
  onFieldsChange(props, fields) {
    if (fields.time) {
      for (let k in fields.time) {
        fields.time[k] = fields.time[k].value.map((i: any) => momentToEpoch(i))
      }
      fields.time = {
        ...props.item.time,
        ...fields.time,
      }
    }

    props.onChange(fields)
  },
})(({ form, t, pool, onSearch, onClear}: SearchFromProps) => {
  const { getFieldDecorator } = form
  const onPressEnter = (e: React.KeyboardEvent) => {
    e.preventDefault()
    onSearch()
  }
  const transformArr = (arr:any[])=>{
    return arr.map((item:any)=>{
      return {value:item.id,text:item.name}
    })
  }
  const rangeDisableDate = (currentDate:any)=>{
    const onlyToday = currentDate && currentDate > moment().endOf('day')
    return onlyToday
  }
  return (
    <Form layout="inline" onSubmit={onSearch}>
      <Form.Item label={t('vehicleName')}>
        {getFieldDecorator('vehicleName')(<AutoComplete dataSource={Object.keys(pool.vehicleAutoComplete)} />)}
      </Form.Item>
      <Form.Item label={t('timeRange')}>
        {getFieldDecorator('time.createdAt')(
          <RangePicker disabledDate={rangeDisableDate} showTime={{ defaultValue: [moment('00:00:00', 'HH:mm:ss')], }} format="YYYY-MM-DD HH:mm:ss"
          />,
        )}
      </Form.Item>

      <Form.Item>
        <Button type="primary" onClick={onSearch}>
          {t('btn.search')}
        </Button>
        <Button style={{ marginLeft: '0.5rem' }} onClick={onClear}>
          {t('btn.reset')}
        </Button>
      </Form.Item>
      <Form.Item>
        <p style={{fontSize:'12px',color:'#faad14'}}>（提示：数据量大，暂支持查询展示一天数据）</p>
      </Form.Item>
    </Form>
  )
})

class Header extends React.PureComponent<HeaderProps> {
  constructor(props: any) {
    super(props)
    this.state = {
      
    }
  }

  render() {
    return <SearchForm {...this.props}/>
  }
}

export default Header
