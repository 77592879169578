export default {
  common: {
    // 故障统计
    modeType: 'module',
    fault:'Fault statistics',
    vehicleState:'vehicle State',
    faultTime:'Fault time',
    faultConditions: 'fault conditions',
    faultTotalDuration: 'fault totalDuration',
    issues: 'issues',
    fixTime:'fixTime',
    faultDuration: 'faultDuration',
    faultStates:'faultStates',
    priority: 'priority',
    followUpPerson: 'followUpPerson',

    normal: 'Normal',
    faults: 'Fault',
    processing:'processing',
    toBeVerified:'To be verified',
    resolved: 'resolved',
    high: 'high',
    middle: 'middle',
    low: 'low',

    target: 'Target',
    eventsType: 'event type',

    routeName: 'route name',
// 事件
extraRouteName: 'extra_route_name',
eventsBag: 'eventsBag',
dealState: 'dealState',
issueNumber: 'issueNumber',
issueLink: 'issueLink',
problemDescription: 'problemDescription',
qa_result:'qa_result',
simulation_info:'simulation_info',
bag_oss_path:'bag_oss_path',
log_oss_path:'log_oss_path',
event_time: 'event_time',
eventTimeRange: 'timeRange',
vehicle_id: 'vehicle Id',
hmi_username: 'hmi_username',
event_category: 'event_category',

mileage_type: 'mileage_type',
event_tag: 'event_tag',
bag_mhd_path: 'bag_mhd_path',
version_config: 'version_config',
peak_type: 'peak_type',
log_mhd_path: 'log_mhd_path',
jira_belonging_module: 'jira_belonging_module',
latitude_longitude: 'latitude_longitude',
event_id: 'event_id',
img_oss_path: 'img_oss_path',
event_second: 'event_second',
description: 'description',
simulation_result: 'simulation_result',
simulation_scenario_id: 'simulation_scenario_id',
simulation_job_id: 'simulation_job_id',
simulation_task_id: 'simulation_task_id',
curr_week:'curr_week',
curr_month:'curr_month',
curr_quarter: 'curr_quarter',
product_dir: 'product_dir',
simulation_bag_path: 'simulation_bag_path',

    vehicleId:'vehicleId',
    category:'category',
    name: 'Name',
    invitations: 'Invitations',
    plocations: 'PLocations',
    plocation: 'PLocation',
    districtId: 'District ID',
    district: 'District',
    longitude: 'Longitude',
    latitude: 'Latitude',
    altitude: 'Altitude',
    position: 'Position',
    orientation: 'Orientation',
    state: 'State',
    status: 'Status',
    startId: 'Start ID',
    start: 'Start',
    destinationId: 'Dest ID',
    destination: 'Destination',
    paths: 'Paths',
    path: 'Path',
    points: 'Points',
    point: 'Point',
    createdAt: 'Created At',
    accel: 'Accel',
    speed: 'Speed',
    mode: 'Mode',
    radius: 'Radius',
    baseLon: 'Base Longitude',
    baseLat: 'Base Latitude',
    baseX: 'Base X',
    baseY: 'Base Y',
    baseZ: 'Base Z',
    mapRegion: 'Map Region',
    type: 'Type',
    vin: 'VIN',
    license: 'License',
    make: 'Make',
    model: 'Model',
    color: 'Color',
    timestamp: 'Timestamp',
    time: 'Time',
    pose: 'Pose',
    twist: 'Twist',
    linear: 'Linear',
    angular: 'Angular',
    operationMode: 'Op Mode',
    planningVersion: 'Pl Version',
    planningState: 'Pl State',
    user: 'User',
    admin: 'Admin',
    vehicle: 'Vehicle',
    vehicles: 'Vehicles',
    phone: 'Phone',
    email: 'Email',
    address: 'Address',
    password: 'Password',
    vehicleName: 'Vehicle Name',
    productVersion: 'Prod Version',
    startTimeRange: 'Start Time Range',
    endTimeRange: 'End Time Range',
    keywords: 'Keywords',
    description: 'Description',
    size: 'Size',
    startTime: 'Start Time',
    endTime: 'End Time',
    tags: 'Tags',
    opMode: {
      normal: 'Normal',
      demo: 'Demo',
    },
    userType: {
      normal: 'Normal',
      invited: 'Invited',
      guest: 'Guest',
    },
    basic: 'Basic',
    spec: 'Spec',
    credential: 'Credential',
    security: 'Security',
    account: 'Account',
    accessKey: 'Access Key',
    accessSecret: 'Access Secret',
    btn: {
      loadMore: 'Load More',
      delete: 'Delete',
      create: 'Create',
      edit: 'Edit',
      search: 'Search',
      reset: 'Reset',
      update: 'Update',
      viz: 'Viz',
      download: 'Download',
      advance: 'Advance',
    },
    msg: {
      createSuccessful: 'Create Sucessful',
      createFailed: 'Create Failed',
      deleteSuccessful: 'Delete Sucessful',
      deleteFailed: 'Delete Failed',
      updateSuccessful: 'Update Successful',
      updateFailed: 'Update Failed',
      startSuccessful: 'Start Successful',
      startFailed: 'Start Failed',
      submitSuccessful: 'Submit Successful',
      submitFailed: 'Submit Failed',
      resetSuccessful: 'Reset Successful',
      resetFailed: 'Reset Failed',
      getSuccessful: 'Get Successful',
      getFailed: 'Get Failed',
    },
    tip: {
      noEmpty: '{{field}} can not be empty',
      noData: 'No Data',
      required: 'Required',
      unknownDistrict: 'Unknown District',
      unknownPLocation: 'Unknown PLocation',
      unknownVehicle: 'Unkown Vehicle',
      noState: 'No State',
    },
  },
  scenario: {
    dataSourceType: 'dataSource',
    area: 'area',
    scenarioPrimaryClassification: 'scenarioPrimary',
    scenarioSecondary: 'scenarioSecondary',
    scenarioTrtiary: 'scenarioTrtiary',
    caseType: 'caseType',
    interactive:'interactive',
    perception:'perception',
    total:'total',
    count: 'count',
  },
  login: {
    usernameMsg: 'Username cannot be empty',
    passwordMsg: 'Password cannot be empty',
    message: {
      unkown: 'Unkown error',
      noUser: 'User does not exist. Please make sure the username or email is correct.',
      wrongPwd: 'The passoword is incorrect.',
    },
    btn: {
      login: 'Login',
      logout: 'Logout',
    },
    plhd: {
      username: 'Username / Email',
      password: 'Password',
    },
    loginFailed: 'Login failed',
    logo: 'ALLRIDE.AI',
  },
  layout: {
    dashboard: 'Dashboard',
    monitor: 'Monitor',
    locations: 'Locations',
    plocations: 'PLocations',
    paths: 'Paths',
    accounts: 'Accounts',
    admins: 'Admins',
    users: 'Users',
    vehicles: 'Vehicles',
    trips: 'Trips',
    districts: 'Districts',
    roadTests: 'Road Tests',
    data: 'Data',
    bags: 'Bags',
    events: 'Events',
    resources: 'Resources',
    datav: 'DataV',
    vmaps:'Vehicle information',
    scenario:'Scenario',
    scenarioSurvey:'scenarioSurvey',
    scenarioList:'scenarioList',
    btn: {
      logout: 'Logout',
    },
    tooltip: {
      logout: 'Logout',
      expand: 'Expand',
      collapse: 'Collapse',
    },
  },
  dashboard: {
    statisticsTitle: 'Statistics',
  },
  resources: {
    resourcesTitle: 'Resources',
    tools: 'Tools',
    internalPlatforms: 'Internal Platforms',
    externalPlatforms: 'External Platforms',
    vehicleMonitoring:'Vehicle Status Monitoring'
  },
  monitor: {
    tip: {
      track: 'Track',
      initView: 'Init View',
      fitPLocations: 'Fit $t(plocations)',
      fitVehicles: 'Fit $t(vehicles)',
    },
    moveDisabled: 'Moving disabled',
    drivingMode: {
      mode_auto: 'Auto',
      mode_manual: 'Manual',
    },
    unkownVersion: 'Unkown Version',
    trafficLayer: 'Traffic Layer',
    fenceLayer: 'Fence Layer',
  },
  admins: {
    adminList: '$t(admin) List',
    createAdmin: 'Create $t(admin)',
    updateAdmin: 'Update $t(admin)',
    name: 'Username',
    roles: 'Roles',
    tooltip: {
      addAdmin: 'Add $t(admin)',
    },
    tip: {
      invalidEmail: 'Email must end with "allride.ai" or "kaizhe.ai"',
      invalidPwd: 'Minimum 8 characters, at least one letter and one number',
    },
  },
  users: {
    userList: '$t(user) List',
    createUser: 'Create $t(user)',
    updateUser: 'Update $t(user)',
    name: 'Username',
    tip: {
      invalidEmail: 'Invalid email address',
      invalidPwd: 'Minimum 6, maximum 20 characters',
    },
    trips: 'Trips',
    tripStatus: {
      SUBMITTED: 'Submitted',
      PICKING_UP: 'Picking Up',
      WAITING: 'Waiting',
      ON_ROUTE: 'On Route',
      COMPLETED: 'Completed',
      CANCELED: 'Canceled',
    },
    tooltip: {
      createUser: 'Create $t(user)',
    },
  },
  vehicles: {
    vehicleList: 'Vehicle List',
    createVehicle: 'Add Vehicle',
    updateVehicle: 'Update Vehicle',
    tooltip: {
      addVehicle: 'Add Vehicle',
    },
    vehicleState: {
      inactive: 'Inactive',
      available: 'Available',
      inuse: 'Inuse',
    },
  },
  plocations: {
    plocationList: 'PLocation List',
    createPLocation: 'Create PLocation',
    updatePLocation: 'Update PLocation',
    basic: 'Basic',
    parameters: 'Parameters',
    tooltip: {
      createPLocation: 'Create PLocation',
    },
  },
  paths: {
    pathList: '$t(path) List',
    createPath: 'Create $t(path)',
    updatePath: 'Update $t(path)',
    tooltip: {
      createPath: 'Create $t(path)',
    },
    btn: {
      addStop: 'Add $t(stop)',
    },
  },
  stateTag: {
    vehicle: {
      inactive: 'Inactive',
      available: 'Available',
      inuse: 'Inuse',
    },
    plocation: {
      deprecated: 'Deprecated',
      active: 'Active',
      inactive: 'Inactive',
      nonstop: 'Nonstop',
    },
    path: {
      active: 'Active',
      inactive: 'Inactive',
    },
    userStatus: {
      active: 'Active',
      inactive: 'Inactive',
    },
  },
  trips: {
    tripList: 'Trips List',
    updateTrip: 'Update Trip',
    userId: 'User ID',
    vehicleId: 'Vehicle ID',
    count: 'Count',
    feedback: 'Feedback',
    cert: 'Cert',
    createdAt: 'Created At',
    startedAt: 'Started At',
    completedAt: 'Completed At',
    serialNum: 'Serial Num',
  },
  roadTests: {
    roadTestList: 'RoadTest List',
    updateRoadTest: 'Update RoadTest',
    vehicleId: 'Vehicle ID',
    driverId: 'Driver ID',
    passengers: 'Passengers',
  },
  districts: {
    districtsList: '$t(district) List',
    createDistrict: 'Add $t(district)',
    updateDistrict: 'Update $t(district)',
    tooltip: {
      createDistrict: 'Create $t(district)',
    },
  },
  invitations: {
    invitationsListTitle: '$t(invitations) List',
  },
  bags: {
    oldName: '$t(name)(Old)',
    oldPath: '$t(path)(Old)',
    createBag: 'Add Bag',
    updateBag: 'Update Bag',
    tooltip: {
      addBag: 'Add Bag',
    },
  },
  vevents: {
    createVEvent: 'Add Event',
    updateVEvent: 'Update Event',
    bagNames: 'Bag Names',
    tooltip: {
      addVEvent: 'Add Event',
    },
  },
}
