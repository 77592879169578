import React from 'react'
import { Modal, Form, Select } from 'antd'
import { FormComponentProps } from 'antd/lib/form/Form'

import '../Trips.scss'
import { ModalType, Trip } from '../../../_types'

interface TripModalProps extends FormComponentProps {
  onOk: (item: Trip) => void
  onCancel: () => void
  modalType: ModalType
  item: Trip
  t: any
}

const { Option } = Select

const formItemLayout = {
  labelAlign: 'left' as 'left',
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

class TripModal extends React.PureComponent<TripModalProps> {
  handleOk = () => {
    const { item = {} as Trip, onOk, form } = this.props
    const { validateFields, getFieldsValue } = form

    validateFields(errors => {
      if (errors) {
        return
      }
      const data: any = {
        ...getFieldsValue(),
        id: item.id,
      }
      onOk(data)
    })
  }

  render() {
    const { item = {} as Trip, onOk, form, modalType, t, ...modalProps } = this.props
    const { getFieldDecorator } = form
    const statusOptions = [
      'NIL_TRIP_STATUS',
      'SUBMITTED',
      'VALID',
      'PICKING_UP',
      'WAITING_FOR_USER',
      'READY_FOR_USER',
      'STARTING_FOR_USER',
      'WAITING',
      'READY',
      'STARTING',
      'WAITING_FOR_PARK',
      'READY_FOR_PARK',
      'STARTING_FOR_PARK',
      'ON_ROUTE',
      'COMPLETED',
      'CANCELED',
    ].map((opt, k) => (
      <Option key={k} value={opt}>
        {opt}
      </Option>
    ))

    return (
      <Modal {...modalProps} onOk={this.handleOk}>
        <Form {...formItemLayout}>
          <Form.Item label={t('status')}>
            {getFieldDecorator('status', {
              initialValue: item && item.status,
            })(<Select>{statusOptions}</Select>)}
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default Form.create<TripModalProps>()(TripModal)
