import modelExtend from 'dva-model-extend'
import lodash from 'lodash'
import { message } from 'antd'
import i18next from 'i18next'
import moment from 'moment'
import { pathMatchRegexp } from '../_helpers'
import { MileagePool, MileageSearch, MileagesReducerState,MileagesReducerStateOnly, MileageModel, ModalState, VEvent } from '../_types'
import api from '../_dva_services'
import { ONE_YEAR } from '../_constants'
import { pageState, pageModel, modalModel, modalState, sortQuery, searchQuery, SearchKeys } from '../_utils'
import { epochToMoment, epochToTime } from '../_helpers'

const MileageModelPool: MileagePool = {
  vehicleName: [],
  route_name:[],
  internal_event_type: [],
  belonging_module:[]
}

const initSearch: MileageSearch = {
  product_version: '',
  sort: {
    field: 'time.createdAt',
    order: 'descend',
  },
  vehicle_name: [],
  ts: ['-1', Date.now().toString()],
  event_time: ['-1', Date.now().toString()],
  route_name:[],
  extra_route_name:'',
  internal_event_type:[],
  belonging_module:[],
  text: '',
  keys: ['Id', 'ProductVersion','eventTime', 'VehicleName', 'Description', 'Name', 'OldName', 'Path', 'OldPath'],
}

const eventModal: ModalState<VEvent> = {
  currentItem: {} as VEvent,
  modalVisible: false,
  modalType: 'create',
  modifying: false,
}

const initState: MileagesReducerStateOnly = {
  eventModal: eventModal,
  pool: MileageModelPool,
  search: initSearch,
  listingBags: false,
  // ...pageState,
  // ...modalState,
}

const searchKeys: SearchKeys = {
  matchKeys: ['productVersion','eventTime', 'vehicleName', 'audit.createdBy'],
  rangeKeys: ['startTime', 'endTime', 'time.createdAt', 'time.updatedAt'],
  multiMatchKeys: [],
}

const MileagesModel: MileageModel = modelExtend<MileageModel>(pageModel, modalModel, {
  namespace: 'mileage',
  state: initState,
  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(location => {
        if (pathMatchRegexp('/takeover', location.pathname)) {
          dispatch({ type: 'query' })
          dispatch({ type: 'queryPool' })
        }
      })
    },
  },
  reducers: {
    reset(state) {
      return {
        ...state,
        search: initSearch,
      }
    },

    listRequest(state, {}) {
      return {
        ...state,
        listingBags: true,
      }
    },

    listSuccess(state: MileagesReducerState) {
      return {
        ...state,
        listingBags: false,
      }
    },

    listFail(state: MileagesReducerState) {
      return {
        ...state,
        listingBags: false,
      }
    },

    // modify = create / update / delete
    modifyRequest(state, {}) {
      return {
        ...state,
        modifying: true,
      }
    },

    modifySuccess(state: MileagesReducerState) {
      return {
        ...state,
        modifying: false,
      }
    },

    modifyFail(state: MileagesReducerState) {
      return {
        ...state,
        modifying: false,
      }
    },
    showEventModal(state, { payload }) {
      return {
        ...state,
        eventModal: {
          ...state.eventModal,
          modalVisible: true,
          ...payload,
        },
      }
    },

    hideEventModal(state) {
      return { ...state, eventModal: { ...state.eventModal, modalVisible: false } }
    },

    
  },
  effects: {
    *queryPool({ payload = {} }, { call, put }) {
      const data = yield [call(api.getVehicleNameUrl), call(api.getInternalEventTypeUrl)
        ,call(api.getBelongingModuleUrl),call(api.getRouteNameUrl)
      ]
      if (data) {
        yield put({
          type: 'updateState',
          payload: {
            pool: {
              vehicleName:data[0].data.field_value,
              internal_event_type:data[1].data.field_value,
              belonging_module:data[2].data.field_value,
              route_name:data[3].data.field_value,
            },
          },
        })
      }
    },



    *query({ payload = {} }, { call, put, select }) {
      const { search, pagination } = yield select((state: any) => state.mileage)
      if(JSON.stringify(payload) !== "{}" ) {
        payload = {  ...payload.search, ...payload.pagination }
        payload.event_time = payload.event_time.map((i: any) => { 
          const va = epochToTime(i)
          return va
        })
      } else {
        payload = {  ...search, ...pagination }
        payload.event_time = payload.event_time.map((i: any) => { 
          const va = epochToTime(i)
          return va
        })
      }
      console.log('事件的参数',payload)
      try {
        yield put({ type: 'listRequest' })
        const datas = yield call(api.queryEventList, payload)
        const data = {
          "code": 200,
          "message": "请求成功",
          "data": [
            {
              "ts": 1691513384063,
              "vehicle_id": "84cf259b-9585-e028-2904-85a669df6ab7",
              "product_version": "master-2.0.465",
              "vehicle_name": "ID6_003",
              "region": "苏州",
              "belonging_module": "planning",
              "hmi_username": "Yicheng.yin",
              "event_time": "09:06:02",
              "event_category": "上报事件",
              "event_type": "手动上报事件-HMI",
              "task_type": "例行化路测",
              "mileage_type": "自动驾驶",
              "event_tag": "刹车",
              "description": "【所在道路】青龙港路",
              "event_bag": "ID6_003_2023-08-09-09-05-04_54.bag|ID6_003_2023-08-09-09-06-04_55.bag",
              "version_config": "carconfig_branch=master\nplanning_area=suzhoubei\nuse_demo_path=true\nuse_lidar=true\nloc_mode=lidar_loc\nloc_version=5.0.27\nsemantic_map_version=8.0.15\nis_calibration_mode=false\nmap_version=8.1.41\nmap_config=0.0.10\nmap_model_version=0.0.3\nmodel_version=3.1.51\nprediction_version=0.0.22\nhmi_version=3.1.14\nhmi_data_version=2.7.4\ncarconfig_version=0.0.1028\nprotoTool_version=0.0.15\nrviz_tool_version=master-1.0.26\nlog_level=INFO\nincar_grafana_image=infra/grafana:7.3.7-ubuntu\nincar_telegraf_image=infra/telegraf:1.17.2-car-0.0.18\nincar_mosquitto_image=infra/mosquitto:2.0.7\nincar_influxdb_image=infra/influxdb:1.7.10\nros_core_image=infra/roscore:kinetic\nmap_db_node_image=map/map_storage:v2_8.1.41\nmap_db_update_image=map/client:v0.1.4\ndnn_node_image=perc/detection3d_node_second_stage:0.0.3-demo_second_center\nperception_node_image=perc/perception:2.0.341-demo_ubuntu18\nlane_detection_node_image=map/surr_lane_detect:v0.0.2\nstopline_detection_node_image=map/stopline_detect:v0.0.9\nloc_node_image=loc/loc:2.2.6-master\nctrl_node_image=ctrl/ctrl:2.1.28-master\nplanning_node_image=plan/plan:4.0.819-master\nincar_data_image=infra/incar-data:0.0.182\ndrv_node_image=hardware/drv:2.0.160-demo_vehicle_driver\nincar_recorder_image=infra/incar_recorder:0.0.15\nsimulation_node_image=sim/simulation:3.1.38-master\nincar_filebeat_image=infra/incar_filebeat:0.0.5\ncarla_server_image=sim/carla_runtime:0.9.6-0.0.2\ncarla_client_image=sim/carla_client:0.0.46\nintegration_node_image=test/diag:1.0.23-master\nprediction_node_image=prediction/prediction:0.0.184-demo_v1-18.04\nnebula2ros_node_image=hardware/nebula2ros:1.0.10-master\nhardware_node_image=hardware/hardware3:1.0.10-master\nHMIDemoPath=2.19.7\nHMIDriver=2.19.7\ncar_service_image=infra/car-service:master-amd64-0.2.78",
              "bag_mhd_path": "/media/allride/allride5/ID6_003@20230809/bags/ID6_003_2023-08-09-09-05-04_54.bag|/media/allride/allride5/ID6_003@20230809/bags/ID6_003_2023-08-09-09-06-04_55.bag",
              "log_mhd_path": "/media/allride/allride4/ID6_003@20230810/logs",
              "peak_type": "早高峰",
              "route_name": "[single_line]41.常态化-大湾-公司",
              "position": "532.470,-788.266,12.199",
              "latitude_longitude": "31.419378131306644,120.6386262442704",
              "processing_status": "已处理",
              "jira_belonging_module": "prediction|planning|perception",
              "issue_key": "PV4-8997",
              "issue_link": "http://jira.allride-ai.cn:8080/browse/PV4-6241",
              "issue_description": "相城大道，自车通过路口时，受对向直行车辆转弯轨迹刹车，油门接管",
              "event_id": "manual_action-ID6_003-1691543162790",
              "qa_result": "碰撞",
              "simulation_info": "是",
              "bag_oss_path": "oss://allride-sharing-archive/event_data/20230424/ID6_001/bags/ID6_001__2023-04-24__08-27-54__08-28-54.bag",
              "log_oss_path": "oss://allride-sharing-archive/event_data/20230424/ID6_001/logs",
              "img_oss_path": "oss://allride-sharing-archive/event_data/ID6_TEST/2023-04-26/imgs/ID6_TEST__2023-04-26__16-52-44__pylon_sweepfront_image_raw_compressed__1682412774071420358.jpg",
              "event_second": "57.9",
              "simulation_result": "未碰撞",
              "simulation_bag_path": "oss://allride-release/simulation-job/bags/20230508/11602/213638_1683539095.bag",
              "simulation_scenario_id": "44603|44604|44605|44606|44607",
              "simulation_job_id": "11602",
              "simulation_task_id": "44603|44604",
              "curr_week": "Y2023-W32",
              "curr_month": "Y2023-M08",
              "curr_quarter": "Y2023-Q3",
              "product_dir": "/home/allride/pro_ctrl/product/daily-product/product",
              "event_confirmor": "yanjiang.wang",
              "event_confirm_time": 1691543384063.999
            },
            {
              "ts": 1691543384063,
              "vehicle_id": "84cf259b-9585-e028-2904-85a669df6ab7",
              "product_version": "master-2.0.465",
              "vehicle_name": "ID6_003",
              "region": "苏州",
              "belonging_module": "planning",
              "hmi_username": "Yicheng.yin",
              "event_time": "09:06:02",
              "event_category": "上报事件",
              "event_type": "手动上报事件-HMI",
              "task_type": "例行化路测",
              "mileage_type": "自动驾驶",
              "event_tag": "刹车",
              "description": "【所在道路】青龙港路",
              "event_bag": "ID6_003_2023-08-09-09-05-04_54.bag|ID6_003_2023-08-09-09-06-04_55.bag",
              "version_config": "carconfig_branch=master\nplanning_area=suzhoubei\nuse_demo_path=true\nuse_lidar=true\nloc_mode=lidar_loc\nloc_version=5.0.27\nsemantic_map_version=8.0.15\nis_calibration_mode=false\nmap_version=8.1.41\nmap_config=0.0.10\nmap_model_version=0.0.3\nmodel_version=3.1.51\nprediction_version=0.0.22\nhmi_version=3.1.14\nhmi_data_version=2.7.4\ncarconfig_version=0.0.1028\nprotoTool_version=0.0.15\nrviz_tool_version=master-1.0.26\nlog_level=INFO\nincar_grafana_image=infra/grafana:7.3.7-ubuntu\nincar_telegraf_image=infra/telegraf:1.17.2-car-0.0.18\nincar_mosquitto_image=infra/mosquitto:2.0.7\nincar_influxdb_image=infra/influxdb:1.7.10\nros_core_image=infra/roscore:kinetic\nmap_db_node_image=map/map_storage:v2_8.1.41\nmap_db_update_image=map/client:v0.1.4\ndnn_node_image=perc/detection3d_node_second_stage:0.0.3-demo_second_center\nperception_node_image=perc/perception:2.0.341-demo_ubuntu18\nlane_detection_node_image=map/surr_lane_detect:v0.0.2\nstopline_detection_node_image=map/stopline_detect:v0.0.9\nloc_node_image=loc/loc:2.2.6-master\nctrl_node_image=ctrl/ctrl:2.1.28-master\nplanning_node_image=plan/plan:4.0.819-master\nincar_data_image=infra/incar-data:0.0.182\ndrv_node_image=hardware/drv:2.0.160-demo_vehicle_driver\nincar_recorder_image=infra/incar_recorder:0.0.15\nsimulation_node_image=sim/simulation:3.1.38-master\nincar_filebeat_image=infra/incar_filebeat:0.0.5\ncarla_server_image=sim/carla_runtime:0.9.6-0.0.2\ncarla_client_image=sim/carla_client:0.0.46\nintegration_node_image=test/diag:1.0.23-master\nprediction_node_image=prediction/prediction:0.0.184-demo_v1-18.04\nnebula2ros_node_image=hardware/nebula2ros:1.0.10-master\nhardware_node_image=hardware/hardware3:1.0.10-master\nHMIDemoPath=2.19.7\nHMIDriver=2.19.7\ncar_service_image=infra/car-service:master-amd64-0.2.78",
              "bag_mhd_path": "/media/allride/allride5/ID6_003@20230809/bags/ID6_003_2023-08-09-09-05-04_54.bag|/media/allride/allride5/ID6_003@20230809/bags/ID6_003_2023-08-09-09-06-04_55.bag",
              "log_mhd_path": "/media/allride/allride4/ID6_003@20230810/logs",
              "peak_type": "早高峰",
              "route_name": "[single_line]41.常态化-大湾-公司",
              "position": "532.470,-788.266,12.199",
              "latitude_longitude": "31.419378131306644,120.6386262442704",
              "processing_status": "已处理",
              "jira_belonging_module": "prediction|planning|perception",
              "issue_key": "PV4-8997",
              "issue_link": "http://jira.allride-ai.cn:8080/browse/PV4-6241",
              "issue_description": "相城大道，自车通过路口时，受对向直行车辆转弯轨迹刹车，油门接管",
              "event_id": "manual_action-ID6_003-1691543162790",
              "qa_result": "碰撞",
              "simulation_info": "是",
              "bag_oss_path": "oss://allride-sharing-archive/event_data/20230424/ID6_001/bags/ID6_001__2023-04-24__08-27-54__08-28-54.bag",
              "log_oss_path": "oss://allride-sharing-archive/event_data/20230424/ID6_001/logs",
              "img_oss_path": "oss://allride-sharing-archive/event_data/ID6_TEST/2023-04-26/imgs/ID6_TEST__2023-04-26__16-52-44__pylon_sweepfront_image_raw_compressed__1682412774071420358.jpg",
              "event_second": "57.9",
              "simulation_result": "未碰撞",
              "simulation_bag_path": "oss://allride-release/simulation-job/bags/20230508/11602/213638_1683539095.bag",
              "simulation_scenario_id": "44603|44604|44605|44606|44607",
              "simulation_job_id": "11602",
              "simulation_task_id": "44603|44604",
              "curr_week": "Y2023-W32",
              "curr_month": "Y2023-M08",
              "curr_quarter": "Y2023-Q3",
              "product_dir": "/home/allride/pro_ctrl/product/daily-product/product",
              "event_confirmor": "yanjiang.wang",
              "event_confirm_time": 1691543384063.999
            }
          ],
          "pagination": {
              "current": 1,
              "pageSize": 10,
              "total": 18
          }
        }
        yield put({
          type: 'updateState',
          payload: {
            list: data.data,
            pagination: {
              ...pagination,
              ...data.pagination,
            },
            // search: {
            //   ...search,
            //   sort: cloneSort,
            // },
          },
        })

        yield put({ type: 'listSuccess' })
      } catch (e) {
        yield put({ type: 'listFail' })
      }
    },

    *create({ payload = {} }, { call, put }) {
      yield put({ type: 'modifyRequest' })

      try {
        yield call(api.createBag, { bag: payload })

        yield put({ type: 'modifySuccess' })
        yield put({ type: 'reset' })
        yield put({ type: 'query' })
        yield put({ type: 'hideModal' })

        message.success(i18next.t('msg.createSuccessful'))
      } catch (e) {
        yield put({ type: 'modifyFail' })

        message.error(i18next.t('msg.createFailed'))
      }
    },

    *update({ payload = {} }, { call, put }) {
      yield put({ type: 'modifyRequest' })

      try {
        yield call(api.updateBag, { bag: payload })

        yield put({ type: 'modifySuccess' })
        yield put({ type: 'query' })

        message.success(i18next.t('msg.updateSuccessful'))
      } catch (e) {
        yield put({ type: 'modifyFail' })

        message.error(i18next.t('msg.updateFailed'))
      }
    },

    *delete({ payload = {} }, { call, put }) {
      yield put({ type: 'modifyRequest' })

      try {
        yield call(api.deleteBag, payload)

        yield put({ type: 'modifySuccess' })
        yield put({ type: 'query' })
        yield put({ type: 'hideModal' })

        message.success(i18next.t('msg.deleteSuccessful'))
      } catch (e) {
        yield put({ type: 'modifyFail' })

        message.error(i18next.t('msg.deleteFailed'))
      }
    },

    *uploadLocation({payload = {}},{call,put}){
      console.log(payload)
      try{
        yield call(api.uploadLocation,{carNameAndDate:payload.location})
      }catch(e){
        message.error(i18next.t('msg.updateFailed'))
      }
    }
  },
})

export default MileagesModel
