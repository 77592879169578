const pwdAdmin = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
const pwdUser = /^[A-Za-z\d@$!%*?&]{6,20}$/
const adminEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(allride.ai)|(kaizhe.ai)$/
const userEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const reg = {
  pwdAdmin,
  pwdUser,
  adminEmail,
  userEmail,
}
