import React from 'react'
import './Layout.scss'
import { connect } from 'dva'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Dispatch } from 'redux'
import { withRouter, Link } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'

import { roleCheck } from '../../_helpers'
import { Admin, DvaState } from '../../_types'
import { LangButton } from '../../common'
import { Layout as AntLayout, Menu, Icon, Tooltip, ConfigProvider } from 'antd'
const { Sider } = AntLayout
const { SubMenu } = Menu

interface LayoutProps extends WithTranslation, RouteComponentProps {
  admin: Admin
  dispatch: Dispatch
}

interface LayoutState {
  collapsed: boolean
  marginLeft: number
}

interface MenuData {
  key: string
  title: string
  role: string
  icon?: string
  path?: string
  children?: MenuData[]
}

const menuData: MenuData[] = [
  // {
  //   key: '/',
  //   icon: 'dashboard',
  //   title: 'dashboard',
  //   path: '/',
  //   role: '',
  // },
  // {
  //   key: '/resources',
  //   icon: 'appstore',
  //   title: 'resources',
  //   path: '/resources',
  //   role: '',
  // },
  // {
  //   key: '/monitor',
  //   icon: 'desktop',
  //   title: 'monitor',
  //   path: '/monitor',
  //   role: '',
  // },
  {
    key: '/datav',
    icon: 'monitor',
    title: 'datav',
    path: '/datav',
    role: 'admin',
  },
  {
    key: 'locations',
    icon: 'environment',
    title: 'locations',
    role: '',
    children: [
      {
        key: '/plocations',
        icon: 'flag',
        title: 'plocations',
        path: '/plocations',
        role: '',
      },
      {
        key: '/paths',
        icon: 'gateway',
        title: 'paths',
        path: '/paths',
        role: '',
      },
    ],
  },
  // {
  //   key: 'accounts',
  //   icon: 'team',
  //   title: 'accounts',
  //   role: '',
  //   children: [
  //     {
  //       key: '/admins',
  //       icon: 'user-add',
  //       title: 'admins',
  //       path: '/admins',
  //       role: '',
  //     },
  //     {
  //       key: '/users',
  //       icon: 'user',
  //       title: 'users',
  //       path: '/users',
  //       role: '',
  //     },
  //     {
  //       key: '/invitations',
  //       icon: 'coffee',
  //       title: 'invitations',
  //       path: '/invitations',
  //       role: '',
  //     },
  //   ],
  // },
  // {
  //   key: '/vehicles',
  //   icon: 'car',
  //   title: 'vehicles',
  //   path: '/vehicles',
  //   role: '',
  // },
  // {
  //   key: '/trips',
  //   icon: 'profile',
  //   title: 'trips',
  //   path: '/trips',
  //   role: '',
  // },
  // {
  //   key: '/districts',
  //   icon: 'deployment-unit',
  //   title: 'districts',
  //   path: '/districts',
  //   role: '',
  // },
  // {
  //   key: '/roadtests',
  //   icon: 'project',
  //   title: 'roadTests',
  //   path: '/roadtests',
  //   role: '',
  // },
  {
    key: 'bags',
    icon: 'database',
    title: 'data',
    role: '',
    children: [
      {
        key: '/bags',
        icon: 'inbox',
        title: 'bags',
        path: '/bags',
        role: '',
      },
      {
        key: '/vevents',
        icon: 'calendar',
        title: 'events',
        path: '/vevents',
        role: '',
      },
      {
        key: '/tags',
        icon: 'tags',
        title: 'tags',
        path: '/tags',
        role: '',
      },
    ],
  },
  {
    key: 'mileTake',
    icon: 'car',
    title: 'mileTake',
    role: '',
    children: [
      {
        key: '/takeover',
        icon: 'exception',
        title: 'takeEvent',
        path: '/takeover',
        role: '',
      },
      {
        key: '/mileage',
        icon: 'inbox',
        title: 'mileage',
        path: '/mileage',
        role: '',
      },
    ],
  },
  {
    key: '/fault',
    icon: 'bug',
    title: 'fault',
    path: '/fault',
    role: '',
  },
  // {
  //   key: '/target',
  //   icon: 'thunderbolt',
  //   title: 'target',
  //   path: '/target',
  //   role: '',
  // },
  {
    key: '/performances',
    icon: 'thunderbolt',
    title: 'performances',
    path: '/performances',
    role: '',
  },
  // {
  //   key: '/vmaps',
  //   icon: 'global',
  //   title: 'vmaps',
  //   path: '/vmaps',
  //   role: '',
  // },
  {
    key: 'scenario',
    icon: 'pic-right',
    title: 'scenario',
    role: '',
    children: [
      {
        key: '/scenarioList',
        icon: 'profile',
        title: 'scenarioList',
        path: '/scenarioList',
        role: '',
      },
      {
        key: '/scenariosurvey',
        icon: 'pie-chart',
        title: 'scenarioSurvey',
        path: '/scenariosurvey',
        role: '',
      },
    ],
  },
]

class Layout extends React.Component<LayoutProps, LayoutState> {
  state = {
    collapsed: false,
    marginLeft: 200,
  }

  onCollapse = (collapsed: boolean) => {
    this.setState({ collapsed })

    setTimeout(() => {
      this.setState({ marginLeft: collapsed ? 80 : 200 })
    }, 220)
  }

  toggleCollapse = () => {
    const { collapsed } = this.state
    this.setState({
      collapsed: !collapsed,
    })

    setTimeout(() => {
      this.setState({ marginLeft: !collapsed ? 80 : 200 })
    }, 220)
  }

  handleLogout = () => {
    const { dispatch } = this.props

    dispatch({ type: 'app/logout' })
  }

  menuRecu(data: MenuData[]) {
    const { admin, t } = this.props
    return data.map((menu: MenuData) => {
      if (menu.children) {
        return roleCheck(admin, menu.role) ? (
          <SubMenu
            key={menu.key}
            title={
              <span>
                <Icon type={menu.icon} />
                <span>{t(menu.title)}</span>
              </span>
            }
          >
            {this.menuRecu(menu.children)}
          </SubMenu>
        ) : null
      }
      return roleCheck(admin, menu.role) ? (
        <Menu.Item key={menu.key}>
          <Link to={{ pathname: menu.path }}>
            {menu.icon ? <Icon type={menu.icon} /> : null}
            <span>{t(menu.title)}</span>
          </Link>
        </Menu.Item>
      ) : null
    })
  }

  getRenderEmpty = () => {
    const { t } = this.props
    return <div style={{ padding: '1rem 0' }}>{t('tip.noData')}</div>
  }

  render() {
    const { marginLeft, collapsed } = this.state
    const { location, children, t } = this.props

    return (
      <div className="layout">
        <AntLayout>
          <Sider
            style={{
              height: '100vh',
              position: 'fixed',
              left: 0,
              zIndex: 1,
            }}
            theme="light"
            collapsible
            collapsed={collapsed}
            onCollapse={this.onCollapse}
            trigger={null}
          >
            <nav className="layout-nav">
              <Menu
                style={{ flex: 1, minHeight: 0, borderRight: 0, overflow: 'auto' }}
                selectedKeys={[location.pathname]}
                mode="inline"
              >
                {this.menuRecu(menuData)}
              </Menu>
              <div className="layout-setting" style={{ flexDirection: collapsed ? 'column' : 'initial' }}>
                <div className="layout-setting-item">
                  <LangButton mini />
                </div>
                <div className="layout-setting-item">
                  <Tooltip title={t('tooltip.logout')} placement={collapsed ? 'right' : 'top'} mouseEnterDelay={1}>
                    <button className="layout-setting-btn" onClick={this.handleLogout}>
                      <Icon type="logout" />
                    </button>
                  </Tooltip>
                </div>
                <div className="layout-setting-item">
                  <Tooltip
                    title={t(`tooltip.${collapsed ? 'expand' : 'collapse'}`)}
                    placement={collapsed ? 'right' : 'top'}
                    mouseEnterDelay={1}
                  >
                    <button className="layout-setting-btn" onClick={this.toggleCollapse}>
                      <Icon
                        type="left"
                        style={{
                          transform: collapsed ? 'rotate(180deg)' : 'rotate(0)',
                          transition: 'transform 0.3s',
                        }}
                      />
                    </button>
                  </Tooltip>
                </div>
              </div>
            </nav>
          </Sider>
        </AntLayout>
        <ConfigProvider renderEmpty={this.getRenderEmpty}>
          <AntLayout style={{ marginLeft: marginLeft, height: '100vh', background: 'none' }}>{children}</AntLayout>
        </ConfigProvider>
      </div>
    )
  }
}

const mapStateToProps = (state: DvaState) => state.app

export default connect(mapStateToProps)(withRouter(withTranslation('layout')(Layout)))
