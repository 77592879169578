import React from 'react'
import { List } from 'antd'
import ReactJson from 'react-json-view'
import SearchShow from './SearchShow'

class SearchResult extends React.PureComponent<any> {
  render() {
    const { name, data, button } = this.props
    return (
      <div>
        {!data || data.length == 0 ? null : (
          <div>
            <SearchShow button={button} data={data} />
            <List
              itemLayout="horizontal"
              dataSource={data}
              renderItem={(item: any) => (
                <List.Item>
                  <ReactJson
                    displayDataTypes={false}
                    iconStyle="triangle"
                    theme="apathy:inverted"
                    name={name}
                    src={item}
                    collapsed={1}
                  />
                </List.Item>
              )}
            />
          </div>
        )}
      </div>
    )
  }
}

export default SearchResult
