import modelExtend from 'dva-model-extend'
import i18next from 'i18next'

import { pathMatchRegexp } from '../_helpers'
import { InvitationModel, InvitationsReducerState, DvaState } from '../_types'
import api from '../_dva_services'

import { message } from 'antd'

const initState: InvitationsReducerState = {
  list: [],
  listingInvitations: false,
  initLoading: true,
}

const invitationsModel: InvitationModel = modelExtend<InvitationModel>({
  namespace: 'invitations',
  state: initState,
  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(location => {
        if (pathMatchRegexp('/invitations', location.pathname)) {
          dispatch({ type: 'reset' })
          dispatch({ type: 'list' })
        }
      })
    },
  },
  reducers: {
    reset() {
      return initState
    },

    updateList(state, { payload }) {
      const { list } = payload

      return {
        ...state,
        list,
      }
    },

    listRequest(state, {}) {
      return {
        ...state,
        listingInvitations: true,
      }
    },

    listSuccess(state, { payload }) {
      const { list } = payload

      return {
        ...state,
        list,
        initLoading: false,
        listingInvitations: false,
      }
    },

    listFail(state, {}) {
      return {
        ...state,
        listingInvitations: false,
      }
    },
  },
  effects: {
    *list({ payload = {} }, { call, put, select }) {
      const invitations = yield select((state: DvaState) => state.invitations)

      try {
        const data = yield call(api.listInvitations, payload)

        yield put({
          type: 'listSuccess',
          payload: {
            list: invitations.list.concat(data.invitations),
          },
        })
      } catch (e) {
        yield put({ type: 'listFail' })
      }
    },

    *create({}, { call, put, select }) {
      const invitations = yield select((state: DvaState) => state.invitations)

      try {
        const data = yield call(api.createInvitation, {})

        yield put({
          type: 'updateList',
          payload: {
            list: [data.invitation].concat(invitations.list),
          },
        })

        message.success(i18next.t('msg.createSuccessful'))
      } catch (e) {
        message.error(i18next.t('msg.createFailed'))
      }
    },

    *delete({ payload }, { call, put, select }) {
      const invitations: InvitationsReducerState = yield select((state: DvaState) => state.invitations)

      try {
        yield call(api.deleteInvitation, payload)

        yield put({
          type: 'updateList',
          payload: {
            list: invitations.list.filter(invitation => invitation.code !== payload.code),
          },
        })

        message.success(i18next.t('msg.deleteSuccessful'))
      } catch (e) {
        message.error(i18next.t('msg.deleteFailed'))
      }
    },
  },
})

export default invitationsModel
