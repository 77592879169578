import React from 'react'
import './Admins.scss'
import { connect } from 'dva'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Dispatch } from 'redux'

import { AdminsReducerState, DvaState, Admin } from '../../_types'
import { AdminList, AdminModal } from './components'

interface AdminsProps extends WithTranslation, AdminsReducerState {
  dispatch: Dispatch
}

class Admins extends React.Component<AdminsProps> {
  get adminlistProps() {
    const { dispatch, admins, listingAdmins, t } = this.props

    return {
      dataSource: admins,
      loading: listingAdmins,
      t,
      onCreate: () => {
        dispatch({
          type: 'admins/showModal',
          payload: {
            modalType: 'create',
          },
        })
      },
      onEdit: (item: Admin) => {
        dispatch({
          type: 'admins/showModal',
          payload: {
            modalType: 'update',
            currentItem: item,
          },
        })
      },
    }
  }

  get adminModalProps() {
    const { dispatch, currentItem, modalVisible, modalType, modifyingAdmin, t } = this.props

    return {
      item: modalType === 'create' ? undefined : currentItem,
      visible: modalVisible,
      modalType,
      title: t(`${modalType}Admin`),
      loading: modifyingAdmin,
      destroyOnClose: true,
      maskClosable: true,
      centered: true,
      t,
      onModify: (data: any, action: string) => {
        dispatch({
          type: `admins/${action}`,
          payload: data,
        })
      },
      onCancel: () => {
        dispatch({
          type: 'admins/hideModal',
        })
      },
    }
  }

  render() {
    const { t } = this.props

    return (
      <div className="admins">
        <div className="admins-inner">
          <header className="admins-header">
            <h2>{t('adminList')}</h2>
          </header>
          <AdminList {...this.adminlistProps} />
          <AdminModal {...this.adminModalProps} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: DvaState) => ({
  ...state.admins,
})

export default connect(mapStateToProps)(withTranslation('admins')(Admins))
