import {
  Login,
  Dashboard,
  Monitor,
  Users,
  Vehicles,
  Admins,
  Trips,
  Districts,
  // Drive,
  RoadTests,
  Search,
  Bags,
  Tags,
  VEvents,
  VEventDetail,
  Invitations,
  PLocations,
  Paths,
  Resources,
  Mileage,
  Takeover,
  Performances,
  VMap,
  VMapDetail,
  ScenarioSurvey,
  ScenarioList,
  ScenarioDetail,
  Fault,
  Target
} from '../pages'

import { Reload } from '../common'

interface Route {
  path: string
  component: React.ComponentType
  auth: boolean
  role: string
  exact?: boolean
}



let routesConfig: Route[] = [
  {
    path: '/',
    component: Dashboard,
    auth: true,
    exact: true,
    role: '',
  },
  // {
  //   path: '/monitor',
  //   component: Monitor,
  //   auth: true,
  //   exact: true,
  //   role: '',
  // },
  {
    path: '/plocations',
    component: PLocations,
    auth: true,
    exact: true,
    role: '',
  },
  {
    path: '/paths',
    component: Paths,
    auth: true,
    exact: true,
    role: '',
  },
  // {
  //   path: '/admins',
  //   component: Admins,
  //   auth: true,
  //   exact: true,
  //   role: '',
  // },
  // {
  //   path: '/users',
  //   component: Users,
  //   auth: true,
  //   exact: true,
  //   role: '',
  // },
  // {
  //   path: '/invitations',
  //   component: Invitations,
  //   auth: true,
  //   exact: true,
  //   role: '',
  // },
  // {
  //   path: '/vehicles',
  //   component: Vehicles,
  //   auth: true,
  //   exact: true,
  //   role: '',
  // },
  // {
  //   path: '/drive',
  //   component: Drive,
  //   auth: true,
  //   exact: true,
  //   role: 'Admin',
  // },
  // {
  //   path: '/trips',
  //   component: Trips,
  //   auth: true,
  //   exact: true,
  //   role: '',
  // },
  // {
  //   path: '/districts',
  //   component: Districts,
  //   auth: true,
  //   exact: true,
  //   role: '',
  // },
  // {
  //   path: '/roadtests',
  //   component: RoadTests,
  //   auth: true,
  //   exact: true,
  //   role: '',
  // },
  {
    path: '/search',
    component: Search,
    auth: true,
    exact: true,
    role: '',
  },
  {
    path: '/bags',
    auth: true,
    exact: true,
    //role: 'Admin',
    role: '',
    component: Bags,
  },
  {
    path: '/vevents',
    component: VEvents,
    auth: true,
    exact: true,
    role: '',
  },
  {
    path: '/vevents/:id',
    component: VEventDetail,
    auth: true,
    exact: true,
    role: '',
  },
  {
    path: '/tags',
    component: Tags,
    auth: true,
    exact: true,
    role: '',
  },
  // {
  //   path: '/resources',
  //   component: Resources,
  //   auth: true,
  //   exact: true,
  //   role: '',
  // },
  {
    path: '/login',
    component: Login,
    auth: false,
    exact: true,
    role: '',
  },
  {
    path: '/datav',
    component: Reload,
    auth: true,
    exact: true,
    role: '',
  },
  {
    path: '/takeover',
    component: Mileage,
    auth: true,
    exact: true,
    role: '',
  },
  {
    path: '/mileage',
    component: Takeover,
    auth: true,
    exact: true,
    role: '',
  },
  {
    path:'/fault',
    component:Fault,
    auth:true,
    exact:true,
    role:'',
  },
  // {
  //   path:'/target',
  //   component:Target,
  //   auth:true,
  //   exact:true,
  //   role:'',
  // },
  {
    path:'/performances',
    component:Performances,
    auth:true,
    exact:true,
    role:'',
  },
  // {
  //   path:'/vmaps',
  //   component:VMap,
  //   auth:true,
  //   exact:true,
  //   role:'',
  // },
  // {
  //   path:'/vmapsdetail',
  //   component:VMapDetail,
  //   auth:true,
  //   exact:true,
  //   role:'',
  // },
  {
    path:'/scenariosurvey',
    component:ScenarioSurvey,
    auth:true,
    exact:true,
    role:'',
  },
  {
    path:'/scenarioList',
    component:ScenarioList,
    auth:true,
    exact:true,
    role:'',
  },
  {
    path:'/scenarioDetail/:id',
    component:ScenarioDetail,
    auth:true,
    exact:true,
    role:'',
  }
]

routesConfig.forEach(item => {
  // @ts-ignore
  item.path = window.__POWERED_BY_QIANKUN__ ? ('/react' + item.path) : item.path
})

export const routes = routesConfig