import axios from 'axios'
import { cloneDeep } from 'lodash'
import * as pathToRegexp from 'path-to-regexp'
import { message } from 'antd'
import { requestConstants } from '../_constants'

declare global {
  interface Window {
    cancelRequest: Map<any, any>
  }
}

const { CANCEL_REQUEST_MESSAGE } = requestConstants
const { CancelToken } = axios
window.cancelRequest = new Map()
let domain = ''

export default function request(options: any) {
  let { data, url, method = 'get' } = options
  const cloneData = cloneDeep(data)

  try {
    const urlMatch = url.match(/[a-zA-z]+:\/\/[^/]*/)
    if (urlMatch) {
      ;[domain] = urlMatch
      url = url.slice(domain.length)
    }

    const match = pathToRegexp.parse(url)
    url = pathToRegexp.compile(url)(data)

    for (const item of match) {
      if (item instanceof Object && item.name in cloneData) {
        delete cloneData[item.name]
      }
    }
    url = domain + url
  } catch (e) {
    message.error(e.message)
  }

  options.url = url
  if (method === 'GET') {
    options.params = cloneData
  }
  options.cancelToken = new CancelToken(cancel => {
    window.cancelRequest.set(Symbol(Date.now()), {
      pathname: window.location.pathname,
      cancel,
    })
  })

  return axios(options)
    .then(response => {
      const { statusText, status, data } = response

      let result: any = {}
      if (typeof data === 'object') {
        result = data
        if (Array.isArray(data)) {
          result.list = data
        }
      } else {
        result.data = data
      }

      return Promise.resolve({
        success: true,
        message: statusText,
        statusCode: status,
        ...result,
      })
    })
    .catch(error => {
      const { response, message } = error
      if (String(message) === CANCEL_REQUEST_MESSAGE) {
        return Promise.reject({
          success: false,
          statusCode: 499,
        })
      }

      let msg, statusCode, detail

      if (response && response instanceof Object) {
        const { data, statusText } = response
        statusCode = response.status
        detail = data.detail || statusText
        msg = data.message || statusText
      } else {
        statusCode = 600
        msg = error.message || 'Network Error'
      }

      /* eslint-disable */
      return Promise.reject({
        success: false,
        statusCode,
        message: msg,
        detail: detail,
      })
    })
}
