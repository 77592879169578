import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'

import { PLocation } from '../../../../_types'
import { EmptyCard, StateTag } from '../../../../common'
import { DEFAULT_VALUE } from '../../constants'

interface PLocationListProps extends WithTranslation {
  plocations: PLocation[]
  selectedPLocationId: string
  onSelectPLocation: (e: React.MouseEvent, plocationId: string) => void
  onClearSelectedPLocation: () => void
}

const PLocationList: React.FC<PLocationListProps> = ({
  plocations,
  selectedPLocationId,
  onSelectPLocation,
  onClearSelectedPLocation,
}) => {
  const plocationList: React.ReactNode[] = plocations.map(plocation => {
    return (
      <li
        key={plocation.id}
        className={`monitor-list-item ${plocation.id === selectedPLocationId ? ' active' : ''} location`}
        onClick={e => onSelectPLocation(e, plocation.id)}
      >
        <div className="monitor-list-content">
          <header className="monitor-list-header">
            <h4 className="monitor-list-title">{plocation.name}</h4>
            <div className="monitor-list-liveness">
              {plocation.state ? <StateTag state={plocation.state} type="plocation" /> : DEFAULT_VALUE}
            </div>
          </header>
        </div>
      </li>
    )
  })

  return (
    <ul className="monitor-list" onClick={onClearSelectedPLocation}>
      {plocationList.length ? (
        plocationList
      ) : (
        <li className="monitor-list-item">
          <EmptyCard />
        </li>
      )}
    </ul>
  )
}

export default withTranslation('monitor')(PLocationList)
