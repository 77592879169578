import modelExtend from 'dva-model-extend'
import i18next from 'i18next'
import { Model } from 'dva'
import { message } from 'antd'

import { pathMatchRegexp } from '../_helpers'
import { DistrictsReducerState } from '../_types'
import api from '../_dva_services'
import { modalState, modalModel } from '../_utils'

const initState: DistrictsReducerState = {
  districts: [],
  listingDistricts: false,
  modifyingDistrict: false,
  ...modalState,
}

const districtsModel = modelExtend<Model>(modalModel, {
  namespace: 'districts',
  state: initState,
  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(location => {
        if (pathMatchRegexp('/districts', location.pathname)) {
          dispatch({ type: 'reset' })
          dispatch({ type: 'list' })
        }
      })
    },
  },
  reducers: {
    reset() {
      return initState
    },

    listRequest(state, {}) {
      return {
        ...state,
        listingDistricts: true,
      }
    },

    listSuccess(state: DistrictsReducerState, { payload }) {
      const { districts } = payload

      return {
        ...state,
        districts,
        listingDistricts: false,
      }
    },

    listFail(state: DistrictsReducerState) {
      return {
        ...state,
        listingDistricts: false,
      }
    },

    // modify = create / update / delete
    modifyRequest(state, {}) {
      return {
        ...state,
        modifyingDistricts: true,
      }
    },

    modifySuccess(state: DistrictsReducerState) {
      return {
        ...state,
        modifyingDistricts: false,
      }
    },

    modifyFail(state: DistrictsReducerState) {
      return {
        ...state,
        modifyingDisttricts: false,
      }
    },
  },
  effects: {
    *list({}, { call, put }) {
      yield put({ type: 'listRequest' })

      try {
        const data = yield call(api.listDistricts, {})
        const districts = data.districts

        yield put({
          type: 'listSuccess',
          payload: { districts },
        })

        return districts
      } catch (e) {
        yield put({ type: 'listFail' })
      }
    },

    *create({ payload }, { call, put }) {
      yield put({ type: 'modifyRequest' })

      try {
        yield call(api.createDistrict, payload)

        yield put({ type: 'modifySuccess' })
        yield put({ type: 'list' })
        yield put({ type: 'hideModal' })

        message.success(i18next.t('msg.createSuccessful'))
      } catch (e) {
        yield put({ type: 'modifyFail' })

        message.error(i18next.t('msg.createFailed'))
      }
    },

    *update({ payload }, { call, put }) {
      yield put({ type: 'modifyRequest' })

      try {
        yield call(api.updateDistrict, payload)

        yield put({ type: 'modifySuccess' })
        yield put({ type: 'list' })

        message.success(i18next.t('msg.updateSuccessful'))
      } catch (e) {
        yield put({ type: 'modifyFail' })

        message.error(i18next.t('msg.updateFailed'))
      }
    },

    *delete({ payload }, { call, put }) {
      yield put({ type: 'modifyRequest' })

      try {
        yield call(api.deleteDistrict, payload)

        yield put({ type: 'modifySuccess' })
        yield put({ type: 'list' })
        yield put({ type: 'hideModal' })

        message.success(i18next.t('msg.deleteSuccessful'))
      } catch (e) {
        yield put({ type: 'modifyFail' })

        message.error(i18next.t('msg.deleteFailed'))
      }
    },
  },
})

export default districtsModel
