import React from 'react'
import './Performances.scss'
import { message } from 'antd'
import { connect } from 'dva'
import { DvaState, PerformancesReducerState, Performances, ModalState } from '../../_types'
import { Dispatch } from 'redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import lodash from 'lodash'

import { Pagination, VEvent } from '../../_types'
// import { Header, List, UploadModal } from './components'
import { Header, List } from './components'
import { Modal as EventModal } from '../VEvents/components'

interface PerformancesProps extends WithTranslation, PerformancesReducerState, ModalState<Performances> {
  dispatch: Dispatch,
}

const decorateEvent = (Performances: Performances) => {
  const { vehicleName, productVersion, startTime } = Performances
  if (!vehicleName || !startTime) {
    return undefined
  }
  const fiveMin = 5 * 60 * 1000
  let bagStartTime = parseInt(startTime)
  let eventStartTime = bagStartTime - fiveMin
  let eventEndTime = bagStartTime + fiveMin
  eventStartTime = eventStartTime > 0 ? eventStartTime : 0
  return {
    vehicleName: vehicleName,
    productVersion: productVersion,
    startTime: eventStartTime.toString(),
    endTime: eventEndTime.toString(),
  }
}
class Performance extends React.PureComponent<PerformancesProps> {
  get searchProps() {
    const { dispatch, pool, search, t } = this.props

    return {
      item: search,
      pool: pool,
      t: t,
      onSearch: () => {
        dispatch({ type: 'performances/query' })
      },
      onChange: (fields: any) => {
        dispatch({
          type: 'performances/updateState',
          payload: {
            search: {
              ...search,
              ...lodash.mapValues(fields, o => ('value' in o ? o.value : o)),
            },
          },
        })
      },
      onClear: () => {
        dispatch({
          type: 'performances/reset',
        })
      },
      onUploadFun: () => {
        dispatch({
          type: 'performances/showModal',
        })
      }
    }
  }

  get listProps() {
    const { list, listingBags, dispatch, search, pagination, t } = this.props
    const changeDate = (date: String) => {
      let dateArray = date.split('')
      dateArray.splice(4, 0, '/')
      dateArray.splice(7, 0, '/')
      let newdate = dateArray.join('')
      return newdate
    }
    const changeEventBag = (eventBag: String) => {
      let eventBagArray = eventBag.split(' ')
      return eventBagArray.join(' <br /> ')
    }
    const refreshList = (tablesource: Array<any>) => {
      let newtableProps = tablesource.map(obj => {
        return { ...obj, time: obj.time, eventBag: changeEventBag(obj.eventBag) }
      })
      return newtableProps
    }
    return {
    //   dataSource: refreshList(list),
      dataSource: list,
      pagination: pagination,
      loading: listingBags,
      sort: search.sort,
      t: t,
      sortDirections: ['descend', 'ascend'],
      onChange: (pagination: Pagination, filters: any, sorter: any) => {
        if (!sorter.order) {
          sorter.order = 'descend'
        }
        dispatch({
          type: 'performances/query',
          payload: {
            // search: search,
            pagination: pagination,
            // sort: sorter,
          },
        })
      },
      onCreate: () => {
        dispatch({
          type: 'performances/showModal',
          payload: {
            modalType: 'create',
            currentItem: {},
          },
        })
      },
      onCreateEvent: (item: Performances) => {
        const event = decorateEvent(item)
        if (!event) {
          message.error('当前bag不能创建event, 请使用正确的bag车辆名, 创建时间')
          return
        }
        dispatch({ type: 'performances/showEventModal', payload: { currentItem: event } })
      },
      onEdit: (item: Performances) => {
        dispatch({
          type: 'performances/showModal',
          payload: {
            modalType: 'update',
            currentItem: item,
          },
        })
      },
    }
  }

  get modalProps() {
    const { admin, dispatch, t, pool, modifying, currentItem, modalVisible, modalType } = this.props

    return {
      item: modalType === 'create' ? undefined : currentItem,
      pool: pool,
      visible: modalVisible,
      destroyOnClose: true,
      maskClosable: true,
      loading: modifying,
      modalType: modalType,
      title: t(`${modalType}Bag`),
      centered: true,
      t: t,
      onModify: (data: Performances, action: string) => {
        let payload = {} as any
        switch (action) {
          case 'create':
            data.audit = { ...currentItem.audit, createdBy: admin ? admin.name : '' }
            payload = data
            break
          case 'update':
            data.audit = { ...currentItem.audit, updatedBy: admin ? admin.name : '' }
            payload = data
            break
        }
        dispatch({
          type: `performances/${action}`,
          payload,
        })
      },
      onCancel: () => {
        dispatch({
          type: 'performances/hideModal',
        })
      },
    }
  }

  get eventModalProps() {
    const { admin, dispatch, pool, t, eventModal } = this.props
    const { modifying, modalVisible, modalType, currentItem } = eventModal
    return {
      item: currentItem,
      pool: pool,
      fromBag: true,
      visible: modalVisible,
      loading: modifying,
      destroyOnClose: true,
      maskClosable: true,
      modalType: modalType,
      title: t(`${modalType}VEvent`),
      centered: true,
      t,
      onModify: (data: VEvent, action: string) => {
        const payload = {} as any
        data.audit = { ...currentItem.audit, createdBy: admin ? admin.name : '' }
        payload.vehicleEvents = [data]
        dispatch({
          type: `vevents/${action}`,
          payload,
        })
      },
      onCancel: () => {
        dispatch({ type: 'performances/hideEventModal' })
      },
    }
  }

  get uploadProps() {
    const { dispatch, t, modifying, modalVisible } = this.props

    return {
      visible: modalVisible,
      destroyOnClose: true,
      maskClosable: true,
      loading: modifying,
      title: t(`btn.upload`) + 'Bag & log',
      centered: true,
      t: t,
      dispatch:dispatch,
      onCancel: () => {
        dispatch({
          type: 'performances/hideModal',
        })
      },
    }
  }

  render() {
    return (
      <div className="pers">
        <div className="pers-inner">
          <Header {...this.searchProps} />
        </div>
        <div className="pers-inner">
          <List {...this.listProps} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: DvaState) => {
  return {
    ...state.performances,
    admin: state.app.admin,
  }
}

export default connect(mapStateToProps)(withTranslation('bags')(Performance))
