import React, { CSSProperties } from 'react'
import { AutoComplete, Modal, Form, Input, DatePicker, Button, InputNumber } from 'antd'
import { FormComponentProps } from 'antd/lib/form'

import { Bag, BagPool, ModalType } from '../../../_types'
import { epochToMoment, momentToEpoch } from '../../../_helpers'

interface BagModalProps extends FormComponentProps {
  item?: Bag
  pool: BagPool
  loading: boolean
  modalType: ModalType
  onModify: any
  onCancel: () => void
  t: any
}

const modalBodyStyle: CSSProperties = {
  maxHeight: '80vh',
  overflow: 'auto',
  padding: '1rem 1.5rem 0.5rem',
}

const formItemLayout = {
  labelAlign: 'left' as 'left',
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

class BagModal extends React.PureComponent<BagModalProps> {
  handleUpdate = () => {
    const { item, onModify, modalType, form, pool } = this.props
    const { validateFields, getFieldsValue } = form
    const { vehicleAutoComplete } = pool
    validateFields(errors => {
      if (errors) {
        return
      }
      const { startTime, endTime, ...fieldsValue } = getFieldsValue()
      let vId = ''
      if (fieldsValue.vehicleName) {
        vId = vehicleAutoComplete[fieldsValue.vehicleName] && vehicleAutoComplete[fieldsValue.vehicleName].id
      }
      if (!vId) {
        return
      }
      const data: any = {
        ...fieldsValue,
        startTime: momentToEpoch(startTime),
        endTime: momentToEpoch(endTime),
        vehicleId: vId,
        ...(item && { id: item.id }),
      }
      onModify(data, modalType)
    })
  }

  render() {
    const { item, form, pool, loading, modalType, onModify, t, ...modalProps } = this.props
    const { getFieldDecorator } = form

    return (
      <div>
        <Modal
          {...modalProps}
          bodyStyle={modalBodyStyle}
          footer={
            <>
              {item ? (
                <Button loading={loading} onClick={() => onModify({ id: item.id }, 'delete')}>
                  {t('btn.delete')}
                </Button>
              ) : null}
              <Button loading={loading} type="primary" onClick={this.handleUpdate}>
                {t(`btn.${modalType}`)}
              </Button>
            </>
          }
        >
          <Form {...formItemLayout}>
            <Form.Item label={t('name')}>
              {getFieldDecorator('name', {
                initialValue: item && item.name,
              })(<Input disabled={!!item} />)}
            </Form.Item>
            <Form.Item label={t('productVersion')}>
              {getFieldDecorator('productVersion', {
                initialValue: item && item.productVersion,
              })(<Input />)}
            </Form.Item>
            <Form.Item label={t('vehicleName')}>
              {getFieldDecorator('vehicleName', {
                initialValue: item && item.vehicleName,
              })(<AutoComplete dataSource={Object.keys(pool.vehicleAutoComplete)} />)}
            </Form.Item>
            <Form.Item label={t('description')}>
              {getFieldDecorator('description', {
                initialValue: item && item.description,
              })(<Input />)}
            </Form.Item>
            <Form.Item label={t('path')}>
              {getFieldDecorator('path', {
                initialValue: item && item.path,
              })(<Input disabled={!!item} />)}
            </Form.Item>
            <Form.Item label={t('size')}>
              {getFieldDecorator('size', {
                initialValue: item && item.size,
              })(<InputNumber />)}
            </Form.Item>
            <Form.Item label={t('startTime')}>
              {getFieldDecorator('startTime', {
                initialValue: item && epochToMoment(item.startTime),
              })(<DatePicker showTime />)}
            </Form.Item>
            <Form.Item label={t('endTime')}>
              {getFieldDecorator('endTime', {
                initialValue: item && epochToMoment(item.endTime),
              })(<DatePicker showTime />)}
            </Form.Item>
          </Form>
        </Modal>
      </div>
    )
  }
}

export default Form.create<BagModalProps>()(BagModal)
