import modelExtend from 'dva-model-extend'
import i18next from 'i18next'
import { Model } from 'dva'
import { message } from 'antd'

import { pathMatchRegexp } from '../_helpers'
import { RoadTestsReducerState } from '../_types'
import api from '../_dva_services'
import { modalState, modalModel } from '../_utils'

const initState: RoadTestsReducerState = {
  roadTests: [],
  listingRoadTests: false,
  modifyingRoadTest: false,
  ...modalState,
}

const roadTestsModel = modelExtend<Model>(modalModel, {
  namespace: 'roadTests',
  state: initState,
  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(location => {
        if (pathMatchRegexp('/roadtests', location.pathname)) {
          dispatch({ type: 'reset' })
          dispatch({ type: 'list' })
        }
      })
    },
  },
  reducers: {
    reset() {
      return initState
    },

    listRequest(state, {}) {
      return {
        ...state,
        listingRoadTests: true,
      }
    },

    listSuccess(state: RoadTestsReducerState, { payload }) {
      const { roadTests } = payload

      return {
        ...state,
        roadTests,
        listingRoadTests: false,
      }
    },

    listFail(state: RoadTestsReducerState) {
      return {
        ...state,
        listingRoadTests: false,
      }
    },

    // modify = create / update / delete
    modifyRequest(state, {}) {
      return {
        ...state,
        modifyingRoadTests: true,
      }
    },

    modifySuccess(state: RoadTestsReducerState) {
      return {
        ...state,
        modifyingRoadTests: false,
      }
    },

    modifyFail(state: RoadTestsReducerState) {
      return {
        ...state,
        modifyingDisttricts: false,
      }
    },
  },
  effects: {
    *list({}, { call, put }) {
      yield put({ type: 'listRequest' })

      try {
        const data = yield call(api.listRoadTests, {})
        const roadTests = data.roadtests

        yield put({
          type: 'listSuccess',
          payload: { roadTests },
        })

        return roadTests
      } catch (e) {
        yield put({ type: 'listFail' })
      }
    },

    *update({ payload }, { call, put }) {
      yield put({ type: 'modifyRequest' })

      try {
        yield call(api.updateRoadTest, payload)

        yield put({ type: 'modifySuccess' })
        yield put({ type: 'list' })

        message.success(i18next.t('msg.updateSuccessful'))
      } catch (e) {
        yield put({ type: 'modifyFail' })

        message.error(i18next.t('msg.updateFailed'))
      }
    },

    *delete({ payload }, { call, put }) {
      yield put({ type: 'modifyRequest' })

      try {
        yield call(api.deleteRoadTest, payload)

        yield put({ type: 'modifySuccess' })
        yield put({ type: 'list' })
        yield put({ type: 'hideModal' })

        message.success(i18next.t('msg.deleteSuccessful'))
      } catch (e) {
        yield put({ type: 'modifyFail' })

        message.error(i18next.t('msg.deleteFailed'))
      }
    },
  },
})

export default roadTestsModel
