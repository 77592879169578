import React from 'react'
import { Table, Button, Input, Icon, Tooltip } from 'antd'
import { ColumnProps } from 'antd/lib/table'

import { PLocation, PointState, District } from '../../../_types'
import { HoverText, StateTag } from '../../../common'

export interface ListProps {
  districts: District[]
  onCreate: () => void
  onEdit: (item: PLocation) => void
  loading: boolean
  t: any
}

export class List extends React.PureComponent<ListProps> {
  getColumnSearchProps = (dataIndex: string): ColumnProps<PLocation> => {
    const { t } = this.props

    const onInputChange = (e: any, setSelectedKeys: any) => {
      setSelectedKeys && setSelectedKeys(e.target.value ? [e.target.value] : [])
    }
    const onReset = (clearFilters: ((selectedKeys: string[]) => void) | undefined) => {
      clearFilters && clearFilters([])
    }
    const onSearch = (selectedKeys: React.ReactText[] | undefined, confirm: (() => void) | undefined) => {
      confirm && confirm()
    }

    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="trips-filter-dropdown">
          <Input
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys ? selectedKeys[0] : ''}
            onChange={e => onInputChange(e, setSelectedKeys)}
            onPressEnter={() => onSearch(selectedKeys, confirm)}
            style={{ width: 160, marginBottom: '0.5rem', display: 'block' }}
          />
          <Button onClick={() => onReset(clearFilters)} size="small" style={{ width: 75, marginRight: 8 }}>
            {t('btn.reset')}
          </Button>
          <Button type="primary" onClick={() => onSearch(selectedKeys, confirm)} size="small" style={{ width: 75 }}>
            {t('btn.search')}
          </Button>
        </div>
      ),
      filterIcon: filtered => (
        <Icon
          type="search"
          style={{
            width: '2rem',
            fontSize: '0.9rem',
            color: filtered ? '#138D75' : undefined,
          }}
        />
      ),
    }
  }

  render() {
    const { districts, t, loading, onCreate, onEdit, ...tableProps } = this.props

    const columns: ColumnProps<PLocation>[] = [
      {
        key: 'name',
        dataIndex: 'name',
        title: t('name'),
        render: (text: string, record) => <HoverText content={record.id} text={text} prefix="ID" />,
        ...this.getColumnSearchProps('name'),
      },
      {
        key: 'districtId',
        dataIndex: 'districtId',
        title: t('district'),
        render: (text: string) => {
          const district = districts.find(district => district.id === text)
          return <HoverText text={district ? district.name : t('tip.unknownDistrict')} content={text} prefix="ID" />
        },
      },
      {
        key: 'description',
        dataIndex: 'description',
        title: t('description'),
      },
      {
        key: 'longitude',
        dataIndex: 'longitude',
        title: t('longitude'),
      },
      {
        key: 'latitude',
        dataIndex: 'latitude',
        title: t('latitude'),
      },
      {
        key: 'altitude',
        dataIndex: 'altitude',
        title: t('altitude'),
      },
      {
        key: 'state',
        dataIndex: 'state',
        title: t('state'),
        render: (text: PointState) => <StateTag state={text} type="plocation" />,
      },
      {
        key: 'actions',
        fixed: 'right',
        title: (
          <Tooltip title={t('tooltip.createPLocation')} mouseEnterDelay={0.5}>
            <Button type="default" size="small" icon="plus" onClick={onCreate} />
          </Tooltip>
        ),
        render: (text, record) => (
          <Button type="link" size="small" onClick={() => onEdit(record)}>
            {t('btn.edit')}
          </Button>
        ),
      },
    ]

    return <Table {...tableProps} columns={columns} rowKey="id" loading={loading} scroll={{ x: true }} />
  }
}
