import modelExtend from 'dva-model-extend'
import i18next from 'i18next'
import { Model } from 'dva'

import { pathMatchRegexp } from '../_helpers'
import { PathsReducerState } from '../_types'
import api from '../_dva_services'
import { modalState, modalModel } from '../_utils'
import { message } from 'antd'

const initState: PathsReducerState = {
  paths: [],
  listingPaths: false,
  modifyingPath: false,
  ...modalState,
}

const pathsModel = modelExtend<Model>(modalModel, {
  namespace: 'paths',
  state: initState,
  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(location => {
        if (pathMatchRegexp('/paths', location.pathname)) {
          dispatch({ type: 'plocations/list' })
          dispatch({ type: 'reset' })
          dispatch({ type: 'list' })
        }
      })
    },
  },
  reducers: {
    reset() {
      return initState
    },

    listRequest(state, {}) {
      return {
        ...state,
        listingPaths: true,
      }
    },

    listSuccess(state: PathsReducerState, { payload }) {
      const { paths } = payload

      return {
        ...state,
        paths,
        listingPaths: false,
      }
    },

    listFail(state: PathsReducerState) {
      return {
        ...state,
        listingPaths: false,
      }
    },

    // modify = create / update / delete
    modifyRequest(state, {}) {
      return {
        ...state,
        modifyingPaths: true,
      }
    },

    modifySuccess(state: PathsReducerState) {
      return {
        ...state,
        modifyingPaths: false,
      }
    },

    modifyFail(state: PathsReducerState) {
      return {
        ...state,
        modifyingPLocations: false,
      }
    },
  },
  effects: {
    *list({}, { call, put }) {
      yield put({ type: 'listRequest' })

      try {
        const data = yield call(api.listPath, {})

        yield put({
          type: 'listSuccess',
          payload: {
            paths: data.paths,
          },
        })
      } catch (e) {
        yield put({ type: 'listFail' })
      }
    },

    *create({ payload }, { call, put }) {
      yield put({ type: 'modifyRequest' })

      try {
        yield call(api.createPath, { path: payload })

        yield put({ type: 'modifySuccess' })
        yield put({ type: 'list' })
        yield put({ type: 'hideModal' })

        message.success(i18next.t('msg.createSuccessful'))
      } catch (e) {
        yield put({ type: 'modifyFail' })

        message.error(i18next.t('msg.createFailed'))
      }
    },

    *update({ payload }, { call, put }) {
      yield put({ type: 'modifyRequest' })

      try {
        yield call(api.updatePath, payload)

        yield put({ type: 'modifySuccess' })
        yield put({ type: 'list' })

        message.success(i18next.t('msg.updateSuccessful'))
      } catch (e) {
        yield put({ type: 'modifyFail' })

        message.error(i18next.t('msg.updateFailed'))
      }
    },

    *delete({ payload }, { call, put }) {
      yield put({ type: 'modifyRequest' })

      try {
        yield call(api.deletePath, payload)

        yield put({ type: 'modifySuccess' })
        yield put({ type: 'list' })
        yield put({ type: 'hideModal' })

        message.success(i18next.t('msg.deleteSuccessful'))
      } catch (e) {
        yield put({ type: 'modifyFail' })

        message.error(i18next.t('msg.deleteFailed'))
      }
    },
  },
})

export default pathsModel
