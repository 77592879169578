import React from 'react'
import { Table, Tooltip, Button } from 'antd'
import { ColumnProps } from 'antd/lib/table'

import { Tag } from '../../../_types'

interface TagListProps {
  t: any
  onCreate: () => void
  onEdit: (item: Tag) => void
}

class TagTable extends React.PureComponent<TagListProps> {
  render() {
    const { t, onCreate, onEdit, ...tableProps } = this.props
    const columns: ColumnProps<Tag>[] = [
      {
        title: t('name'),
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: t('description'),
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: t('type'),
        dataIndex: 'type',
        key: 'type',
      },
      {
        key: 'action',
        title: (
          <Tooltip title={t('tooltip.addTag')} mouseEnterDelay={0.5}>
            <Button type="default" size="small" icon="plus" onClick={onCreate} />
          </Tooltip>
        ),
        render: (text: string, tag: Tag) => (
          <div className="tags-actions">
            <div className="tags-actions-btn">
              <Button type="link" size="small" onClick={() => onEdit(tag)}>
                {t('btn.edit')}
              </Button>
            </div>
            {/* <div className="tags-actions-btn">
              <Button type="primary" size="small" onClick={() => onDelete(tag)}>
                {t('btn.delete')}
              </Button>
            </div> */}
          </div>
        ),
      },
    ]

    return (
      <div>
        <Table {...tableProps} columns={columns} rowKey="id" />
      </div>
    )
  }
}

export default TagTable
