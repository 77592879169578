import lodash from 'lodash'
import { flattenObj } from '../_helpers'
import { BagSearch, FaultSearch, VEventSearch, Sort } from '../_types'
import { getConstMapValue } from '../_utils'

export const queryConst = {
  match: 'MATCH_QUERY',
  matchPhrase: 'MATCH_PHRASE_QUERY',
  term: 'TERM_QUERY',
  range: 'RANGE_QUERY',
  multiMatch: 'MULTI_MATCH_QUERY',
  exists: 'EXISTS_QUERY',
}

export interface SearchKeys {
  existKeys?: string[]
  matchKeys?: string[]
  rangeKeys?: string[]
  multiMatchKeys?: string[]
}

export const sortQuery = (sort: Sort) => {
  return {
    key: getConstMapValue(sort.field),
    sort: getConstMapValue(sort.order),
  }
}

export const searchQuery = (obj: BagSearch | FaultSearch | VEventSearch, keys: SearchKeys) => {
  if (!obj) {
    return {}
  }

  const flatted = flattenObj(obj)

  const query = {
    must_queries: [] as any,
    should_queries: [] as any,
    must_not_queries: [] as any,
  }

  // exists keys query
  if (keys.existKeys) {
    query.must_queries.push({
      keys: keys.existKeys,
      queryType: queryConst.exists,
    })
  }

  // match query
  keys.matchKeys?.forEach(k => {
    if (flatted[k]) {
      query.must_queries.push({
        key: getConstMapValue(k),
        value: flatted[k],
        queryType: queryConst.match,
      })
    }
  })

  // range_query
  keys.rangeKeys?.forEach(k => {
    if (Array.isArray(flatted[k]) && flatted[k].length === 2 && !(flatted[k][0] === '-1' && flatted[k][1] === '-1')) {
      query.must_queries.push({
        key: getConstMapValue(k),
        from: flatted[k][0] || '-1',
        to: flatted[k][1] || Date.now().toString(),
        queryType: queryConst.range,
      })
    }
  })

  // multi_match_query
  if (obj.text) {
    query.must_queries.push({
      value: obj.text,
      keys: keys.multiMatchKeys,
      queryType: queryConst.multiMatch,
    })
  }

  return lodash.pickBy(query, prop => prop.length)
}
