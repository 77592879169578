import { combineReducers } from 'redux'

import { search } from './search.reducer'

const rootReducer = combineReducers({
  search,
})

export default rootReducer

export type State = ReturnType<typeof rootReducer>
