import React from 'react'
import { AutoComplete, Button, Input, Form, DatePicker, Collapse, Icon, Divider } from 'antd'
import { FormComponentProps } from 'antd/lib/form'

import { BagSearch, BagPool } from '../../../_types'
import { epochToMoment, momentToEpoch } from '../../../_helpers'

const { RangePicker } = DatePicker
const { Panel } = Collapse

// Collapse Panel style
const panelStyle = {
  border: 'none',
}

interface HeaderProps {
  item: BagSearch
  pool: BagPool
  t: any
  onSearch: () => void
  onChange: (field: any) => void
  onClear: () => void
}

interface HeaderState {
  activeKey: string
}

interface SearchFromProps extends HeaderProps, FormComponentProps {
  activeKey: string
  onCollapse: () => void
}

const SearchForm = Form.create<SearchFromProps>({
  mapPropsToFields(props) {
    return {
      name: Form.createFormField({ value: props.item.name }),
      text: Form.createFormField({ value: props.item.text }),
      vehicleName: Form.createFormField({ value: props.item.vehicleName }),
      productVersion: Form.createFormField({ value: props.item.productVersion }),
      'audit.createdBy': Form.createFormField({
        value: props.item.audit.createdBy,
      }),
      'time.createdAt': Form.createFormField({
        value: props.item.time.createdAt.map(i => epochToMoment(i)),
      }),
      startTime: Form.createFormField({
        value: props.item.startTime.map(i => epochToMoment(i)),
      }),
      endTime: Form.createFormField({
        value: props.item.endTime.map(i => epochToMoment(i)),
      }),
    }
  },
  onFieldsChange(props, fields) {
    if (fields.time) {
      for (let k in fields.time) {
        fields.time[k] = fields.time[k].value.map((i: any) => momentToEpoch(i))
      }
      fields.time = {
        ...props.item.time,
        ...fields.time,
      }
    }

    if (fields.audit) {
      for (let k in fields.audit) {
        fields.audit[k] = fields.audit[k].value
      }
      fields.audit = {
        ...props.item.audit,
        ...fields.audit,
      }
    }

    if (fields.startTime) {
      fields.startTime = fields.startTime.value.map((i: any) => momentToEpoch(i))
    }

    if (fields.endTime) {
      fields.endTime = fields.endTime.value.map((i: any) => momentToEpoch(i))
    }

    props.onChange(fields)
  },
})(({ form, t, pool, onSearch, onClear, item, activeKey, onCollapse }: SearchFromProps) => {
  const { getFieldDecorator } = form

  const onPressEnter = (e: React.KeyboardEvent) => {
    e.preventDefault()
    onSearch()
  }

  return (
    <Form layout="inline" onSubmit={onSearch}>
      <Form.Item>
        {getFieldDecorator('text')(
          <Input
            addonAfter={
              <Icon type="filter" style={{ color: activeKey ? '#16A085' : undefined }} onClick={onCollapse} />
            }
            style={{ minWidth: '32rem' }}
            placeholder={`${t('name')} / ${t('path')} / ${t('productVersion')} / ${t('vehicleName')} / ${t(
              'description',
            )}`}
            onPressEnter={onPressEnter}
          />,
        )}
      </Form.Item>
      <Form.Item>
        <Button type="primary" onClick={onSearch}>
          {t('btn.search')}
        </Button>
        <Button style={{ marginLeft: '0.5rem' }} onClick={onClear}>
          {t('btn.reset')}
        </Button>
      </Form.Item>
      <Collapse bordered={false} activeKey={activeKey} onChange={onCollapse}>
        <Panel key="0" header={null} style={panelStyle} showArrow={false}>
          <Divider dashed style={{ margin: '1rem 0' }} />
          <Form.Item label={t('name')}>{getFieldDecorator('name')(<Input onPressEnter={onPressEnter} />)}</Form.Item>
          <Form.Item label={t('vehicleName')}>
            {getFieldDecorator('vehicleName')(<AutoComplete dataSource={Object.keys(pool.vehicleAutoComplete)} />)}
          </Form.Item>
          <Form.Item label={t('productVersion')}>
            {getFieldDecorator('productVersion')(<Input onPressEnter={onPressEnter} />)}
          </Form.Item>
          <Form.Item label={t('createdBy')}>
            {getFieldDecorator('audit.createdBy')(<Input onPressEnter={onPressEnter} />)}
          </Form.Item>
          <Form.Item label={t('timeRange')}>
            {getFieldDecorator('time.createdAt')(
              <RangePicker showTime={{ format: 'HH:mm:ss' }} format="YYYY-MM-DD HH:mm:ss" />,
            )}
          </Form.Item>
          <Form.Item label={t('startTimeRange')}>
            {getFieldDecorator(`startTime`)(
              <RangePicker showTime={{ format: 'HH:mm:ss' }} format="YYYY-MM-DD HH:mm:ss" />,
            )}
          </Form.Item>
          <Form.Item label={t('endTimeRange')}>
            {getFieldDecorator(`endTime`)(
              <RangePicker showTime={{ format: 'HH:mm:ss' }} format="YYYY-MM-DD HH:mm:ss" />,
            )}
          </Form.Item>
        </Panel>
      </Collapse>
    </Form>
  )
})

class Header extends React.PureComponent<HeaderProps, HeaderState> {
  constructor(props: any) {
    super(props)

    this.state = {
      activeKey: '',
    }
  }

  get collapseProps() {
    const { activeKey } = this.state

    return {
      activeKey,
      onCollapse: () => {
        this.setState({ activeKey: activeKey ? '' : '0' })
      },
    }
  }

  render() {
    return <SearchForm {...this.props} {...this.collapseProps} />
  }
}

export default Header
