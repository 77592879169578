import React from 'react'
import { AutoComplete, Button, Input, Form, DatePicker, Collapse, Icon, Divider } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import moment from 'moment'
import { PerformancesSearch, PerformancesPool } from '../../../_types'
import { epochToMoment, momentToEpoch } from '../../../_helpers'

const { RangePicker } = DatePicker
const { Panel } = Collapse

// Collapse Panel style
const panelStyle = {
    border: 'none',
}

interface HeaderProps {
    item: PerformancesSearch
    pool: PerformancesPool
    t: any
    onSearch: () => void
    onChange: (field: any) => void
    onClear: () => void
    onUploadFun: () => void
}

interface HeaderState {
    activeKey: string
}

interface SearchFromProps extends HeaderProps, FormComponentProps {
    activeKey: string
    onCollapse: () => void
}

const SearchForm = Form.create<SearchFromProps>({
    mapPropsToFields(props) {
        return {
            vehicleName: Form.createFormField({ value: props.item.vehicleName }),
            productVersion: Form.createFormField({ value: props.item.productVersion }),
            'audit.createdBy': Form.createFormField({
                value: props.item.audit.createdBy,
            }),
            'time.createdAt': Form.createFormField({
                value:
                    [epochToMoment(props.item.time.createdAt[0]), epochToMoment(props.item.time.createdAt[1])]
            }),
            startTime: Form.createFormField({
                value: props.item.startTime.map(i => epochToMoment(i)),
            }),
            endTime: Form.createFormField({
                value: props.item.endTime.map(i => epochToMoment(i)),
            }),
            taskId: Form.createFormField({ value: props.item.taskId }),
            taskModel: Form.createFormField({ value: props.item.taskModel }),
            modType: Form.createFormField({ value: props.item.modType }),
        }
    },
    onFieldsChange(props, fields) {
        if (fields.time) {
            for (let k in fields.time) {
                fields.time[k] = fields.time[k].value.map((i: any) => momentToEpoch(i))
            }
            fields.time = {
                ...props.item.time,
                ...fields.time,
            }
        }

        if (fields.audit) {
            for (let k in fields.audit) {
                fields.audit[k] = fields.audit[k].value
            }
            fields.audit = {
                ...props.item.audit,
                ...fields.audit,
            }
        }

        if (fields.startTime) {
            fields.startTime = fields.startTime.value.map((i: any) => momentToEpoch(i))
        }

        if (fields.endTime) {
            fields.endTime = fields.endTime.value.map((i: any) => momentToEpoch(i))
        }

        props.onChange(fields)
    },
})(({ form, t, pool, onSearch, onClear, onUploadFun, item, activeKey, onCollapse }: SearchFromProps) => {
    const { getFieldDecorator } = form
    const onPressEnter = (e: React.KeyboardEvent) => {
        e.preventDefault()
        onSearch()
    }
    // console.log(Object.keys(pool.vehicleAutoComplete))
    return (
        <Form layout="inline" onSubmit={onSearch}>
            {/* <Form.Item>
        {getFieldDecorator('text')(
          <Input
            addonAfter={
              <Icon type="filter" style={{ color: activeKey ? '#16A085' : undefined }} onClick={onCollapse} />
            }
            style={{ minWidth: '32rem' }}
            placeholder={`${t('name')} / ${t('path')} / ${t('productVersion')} / ${t('vehicleName')} / ${t(
              'description',
            )}`}
            onPressEnter={onPressEnter}
          />,
        )}
      </Form.Item> */}
            {/* <Form.Item>
        <Button type="primary" onClick={onSearch}>
          {t('btn.search')}
        </Button>
        <Button style={{ marginLeft: '0.5rem' }} onClick={onClear}>
          {t('btn.reset')}
        </Button>
      </Form.Item> */}
            {/* <Collapse bordered={false}  onChange={onCollapse}>  
      activeKey={activeKey}
        <Panel key="0" header={null} style={panelStyle} showArrow={true}>
          <Divider dashed style={{ margin: '1rem 0' }} /> */}
            {/* <Form.Item label={t('name')}>{getFieldDecorator('name')(<Input onPressEnter={onPressEnter} />)}</Form.Item> */}

            <Form.Item label={t('vehicleName')}>
                {getFieldDecorator('vehicleName')(<AutoComplete dataSource={Object.keys(pool.vehicleAutoComplete)} />)}
            </Form.Item>
            {/* <Form.Item label={t('ts')}>
            {getFieldDecorator('eventTime')(<Input onPressEnter={onPressEnter}/>)}
          </Form.Item> */}
            {/* <Form.Item label={t('productVersion')}>
            {getFieldDecorator('productVersion')(<Input onPressEnter={onPressEnter} />)}
          </Form.Item> */}
            {/* <Form.Item label={t('createdBy')}>
            {getFieldDecorator('audit.createdBy')(<Input onPressEnter={onPressEnter} />)}
          </Form.Item> */}
            <Form.Item label={t('timeRange')}>
                {getFieldDecorator('time.createdAt')(
                    <RangePicker defaultPickerValue={[,moment().endOf('day')]}
                        placeholder={['开始时间', '结束时间']} format="YYYY-MM-DD"
                    />,
                )}
            </Form.Item>
            {/* <Form.Item label={t('startTimeRange')}>
            {getFieldDecorator(`startTime`)(
              <RangePicker showTime={{ format: 'HH:mm:ss' }} format="YYYY-MM-DD HH:mm:ss" />,
            )}
          </Form.Item>
          <Form.Item label={t('endTimeRange')}>
            {getFieldDecorator(`endTime`)(
              <RangePicker showTime={{ format: 'HH:mm:ss' }} format="YYYY-MM-DD HH:mm:ss" />,
            )}
          </Form.Item> */}
            <Form.Item label={t('taskId')}>
                {getFieldDecorator('taskId')(<Input />)}
            </Form.Item>
            <Form.Item label={t('productVersion')}>
                {getFieldDecorator('productVersion')(<Input />)}
            </Form.Item>
            <Form.Item label={t('modType')}>
                {getFieldDecorator('modType')(
                    <AutoComplete dataSource={
                        //   [{value:'manual_driving',text:'油门'},{value:'manual_braking',text:'刹车'},
                        // {value:'manual_steering',text:'方向盘'},{value:'manual_button',text:'按钮'},{value:'none',text:'其他'},{value:'ManualAction',text:'手动触发'},]
                        pool.modTypes
                    } allowClear/>
                )}
            </Form.Item>
            <Form.Item label={t('taskModel')}>
                {getFieldDecorator('taskModel')(
                    <AutoComplete dataSource={
                        //   [{value:'manual_driving',text:'油门'},{value:'manual_braking',text:'刹车'},
                        // {value:'manual_steering',text:'方向盘'},{value:'manual_button',text:'按钮'},{value:'none',text:'其他'},{value:'ManualAction',text:'手动触发'},]
                        pool.taskModels
                    } allowClear />
                )}
            </Form.Item>
            <Form.Item>
                <Button type="primary" onClick={onSearch}>
                    {t('btn.search')}
                </Button>
                <Button style={{ marginLeft: '0.5rem' }} onClick={onClear}>
                    {t('btn.reset')}
                </Button>
            </Form.Item>
            {/* </Panel>
      </Collapse> */}
        </Form>
    )
})

class Header extends React.PureComponent<HeaderProps, HeaderState> {
    constructor(props: any) {
        super(props)

        this.state = {
            activeKey: '',
        }
    }

    get collapseProps() {
        const { activeKey } = this.state

        return {
            activeKey,
            onCollapse: () => {
                this.setState({ activeKey: activeKey ? '' : '0' })
            },
        }
    }

    render() {
        return <SearchForm {...this.props} {...this.collapseProps} />
    }
}

export default Header
