// Basic libs
import React from 'react'
import './Login.scss'
import { connect } from 'dva'
import { withTranslation, WithTranslation } from 'react-i18next'
import { withCookies, Cookies } from 'react-cookie'
import { Redirect, withRouter, RouteComponentProps } from 'react-router-dom'
import { Dispatch } from 'redux'

// Custom libs
import { DvaState, AppReducerState, AuthReducerState } from '../../_types'

// UI libs
import { LangButton } from '../../common'
import { Button, Input, Icon, notification } from 'antd'

interface LoginProps extends AppReducerState, AuthReducerState, WithTranslation, RouteComponentProps {
  dispatch: Dispatch
  cookies: Cookies
}

interface LoginState {
  username: string
  password: string
  usernameMsg: string
  passwordMsg: string
  [propName: string]: string
}

class Login extends React.Component<LoginProps, LoginState> {
  state: LoginState = {
    username: '',
    password: '',
    usernameMsg: '',
    passwordMsg: '',
  }

  componentDidMount() {
    window.addEventListener('keypress', this.handlePressEnter)
  }

  componentWillUnmount() {
    window.removeEventListener('keypress', this.handlePressEnter)
  }

  componentDidUpdate(prevProps: LoginProps) {
    const { message, dispatch, t } = this.props
    const prevMessage = prevProps.message

    if (message && message.detail !== (prevMessage ? prevMessage.detail : '')) {
      notification.error({
        message: t('loginFailed'),
        description: this.translateErrorToMessage(message.detail),
        duration: 3,
        placement: 'topLeft',
        onClose: () => {
          dispatch({ type: 'auth/clear' })
        },
      })
    }
  }

  handleLogin = () => {
    const { username, password } = this.state
    const { t, dispatch } = this.props

    username || this.setState({ usernameMsg: t('usernameMsg') })
    password || this.setState({ passwordMsg: t('passwordMsg') })

    if (username && password) {
      dispatch({
        type: 'auth/login',
        payload: {
          name: username,
          password: password,
        },
      })
    }
  }

  handleInputChange: React.ChangeEventHandler = e => {
    const { usernameMsg, passwordMsg } = this.state
    const { name, value } = e.target as HTMLInputElement

    this.setState({
      [name]: value.trim(),
    })

    if (usernameMsg || passwordMsg) {
      this.clearMessages()
    }
  }

  handlePressEnter = (e: KeyboardEvent) => {
    const { username, password } = this.state

    if (e.keyCode === 13 && (username || password)) {
      this.handleLogin()
    }
  }

  clearMessages = () => {
    this.setState({
      usernameMsg: '',
      passwordMsg: '',
    })
  }

  translateErrorToMessage = (detail: string) => {
    const { t } = this.props

    const MESSAGES = [t('message.unkown') + ': ' + detail, t('message.noUser'), t('message.wrongPwd')]
    const ERRORS = ['record not found', 'given password']

    const index = ERRORS.reduce((prevValue, ERROR, index) => (detail.includes(ERROR) ? index + 1 : prevValue), 0)

    return MESSAGES[index]
  }

  render() {
    const { username, password, usernameMsg, passwordMsg } = this.state
    const { t, loggingIn, cookies, location } = this.props
    const { from } = location.state || { from: { pathname: '/' } }
    if (cookies.get('token')) {
      return <Redirect to={from} />
    }

    return (
      <div className="login">
        <div className="login-left">
          <div className="login-panel">
            <div className="login-row">
              <div className="login-lang-row">
                <span className="login-text-logo">ALLRIDE.AI</span>
                <LangButton />
              </div>
            </div>
            <div className="login-row">
              <Input
                placeholder={t('plhd.username')}
                prefix={<Icon type="user" />}
                name="username"
                value={username}
                onChange={this.handleInputChange}
                allowClear
              />
              <div className="login-message">
                <span>{usernameMsg}</span>
              </div>
            </div>
            <div className="login-row">
              <Input.Password
                placeholder={t('plhd.password')}
                prefix={<Icon type="lock" />}
                name="password"
                value={password}
                onChange={this.handleInputChange}
                allowClear
              />
              <div className="login-message">
                <span>{passwordMsg}</span>
              </div>
            </div>
            <div className="login-row">
              <Button type="primary" onClick={this.handleLogin} loading={loggingIn}>
                {t('btn.login')}
              </Button>
            </div>
          </div>
        </div>
        <div className="login-right" />
      </div>
    )
  }
}

const mapStateToProps = (state: DvaState) => {
  return {
    ...state.app,
    ...state.auth,
  }
}

export default connect(mapStateToProps)(withRouter(withCookies(withTranslation('login')(Login))))
