import React from 'react'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { Button, Modal } from 'antd'

class SearchShow extends React.PureComponent<any> {
  state = {
    visible: false,
  }
  showModal = () => {
    this.setState({
      visible: true,
    })
  }
  handleClick = () => {
    this.showModal()
  }
  handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  codeString = (data: any) => {
    const prefix = 'oss://allride-data/data/car/raw/'
    const bags = data
      .reduce((acc: any, cur: any) => {
        acc.push(cur.bags)
        return acc
      }, [])
      .flat()
    const stringForamter = (str: string) => {
      const datetime = str.split('_')[1]
      const dateStr = datetime
        .split('-')
        .slice(0, 3)
        .join('/')
      return `${dateStr}/${str}`
    }
    let codestring = ''
    bags.forEach((item: string) => {
      const cpStr = stringForamter(item)
      codestring += `ossutil64 cp ${prefix}${cpStr} .\n`
    })
    return codestring
    // const code = bags.
  }
  render() {
    const { button, data } = this.props
    if (button) {
      return (
        <div>
          <Button onClick={this.handleClick} type="primary">
            Show Download Script
          </Button>
          <Modal title="script" visible={this.state.visible} footer={null} onCancel={this.handleCancel}>
            <SyntaxHighlighter language="bash">{this.codeString(data)}</SyntaxHighlighter>
          </Modal>
        </div>
      )
    }
    return null
  }
}

export default SearchShow
