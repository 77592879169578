import modelExtend from 'dva-model-extend'
import i18next from 'i18next'
import { Model } from 'dva'
import { message } from 'antd'
// @ts-ignore
import * as echarts from 'echarts';

import { pathMatchRegexp } from '../_helpers'
import { ScenarioReducerState } from '../_types/scenariosurvey.types'
import api from '../_dva_services'
import { pageModel, modalModel } from '../_utils'
import { pad } from 'lodash';
import { Console } from 'console';

const funArr = (arr:any,num:string)=>{
  let newArr:any = []
  arr.map((item:any)=>{
    let obj = {
      name:'',
      value: 0 ,
      total: 0
    }
    obj.name = item.key
    obj.value = Number(item.value)
    obj.total = Number(num)
    newArr.push(obj)
  })
  return newArr
}
const initState: any = {
  list:[],
  // selectOption: [{
  //   label: '苏州北',
  //   value: 'suzhoubei' 
  // }, {
  //   label: '江宁',
  //   value: 'jiangning'
  // }],
  // vehicleOption: [{
  //   label: '路端',
  //   value: 'rsu' 
  // }, {
  //   label: '车端',
  //   value: 'car'
  // }],
  // taskTypeOption:[{
  //   label: 'interactive_class',
  //   value: 'interactive_class' 
  // }, {
  //   label: 'perception_class',
  //   value: 'perception_class'
  // }],
  // caseTotalChartOption: {
  //   title: {
  //     show: true,
  //     text: '场景总数(0个)',
  //     left: 'center',
  //     top: 'center',
  //     textStyle: {
  //       fontSize: 15
  //     }
  //   },
  //   tooltip: {
  //     trigger: 'item',
  //     formatter: '类别：{b}<br/>数量：{c}'
  //   },
  //   legend: {
  //     bottom: '20',
  //     icon: 'circle',
  //     left: 'center'
  //   },
  //   grid: {
  //     bottom:20
  //   },
  //   color:['#5470C6','#91CC75','#73C0DE','#EE6666','#FAC858'],
  //   series: [{
  //     radius: ['35%', '60%'],
  //     avoidLabelOverlap: true,
  //     name: '接管原因统计',
  //     itemStyle: {
  //       borderRadius: 10,
  //       borderColor: '#f4f4f4',
  //       borderWidth: 2,
  //     },
  //     label: {
  //       show: true,
  //       formatter(params: any) {
  //         let percentNum = params.data.total ? ((params.value / params.data.total * 100).toFixed(2)) : 0
  //         return params.name + ' :' + params.value + ' (' + percentNum + '%)'
  //       },
  //       position: 'outside'
  //     },
  //     labelLine: {
  //       show: true
  //     },
  //     type: 'pie',
  //     minAngle: 3,
  //     data: []
  //   }]
  // },
  // interactiveChartOption: {
  //   title: {
  //     show: true,
  //     text: '交互(0个)',
  //     left: 'center',
  //     top: 'center',
  //     textStyle: {
  //       fontSize: 15
  //     }
  //   },
  //   tooltip: {
  //     trigger: 'item',
  //     formatter: '类别：{b}<br/>数量：{c}'
  //   },
  //   legend: {
  //     bottom: '0',
  //     icon: 'circle',
  //     left: 'center'
  //   },
  //   series: [{
  //     radius: ['35%', '60%'],
  //     avoidLabelOverlap: true,
  //     name: '一级分类',
  //     itemStyle: {
  //       borderColor: '#f4f4f4',
  //       borderWidth: 2,
  //       color(params: any) {
  //         let colorList = [
  //           ['#108D89', '#83FFE9'],
  //           ['#8D3510', '#FFA783'],
  //           ['#651180', '#D099FF'],
  //           ['#4B8D10', '#83FFC0'],
  //           ['#8D104B', '#FF83D8'],
  //           ['#EF33B1', '#F6E6BC'],
  //           ['#7F610F', '#FFE899'],
  //           ['#F9957F', '#F2F5D0'],
  //           ['#6CC6CB', '#EAE5C9'],
  //           ['#4B8D10', '#83FFC0'],
  //           ['#C973FF', '#AEBAF8'],
  //           ['#0F5C7F', '#99DFFF']
  //         ]
  //         return new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
  //           offset: 0,
  //           color: colorList[params.dataIndex][0]
  //         }, {
  //           offset: 1,
  //           color: colorList[params.dataIndex][1]
  //         }])
  //       }
  //     },
  //     label: {
  //       show: true,
  //       formatter(params: any) {
  //         let percentNum = params.data.total ? ((params.value / params.data.total * 100).toFixed(2)) : 0
  //         return params.name + ' :' + params.value + ' (' + percentNum + '%)'
  //       },
  //       position: 'outside'
  //     },
  //     labelLine: {
  //       show: true
  //     },
  //     type: 'pie',
  //     minAngle: 3,
  //     data: []
  //   }]
  // },
  // perceptionChartOption: {
  //   title: {
  //     show: true,
  //     text: '感知(0个)',
  //     left: 'center',
  //     top: 'center',
  //     textStyle: {
  //       fontSize: 15
  //     }
  //   },
  //   tooltip: {
  //     trigger: 'item',
  //     formatter: '类别：{b}<br/>数量：{c}'
  //   },
  //   legend: {
  //     bottom: '0',
  //     icon: 'circle',
  //     left: 'center'
  //   },
  //   series: [{
  //     radius: ['35%', '60%'],
  //     avoidLabelOverlap: true,
  //     //nameS
  //     itemStyle: {
  //       borderColor: '#f4f4f4',
  //       borderWidth: 2,
  //       color(params: any) {
  //         let colorList = [
  //           ['#108D89', '#83FFE9'],
  //           ['#8D3510', '#FFA783'],
  //           ['#651180', '#D099FF'],
  //           ['#4B8D10', '#83FFC0'],
  //           ['#8D104B', '#FF83D8'],
  //           ['#EF33B1', '#F6E6BC'],
  //           ['#7F610F', '#FFE899'],
  //           ['#F9957F', '#F2F5D0'],
  //           ['#6CC6CB', '#EAE5C9'],
  //           ['#4B8D10', '#83FFC0'],
  //           ['#C973FF', '#AEBAF8'],
  //           ['#0F5C7F', '#99DFFF']
  //         ]
  //         return new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
  //           offset: 0,
  //           color: colorList[params.dataIndex][0]
  //         }, {
  //           offset: 1,
  //           color: colorList[params.dataIndex][1]
  //         }])
  //       }
  //     },
  //     label: {
  //       show: true,
  //       formatter(params: any) {
  //         let percentNum = params.data.total ? ((params.value / params.data.total * 100).toFixed(2)) : 0
  //         return params.name + ' :' + params.value + ' (' + percentNum + '%)'
  //       },
  //       position: 'outside'
  //     },
  //     labelLine: {
  //       show: true
  //     },
  //     type: 'pie',
  //     minAngle: 3,
  //     data: [
  //       {
  //         "value": 2,
  //         "name": "nudge",
  //         "total": 34
  //       },
  //       {
  //         "value": 3,
  //         "name": "物体重合",
  //         "total": 34
  //       },
  //       {
  //         "value": 1,
  //         "name": "会车",
  //         "total": 34
  //       },
  //       {
  //         "value": 7,
  //         "name": "cut-out",
  //         "total": 34
  //      },
  //       {
  //         "value": 6,
  //         "name": "cut-in",
  //         "total": 34
  //       },
  //       {
  //         "value": 5,
  //         "name": "变道跟车",
  //         "total": 34
  //       },
  //       {
  //         "value": 4,
  //         "name": "交叉穿行",
  //         "total": 34
  //       },
  //       {
  //         "value": 3,
  //         "name": "前方跟车",
  //         "total": 34
  //       },
  //       {
  //           "value": 2,
  //           "name": "后方跟车",
  //           "total": 34
  //       },
  //       {
  //           "value": 1,
  //           "name": "逆行",
  //           "total": 34
  //       }
  //    ]
  //   }]
  // },
  // caseOption: {
  //   title: {
  //     text: '按日期case条数统计',
  //     left: 'center',
  //     y: 15,
  //     textStyle: {
  //       fontSize: 15,
  //     }
  //   },
  //   color: ['rgba(58, 190, 250, 1)', 'rgba(255, 102, 0, 1)'],
  //   tooltip: {
  //     trigger: 'axis',
  //     formatter: '{b0}<br/>case：{c0}条'
  //   },
  //   dataZoom: [{
  //     show: true,
  //     start: 5,
  //     end: 95
  //   }],
  //   grid: {
  //     top: 50,
  //     left: 50,
  //     right:50,
  //     bottom:60
  //   },
  //   xAxis: {
  //     type: 'category',
  //     data: []
  //   },
  //   yAxis: [
  //     {
  //       type: 'value'
  //     }
  //   ],
  //   series: [
  //     {
  //       type: 'line',
  //       data: [],
  //     }
  //   ]
  // },
  listingBags: false,
}

const scenariorModel = modelExtend<Model>(pageModel, modalModel, {
  namespace: 'scenario',
  state: initState,
  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(location => {
        if (pathMatchRegexp('/scenariosurvey', location.pathname)) {
          dispatch({ type: 'initPageData' })
        }
      })
    },
  },
  reducers: {
    initPageData() {
      return initState
    },
    scenariorData(state: ScenarioReducerState, { payload }) {
      return {
        ...state,
        ...payload
      }
    },
    listRequest(state, { }) {
      return {
        ...state,
        listingBags: true
      }
    },
    listSuccess(state, { }) {
      return {
        ...state,
        listingBags: false
      }
    },
    listFail(state, { }) {
      return {
        ...state,
        listingBags: false
      }
    }
  },
  effects: {
    *setScenarioChart({payload},{call,put}){
      try {
        yield put({ type: 'listRequest' })
        // 数据集统计数据
        const data = yield call(api.scenarioTotalList, {
          area: payload.area||'',
          data_source_type:payload.dataSourceType||'',
          start_date: payload.startDate,
          end_date: payload.endDate
        })
        // 数据集验证统计数据
        const verifyData = yield call(api.scenarioVerify,{})
        if (data && data.items && verifyData) {
          // 根据 dataSourceType,area,sourceTable归类数据
          let scenarioTitle:any = {}
          let itemsArr:any = []
          
          verifyData.items.forEach((item:any) => {
            let itemObj:any = {}
            let titleKey = `${item.dataSourceType}-${item.area}-${item.sourceTable}`
            if(!scenarioTitle[titleKey]){
              scenarioTitle[titleKey] = true
              // 给每条数据添加标题
              itemObj.title = titleKey
              itemObj.items = []
              itemObj.secondItems = []
              itemObj.verifyT = {
                minedBagCntT:0,
                minedScenarioCntT:0,
                minedDaysT:0,
                classifiedScenarioCntT:0,
                validScenarioCntT:0,
                validOriginalP:'',
                generatedSimulateScenarioCntT:0,
                simulatedScenarioCntT:0,
                simulatedGeneratedP:'',
                simulateSucceedCntT:0,
                simulateSucceedP:'',
                verifiedScenarioCntT:0,
                verifiedScenarioP:'',
                TP:0,
                FP:0,
                TN:0,
                FN:0,
                accuracyP:'',
                precisionP:'',
                recallP:'',
                coverP:'',
              }
              // 给标签展示顺序排序
              if(item.area === 'suzhoubei' && item.sourceTable === 'scenario_conflict_interaction'){
                itemObj.sort = 0
              }else if(item.area === 'jiangning' && item.sourceTable === 'scenario_conflict_interaction'){
                itemObj.sort = 1
              }else {
                itemObj.sort = 2
              }
              itemsArr.push(itemObj)
            }
          });
          itemsArr.sort((a:any,b:any)=>{
            return a.sort >= b.sort ? 1 : -1
          })
          console.log(itemsArr)
          // 标题数据模块
          itemsArr.forEach((ele:any) => {
            let caseTypeObj:any = {}
            let caseSecondObj:any = {}
            // 数据集统计表
            data.items.forEach((item:any) => {
              let caseTypeData:any = {}
              let caseSecondData:any = {}
              let titleKey = `${item.dataSourceType}-${item.area}-${item.sourceTable}` 
              if(titleKey === ele.title){
                // 第一个表格table items
                if(!caseTypeObj[item.realCaseType]){
                  caseTypeObj[item.realCaseType] = true
                  // 给每条数据添加类型标题
                  caseTypeData.name = item.realCaseType
                  caseTypeData.interactive = 0
                  caseTypeData.perception = 0
                  // 判断一级分类
                  if(item.scenarioPrimaryClassification === 'interactive_class'){
                    caseTypeData.interactive += Number(item.cnt)
                  }else if(item.scenarioPrimaryClassification === 'perception_class'){
                    caseTypeData.perception += Number(item.cnt)
                  }
                  ele.items.push(caseTypeData)
                }else {
                  ele.items.forEach((list:any) => {
                    if(list.name === item.realCaseType){
                      if(item.scenarioPrimaryClassification === 'interactive_class'){
                        list.interactive += Number(item.cnt)
                      }else if(item.scenarioPrimaryClassification === 'perception_class'){
                        list.perception += Number(item.cnt)
                      }
                    }
                  });
                }
                //第二个表格
                if(!caseSecondObj[item.scenarioSecondaryClassification]){
                  caseSecondObj[item.scenarioSecondaryClassification] = true
                  // 给每条数据添加类型标题
                  caseSecondData.name = item.scenarioSecondaryClassification
                  caseSecondData.perception = 0
                  caseSecondData.map = 0
                  caseSecondData.prediction = 0
                  caseSecondData.planning = 0
                  caseSecondData.control = 0
                  caseSecondData.simulation = 0
                  caseSecondData.comprehensive = 0
                  caseSecondData.unknown = 0
                  // 判断一级分类
                  if(item.realScenarioProblemAscription === 'perception'){
                    caseSecondData.perception += Number(item.cnt)
                  }else if(item.realScenarioProblemAscription === 'map'){
                    caseSecondData.map += Number(item.cnt)
                  }else if(item.realScenarioProblemAscription === 'prediction'){
                    caseSecondData.prediction += Number(item.cnt)
                  }else if(item.realScenarioProblemAscription === 'planning'){
                    caseSecondData.planning += Number(item.cnt)
                  }else if(item.realScenarioProblemAscription === 'control'){
                    caseSecondData.control += Number(item.cnt)
                  }else if(item.realScenarioProblemAscription === 'simulation'){
                    caseSecondData.simulation += Number(item.cnt)
                  }else if(item.realScenarioProblemAscription === 'comprehensive'){
                    caseSecondData.comprehensive += Number(item.cnt)
                  }else if(item.realScenarioProblemAscription === 'unknown'){
                    caseSecondData.unknown += Number(item.cnt)
                  }
                  ele.secondItems.push(caseSecondData)
                }else {
                  ele.secondItems.forEach((list:any) => {
                    if(list.name === item.scenarioSecondaryClassification){
                      if(item.realScenarioProblemAscription === 'perception'){
                        list.perception += Number(item.cnt)
                      }else if(item.realScenarioProblemAscription === 'map'){
                        list.map += Number(item.cnt)
                      }else if(item.realScenarioProblemAscription === 'prediction'){
                        list.prediction += Number(item.cnt)
                      }else if(item.realScenarioProblemAscription === 'planning'){
                        list.planning += Number(item.cnt)
                      }else if(item.realScenarioProblemAscription === 'control'){
                        list.control += Number(item.cnt)
                      }else if(item.realScenarioProblemAscription === 'simulation'){
                        list.simulation += Number(item.cnt)
                      }else if(item.realScenarioProblemAscription === 'comprehensive'){
                        list.comprehensive += Number(item.cnt)
                      }else if(item.realScenarioProblemAscription === 'unknown'){
                        list.unknown += Number(item.cnt)
                      }
                    }
                  });
                }
               
              }
            });
            // 数据集验证统计字段
            if(verifyData && verifyData.items){
              let minedDaysObj:any = {}
              verifyData.items.forEach((list:any) => {
                let titleKey = `${list.dataSourceType}-${list.area}-${list.sourceTable}` 
                if(titleKey === ele.title){
                  ele.verifyT.minedBagCntT += Number(list.minedBagCnt)
                  ele.verifyT.minedScenarioCntT += Number(list.minedScenarioCnt)
                  ele.verifyT.classifiedScenarioCntT += Number(list.classifiedScenarioCnt)
                  ele.verifyT.validScenarioCntT += Number(list.validScenarioCnt)
                  ele.verifyT.generatedSimulateScenarioCntT += Number(list.generatedSimulateScenarioCnt)
                  ele.verifyT.simulatedScenarioCntT += Number(list.simulatedScenarioCnt)
                  ele.verifyT.simulateSucceedCntT += Number(list.simulateSucceedCnt)
                  ele.verifyT.verifiedScenarioCntT += Number(list.verifiedScenarioCnt)
                  ele.verifyT.TP += Number(list.TP)
                  ele.verifyT.FP += Number(list.FP)
                  ele.verifyT.TN += Number(list.TN)
                  ele.verifyT.FN += Number(list.FN)
                  // 计算挖掘天数
                  if(!minedDaysObj[list.days]){
                    minedDaysObj[list.days] = true
                  }
                }
              });
              //console.log(Object.keys(minedDaysObj))
              // 计算百分比
              ele.verifyT.minedDaysT = Object.keys(minedDaysObj).length
              ele.verifyT.validOriginalP = (ele.verifyT.validScenarioCntT/ele.verifyT.classifiedScenarioCntT*100 || 0).toFixed(2)+'%'
              ele.verifyT.simulatedGeneratedP = (ele.verifyT.simulatedScenarioCntT/ele.verifyT.generatedSimulateScenarioCntT*100 || 0).toFixed(2)+'%'
              ele.verifyT.simulateSucceedP = (ele.verifyT.simulateSucceedCntT/ele.verifyT.simulatedScenarioCntT*100 || 0).toFixed(2)+'%'
              ele.verifyT.verifiedScenarioP = (ele.verifyT.verifiedScenarioCntT/ele.verifyT.simulateSucceedCntT*100 || 0).toFixed(2)+'%'
              ele.verifyT.accuracyP = ((ele.verifyT.TP + ele.verifyT.TN)/(ele.verifyT.TP + ele.verifyT.TN+ele.verifyT.FP + ele.verifyT.FN)*100 || 0).toFixed(2)+'%'
              ele.verifyT.precisionP = (ele.verifyT.TP/(ele.verifyT.TP+ele.verifyT.FP)*100 || 0).toFixed(2)+'%'
              ele.verifyT.recallP = (ele.verifyT.TP/(ele.verifyT.TP + ele.verifyT.FN)*100 || 0).toFixed(2)+'%'
              ele.verifyT.coverP = ((ele.verifyT.TP + ele.verifyT.FN)/(ele.verifyT.TP + ele.verifyT.TN+ele.verifyT.FP + ele.verifyT.FN)*100 || 0).toFixed(2)+'%'
            }
          });

          itemsArr.forEach((item:any) => {
            // 第一个表格统计
            let interactiveTotal = 0
            let perceptionTotal = 0
            let totalTotal = 0
            item.items.forEach((list:any) => {
              list.total = list.interactive + list.perception
              interactiveTotal+=list.interactive
              perceptionTotal+=list.perception
              totalTotal+=list.total
            });
            let totalObj = {
              name:'总计',
              interactive:interactiveTotal,
              perception:perceptionTotal,
              total:totalTotal
            }
            if(item.items.length>0){
              item.items.push(totalObj)
            }
            // 第二个表格统计
            let perceptionSecondTotal = 0
            let mapTotal = 0
            let predictionTotal = 0
            let planningTotal = 0
            let controlTotal = 0
            let simulationTotal = 0
            let comprehensiveTotal = 0
            let unknownTotal = 0
            let totalSecondTotal = 0
            item.secondItems.forEach((list:any) => {
              list.total = list.perception + list.map+ list.prediction+ list.planning+ list.control+ list.simulation+ list.comprehensive+ list.unknown
              perceptionSecondTotal+=list.perception
              mapTotal+=list.map
              predictionTotal+=list.prediction
              planningTotal+=list.planning
              controlTotal+=list.control
              simulationTotal+=list.simulation
              comprehensiveTotal+=list.comprehensive
              unknownTotal+=list.unknown
              totalSecondTotal+=list.total
            });
            let totalSecondObj = {
              name:'总计',
              perception:perceptionSecondTotal,
              map:mapTotal,
              prediction:predictionTotal,
              planning:planningTotal,
              control:controlTotal,
              simulation:simulationTotal,
              comprehensive:comprehensiveTotal,
              unknown:unknownTotal,
              total:totalSecondTotal
            }
            if(item.secondItems.length>0){
              item.secondItems.push(totalSecondObj)
            }
            //第二个表格统计
          });
          console.log(itemsArr)
          // 数据列表
          yield put({
            type: 'updateState',
            payload: {
              list: itemsArr
            }
          })
         
        }
        yield put({ type: 'listSuccess' })

      } catch (e) {
        yield put({ type: 'listFail' })
        console.log(e)
      }
    },
  },
})

export default scenariorModel
