import React from 'react'
import './Trips.scss'
import { connect } from 'dva'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Dispatch } from 'redux'

import List from './components/List'
import Modal from './components/Modal'
import { Trip, TripsReducerState, DvaState, Pagination, Vehicle } from '../../_types'

// Types
interface TripsProps extends WithTranslation, TripsReducerState {
  vehicles: Vehicle[]
  dispatch: Dispatch
}

class Trips extends React.PureComponent<TripsProps, TripsReducerState> {
  get listProps() {
    const { dispatch, list, vehicles, loading, t, pagination } = this.props

    return {
      dataSource: list,
      vehicles,
      pagination: pagination,
      loading: loading,
      t: t,
      onEditItem: (item: Trip) => {
        dispatch({
          type: 'trips/showModal',
          payload: {
            modalType: 'update',
            currentItem: item,
          },
        })
      },
      onChange: (pagination: Pagination, filters: any) => {
        if (filters && Array.isArray(filters.serialNum) && filters.serialNum.length) {
          dispatch({
            type: 'trips/queryBySerial',
            payload: {
              serialNum: filters.serialNum[0],
            },
          })
          return
        }
        dispatch({
          type: 'trips/updateState',
          payload: {
            pagination: pagination,
          },
        })
        dispatch({ type: 'trips/query' })
      },
    }
  }

  get modalProps() {
    const { dispatch, t, currentItem, modalVisible, modalType } = this.props

    return {
      item: modalType === 'create' ? ({} as Trip) : currentItem,
      visible: modalVisible,
      destroyOnClose: true,
      maskClosable: true,
      modalType: modalType,
      // confirmLoading: loading.effects[`user/${modalType}`],
      title: t(`${modalType}Trip`),
      centered: true,
      t: t,
      onOk: (data: Trip) => {
        dispatch({
          type: `trips/${modalType}`,
          payload: data,
        })
      },
      onCancel: () => {
        dispatch({
          type: 'trips/hideModal',
        })
      },
    }
  }

  render() {
    const { t } = this.props

    return (
      <div className="trips">
        <div className="trips-inner">
          <header className="trips-header">
            <h2>{t('tripList')}</h2>
          </header>
          <List {...this.listProps} />
          <Modal {...this.modalProps} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: DvaState) => ({
  ...state.trips,
  vehicles: state.vehicles.vehicles,
})

export default connect(mapStateToProps)(withTranslation('trips')(Trips))
