import React from 'react'
import { Table, Tooltip, Button, Popconfirm } from 'antd'
import { ColumnProps } from 'antd/lib/table'

import { Bag, Pagination, Sort, Mileage } from '../../../_types'
import { bytesToSize, epochToDate } from '../../../_helpers'
import { HoverText } from '../../../common'
import { Resizable } from 'react-resizable';

interface MileageListProps {
  t: any
  pagination: Pagination
  onChange: any
  sort: Sort
  onCreate: () => void
  onCreateEvent: (item: Mileage) => void
  onEdit: (item: Mileage) => void
  onDelete: (item: Mileage) => void
  onCopy: (item: string) => void
}

const ResizeableTitle = (props:any) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};
class MileageTable extends React.PureComponent<MileageListProps> {
  constructor(props: any) {
    super(props)
    const { t, onCopy, onDelete, onEdit } = this.props
    this.state = {
      columns:  [
        {
          title: t('eventsBag'),
          dataIndex: 'event_bag',
          width: 100,
          render: (text: string) => (
            <HoverText content={text} text={text.substr(0, 8) + '...'} onClick={() => onCopy(text)} />
          )
        },
        {
          title: t('productVersion'),
          dataIndex: 'product_version',
          render: (text: string) => (
            <HoverText content={text} text={text} onClick={() => onCopy(text)} />
          )
        },
        {
          title: t('dealState'),
          dataIndex: 'processing_status',
          // ellipsis: true,
        },
        {
          title: t('modeType'),
          dataIndex: 'belonging_module',
        },
        {
          title: t('issueNumber'),
          dataIndex: 'issue_key',
        },
        {
          title: t('issueLink'),
          dataIndex: 'issue_link',
          width: 100,
          render: (text: string) => text && <HoverText content={text} text={text.substr(0, 12) + '...'} onClick={() => onCopy(text)} />,
        },
        {
          title: t('problemDescription'),
          dataIndex: 'issue_description',
          width: 120,
          // render: (text: string) => text && <HoverText content={text} text={text.substr(0, 14) + '...'} onClick={() => onCopy(text)} />,
        },
        {
          title: t('qa_result'),
          dataIndex: 'qa_result',
        },
        {
          title: t('simulation_info'),
          dataIndex: 'simulation_info',
        },
        {
          title: t('bag_oss_path'),
          dataIndex: 'bag_oss_path',
          width: 100,
          render: (text: string) => text && <HoverText content={text} text={text.substr(0, 16) + '...'} onClick={() => onCopy(text)} />,
        },
        {
          title:t('log_oss_path'),
          dataIndex:'log_oss_path',
          width:100,
          render: (text: string) => text && <HoverText content={text} text={text.substr(0, 16) + '...'} onClick={() => onCopy(text)} />,
        },
        {
          title: t('event_time'),
          dataIndex: 'event_time',
        },
        //从这里开始其他字段
        {
          title: t('vehicleName'),
          dataIndex: 'vehicle_name',
        },
        {
          title: t('vehicle_id'),
          dataIndex: 'vehicle_id',
          width: 100,
          render: (text: string) => text && <HoverText content={text} text={text.substr(0, 16) + '...'} onClick={() => onCopy(text)} />,
        },
        {
          title: t('ts'),
          dataIndex: 'ts',
        },
        {
          title: t('description'),
          dataIndex: 'description',
          key: 'description',
        },
        {
          title: t('region'),
          dataIndex: 'region',
          key: 'region',
        },
        {
          title: t('hmi_username'),
          dataIndex: 'hmi_username',
          key: 'hmi_username'
        },
        {
          title: t('event_category'),
          dataIndex: 'event_category',
          key: 'event_category',
        },
        {
          title: t('eventsType'),
          dataIndex: 'event_type',
          key: 'event_type'
        },
        {
          title: t('taskType'),
          dataIndex: 'task_type',
          key: 'task_type',
        },
        {
          title: t('mileage_type'),
          dataIndex: 'mileage_type',
        },
        {
          title: t('event_tag'),
          dataIndex: 'event_tag',
          key: 'event_tag',
        },
        {
          title: t('version_config'),
          dataIndex: 'version_config',
          key: 'version_config',
          render: (text: string) => text && <HoverText content={text} text={text.substr(0, 16) + '...'} onClick={() => onCopy(text)} />,
        },
        {
          title: t('bag_mhd_path'),
          dataIndex: 'bag_mhd_path',
          key: 'bag_mhd_path',
          render: (text: string) => text && <HoverText content={text} text={text.substr(0, 16) + '...'} onClick={() => onCopy(text)} />,
        },
        {
          title: t('log_mhd_path'),
          dataIndex: 'log_mhd_path',
          key: 'log_mhd_path',
          render: (text: string) => text && <HoverText content={text} text={text.substr(0, 16) + '...'} onClick={() => onCopy(text)} />,
        },
        {
          title: t('peak_type'),
          dataIndex: 'peak_type',
          key: 'peak_type'
        },
        {
          title: t('routeName'),
          dataIndex: 'route_name',
          key: 'route_name',
        },
        {
          title: t('position'),
          dataIndex: 'position',
          key: 'position',
        },
        {
          title: t('latitude_longitude'),
          dataIndex: 'latitude_longitude',
          key: 'latitude_longitude',
          render: (text: string) => text && <HoverText content={text} text={text} onClick={() => onCopy(text)} />,
        },
        {
          title: t('jira_belonging_module'),
          dataIndex: 'jira_belonging_module',
          key: 'jira_belonging_module',
        },
        {
          title: t('event_id'),
          dataIndex: 'event_id',
          key: 'event_id',
        },
        {
          title: t('img_oss_path'),
          dataIndex: 'img_oss_path',
          key: 'img_oss_path',
          render: (text: string) => text && <HoverText content={text} text={text.substr(0, 16) + '...'} onClick={() => onCopy(text)} />,
        },
        {
          title: t('event_second'),
          dataIndex: 'event_second',
        },
        {
          title: t('simulation_result'),
          dataIndex: 'simulation_result',
        },
        {
          title: t('simulation_scenario_id'),
          dataIndex: 'simulation_scenario_id',
        },
        {
          title:t('simulation_job_id'),
          dataIndex:'simulation_job_id',          
        },
        {
          title: t('simulation_task_id'),
          dataIndex: 'simulation_task_id',
        },
        {
          title: t('curr_week'),
          dataIndex: 'curr_week',
          width: 100
        },
        {
          title: t('curr_month'),
          dataIndex: 'curr_month',
          width: 100
        },
        {
          title: t('curr_quarter'),
          dataIndex: 'curr_quarter',
          width: 100,
        },
        {
          title: t('product_dir'),
          dataIndex: 'product_dir',
          width: 150,
        },
        {
          title: t('simulation_bag_path'),
          dataIndex: 'simulation_bag_path',
          key: 'simulation_bag_path',
          render: (text: string) => text && <HoverText content={text} text={text.substr(0, 16) + '...'} onClick={() => onCopy(text)} />
        },
        // {
        //   key: 'action',
        //   fixed: 'right',
        //   render: (text: string, bag: Mileage) => (
        //     <div className="bags-list-actions">
        //       <Button type="link" size="small" onClick={() => onEdit(bag)}>
        //         {t('btn.edit')}
        //       </Button>
        //       <Popconfirm title="Sure to delete?" onConfirm={() => onDelete(bag)}>
        //         <Button type="link" size="small">{t('btn.delete')}</Button>
        //       </Popconfirm>
        //     </div>
        //   ),
        // },
      ],
    };
  }
  components =  {
    header: {
      cell: ResizeableTitle,
    },
  }
  handleResize = (index:number) => (e:any, { size }:any) => {
    this.setState(({ columns }:any) => {
      const nextColumns = [...columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };
      return { columns: nextColumns };
    });
  };

  render() {
    const { t, onCreate, sort, onEdit, onCreateEvent,onDelete, pagination, ...tableProps } = this.props
    const  {columns}:any = this.state
    const columnscResiz = columns.map((col:any, index:number) => ({
      ...col,
      onHeaderCell: (column:any) => ({
        width: column.width,
        onResize: this.handleResize(index),
      }),
    }));
    return (
      <div>
        <Table
          {...tableProps}
          pagination={{
            size: 'small',
            ...pagination,
          }}
          size="small"
          bordered
          components={this.components}
          columns={columnscResiz}
          rowKey='ts'
          className ="columnscResiz"
          scroll={{ x: true }}
        />
      </div>
    )
  }
}

export default MileageTable
