import React from 'react'
import './Scenario.scss'
import { connect } from 'dva'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Dispatch } from 'redux'
import { ScenarioListReducerState } from '../../_types/scenariosurvey.types'
import { DvaState,ModalState,ScenarioList,Pagination } from '../../_types'
import { ScenarioTable,SearchTree } from './components'
import { Select, DatePicker, Spin,Row,Col } from 'antd'
import moment from 'moment'

interface ScenarioProps extends WithTranslation, ScenarioListReducerState, ModalState<ScenarioList> {
  dispatch: Dispatch,
  list:any
}

class Scenario extends React.Component<ScenarioProps> {
  private secondaryClassification: string = ''
  componentDidMount() {
    const { dispatch,pagination } = this.props
    console.log(this.props)
    dispatch({
      type: 'scenarioList/queryScenario',
      payload: {
        pagination: pagination,
      },
    })
  }
  get listProps() {
    const { list, listingBags, dispatch, pagination, t } = this.props
    return {
      dataSource: list,
      pagination: pagination,
      loading: listingBags,
      t: t,
      onChange: (pagination: Pagination) => {
        dispatch({
          type: 'scenarioList/queryScenario',
          payload: {
            pagination: pagination,
            secondaryClassification: this.secondaryClassification,
          },
        })
      },
    }
  }
  get SearchTree() {
    const { t,dispatch,pagination } = this.props
    return {
      t:t,
      onClickTree: (item: any,e:any) => {
        if(!e.node.props.children){
          this.secondaryClassification = item[0]
          pagination.current = 1
          dispatch({
            type: 'scenarioList/queryScenario',
            payload: {
              pagination: pagination,
              secondaryClassification: item[0],
            },
          })
        }
      },
    }
  }
  render() {
    return (
      <div className="scenario">
          <div className="scenario-list-table">
            <header className="roadTests-header">
              <h2>场景库列表</h2>
            </header>
            <Row>
              <Col span={4}>
                 <SearchTree {...this.SearchTree} />
              </Col>
              <Col span={20}>
                  <ScenarioTable {...this.listProps} />
              </Col>
            </Row>
          </div>
      </div>
    )
  }
}

const mapStateToProps = (state: DvaState) => ({
  ...state.scenarioList,
})

export default connect(mapStateToProps)(withTranslation('scenario')(Scenario))
