import React, { CSSProperties } from 'react'
import { AutoComplete, Modal, Form, Input, DatePicker, Button, Popconfirm,Upload,Icon,message,Row, Col, } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { Bag} from '../../../_types'
import CryptoJS from 'crypto-js'
import Base64 from 'base-64'
import OSS from 'ali-oss'
import { epochToMoment, momentToEpoch } from '../../../_helpers'
 import Progressd from './progress'
 import {compose, Dispatch} from 'redux'

interface UploadModalProps extends FormComponentProps {
  loading: boolean
  onCancel: () => void
  t: any
  dispatch:Dispatch
}

interface UploadState {
  dir: string,
  part:any,
  progressProps:any,
  abortCheckpoint:any
}

const modalBodyStyle: CSSProperties = {
  maxHeight: '80vh',
  overflow: 'auto',
  padding: '1rem 1.5rem 0.5rem',
}

const formItemLayout = {
  labelAlign: 'left' as 'left',
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

const client:any = new OSS({
  region:'oss-cn-shanghai',
  accessKeyId: 'LTAI5tNUidgJXZsqwXaCfYbn',
  accessKeySecret:'BfPf8kXhN9e5YNSm6jpIIQAc6tGeWE',
  bucket:'allride-sharing-archive',
  timeout:6000000
})

class BagModal extends React.PureComponent<UploadModalProps,UploadState> {
  constructor(props: any) {
    super(props)
    this.state = {
      dir: 'incardata/',
      part:{
        //parallel:4,
        partSize:1024 * 1024,
        timeout:6000000
      },
      progressProps:[],
      abortCheckpoint:null,

    }
  }

  // 查找数据
  findByUrl = (fileUrl:string)=>{
    const {progressProps} = this.state
    if(progressProps.length>0){
      return progressProps.findIndex((item:any)=>{
        // console.log('fileUrl',fileUrl,fileUrl.split('@').join('/'))
         return item.file.url === fileUrl
      })
    }
  }
  // 上传列表初始化数据
  progressListFun = (file:any)=>{
    const process = {
      statue: true,
      result:true,
      file:file,
      percent:0,
      onReUpload:this.resumeMultipartUpload
    }
    const index = this.findByUrl(file.url)
    if(this.state.progressProps.length === 0||index<0){
      this.setState(state => ({
        progressProps: [...state.progressProps, process],
      }));
    }else{
      let arr = this.state.progressProps
      arr.splice(index,1,process)
      this.setState(state => ({
        progressProps: [...arr],
      }));
    }
  }
  // 上传结果进度条方法
  uploadResultFun = (file:any,res:boolean) =>{
    const index = this.findByUrl(file.url)
    let progressProps = this.state.progressProps
    if(progressProps.length>0){
      progressProps[index].statue = false
      res?progressProps[index].percent = 100:progressProps[index].result = false
      this.setState({
        progressProps:[...progressProps]
      })
    }
  }
  // 普通上传
  commonUpload = (file:any) =>{
    const fileUrl = file.url
    this.progressListFun(file)
    return client.put(fileUrl, file).then((result:any) => {
      this.uploadResultFun(file,true)
      this.getlocation(result.name)
      console.log(`Common upload ${file.name} succeeded, result === `, result)
    }).catch((err:any) => {
      this.uploadResultFun(file,false)
      console.log(`Common upload ${file.name} failed === `, err);
    });
  }
  // 分片上传
  multipartFunUpload = (file:any)=>{
    const fileUrl = file.url
    const {part} = this.state
    const objKey = fileUrl+ "_.json"
    this.progressListFun(file)
    return client.multipartUpload(fileUrl, file, {
      ...part,
      progress: this.onMultipartUploadProgress
    }).then((result:any) => {
      client.delete(objKey); 
      this.uploadResultFun(file,true)
      this.getlocation(result.name)
      console.log(`Multipart upload ${file.name} succeeded, result === `, result)
    }).catch((err:any) => {
      this.uploadResultFun(file,false)
      console.log(`Multipart upload ${file.name} failed === `, err);
    });
  }
  onMultipartUploadProgress = (progress:number, checkpoint:any, res:any) => {
    if (checkpoint != undefined) {
      var content = JSON.stringify(checkpoint);
      client.put(checkpoint.file.url + "_.json", new Blob([content]));
    }
    let index = this.findByUrl(checkpoint.file.url)
    let obj = this.state.progressProps
    obj[index].percent = Number((progress*100).toFixed(2))
    this.setState({
      progressProps:[...obj],
      abortCheckpoint:checkpoint
    })
 }
  // 断点续传
  resumeMultipartUpload = (item:any)=>{
    const { part } = this.state;
    if(item.size<part.partSize){
      this.commonUpload(item)
    }else{
      const objKey = item.url+ "_.json"
      return client.get(objKey).then((res:any)=> {
        let data = JSON.parse(res.content);
        this.progressListFun(item)
        return client.multipartUpload(item.url, item, {
          checkpoint: data,
          progress: this.onMultipartUploadProgress
        }).then((result:any) => {
          client.delete(objKey); 
          this.uploadResultFun(item,true)
          this.getlocation(result.name)
          console.log(`Multipart upload ${item.name} succeeded, result === `, result)
        }).catch((err:any) => {
          this.uploadResultFun(item,false)
          this.multipartFunUpload(item)
          console.log(`Multipart upload ${item.name} failed === `, err);
        });
      }).catch((err:any)=>{
        console.log(err)
        this.multipartFunUpload(item)
      });
    }
  }
  // 中断分片上传
  abortUpload = ()=>{
    const {abortCheckpoint} = this.state
    return client.abortMultipartUpload(abortCheckpoint.name, abortCheckpoint.uploadId)
  }
  beforeUpload = (file:any) => {
    const { dir,part } = this.state;
    const filename = file.name
    const relativePath = file.webkitRelativePath
    const fileCatalog = relativePath.substring(0,relativePath.lastIndexOf('/')+1).split('@').join('/')
    file.url = dir + fileCatalog + filename;
    console.log('url',file.url)
    //判断上传方式
    if(file.size<part.partSize){
      this.commonUpload(file)
    }else{
      this.multipartFunUpload(file)
    }
    return false 
  };

  confirm = (e:any)=>{
    const { onCancel} = this.props
    const {abortCheckpoint} = this.state
    if(abortCheckpoint){
      this.abortUpload()
    }
    this.setState({
      progressProps:[]
    })
    onCancel()
  }
  cancel = (e:any)=>{
    console.log(e);
  }

  getlocation = (name:string)=>{
    const {dispatch} = this.props
    let names = name.split('/')
    let location = names[1]+'@'+names[2]
    dispatch({type:'mileage/uploadLocation',payload:{location}})

  }
  
  render() {
    const { form,  loading, t, ...modalProps } = this.props
    const {progressProps} = this.state
    const props = {
      name: 'file',
      showUploadList:false,
      beforeUpload: this.beforeUpload,
    };
    return (
      <div>
        <Modal
          {...modalProps}
          maskClosable = {false}
          bodyStyle={modalBodyStyle}
          footer={
            <>
              <Popconfirm
                title="关闭弹框，会初始化上传列表，确定关闭吗?"
                onConfirm={this.confirm}
                onCancel={this.cancel}
                okText="Yes"
                cancelText="No"
              >
                <Button loading={loading} type="primary">
                  {t(`btn.confirm`)}
                </Button>
              </Popconfirm>
            </>
          }
        >
          <Form {...formItemLayout}>
            <Form.Item label={t('bagLogData')} >
                <Upload {...props} directory>
                  <Button>
                    <Icon type="upload" /> Upload Bag &amp; log
                  </Button>
                </Upload>
                {progressProps.map((itemProps:any,index:number) =>
                  <Progressd {...itemProps} key={index}/>
                )}
            </Form.Item>
          </Form>
        </Modal>
      </div>
    )
  }
}

export default Form.create<UploadModalProps>()(BagModal)
