import React from 'react'
import './Paths.scss'
import { connect } from 'dva'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Dispatch } from 'redux'

import { PathsReducerState, DvaState, Path, PLocation } from '../../_types'
import { PathModal, PathList } from './components'

interface PathsProps extends WithTranslation, PathsReducerState {
  plocations: PLocation[]
  dispatch: Dispatch
}

class Paths extends React.PureComponent<PathsProps> {
  get listProps() {
    const { dispatch, paths, listingPaths, plocations, t } = this.props

    return {
      dataSource: paths,
      plocations,
      loading: listingPaths,
      t,
      onCreate: () => {
        dispatch({
          type: 'paths/showModal',
          payload: {
            modalType: 'create',
          },
        })
      },
      onEdit: (item: Path) => {
        dispatch({
          type: 'paths/showModal',
          payload: {
            modalType: 'update',
            currentItem: item,
          },
        })
      },
    }
  }

  get pathModalProps() {
    const { dispatch, currentItem, modalVisible, modalType, modifyingPath, plocations, t } = this.props

    return {
      item: modalType === 'create' ? undefined : currentItem,
      plocations,
      visible: modalVisible,
      modalType,
      title: t(`${modalType}Path`),
      loading: modifyingPath,
      destroyOnClose: true,
      maskClosable: true,
      centered: true,
      t,
      onModify: (data: any, action: string) => {
        dispatch({
          type: `paths/${action}`,
          payload: data,
        })
      },
      onCancel: () => {
        dispatch({
          type: 'paths/hideModal',
        })
      },
    }
  }

  render() {
    const { t } = this.props

    return (
      <div className="plocations">
        <div className="plocations-paths">
          <div className="plocations-inner">
            <div className="plocations-header">
              <h2>{t('pathList')}</h2>
            </div>
            <PathList {...this.listProps} />
            <PathModal {...this.pathModalProps} />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: DvaState) => ({
  ...state.paths,
  plocations: state.plocations.plocations,
  districts: state.districts.districts,
})

export default connect(mapStateToProps)(withTranslation('paths')(Paths))
