export const api = {
  loginUser: 'POST /api/v1/apiadminauth/login',
  queryUserInfo: 'POST /api/v1/apiadmin/getAdmin',
  createAdmin: 'POST /api/v1/apiadmin/createAdmin',
  listAdmins: 'POST /api/v1/apiadmin/listAdmin',
  updateAdmin: 'POST /api/v1/apiadmin/updateAdmin',
  deleteAdmin: 'POST /api/v1/apiadmin/deleteAdmin',

  queryBagList: 'POST /api/v1/apibag/list',
  updateBag: 'POST /api/v1/apibag/update',
  createBag: 'POST /api/v1/apibag/create',
  deleteBag: 'POST /api/v1/apibag/delete',

  listTag: 'POST /api/v1/apitag/list',
  updateTag: 'POST /api/v1/apitag/update',
  createTag: 'POST /api/v1/apitag/create',
  deleteTag: 'POST /api/v1/apitag/delete',

  getVEvent: 'POST /api/v1/apievent/get',
  listVEvent: 'POST /api/v1/apievent/list',
  updateVEvent: 'POST /api/v1/apievent/update',
  createVEvent: 'POST /api/v1/apievent/create',
  deleteVEvent: 'POST /api/v1/apievent/delete',

  queryTripListByPage: 'POST /api/v1/apitripctr/queryByPage',
  queryTripBySerial: 'POST /api/v1/apitripctr/getBySerial',
  updateTrip: 'POST /api/v1/apitripctr/update',

  listInvitations: 'POST /api/v1/apiadmin/listInvitations',
  createInvitation: 'POST /api/v1/apiadmin/createInvitation',
  deleteInvitation: 'POST /api/v1/apiadmin/deleteInvitation',

  listPLocations: 'POST /api/v1/apiplocctr/list',
  listPLocationsByDistrictId: 'POST /api/v1/apiplocctr/listByDistrictId',
  createPLocation: 'POST /api/v1/apiplocctr/create',
  updatePLocation: 'POST /api/v1/apiplocctr/update',
  deletePLocation: 'POST /api/v1/apiplocctr/delete',

  listDistricts: 'POST /api/v1/apidistrictctr/list',
  createDistrict: 'POST /api/v1/apidistrictctr/create',
  updateDistrict: 'POST /api/v1/apidistrictctr/update',
  deleteDistrict: 'POST /api/v1/apidistrictctr/delete',

  getPath: 'POST /api/v1/apiplocctr/getPath',
  queryPath: 'POST /api/v1/apiplocctr/queryPath',
  createPath: 'POST /api/v1/apiplocctr/createPath',
  listPath: 'POST /api/v1/apiplocctr/listPath',
  updatePath: 'POST /api/v1/apiplocctr/updatePath',
  deletePath: 'POST /api/v1/apiplocctr/deletePath',

  listVehicles: 'POST /api/v1/apivehctr/list',
  queryVehicles: 'POST /api/v1/apivehctr/query',
  createVehicle: 'POST /api/v1/apivehctr/register',
  updateVehicle: 'POST /api/v1/apivehctr/update',
  deleteVehicle: 'POST /api/v1/apivehctr/delete',
  getVehicle: 'POST /api/v1/apivehctr/get',

  listUsers: 'POST /api/v1/apiadmin/listUser',
  createUser: 'POST /api/v1/apiadmin/createUser',
  updateUser: 'POST /api/v1/apiadmin/updateUser',
  deleteUser: 'POST /api/v1/apiadmin/deleteUser',

  listRoadTests: 'POST /api/v1/apiroadtestctr/list',
  getRoadTests: 'POST /api/v1/apiroadtestctr/get',
  queryRoadTests: 'POST /api/v1/apiroadtestctr/query',
  updateRoadTest: 'POST /api/v1/apiroadtestctr/update',
  deleteRoadTest: 'POST /api/v1/apiroadtestctr/delete',

  mileagesGetsum: 'GET /api/mileages/getsum',
  takeoversGetsum: 'GET /api/takeovers/getsum',
  mpiList: 'GET /api/mpi/list',
  regionVehicleList: 'GET /api/mpi/regionVehicleStat/list',
  mpiGetsum: 'GET /api/mpi/getsum',
  regionStatList: 'GET /api/mpi/regionStat/list',
  reasonList: 'GET /api/mpi/takeoverReasonStat/list',

  listEventType:'GET /api/mpi/eventType/list',
  listTaskType:'GET /api/mpi/taskType/list',

  uploadLocation:'GET /api/mpi/uploadCallback',
  uploadStatus:'GET /api/mpi/uploadStatus/list',

  listTaskModel:'GET /api/mpi/taskModel/list',
  listModType:'GET /api/mpi/modType/list',
  queryTaskList:'POST /api/mpi/task/list',
  vehicleMapList: 'POST /api/mpi/vehicleMap/list',
  DetailVehicleMap: 'POST /api/mpi/vehicleMap/detail',
  scenarioTotalList: 'POST /api/mpi/scenario/list',
  scenarioVerify: 'GET /api/mpi/scenarioVerify/list',
  sListTotalList: 'POST /api/mpi/sListTotal/list',
  sListTotalDetail: 'POST /api/mpi/sListTotal/detail',
  // 故障
  userListUrl:'GET /api/fault/list/users',
  modulesListUrl:'GET /api/fault/list/modules',
  vehiclesListUrl:'GET /api/fault/list/vehicles',
  queryEventListUrl:'POST /api/fault/query/event',
  updateEventUrl:'POST /api/fault/update/event',

  // 事件
  getVehicleNameUrl: 'GET /api/data_mpi/menu/get_vehicle_name',
  getRouteNameUrl: 'GET /api/data_mpi/menu/get_route_name',
  getBelongingModuleUrl: 'GET /api/data_mpi/menu/get_belonging_module',
  getMetricsNameUrl: 'GET /api/data_mpi/menu/get_metrics_name',
  getInternalEventTypeUrl: 'GET /api/data_mpi/menu/get_internal_event_type',

  queryEventList: 'POST /api/data_mpi/event/query_event_list',

}

export type APIKeys = keyof typeof api
