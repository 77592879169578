import modelExtend from 'dva-model-extend'
import lodash from 'lodash'
import { message } from 'antd'
import i18next from 'i18next'
import moment from 'moment'
// @ts-ignore
import * as echarts from 'echarts';
import { pathMatchRegexp } from '../_helpers'
import { VMapePool, VMapSearch, VMapsDetailReducerState, VMapsModel} from '../_types'
import api from '../_dva_services'
import {  modalModel} from '../_utils'

const VMapePools: VMapePool = {
  vehicleAutoComplete: {},
  districts :[]
}
const barChartOption: any = {
  title: {
    show: true,
    text: '车辆',
    left: '42%',
    y: 10,
    textStyle: {
      fontSize: 15
    }
  },
  color: ['rgba(58, 190, 250, 1)', 'rgba(255, 102, 0, 1)', 'rgba(46, 195, 119, 1)'],
  legend: {
    data: ['速度', '加速度'],
    top: '10',
    left: '50%',
    icon: 'circle',
  },
  tooltip: {
    trigger: 'axis',
   // formatter: '时间：{b0}<br/>速度：{c0} m/s<br/>加速度：{c1} m/s²'
  },
  grid: {
    top: 50,
    left: 50,
    right: 50,
    bottom:'22%'
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: []
  },
  yAxis: [{
    name: '速度(m/s)',
    type: 'value',
    nameTextStyle: {
      padding: [0, 0, 0, 15]
    },
    splitLine: {
      show: true
    }
  }, {
    name: '加速度(m/s²)',
    type: 'value',
    nameTextStyle: {
      padding: [0, 30, 0, 0]
    },
    splitLine: {
      show: false
    }
  }],
  dataZoom: [{
    show: true,
    start: 5,
    end: 95
  }]
}
const lineChartOption: any = {
  title: {
    show: true,
    text: '车辆',
    left: '42%',
    y: 10,
    textStyle: {
      fontSize: 15
    }
  },
  color: ['rgba(58, 190, 250, 1)', 'rgba(255, 102, 0, 1)', 'rgba(46, 195, 119, 1)'],
  legend: {
    data: ['加加速度'],
    top: '10',
    left: '50%',
    icon: 'circle',
  },
  tooltip: {
    trigger: 'axis',
  },
  grid: {
    top: 50,
    left: 50,
    right: 50,
    bottom:'22%'
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: []
  },
  yAxis: [{
    name: '加加速度(m/s³)',
    type: 'value',
    nameTextStyle: {
      padding: [0, 0, 0, 15]
    },
    splitLine: {
      show: true
    }
  }],
  dataZoom: [{
    show: true,
    start: 5,
    end: 95
  }]
}
const initSearch: VMapSearch = {
  time: {
    createdAt: ['', (Date.now()).toString()],
    //createdAt: ['1662085525000', '1662085585455'],
    updatedAt: ['', (Date.now() ).toString()],
  },
  vehicleName: '',
}

const initState: VMapsDetailReducerState = {
  listingBags: false,
  regionChartOption: {
    ...barChartOption,
    series: []
  },
  speedChartOption: {
    ...lineChartOption,
    series: []
  },
  list:[],
  vehicleName:''
}
const vmapsModel: VMapsModel = modelExtend<VMapsModel>(modalModel, {
  namespace: 'vmapsDetail',
  state: initState,
  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(location => {
        if (pathMatchRegexp('/vmapsDetail', location.pathname)) {
          dispatch({ type: 'reset' })
        }
      })
    },
  },
  reducers: {
    reset(state) {
      state.regionChartOption.xAxis.data = []
      state.regionChartOption.series = []
      state.regionChartOption.title.text = `车辆`
      state.speedChartOption.xAxis.data = []
      state.speedChartOption.series = []
      state.speedChartOption.title.text = `车辆`
      return {
        ...state,
      }
    },
    listRequest(state, {}) {
      return {
        ...state,
        listingBags: true,
      }
    },

    listSuccess(state: VMapsDetailReducerState) {
      return {
        ...state,
        listingBags: false,
      }
    },

    listFail(state: VMapsDetailReducerState) {
      return {
        ...state,
        listingBags: false,
      }
    },

    listRegionChart(state: VMapsDetailReducerState, { payload }) {
      state.regionChartOption.xAxis.data =  payload.xAxisData.map(function (str:any) {
        return str.replace('.', '\n');
      })
      state.regionChartOption.series = payload.seriesData
      state.regionChartOption.title.text = `车辆${payload.title}`
      payload.regionChart.clear()
      payload.regionChart.setOption(state.regionChartOption)

      // 加加速度
      state.speedChartOption.xAxis.data =  payload.xAxisData.map(function (str:any) {
        return str.replace('.', '\n');
      })
      state.speedChartOption.series = payload.speedData
      state.speedChartOption.title.text = `车辆${payload.title}`
      payload.speedChart.clear()
      console.log(state.speedChartOption)
      payload.speedChart.setOption(state.speedChartOption)
      return {
        ...state,
      }
    },
  },
  effects: {
    *setMapChart({payload},{call,put,select}){
      if(payload.para.vehicleName === ''){
        message.warning('请选择车辆')
        return
      }
      if(payload.para.baseLat === '0'||payload.para.baseLon==='0'){
        message.warning('该车辆没有所属区域')
        return
      }
      let speedObj = []
      let accelerationObj = []
      let timeObj:string[] = []
      let accelerationSpeed = []
      let title = ''
      const para = {
        start_time: String(new Date(payload.para.timeBegin).getTime()),
        end_time: String(new Date(payload.para.timeEnd).getTime()),
        vehicle_name:payload.para.vehicleName,
        base_lat :String(payload.para.baseLat),
        base_lon:String(payload.para.baseLon),
      }
      yield put({ type: 'listRequest' })
      try {
        const data = yield call(api.DetailVehicleMap, para)
        let detailList:any = []
        data.items.forEach((ele:any) => {
          let obj:any = {}
          obj.time = ele.item[0]
          obj.acceleration = Number(ele.item[1])
          obj.speed = Number(ele.item[2])
          obj.longitude = Number(ele.item[3])
          obj.latitude = Number(ele.item[4])
          detailList.push(obj)
        });
        yield put({ type: 'listSuccess', })
        yield put({
          type: 'updateState',
          payload: {
            list: detailList,
            vehicleName:data.vehicleName
          },
        })
        // 处理速度，加速度，时间字段
        const timeFormate = (time:string)=>{
          const regularTime = moment(time).format('HH:mm:ss')
          let nanoSecond = '0'
          if(time.indexOf('.')>0){
            nanoSecond = time.split('.')[1].split('+')[0]
          }
          return `${regularTime}.${nanoSecond}`
        }
        // 如果没数据，给提示
        if(data && data.items && data.items.length === 0){
          message.warn(`${data.vehicleName}车辆暂没有数据`)
        }
        if (data && data.items && data.items.length) {
           // 加加速度
           accelerationSpeed = JSON.parse(JSON.stringify(detailList)).map((item:any)=>{
            return Object.assign(item,{value:0})
          })
          detailList.forEach((item:any)=>{
            item.acceleration = Number(item.acceleration.toFixed(6))
          })
          // 速度
          speedObj = JSON.parse(JSON.stringify(detailList)).map((item:any)=>{
            return Object.assign(item,{value:item.speed})
          })
          //加速度
          accelerationObj = JSON.parse(JSON.stringify(detailList)).map((item:any)=>{
            return Object.assign(item,{value:item.acceleration})
          })
          //时间
          detailList.forEach((item:any) => {
            timeObj.push(timeFormate(item.time))
          });
          // 加加速度
          for(let i=1; i<accelerationSpeed.length; i++){
            let timePrevInt:any = parseInt((new Date(accelerationSpeed[i-1].time).getTime()/1000).toString())+'.'+accelerationSpeed[i-1].time.split('+')[0].split('.')[1]
            let timeCureInt:any = parseInt((new Date(accelerationSpeed[i].time).getTime()/1000).toString())+'.'+accelerationSpeed[i].time.split('+')[0].split('.')[1]
            let timeDiff:any = (timeCureInt - timePrevInt).toFixed(9)
            
            if(accelerationSpeed[i].acceleration!==accelerationSpeed[i-1].acceleration){
              accelerationSpeed[i].value = ((accelerationSpeed[i].acceleration - accelerationSpeed[i-1].acceleration)/timeDiff).toFixed(6)
            }
            // console.log(timePrevInt)
            // console.log(timeCureInt)
            // console.log(timeDiff)
            // console.log(accelerationSpeed[i].value)
          }
         // console.log(accelerationSpeed)
        }
        if(data.vehicleName){
          title = data.vehicleName
        }
      } catch (e) {
        console.log(e)
        yield put({ type: 'listFail' })
        message.error('请求数据失败')
      }
      const seriesData = [{
        data:speedObj,
        label: {
          show: true,
          position: 'top'
        },
        name: '速度',
        sampling: 'average',
        type: 'line',
        smooth: true,
        yAxisIndex: 0,
      }, {
        data:accelerationObj,
        label: {
          show: true,
          position: 'top'
        },
        name: '加速度',
        sampling: 'average',
        type: 'line',
        smooth: true,
        yAxisIndex: 1
      }]
      const speedData = [{
        data:accelerationSpeed,
        label: {
          show: true,
          position: 'top'
        },
        name: '加加速度',
        sampling: 'average',
        type: 'line',
        smooth: true,
        markLine: {
         // symbol: "none", //去掉警戒线最后面的箭头
          data: [
            {
             // silent: false, //鼠标悬停事件  true没有，false有
              lineStyle: {
                //警戒线的样式  ，虚实  颜色
                type: "dashed",
                color: "#FA3934",
              },
              yAxis: "100", 
              label: {
                position: "end",
                formatter:'舒适区'
              },
            },
            {
             // silent: false, //鼠标悬停事件  true没有，false有
              lineStyle: {
                //警戒线的样式  ，虚实  颜色
                type: "dashed",
                color: "#FA3934",
              },
              yAxis: "-100", 
              label: {
                position: "end", 
                formatter:'舒适区'
              },
            },
          ],
        },
      }]
      yield put({
        type: 'listRegionChart',
        payload: {
          seriesData,
          speedData,
          title,
          xAxisData: timeObj,
          regionChart: payload.mapChart,
          speedChart: payload.speedChart
        }
      })
      payload.drawLine
    },
  },
})

export default vmapsModel
