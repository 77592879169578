import React from 'react'
import './LangButton.scss'
import { withTranslation, WithTranslation } from 'react-i18next'

interface LangButtonProps extends WithTranslation {
  mini?: boolean
}

const LangButton: React.FC<LangButtonProps> = ({ i18n, mini }) => {
  const handleChangeLang = (lang: string) => {
    i18n.changeLanguage(lang)
  }

  return (
    <div className="lang">
      {!mini || i18n.language === 'zh' ? (
        <button className="lang-btn" onClick={() => handleChangeLang('en')}>
          En{' '}
        </button>
      ) : null}
      {mini ? null : '/'}
      {!mini || i18n.language === 'en' ? (
        <button className="lang-btn" onClick={() => handleChangeLang('zh')}>
          中
        </button>
      ) : null}
    </div>
  )
}

export default withTranslation()(LangButton)
