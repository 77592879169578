import modelExtend from 'dva-model-extend'

import { pathMatchRegexp } from '../_helpers'
import { TripStatus, TripsReducerState, TripModel, DvaState } from '../_types'
import api from '../_dva_services'
import { pageState, pageModel, modalModel, modalState } from '../_utils'

const initStatus: TripStatus[] = [
  'NIL_TRIP_STATUS',
  'SUBMITTED',
  'VALID',
  'PICKING_UP',
  'WAITING_FOR_USER',
  'READY_FOR_USER',
  'STARTING_FOR_USER',
  'WAITING',
  'READY',
  'STARTING',
  'WAITING_FOR_PARK',
  'READY_FOR_PARK',
  'STARTING_FOR_PARK',
  'ON_ROUTE',
  'COMPLETED',
  'CANCELED',
]

const initState: TripsReducerState = {
  loading: false,
  statusList: initStatus,
  ...pageState,
  ...modalState,
}

const tripsModel: TripModel = modelExtend<TripModel>(pageModel, modalModel, {
  namespace: 'trips',
  state: initState,
  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(location => {
        if (pathMatchRegexp('/trips', location.pathname)) {
          dispatch({ type: 'query' })
        }
      })
    },
  },
  effects: {
    *query({ payload = {} }, { call, put, select }) {
      const trips = yield select((state: DvaState) => state.trips)
      let pagination = {
        current: trips.pagination.current,
        pageSize: trips.pagination.pageSize,
      }
      payload = {
        statusList: trips.statusList,
        pagination: pagination,
        ...payload,
      }
      const data = yield call(api.queryTripListByPage, payload)
      if (data) {
        yield put({
          type: 'querySuccess',
          payload: {
            list: data.trips,
            pagination: data.pagination,
          },
        })
      }
    },
    *queryBySerial({ payload = {} }, { call, put, select }) {
      const data = yield call(api.queryTripBySerial, payload)
      if (data) {
        const trips = yield select((state: DvaState) => state.trips)

        yield put({
          type: 'querySuccess',
          payload: {
            list: [data.trip],
            pagination: {
              ...trips.pagination,
              total: 1,
              current: 1,
            },
          },
        })
      }
    },
    *update({ payload = {} }, { call, put }) {
      const data = yield call(api.updateTrip, payload)
      if (data) {
        yield put({ type: 'query' })
        yield put({ type: 'hideModal' })
      }
    },
  },
})

export default tripsModel
