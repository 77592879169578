import React from 'react'
import './Target.scss'
import { message } from 'antd'
import { connect } from 'dva'
import { DvaState, TargetsReducerState, Target, ModalState } from '../../_types'
import { Dispatch } from 'redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import lodash from 'lodash'

import { Pagination } from '../../_types'
import { Header, List, Modal } from './components'

interface TargetsProps extends WithTranslation, TargetsReducerState, ModalState<Target> {
  dispatch: Dispatch
}

class Targets extends React.PureComponent<TargetsProps> {
  get searchProps() {
    const { dispatch, pool, search, t } = this.props

    return {
      item: search,
      pool: pool,
      t: t,
      onSearch: () => {
        dispatch({ type: 'targets/query' })
      },
      onChange: (fields: any) => {
        dispatch({
          type: 'targets/updateState',
          payload: {
            search: {
              ...search,
              ...lodash.mapValues(fields, o => ('value' in o ? o.value : o)),
            },
          },
        })
      },
      onClear: () => {
        dispatch({
          type: 'targets/reset',
        })
      },
    }
  }

  get listProps() {
    const { list, listingBags, dispatch, search, pagination, t } = this.props
    return {
      dataSource: list,
      pagination: pagination,
      loading: listingBags,
      sort: search.sort,
      t: t,
      sortDirections: ['descend', 'ascend'],
      onChange: (pagination: Pagination, filters: any, sorter: any) => {
        if (!sorter.order) {
          sorter.order = 'descend'
        }
        dispatch({
          type: 'targets/query',
          payload: {
            search: search,
            pagination: pagination,
            sort: sorter,
          },
        })
      },
      onCreate: () => {
        dispatch({
          type: 'targets/showModal',
          payload: {
            modalType: 'create',
            currentItem: {},
          },
        })
      },
      onEdit: (item: Target) => {
        dispatch({
          type: 'targets/showModal',
          payload: {
            modalType: 'update',
            currentItem: item,
          },
        })
      },
    }
  }

  get modalProps() {
    const { admin, dispatch, t, pool, modifying, currentItem, modalVisible, modalType } = this.props

    return {
      item: modalType === 'create' ? undefined : currentItem,
      pool: pool,
      visible: modalVisible,
      destroyOnClose: true,
      maskClosable: true,
      loading: modifying,
      modalType: modalType,
      title: modalType === 'create' ? t('btn.create') : t('btn.edit'),
      centered: true,
      t: t,
      onModify: (data: Target, action: string) => {
        let payload = {} as any
        switch (action) {
          case 'create':
            data.audit = { ...currentItem.audit, createdBy: admin ? admin.name : '' }
            payload = data
            break
          case 'update':
            data.audit = { ...currentItem.audit, updatedBy: admin ? admin.name : '' }
            payload = data
            break
        }
        dispatch({
          type: `targets/${action}`, // 通过文件名称+方法名调用方法
          payload,
        })
      },
      onCancel: () => {
        dispatch({
          type: 'targets/hideModal',
        })
      },
    }
  }

  render() {
    return (
      <div className="target">
        <div className="target-inner">
          <Header {...this.searchProps} />
        </div>
        <div className="target-inner">
          <List {...this.listProps} />
          <Modal {...this.modalProps} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: DvaState) => {
  // 此处关联modal是否可以弹出
  return {
    ...state.targets,
    admin: state.app.admin,
  }
}

export default connect(mapStateToProps)(withTranslation('targets')(Targets)) // 此处要和model里面的namespace保持一致
