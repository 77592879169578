import React from 'react'
import {Descriptions} from 'antd'

interface ListProps {
  t: any,
  dataSource:any
}
class BagTable extends React.PureComponent<ListProps> {
  render() {
    const { t, dataSource} = this.props
    return (
      <div className="info-descript">
        <Descriptions  bordered column={{ xs: 1, sm: 2, md: 4}}>
        <Descriptions.Item label="挖掘初筛bag数量">{dataSource.minedBagCntT}</Descriptions.Item>
        <Descriptions.Item label="挖掘初筛场景数量">{dataSource.minedScenarioCntT}</Descriptions.Item>
        <Descriptions.Item label="挖掘累计天数"  span={2}>{dataSource.minedDaysT}</Descriptions.Item>
        <Descriptions.Item label="分类成功场景数量">{dataSource.classifiedScenarioCntT}</Descriptions.Item>
        <Descriptions.Item label="精筛后场景数量">{dataSource.validScenarioCntT}</Descriptions.Item>
        <Descriptions.Item label="精筛后比例" span={2}>{dataSource.validOriginalP}</Descriptions.Item>
        <Descriptions.Item label="衍生出仿真场景数量">{dataSource.generatedSimulateScenarioCntT}</Descriptions.Item>
        <Descriptions.Item label="经过仿真评估数量">{dataSource.simulatedScenarioCntT}</Descriptions.Item>
        <Descriptions.Item label="仿真评估比例" span={2}>{dataSource.simulatedGeneratedP}</Descriptions.Item>
        <Descriptions.Item label="仿真评估成功数量">{dataSource.simulateSucceedCntT}</Descriptions.Item>
        <Descriptions.Item label="仿真评估成功率"  span={3}>{dataSource.simulateSucceedP}</Descriptions.Item>
        <Descriptions.Item label="评估后校验场景数量">{dataSource.verifiedScenarioCntT}</Descriptions.Item>
        <Descriptions.Item label="评估后校验比例" span={3}>{dataSource.verifiedScenarioP}</Descriptions.Item>
        <Descriptions.Item label="准确率">{dataSource.accuracyP}</Descriptions.Item>
        <Descriptions.Item label="精确率">{dataSource.precisionP}</Descriptions.Item>
        <Descriptions.Item label="召回率">{dataSource.recallP}</Descriptions.Item>
        <Descriptions.Item label="覆盖率">{dataSource.coverP}</Descriptions.Item>
      </Descriptions>
      </div>
    )
  }
}

export default BagTable
