import modelExtend from 'dva-model-extend'
import i18next from 'i18next'
import { Model } from 'dva'
import { message } from 'antd'
// @ts-ignore
import * as echarts from 'echarts';

import { pathMatchRegexp } from '../_helpers'
import { ScenarioReducerState } from '../_types/scenariosurvey.types'
import api from '../_dva_services'
import {pageState, pageModel, modalModel } from '../_utils'
import { pad } from 'lodash';
import { Console } from 'console';

const initState: any = {
  listingBags: false,
  ...pageState,
  scenariorData:{}
}

const scenariorModel = modelExtend<Model>(pageModel, modalModel, {
  namespace: 'scenarioList',
  state: initState,
  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(location => {
        if (pathMatchRegexp('/scenarioList', location.pathname)) {
          dispatch({ type: 'initPageData' })
        }
      })
    },
  },
  reducers: {
    initPageData() {
      return initState
    },
    listRequest(state, { }) {
      return {
        ...state,
        listingBags: true
      }
    },
    listSuccess(state, { }) {
      return {
        ...state,
        listingBags: false
      }
    },
    listFail(state, { }) {
      return {
        ...state,
        listingBags: false
      }
    }
  },
  effects: {
    *queryScenario({payload},{call,put,select}){
      const { pagination } = yield select((state: any) => state.scenarioList)
      try {
        yield put({ type: 'listRequest' })
        // 数据集统计数据
        const data = yield call(api.sListTotalList, {
          ...payload
        })
        if (data && data.items) {
          // 数据列表
          yield put({
            type: 'updateState',
            payload: {
              list: data.items,
              pagination: {
                ...pagination,
                ...data.pagination,
              },
            }
          })
         
        }
        yield put({ type: 'listSuccess' })

      } catch (e) {
        yield put({ type: 'listFail' })
        console.log(e)
      }
    },
    *queryScenarioDetail({payload},{call,put,select}){
      try {
        yield put({ type: 'listRequest' })
        // 数据集统计数据
        const data = yield call(api.sListTotalDetail, {
          ...payload
        })
        if (data && data.success) {
          console.log(data)
          // 详情数据
          yield put({
            type: 'updateState',
            payload: {
              scenariorData: data,
            }
          })
         
        }
        yield put({ type: 'listSuccess' })

      } catch (e) {
        yield put({ type: 'listFail' })
        console.log(e)
      }
    },
  },
})

export default scenariorModel
