import React from 'react'
import './Districts.scss'
import { connect } from 'dva'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Dispatch } from 'redux'

import { DvaState, DistrictsReducerState, District } from '../../_types'
import { DistrictList, DistrictModal } from './components'

interface DistrictsProps extends WithTranslation, DistrictsReducerState {
  districts: District[]
  dispatch: Dispatch
}

class Districts extends React.PureComponent<DistrictsProps> {
  get districtListProps() {
    const { dispatch, districts, listingDistricts, t } = this.props

    return {
      dataSource: districts,
      districts,
      loading: listingDistricts,
      t,
      onCreate: () => {
        dispatch({
          type: 'districts/showModal',
          payload: {
            modalType: 'create',
          },
        })
      },
      onEdit: (item: District) => {
        dispatch({
          type: 'districts/showModal',
          payload: {
            modalType: 'update',
            currentItem: item,
          },
        })
      },
    }
  }

  get DistrictModalProps() {
    const { districts, dispatch, currentItem, modalVisible, modalType, modifyingDistrict, t } = this.props

    return {
      item: modalType === 'create' ? undefined : currentItem,
      districts,
      visible: modalVisible,
      modalType,
      title: t(`${modalType}District`),
      loading: modifyingDistrict,
      destroyOnClose: true,
      maskClosable: true,
      centered: true,
      t,
      onModify: (data: any, action: string) => {
        dispatch({
          type: `districts/${action}`,
          payload: data,
        })
      },
      onCancel: () => {
        dispatch({
          type: 'districts/hideModal',
        })
      },
    }
  }

  render() {
    const { t } = this.props

    return (
      <div className="districts">
        <div className="districts-inner">
          <header className="districts-header">
            <h2>{t('districtsList')}</h2>
          </header>
          <DistrictList {...this.districtListProps} />
          <DistrictModal {...this.DistrictModalProps} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: DvaState) => ({
  ...state.districts,
})

export default connect(mapStateToProps)(withTranslation('districts')(Districts))
