import React from 'react'
import { Table, Tooltip, Button } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { epochToDate } from '../../../_helpers'

import { Fault, Pagination, Sort } from '../../../_types'

interface FaultListProps {
  t: any
  pagination: Pagination
  onChange: any
  sort: Sort
  onCreate: () => void
  onEdit: (item: Fault) => void
}

class FaultTable extends React.PureComponent<FaultListProps> {
  render() {
    const { t, onCreate, sort, onEdit, pagination, ...tableProps } = this.props
    const columns: ColumnProps<Fault>[] = [
      {
        title: t('vehicleName'),
        dataIndex: 'vehicleName',
        key: 'vehicleName',
      },
      {
        title: t('vehicleState'),
        dataIndex: 'vehicleState',
        key: 'vehicleState',
      },
      {
        title: t('faultTotalDuration')+'(min)',
        dataIndex: 'faultTotalDuration',
        key: 'faultTotalDuration',
    // 先把数组按照vehicleName排序，如果名称相同，累加时长，记录初始index，合并行，设置多余的行高为0
        render: (value, record, index) => {
          const obj = {
            children: value,
            props: {
              rowSpan: 1
            }
          }
          // if (index === 3) {
          //   obj.props.rowSpan = 2
          // }
          // if (index === 4) {
          //   obj.props.rowSpan = 0
          // }
          // 如果当前行等于需要合并的哪行下标，就让他合并应该合并的行数
          if(record.firstRowIndex === index) {
            obj.props.rowSpan = record.rowSpan
          }
          for (let indexs = 1; indexs < record.rowSpan; indexs++) {
            // console.log(indexs)
          // 被合并的行数，通过初始下标的累加，找到后面需要设置为空的行数
            if (index === record.firstRowIndex + indexs) {
              obj.props.rowSpan = 0
            }
          }
          return obj;
        }
      },
      {
        title: t('faultConditions'),
        dataIndex: 'faultConditions',
        key: 'faultConditions'
      },
      {
        title: t('faultTime'),
        dataIndex: 'faultTime',
        key: 'faultTime',
        render: (time: any) => epochToDate(time),
      },
      {
        title: t('issues'),
        dataIndex: 'fixIssue',
        key: 'fixIssue',
      },
      {
        title: t('fixTime'),
        dataIndex: 'fixTime',
        key: 'fixTime',
        render: (time: any) => epochToDate(time),
      },
      {
        title: t('faultStates'),
        dataIndex: 'faultState',
        key: 'faultState'
      },
      {
        title: t('faultDuration'),
        dataIndex: 'faultDuration',
        key: 'faultDuration',
      },
      {
        title: t('modeType'),
        dataIndex: 'modType',
        key: 'modType',
      },
      {
        title: t('priority'),
        dataIndex: 'priority',
        key: 'priority',
      },
      {
        title: t('followUpPerson'),
        dataIndex: 'followUpPerson',
        key: 'followUpPerson',
      },
      {
        title: t('createdBy'),
        dataIndex: 'createdBy',
        key: 'createdBy',
      },
      {
        key: 'action',
        fixed: 'right',
        title: (
          <Tooltip title={t('btn.create')} mouseEnterDelay={0.5}>
            <Button type="default" size="small" icon="plus" onClick={onCreate} />
          </Tooltip>
        ),
        render: (text: string, fault: Fault) => (
          <div className="bags-list-actions">
            <Button type="link" size="small" onClick={() => onEdit(fault)}>
              {t('btn.edit')}
            </Button>
          </div>
        ),
      },
    ]

    return (
      <div>
        <Table
          {...tableProps}
          pagination={{
            size: 'small',
            ...pagination,
          }}
          columns={columns}
          rowKey="id"
          scroll={{ x: true }}
        />
      </div>
    )
  }
}

export default FaultTable
